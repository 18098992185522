import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SharedModule} from '../shared/shared.module';
import {ListApiHistoryComponent} from './list-api-history/list-api-history.component';
import {ViewApiEntryComponent} from './view-api-entry/view-api-entry.component';
import {AuthGuard} from '../auth/auth.guard';
import {SecureLayoutComponent} from '../shared/secure-layout/secure-layout.component';
import {ApiAdminResolver} from './resolvers.service';
import {AdminGuard} from '../core/admin.guard';
import {EscapeHtmlPipe} from '../core/pipes/keep-html.pipe';
import {AgGridModule} from 'ag-grid-angular';
import {FilterApiStatusComponent} from './filter-grid-status/filter-grid-status.component';
import {ViewButtonRendererComponent} from './view-button-renderer/view-button-renderer.component';
import {DescriptionRendererComponent} from './description-renderer/description-renderer.component';
const routes: Routes = [
  {
    path: 'api-admin', component: SecureLayoutComponent, canActivateChild: [AuthGuard, AdminGuard], children: [
      {
        path: '',
        component: ListApiHistoryComponent,
        resolve: {
          apiHistory: ApiAdminResolver
        }
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    AgGridModule.withComponents([FilterApiStatusComponent, ViewButtonRendererComponent, DescriptionRendererComponent])
  ],
  exports: [],
  declarations: [
    ListApiHistoryComponent,
    ViewApiEntryComponent,
    FilterApiStatusComponent,
    ViewButtonRendererComponent,
    DescriptionRendererComponent
  ],
  providers: [
    ApiAdminResolver
  ]
})
export class ApiAdminModule {
}
