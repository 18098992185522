import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {FeedbackUserService} from '../../core/feedback-user.service';
import {Utility} from '../../core/utility';
import {AssessmentRequestProxyService} from '../../core/assessment-request-proxy.service';
import {ProxySubmitItem} from '../../core/interfaces/assessment-request-proxy.interface';
import { AssessmentRequestListItemModel } from '../assessment-request-list-item.model';
import { ConfirmationDialogComponent } from 'app/shared/confirmation-dialog/confirmation-dialog.component';
import {AttachmentService} from '../../core/attachment.service';
import {UploadErrorDialogComponent} from '../../shared/upload-error-dialog/upload-error-dialog.component';

@Component({
  selector: 'email-report-dialog',
  templateUrl: './email-report-dialog.component.html',
  styleUrls: ['./email-report-dialog.component.scss']
})
export class EmailReportDialogComponent implements OnInit {
  @Input() public attachmentId: number;
  @Input() public assessmentRequestId: number;
  public emails: string[];
  public inputFieldValue: string;
  private _modal: NgbModalRef;
  private AddAttempted = false;
  public errorWarn = false;
  public disableSendButton = false;
  constructor(public activeModal: NgbActiveModal,
              private modalService: NgbModal,
              private AttachmentService: AttachmentService) {
  }

  public validateEmail(input){
    let regEx = /^[a-zA-Z0-9!#$%&‘'*+\/=?^_`{|}~.-]+@[a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?(\.[a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?)+$/i;
    var result = regEx.test(input);
    return result;
  }

  public ngOnInit(): void {
    this.emails = [];
  }

  public onDismiss() {
    this.activeModal.dismiss();
  }

  public onKeyDown(event){
    if(this.AddAttempted || this.errorWarn)
      this.errorWarn = !this.validateEmail(this.inputFieldValue);
    if(event.key == "Enter" && !this.errorWarn){
      this.AddAttempted = true;
      this.onAdd();
    }  
  }

  public onAdd(){
    if(!this.validateEmail(this.inputFieldValue)){
      this.errorWarn = true;
      return;
    }
      
    //Check for duplicates
    if(this.emails.indexOf(this.inputFieldValue) < 0)
      this.emails.push(this.inputFieldValue);
    //One way or the other, clear the field.
    this.inputFieldValue = "";
    //And reset attempted
    this.AddAttempted = false;
    this.errorWarn = false;
  }

  public onRemove(email: string){
    this.emails = this.emails.filter(x => x != email);
  }

  public onSend() {
    //EmailReportToRecipients
    this.disableSendButton = true;
    this.AttachmentService.email(this.attachmentId, this.assessmentRequestId, this.emails).subscribe(
      x => { 
        this.activeModal.dismiss(); 
        this.disableSendButton = false;
      },
      error => {
        this._modal = this.modalService.open(UploadErrorDialogComponent);
        const errorDialogInstance = this._modal.componentInstance as UploadErrorDialogComponent;
        errorDialogInstance.title = 'Error';
        errorDialogInstance.body = `An error occurred while trying to Send Mail.
        <br/>
        <br/>Please try again or contact us at:
        <br/><a href='tel:314-993-8008'>314-993-8008</a> or <a href='mailto:testing@leadershipall.com'>testing@leadershipall.com</a>.
        <br/>`;
        errorDialogInstance.onConfirm.subscribe(
          (isConfirmed) => {
            if (isConfirmed) {
              this.activeModal.dismiss();
            }
        })
        this.disableSendButton = false;
      }
    );
  }
}
