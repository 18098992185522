<h1>Forgot your password?</h1>
<p class="text-muted">
  Enter your username or email and we will send you an email with instructions on how to reset your password.
</p>
<form novalidate [formGroup]="form" (ngSubmit)="submit()">
  <div class="form-group" [ngClass]="{'has-danger': usernameControl.touched && usernameControl.invalid}">
    <label for="username" class="form-control-label">Username or Email</label>
    <input type="text" class="form-control" id="username" formControlName="username" [ngClass]="{'form-control-danger': usernameControl.touched && usernameControl.invalid}"/>
    <div *ngIf="usernameControl.touched && usernameControl.hasError('required')" class="form-control-feedback">Username or Email is required</div>
  </div>
  <button type="submit" class="btn btn-primary" [disabled]="form.invalid">Submit</button>
</form>

