<div class="site-wrapper">
  <div class="left-nav-wrapper">
    <h1 class="logo">
      <a [routerLink]="['/']"><img src="../assets/la-logo.png" alt="Leadership Alliance"/></a>
      <span class="sr-only">Leadership Alliance</span>
    </h1>
    <div class="left-nav"></div>
  </div>
  <div class="content-wrapper">
    <header></header>
    <div class="action-bar"></div>
    <div class="content container-fluid">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
