<action-bar>
  <action-button (actionClick)="backToList()" [iconClass]="'fa fa-arrow-left'" [className]="'action-default'">Return to List</action-button>
  <action-button *ngIf="allowAssignConsultant" (actionClick)="assignClick()" [iconClass]="'fa fa-user-plus'" [className]="'action-primary'">Assign Consultant</action-button>
  <action-button (actionClick)="additionalServices()" [iconClass]="'fa fa-plus'" [className]="'action-primary'" [disabled]="!allowAdditionalServices">Additional Services</action-button>
  <action-group [title]="'Actions'">
    <action-button (actionClick)="newAssessment()" [iconClass]="'fa fa-plus'">New Assessment</action-button>
    <action-button *ngIf="allowStatusChange" (actionClick)="showStatusChangeDialog()" [iconClass]="'fa fa-edit'">Make In-Process</action-button>
    <action-button *ngIf="allowEdit" (actionClick)="editAssessment()" [iconClass]="'fa fa-edit'">Edit Assessment</action-button>
    <action-button *ngIf="allowDelete" (actionClick)="deleteAssessment()" [iconClass]="'fa fa-trash-o'" [className]="'text-danger'">Delete Assessment</action-button>
    <action-button *ngIf="allowAttachmentPurge" (actionClick)="purgeAttachments()" [iconClass]="'fa fa-user-times'" [className]="'text-danger'">Purge Attachments</action-button>
  </action-group>
  <action-group [title]="'Upload'" *ngIf="assessmentRequestPermissions.isAdmin()">
    <action-button [iconClass]="'fa fa-cloud-upload'" (actionClick)="uploadReports()" *ngIf="assessmentRequestPermissions.isAdmin()">Upload Reports</action-button>
    <!-- <action-button [iconClass]="'fa fa-cloud-upload'" (actionClick)="uploadResume()">Upload Resume</action-button>
    <action-button [iconClass]="'fa fa-cloud-upload'" (actionClick)="uploadJobDesc()">Upload Job Description</action-button> -->
  </action-group>
  <action-group [title]="'Download'">
    <action-button [iconClass]="'fa fa-cloud-download'" (actionClick)="downloadReports()" *ngIf="allowDownloadReports" [disabled]="disableDownloadReports()" >Download Reports</action-button>
    <action-button [iconClass]="'fa fa-cloud-download'" (actionClick)="downloadRequest()" *ngIf="allowDownloadAssessmentRequest" [disabled]="disableDownloadAssessementRequest()" >Download Request</action-button>
    <action-button [iconClass]="'fa fa-cloud-download'" (actionClick)="AttachmentService.download(model.resumeAttachmentId)" *ngIf="allowDownloadResume" [disabled]="disableDownloadResume()" >Download Resume</action-button>
    <action-button [iconClass]="'fa fa-cloud-download'" (actionClick)="AttachmentService.download(model.jobDescriptionAttachmentId)" *ngIf="allowDownloadJobDescription" [disabled]="disableDownloadDescription()">Download Job Description</action-button>
  </action-group>
  <div *ngIf="model.isConfidential" style="margin-right: 200px; float: right; cursor: initial;" class="action-object green-background"><span style="padding-right:8px;" class="fa fa-lock"></span>{{model.isConfidential?'Confidential':'Non-Confidential'}}</div>
</action-bar>

<ps-sections-container [showNav]="true">
  <!--              Client              -->
  <ps-section [sectionId]="'client-info'" [sectionTitle]="'Client'">
    <div class="row">
      <div class="col">
        <label class="form-control-label">Client Name</label>
        <div class="static-text">{{model.clientName}}</div>
      </div>
      <div class="col">
        <label class="form-control-label">Requested By</label>
        <div class="static-text">{{model.requestedByUserDisplayName}}</div>
      </div>
    </div>
    <div style="margin:0px;" class="row" *ngIf="settings">
      <div class="customField" *ngIf="settings.enableCostCenter">
        <label class="form-control-label">Cost Center</label>
        <div class="static-text">{{model.costCenterText}}</div>
      </div>
      <div class="customField" *ngIf="settings.enableCostCenterOwner">
          <label class="form-control-label">Cost Center Owner</label>
          <div class="static-text">{{model.costCenterOwner}}</div>
        </div>
      <div class="customField" *ngIf="settings.enableCompanyCode">
        <label class="form-control-label">Company Code</label>
        <div class="static-text">{{model.companyCode}}</div>
      </div>
      <div class="customField" *ngIf="settings.enableGeneralLedger">
        <label class="form-control-label">General Ledger</label>
        <div class="static-text">{{model.generalLedger}}</div>
      </div>
      <div class="customField" *ngIf="settings.enableActivityCenter">
        <label class="form-control-label">Activity Center</label>
        <div class="static-text">{{model.activityCenter}}</div>
      </div>
      <div class="customField" *ngIf="settings.enableCandidateId">
        <label class="form-control-label">Candidate Id</label>
        <div class="static-text">{{model.customCandidateId}}</div>
      </div>
      <div class="customField" *ngIf="settings.enablePo">
        <label class="form-control-label">Purchase Order</label>
        <div class="static-text">{{model.customPo}}</div>
      </div>
    </div>
  </ps-section>

  <!--              Candidate              -->
  <ps-section [sectionId]="'candidate-details'" [sectionTitle]="'Candidate'">
    <div class="card-accent">
      {{model.candidateType === "I" ? "Internal" : "External"}}
    </div>
    <div class="row">
      <div class="col">
        <label class="form-control-label">Name</label>
        <div class="static-text">{{model.candidateDisplayName}}</div>
      </div>
      <div class="col">
        <label class="form-control-label">Current Position</label>
        <div class="static-text">{{model.candidatePosition}}</div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label class="form-control-label">Email</label>
        <div class="static-text">{{model.candidateEmail}}&nbsp;
          <a *ngIf="model.candidateEmail" [attr.href]="'mailto:' + model.candidateEmail"><i
            class="fa fa-envelope-o"></i></a>
        </div>
      </div>
      <div class="col">
        <label class="form-control-label">Mobile Phone</label>
        <div class="static-text">
              <span *ngIf="model.candidateMobilePhone">
                 {{model.candidateMobilePhone}}&nbsp;
              <a [attr.href]="'tel:' + model.candidateMobilePhone"><i class="fa fa-phone"></i></a>
              </span>
          <span *ngIf="!model.candidateMobilePhone">
                None Provided
              </span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label class="form-control-label">Gender</label>
        <div class="static-text">{{model.candidateGender | gender}}</div>
      </div>
      <div class="col">
        <label class="form-control-label">Work Phone</label>
        <div class="static-text">
              <span *ngIf="model.candidateWorkPhone">
                 {{model.candidateWorkPhone}}&nbsp;
                  <a [attr.href]="'tel:' + model.candidateWorkPhone"><i class="fa fa-phone"></i></a>
              </span>
          <span *ngIf="!model.candidateWorkPhone">
                  None Provided
              </span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <label class="form-control-label">Language Preference</label>
        <div class="static-text">{{model.candidateLanguageName}}</div>
      </div>
      <div class="col-6">
        <label class="form-control-label">Resume Available</label>
        <div class="static-text">{{((model.candidateNoResume === false || model.candidateNoResume === null) && (model.resumeAttachmentId != null || model.resumeAttachmentId > 0)) | bool:"YN"}}</div>
      </div>
    </div>
  </ps-section>

  <!--              Assessment Purpose              -->
  <ps-section [sectionId]="'assessment-purpose'" [sectionTitle]="'Assessment Purpose'">
    <div class="card-accent">
      {{model.assessmentPurpose}}
    </div>
    <div class="row">
      <div class="col">
        <label class="form-control-label">Position Being Filled</label>
        <div class="static-text">{{model.positionDescription}}</div>
      </div>
      <div class="col">
        <label class="form-control-label">Position Level</label>
        <div class="static-text">{{model.positionLevelName}}</div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label class="form-control-label">Requesting Manager</label>
        <div class="static-text" *ngIf="!model.requestingManager">None provided</div>
        <div class="static-text" *ngIf="model.requestingManager">{{model.requestingManager}}</div>
      </div>
      <div class="col">
        <label class="form-control-label">Position Reports To</label>
        <div class="static-text">{{model.positionReportsTo}}</div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <label class="form-control-label">Number of Direct Reports</label>
        <div class="static-text">{{model.numberOfDirectReports}}</div>
      </div>
    </div>
  </ps-section>

  <!--              Position Competencies              -->
  <ps-section [sectionId]="'position-competencies'" [sectionTitle]="'Position Competencies'">
    <div class="row">
      <div class="col">
        <label class="form-control-label">Competency List</label>
        <competency-list *ngIf="model.competencies.length" [columns]="2" [competencies]="model.competencies"></competency-list>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label class="form-control-label">Custom Competency</label>
        <div *ngIf="model.customCompetencies" class="static-text">
          {{model.customCompetencies}}
        </div>
        <div *ngIf="!model.customCompetencies" class="static-text">
          None Provided
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label class="form-control-label">Questions/Concerns/Comments</label>
        <div *ngIf="model.purposeNotes" class="static-text">
          {{model.purposeNotes}}
        </div>
        <div *ngIf="!model.purposeNotes" class="static-text">
          None Provided
        </div>
      </div>
    </div>

  </ps-section>

  <!--              Services Requested              -->
  <ps-section [sectionId]="'services-requested'" [sectionTitle]="'Services Requested'">
    <div class="row">
      <div class="col">
        <label class="form-control-label">Assessment Level Requested</label>
        <div class="static-text">{{model.assessmentLevelRequested}}</div>
      </div>
      <div class="col">
        <label class="form-control-label">Additional Services Required</label>
        <div *ngIf="model.additionalServices.length > 0" class="static-text">
          <div *ngFor="let service of model.additionalServices">{{service.additionalService}}</div>
        </div>
        <div *ngIf="!model.additionalServices || model.additionalServices.length === 0" class="static-text">
          No Additional Services Required
        </div>
      </div>
    </div>
  </ps-section>

  <!--              Feedback Recipients              -->
  <ps-section [sectionId]="'feedback-recipients'" [sectionTitle]="'Feedback Recipients'">
    <div class="row">
      <div class="col">
        <label class="form-control-label">Recipients List</label>
        <div *ngIf="model.feedbackRecipients.length > 0">
          <table class="table table-sm">
            <tr *ngFor="let recipient of model.feedbackRecipients" class="static-text">
              <td>{{recipient.name}}</td>
              <td>Email:&nbsp;{{recipient.email}}</td>
              <td>Phone:&nbsp;{{recipient.phoneWork}}</td>
              <td>Verbal:&nbsp;{{recipient.verbal | bool:"YN"}}</td>
              <td>Written:&nbsp;{{recipient.written | bool:"YN"}}</td>
            </tr>
          </table>
        </div>
        <div *ngIf="!model.feedbackRecipients || model.feedbackRecipients.length === 0" class="static-text">
          No Feedback Recipients
        </div>
      </div>
    </div>
  </ps-section>

  <!--              Testing Location              -->
  <ps-section [sectionId]="'testing-location'" [sectionTitle]="'Testing Location'">
    <div class="row">
      <div class="col">
        <label class="form-control-label">Location</label>
        <div class="static-text">{{model.testingLocation}}</div>
      </div>
      <div class="col">
        <label class="form-control-label">Can Leadership Contact Candidate</label>
        <div class="static-text">{{model.canContactCandidate | bool:"YN"}}</div>
      </div>
    </div>
  </ps-section>

  <!--              Billing Contact              -->
  <ps-section [sectionId]="'billing-contact'" [sectionTitle]="'Billing Contact'">
    <div class="row">
      <div class="col">
        <label class="form-control-label">Name</label>
        <div class="static-text">{{model.billingContactDisplayName}}</div>
      </div>
      <div class="col">
        <label class="form-control-label">Email</label>
        <div class="static-text">{{model.billingContactEmail}}</div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label class="form-control-label">Address</label>
        <address class="static-text">
          {{model.billingContactAddress}}<br/>
          <span *ngIf="model.billingContactAddress2">{{model.billingContactAddress2}}<br/></span>
          {{model.billingContactCity}},&nbsp;{{(model.billingContactCountryId == 196)?model.billingContactState:model.billingContactProvince}}&nbsp;{{model.billingContactZipCode}}<br/>
          {{model.billingContactCountry}}
        </address>
      </div>
      <div class="col">
        <label class="form-control-label">Phone</label>
        <div class="static-text">{{model.billingContactPhone}}</div>
      </div>
    </div>
  </ps-section>

  <!--                Testing Schedule                -->
  <ps-section [sectionId]="'testing-schedule'" [sectionTitle]="'Testing Schedule'" *ngIf="showTestingSchedule">
    <div class="row">
      <div class="col">
        <label class="form-control-label">Testing Date</label>
        <div class="static-text">{{formatDate(model.testingDate)}}</div>
      </div>
      <div class="col">
        <label class="form-control-label">Interview Date</label>
        <div class="static-text">{{formatDate(model.interviewDate)}}</div>
      </div>
      <div class="col">
          <label class="form-control-label">Feedback Date</label>
          <div class="static-text">{{formatDate(model.feedbackDate)}}</div>
        </div>
    </div>
  </ps-section>
</ps-sections-container>

<div class="card" *ngIf="false">
  <div class="card-header">Debugging</div>
  <div class="card-body">
    <pre><code>{{model | json}}</code></pre>
  </div>
</div>


