export class ChangePasswordRequest {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export class ChangePasswordResponse {
  success: boolean;
  message: string;
}
