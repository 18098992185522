
import {defer as observableDefer, Observable} from 'rxjs';
import {map, publishReplay, refCount, take} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ServiceBase} from './service-base';
import {AuthHttpService} from '../auth/auth-http.service';
import {IAssessmentLevelReferenceModel} from './interfaces/assessment-level-reference.interface';
import {AssessmentRequestListItemModel} from '../assessment-requests/assessment-request-list-item.model';
import {ISelectItemModel} from './interfaces/select-item.interface';
import { HttpClient} from '@angular/common/http';

@Injectable()
export class AssessmentLevelReferenceService extends ServiceBase {
  private static ApiUrl = 'api/assessment-level-reference';
  private data$: Observable<IAssessmentLevelReferenceModel[]>;

  constructor(private http: HttpClient) {
    super();
  }

  public getList(): Observable<IAssessmentLevelReferenceModel[]> {
    if (!this.data$) {
      this.data$ = observableDefer(() => this.http.get(AssessmentLevelReferenceService.ApiUrl)).pipe(
        map((res: Response) => (res || []) as IAssessmentLevelReferenceModel[]),
        publishReplay(1),
        refCount(),
        take(1)
      );

    }

    return this.data$;
  }

  public get(id: number): Observable<AssessmentRequestListItemModel> {
    const url = `${AssessmentLevelReferenceService.ApiUrl}/${id}`;
    return this.http.get<AssessmentRequestListItemModel>(url);
  }

  public getTypes(): Observable<ISelectItemModel[]> {
    const url = `${AssessmentLevelReferenceService.ApiUrl}/list`;
    return this.http.get<ISelectItemModel[]>(url);
  }
}
