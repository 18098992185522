import {DataSource} from './data-source';
import {LocalFilter, LocalPager, LocalSorter} from './local-helpers';
import {IFilterConfig} from '../models';

export class LocalDataSource extends DataSource {
  protected data: Array<any> = [];
  protected filteredAndSorted: Array<any> = [];
  protected sortConf: Array<any> = [];
  protected quickQuery: string;
  protected pagingConf = {};
  protected filterConfigs: IFilterConfig[] = [];

  constructor(data: Array<any> = []) {
    super();

    this.data = data;
  }

  public load(data: Array<any>): Promise<any> {
    this.data = data;
    return super.load(data);
  }

  public remove(element: any): Promise<any> {
    this.data = this.data.filter(el => el !== element);

    return super.remove(element);
  }

  public getElements(): Promise<any> {
    const data = this.data.slice(0);
    return Promise.resolve(this.prepareData(data));
  }

  public getAll(): Promise<any> {
    const data = this.data.slice(0);
    return Promise.resolve(data);
  }

  public reset(silent = false): void {
    if (silent) {
      this.quickQuery = null;
      this.sortConf = [];
      this.pagingConf['page'] = 1;
    } else {
      this.setQuickFilter(null, false);
      this.setSort([], false);
      this.setPage(1);
    }
  }

  public empty(): Promise<any> {
    this.data = [];

    return super.empty();
  }

  public count(): number {
    return this.filteredAndSorted.length;
  }

  /**
   *
   * Array of conf objects
   * [
   *  {field: string, direction: asc|desc|null, compare: Function|null},
   * ]
   * @param conf
   * @param doEmit
   * @returns {LocalDataSource}
   */
  public setSort(conf: Array<any>, doEmit = true): LocalDataSource {
    if (conf !== null) {

      conf.forEach((fieldConf) => {
        if (!fieldConf['field'] || typeof fieldConf['direction'] === 'undefined') {
          throw new Error('Sort configuration object is not valid');
        }
      });
      this.sortConf = conf;
    }

    super.setSort(conf, doEmit);
    return this;
  }

  /**
   *
   * Array of conf objects
   * [
   *  {field: string, search: string, filter: Function|null},
   * ]
   * @param conf
   * @param andOperator
   * @param doEmit
   * @returns {LocalDataSource}
   */
  public setQuickFilter(query: string, doEmit = true): LocalDataSource {
    this.pagingConf['page'] = 1;

    super.setQuickFilter(query, doEmit);
    return this;
  }

  public addQuickFilter(query: string, doEmit: boolean = true): LocalDataSource {
    if (!query) {
      query = '';
    }
    this.quickQuery = query;
    super.addQuickFilter(query, doEmit);
    return this;
  }

  public setFilter(conf: IFilterConfig[], doEmit = true): LocalDataSource {
    if (conf && conf.length > 0) {
      conf.forEach((fieldConf) => {
        this.addFilter(fieldConf, false);
      });
    } else {
      this.filterConfigs = [];
    }
    this.pagingConf['page'] = 1;

    super.setFilter(conf, doEmit);
    return this;
  }

  public addFilter(query: IFilterConfig, doEmit: boolean = true): LocalDataSource {
    let found = false;
    this.filterConfigs.forEach((c, index) => {
      if (c.field === query.field) {
        this.filterConfigs[index] = query;
        found = true;
      }
    });
    if (!found) {
      this.filterConfigs.push(query);
    }
    super.addFilter(query, doEmit);
    return this;
  }

  public addFilters(filterConfigs: IFilterConfig[], doEmit: boolean = true): LocalDataSource {
    filterConfigs.forEach(e => {
      const found = this.filterConfigs.findIndex((f) => {
        return f.field === e.field;
      });
      if (found > -1) {
        this.filterConfigs[found] = e;
      } else {
        this.filterConfigs.push(e);
      }
    });
    super.addFilters(filterConfigs, doEmit);
    return this;
  }

  public removeFilter(filter: IFilterConfig, doEmit: boolean = true): LocalDataSource {
    const idx = this.filterConfigs.findIndex(e => {
      return e.field.toLowerCase() === filter.field.toLowerCase();
    });
    if (idx === -1) {
      return this;
    }
    this.filterConfigs.splice(idx, 1);
    super.removeFilter(filter, doEmit);
    return this;
  }

  public removeFilters(filterConfigs: IFilterConfig[], doEmit: boolean = true): LocalDataSource {
    this.filterConfigs = this.filterConfigs.filter((e) => {
      const idx = filterConfigs.findIndex(f => {
        return f.field.toLowerCase() === e.field.toLowerCase();
      });
      return idx === -1;
    });

    super.removeFilters(filterConfigs, doEmit);
    return this;
  }


  public clearFilters(doEmit: boolean = true): LocalDataSource {
    this.filterConfigs = [];
    super.clearFilters(doEmit);
    return this;
  }

  public setPaging(page: number, perPage: number, doEmit: boolean = true): LocalDataSource {
    this.pagingConf['page'] = page;
    this.pagingConf['perPage'] = perPage;

    super.setPaging(page, perPage, doEmit);
    return this;
  }

  public setPage(page: number, doEmit: boolean = true): LocalDataSource {
    this.pagingConf['page'] = page;
    super.setPage(page, doEmit);
    return this;
  }

  public getSort(): any {
    return this.sortConf;
  }

  public getQuickFilter(): any {
    return this.quickQuery;
  }

  public getPaging(): any {
    return this.pagingConf;
  }

  public getFilterConfigs(): IFilterConfig[] {
    return this.filterConfigs;
  }

  protected prepareData(data: Array<any>): Array<any> {
    data = this.filter(data);
    data = this.quickFilter(data);
    data = this.sort(data);
    this.filteredAndSorted = data.slice(0);

    return this.paginate(data);
  }

  protected sort(data: Array<any>): Array<any> {
    if (this.sortConf) {
      this.sortConf.forEach((fieldConf) => {
        data = LocalSorter
          .sort(data, fieldConf['field'], fieldConf['direction'], fieldConf['compare']);
      });
    }
    return data;
  }

  // TODO: refactor?
  protected quickFilter(data: Array<any>): Array<any> {
    if (this.quickQuery) {
      let mergedData = [];
      mergedData = mergedData.concat(LocalFilter.quickFilter(data, this.quickQuery));

      // remove non unique items
      data = mergedData.filter((elem, pos, arr) => {
        return arr.indexOf(elem) === pos;
      });
    }

    return data;
  }

  protected filter(data: Array<any>): Array<any> {
    if (this.filterConfigs) {
      this.filterConfigs.forEach((c) => {
        data = LocalFilter.filter(data, c.field, c.search, c.filter);
      });
    }
    return data;
  }

  protected paginate(data: Array<any>): Array<any> {
    if (this.pagingConf && this.pagingConf['page'] && this.pagingConf['perPage']) {
      data = LocalPager.paginate(data, this.pagingConf['page'], this.pagingConf['perPage']);
    }
    return data;
  }
}
