import {Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {PsOptionsComponent} from '../ps-options/ps-options';
import {AutoCompleteSelectSettings} from '../ps-autocomplete-settings';
import {Subject} from 'rxjs';
import {DOWN_ARROW, END, ENTER, HOME, RIGHT_ARROW, TAB, UP_ARROW} from '../../core/keyboard/keycodes';
import {PsListItem} from '../../core/listitem/ps-list-item';

let _uniqueAutoCompleteId = 0;

@Component({
  selector: 'ps-autocomplete',
  templateUrl: './ps-autocomplete.html',
  styleUrls: ['../styles/ps-autocomplete.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PsAutoCompleteComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => PsAutoCompleteComponent),
    multi: true,
  }]
})
export class PsAutoCompleteComponent implements OnInit {
  @Input() settings: AutoCompleteSelectSettings;
  @Input() placeHolderText: string;
  @Input() selectList: PsListItem[];
  @Output() selectionChanged: EventEmitter<PsListItem> = new EventEmitter<PsListItem>();
  @ViewChild(PsOptionsComponent) optionComp: PsOptionsComponent;
  @ViewChild('filterInput') filterInputElem: ElementRef;

  listHidden = true;
  filtered: PsListItem[];
  data: string;
  id = `ps-autocomplete-${_uniqueAutoCompleteId++}`;

  private _tabOut: Subject<any> = new Subject();

  defaultSettings: AutoCompleteSelectSettings = {
    itemsToShow: 100,
    showOnFocus: true,
    hideOnBlur: true,
    listHeight: 256,
    location: 'bottom'
  } as AutoCompleteSelectSettings;

  private propagateChange = (v: any) => {
  };

  ngOnInit(): void {
    this.initSettings();
    this.filtered = this.selectList;
  }

  writeValue(val: string): void {
    this.data = !val
      ? ''
      : val;
    this.propagateChange(val);
  }

  writeEmptyValue() {
    this.propagateChange(null);
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {

  }

  validate(c: UntypedFormControl): { [key: string]: any } {
    return null;
  }

  focusInput() {
    this.filterInputElem.nativeElement.value = !this.data
      ? ''
      : this.data;
    this.filterInputElem.nativeElement.focus();
  }

  clear() {
    this.optionComp.reset();
    this.writeEmptyValue();
    this.focusInput();
  }

  onChange(event) {
    const term = event.target.value;
    this.writeValue(term);
  }

  onFocus(event) {
    if (this.settings.showOnFocus) {
      const term = event.target.value;
      this.filtered = this.filterList(term);
      this.openPanel();
    }
  }

  onBlur(event) {
    if (!event) {
      return;
    }
    const relatedTarget = event.relatedTarget;
    if (!relatedTarget) {
      return;
    }
    const dataType = relatedTarget.getAttribute('data-type');
    if (dataType && dataType.toLowerCase() === 'select-option') {
      return;
    }
    if (this.settings.hideOnBlur) {
      this.closePanel();
    }
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.keyCode === ENTER || event.keyCode === RIGHT_ARROW) {
      this.selectActiveItem();
    } else {
      this.handleKeyDown(event);
      if (event.keyCode === UP_ARROW || event.keyCode === DOWN_ARROW) {
        this.openPanel();
      }
    }
  }

  onKeyUp(event) {
    if (event.keyCode === UP_ARROW || event.keyCode === DOWN_ARROW) {
      return;
    }
    const term = event.target.value;
    this.data = term;
    this.filtered = this.filterList(term);
  }

  onSelectionChanged(item: PsListItem) {
    this.writeValue(item.name);
    this.selectionChanged.emit(item);
    this.closePanel();
  }

  private openPanel() {
    this.listHidden = false;
  }

  private closePanel() {
    this.listHidden = true;
  }

  private selectActiveItem() {
    this.optionComp.selectActiveItem();
  }

  private handleKeyDown(event: KeyboardEvent) {
    switch (event.keyCode) {
      case DOWN_ARROW:
        this.optionComp.setNextItemActive();
        break;
      case UP_ARROW:
        this.optionComp.setPrevItemActive();
        break;
      case HOME:
        this.optionComp.setFirstItemActive();
        break;
      case END:
        this.optionComp.setLastItemActive();
        break;
      case TAB:
        // Note that we shouldn't prevent the default action on tab.
        this._tabOut.next(null);
        return;
      default:
        return;
    }

    event.preventDefault();
  }

  private filterList(term: string = null): PsListItem[] {
    if (term == null || term.length === 0) {
      return this.selectList.slice(0, this.settings.itemsToShow);
    } else {
      return this.selectList.filter((c) => new RegExp(term, 'gi').test(c.name)).splice(0, this.settings.itemsToShow);
    }
  }

  private initSettings() {
    if (!this.settings) {
      this.settings = this.defaultSettings;
    }
  }
}
