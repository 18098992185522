import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ICompetencySetListItem} from '../../core/interfaces';

@Component({
  selector: 'add-competency-set',
  templateUrl: './add-competency-set.component.html'
})
export class AddCompetencySetComponent implements OnInit {
  public addSetForm: UntypedFormGroup;
  private addSetModel: ICompetencySetListItem;

  constructor(private builder: UntypedFormBuilder) {

  }

  public ngOnInit() {
    this.addSetForm = this.buildForm();
  }

  private buildForm(): UntypedFormGroup {
    return this.builder.group({
      name: this.builder.control({  })
    })
  }

}
