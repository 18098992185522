<div [formGroup]="form" *ngIf="form">
  <ps-sections-container [showNav]="false">
    <ps-section formGroupName="position" [sectionId]="'position'" [sectionTitle]="title">
      <div class="form-group row">
        <ps-input-validation class="col-3">
          <label class="form-control-label" for="position-level">Level *</label>
          <select psInput class="form-control" id="position-level" formControlName="positionLevel">
            <option [value]="null"></option>
            <option *ngFor="let positionLevel of positionLevelNames" [value]="positionLevel">{{positionLevel}}</option>
          </select>
        </ps-input-validation>
        <div class="col-3">
          <div style="margin-top:22px; display:inline-block;" ngbTooltip="{{position.isConfidential ? 'The position has been marked confidential, and cannot be viewed by any other User': (position.positionId ? 'To mark this confidential, please contact Leadership Alliance at 314-993-8008.' : 'Make Confidential')}}" container="body">
            <button type="button" class="btn btn-sm btn-confidential button-right" (click)="onConfidential()" [attr.disabled]="previousConfidentialState || position.positionId || copy || userLevel == 1 || confidential == true || confidential === false ? 'true' : null">
              <span style="padding-right:8px;" [ngClass]="position.isConfidential?'fa fa-lock':'fa fa-unlock'"></span> {{position.isConfidential ? 'Confidential' : (position.positionId ? 'Not Confidential' : 'Set Confidential')}}
            </button>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <ps-input-validation>
          <label class="form-control-label" for="position-function">Function *</label>
          <select psInput class="form-control" id="position-function" formControlName="positionFunction">
            <option [value]="null"></option>
            <option *ngFor="let positionFunction of positionFunctionNames" [value]="positionFunction">{{positionFunction}}</option>
          </select>
        </ps-input-validation>
      </div>
      <div class="form-group row">
        <ps-input-validation>
          <label class="form-control-label" for="position-description">Position *</label>
          <input id="position-description" psInput class="form-control" formControlName="description">
        </ps-input-validation>
      </div>
      <div class="form-group row">
        <ps-input-validation>
          <label class="form-control-label" for="position-orgunit">Department *</label> 
          <select [attr.disabled]="readOnlyPosition ? 'true' : null" psInput class="form-control" id="position-orgunit" formControlName="positionOrgUnit">
            <option *ngFor="let positionOrgUnit of userOrgUnits" [value]="positionOrgUnit.id">{{positionOrgUnit.name}}</option>
          </select>
        </ps-input-validation>
      </div>
      <br>
      <div class="form-group row">
        <div class="col">
          <label class="btn btn-sm btn-secondary btn-file">
            <span class="fa fa-cloud-upload"></span> Upload Job Description <input type="file" hidden (change)="onUploadJobDescriptionFileChange($event)" accept=".pdf,.doc,.docx">
          </label>
        </div>
        <div class="col" [hidden]="!jobDescriptionAttachmentIdControl.value">
            <a (click)="attachmentService.download(jobDescriptionAttachmentIdControl.value)" class="btn btn-sm btn-secondary"><span class="fa fa-cloud-download"></span> {{fileName}}</a>
        </div>
        <div class="col">
          <ps-input-validation>
            <label class="form-check-label">
              <input psInput id="position-noJobDescription" type="checkbox" class="form-check-input" formControlName="noJobDescription">
              No Job Description
            </label>
          </ps-input-validation>
        </div>
      </div>
    </ps-section>
  </ps-sections-container>
  <div class="row">
    <div class="col">
      <div class="card">
        <h4 class="card-header">
          Position Competencies
        </h4>
        <div class="card-body">
          <assign-competencies
            [availableCompetencies]="position.clientCompetencies"
            [assignedCompetencies]="position.competencies"
            (selectedCompetencyChanged)="onSelectedCompetenciesChanged($event)">
          </assign-competencies>
          <div class="form-group row space-above" formGroupName="position">
            <ps-input-validation>
              <label class="form-control-label" for="custom-competencies">Custom Competencies</label>
              <input psInput id="custom-competencies" type="text" class="form-control" formControlName="customCompetency">
            </ps-input-validation>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="alert alert-info" style="margin-top: 1rem;" *ngIf="false">
  <pre><code>{{ form.value | json }}</code></pre>
</div>
