import {Component, ContentChildren, Input, QueryList} from '@angular/core';
import {PsInputValidation} from '../ps-input-validation/ps-input-validation';

@Component({
  selector: 'ps-section',
  templateUrl: './ps-section.html'
})
export class PsSectionComponent {
  @Input() sectionId: string;
  @Input() sectionTitle: string;
  @Input() hideInNav = false;
  @Input() showInvalid = false;
  @ContentChildren(PsInputValidation, { descendants: true })
  private inputValidations: QueryList<PsInputValidation>;

  public get showingErrors(): boolean {
    let showing: boolean;
    for (const val of this.inputValidations.toArray()) {
      if (!val.showError) {
        continue;
      }
      showing = true;
    }
    return showing || this.showInvalid;
  }
}
