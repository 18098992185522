import { AbstractControl, FormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';

export module PsValidators {

  export function hasFeedbackUsers(group: UntypedFormGroup) {
    const fg = group.value;
    // if (fg.feedbackUsers && fg.feedbackUsers.length) {
    //   return null;
    // }
    // return {
    //   notPresent: true
    // };

    var foundVerbal: boolean = false;

    if (fg.feedbackUsers != null) {
      fg.feedbackUsers.forEach((r) => {
        if(r.verbal)
          foundVerbal = true;
      });
      if (!foundVerbal)
      return {
        notPresent: true
      };
      else
        return null;
    } 
    else
    return {
        notPresent: true
      };
  }

  export function hasBillingContact(group: UntypedFormGroup) {
    const fg = group.value; 
    if (fg != null && fg.billingContactId != null)
      return null;
    else
    return {
      notPresent: true
    };
  }

  export function hasCompetencies(group: UntypedFormGroup) {
    const fg = group.value;
    if (fg.competencies) {
      if (fg.competencies.length) {
        return null;
      }
    }
    return {
      notPresent: true
    };
  }

}
