<action-bar>
  <action-button [className]="'action-danger'" [iconClass]="'fa fa-ban'" (actionClick)="onClickCancel()">Cancel</action-button>
  <action-button [className]="'action-primary'" [iconClass]="'fa fa-floppy-o'" (actionClick)="onClickSave()" [disabled]="!allowSave">Save</action-button>
</action-bar>

<div class="card">
  <h4 class="card-header">Testing Schedule {{(candidateName ? (" : "+ candidateName) : "") }}</h4>
  <div class="card-body">
    <form [formGroup]="form">
      <div class="form-group row">
        <div class="col-sm-4">
          <div style="max-width:300px;">
            <label class="form-control-label font-weight-bold">Testing Date/Time</label>
            <ngb-datepicker formControlName="testingDate" [firstDayOfWeek]="0"></ngb-datepicker>
            <button class="clearButton" (click)="clearTesting()">Clear</button>
            <ngb-timepicker [minuteStep]="15" [meridian]="true" formControlName="testingTime"></ngb-timepicker>
          </div>
        </div>
        <div class="col-sm-4">
          <div style="max-width:300px;">
            <label class="form-control-label font-weight-bold">Interview Date/Time</label>
            <ngb-datepicker formControlName="interviewDate" [firstDayOfWeek]="0"></ngb-datepicker>
            <button class="clearButton" (click)="clearInterview()">Clear</button>
            <ngb-timepicker [minuteStep]="15" [meridian]="true" formControlName="interviewTime"></ngb-timepicker>
          </div>
        </div>
        <div class="col-sm-4">
          <div style="max-width:300px;">
            <label class="form-control-label font-weight-bold">Feedback Date/Time</label>
            <ngb-datepicker formControlName="feedbackDate" [firstDayOfWeek]="0"></ngb-datepicker>
            <button class="clearButton" (click)="clearFeedback()">Clear</button>
            <ngb-timepicker [minuteStep]="15" [meridian]="true" formControlName="feedbackTime"></ngb-timepicker>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <div class="col">
          <label class="form-control-label" for="testing-notes">Notes</label>
          <textarea class="form-control" id="testing-notes" rows="5" formControlName="notes"></textarea>
        </div>
      </div>
      <div class="form-group row">
        <div class="col">
          <label class="form-control-label" for="email-template">Testing Schedule Decision</label>
          <select class="form-control" id="email-template" formControlName="emailTemplate">
            <option *ngFor="let emailTemplate of emailTemplates" [ngValue]="emailTemplate">{{emailTemplate.name}}</option>
          </select>
        </div>
      </div>
      <div class="form-group row">
        <div class="col">
          <div class="form-check">
            <label class="form-check-label">
              <input class="form-check-input" type="checkbox" formControlName="includeNote"> Include Note in Email
            </label>
          </div>
        </div>
      </div>
    </form>
    <div class="row" *ngIf="false">
      <div class="col">
        <pre><code>{{formValue | json}}</code></pre>
        <div>
          <strong>Touched:</strong> {{form.touched}}
        </div>
        <div>
          <strong>Dirty:</strong> {{form.dirty}}
        </div>
        <div>
          <strong>Valid:</strong> {{form.valid}}
        </div>
      </div>
    </div>
  </div>
</div>
