<div class="modal-header">
  <h5 class="modal-title">{{title}}</h5>
  <span class="close-icon" aria-label="Close" (click)="onClickCancel()" style="cursor: pointer;">
    &times;
  </span>
</div>
<div class="modal-body">
  <div class="container">
    <div class="form-group row">
      <label class="form-control-label">Report Type</label>
      <select class="form-control" class="form-control" [(ngModel)]="reportType">
        <option *ngFor="let reportType of reportTypes" [ngValue]="reportType">{{reportType.name}}</option>
      </select>
    </div>
    <div class="form-group row">
      <label class="form-control-label">Description</label>
      <input class="form-control" type="text" [(ngModel)]="description"/>
    </div>
  </div>
</div>
<div class="modal-footer">
  <label class="btn btn-primary btn-file" [ngClass]="{'disabled': !reportType}">
    <span class="fa fa-cloud-upload"></span> Select File to Upload <input type="file" hidden (change)="onClickUpload($event)" [disabled]="!reportType" accept=".pdf,.doc,.docx">
  </label>
</div>
