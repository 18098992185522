import { Component, ViewChild, ViewContainerRef} from '@angular/core';
import {IFilterAngularComp} from 'ag-grid-angular';
import {IAfterGuiAttachedParams, IDoesFilterPassParams, IFilterParams, RowNode} from "ag-grid-community";
@Component({
  selector: 'app-filter-grid-status',
  templateUrl: './filter-grid-status.component.html',
  styleUrls: ['./filter-grid-status.component.scss']
})

export class FilterUsersStatusComponent implements IFilterAngularComp  {

  private params: IFilterParams;
  public text = '';
  public showAll = true;        // = true;
  public active: boolean;         // = false;
  public inactive: boolean;       // = false;

  agInit(params: IFilterParams): void {
    this.params = params;
  }

  isFilterActive(): boolean {
    // The only way the filter doesn't need to be active (FALSE, i.e. no filter) is if showAll is selected.
    return !(this.showAll)
  }

  // Returns bool to indicate whether the row will appear in result list.
  doesFilterPass(params: IDoesFilterPassParams): boolean {
    switch (params.node.data.isActive) {
      case true:
        return this.active || this.showAll;
      case false:
        return this.inactive || this.showAll;
      default:
        return true;
    }
  }

  getModel(): any {
    return {showAll: this.showAll, notSubmitted: this.active, submitted: this.inactive};
  }

  setModel(model: any): void {
    // Not much effort to ensure this is a 'valid' state coming in but... it can only go so far wrong.
    if (model != null) {
      this.showAll = model.showAll || false;
      this.active = model.notSubmitted || false;
      this.inactive = model.submitted || false;
    } else {
      this.showAll = true;
    }
  }

  onChange(event): void {
    // If the option was enabled
    if (event.target.checked) {
      // and it was showAll
      if (event.target.id !== 'showAll') {
        // turn showAll off
        this.showAll = false;
      }
      // If we turned showAll on, we need to uncheck the others
      if (event.target.id === 'showAll') {
        this.active = false;
        this.inactive = false;
      }
      if (event.target.id === 'active' && this.inactive) {
        this.inactive = false;
      }
      if (event.target.id === 'inactive' && this.active) {
        this.active = false;
      }
    } else {
      if (!this.active && !this.inactive) {
        this.showAll = true; // If all other filters are false, we should probably turn showAll back on (unlikely the user actually wants to see nothing.)
        if (event.target.id === 'showAll') { // Also, If this is coming in on the showAll checkbox, we need to set the target too!
          event.target.checked = true;
        }
      }
    }
    this.params.filterChangedCallback();
  }
}
