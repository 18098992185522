import {Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChange, ViewEncapsulation} from '@angular/core';
import {ICompetencyNode} from '../competency-node.interface';
import {ICompetencyModel} from '../../core/interfaces/competency.interface';

@Component({
  selector: 'competency-list',
  templateUrl: './competency-list.component.html',
  styleUrls: ['competency-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CompetencyListComponent implements OnChanges, OnInit {
  @Input() public competencies: ICompetencyModel[];
  @Input() public iconClasses: string;
  @Input() public columns = 1;
  @Input() public scrollSize = 'none';
  @Input() public iconSide = 'left';
  @HostBinding('class') scrollClass;
  @Output() public onCompetencyClicked = new EventEmitter<ICompetencyNode>();

  public columnArray: number[];
  public sortedCompetencies: ICompetencyModel[] = [];

  ngOnInit(): void {
    this.scrollClass = this.setScrollSize();
    this.columnArray = Array.from(Array(this.columns).keys());
  }

  public ngOnChanges(changes: { competencies: SimpleChange }) {
    if (changes && changes.competencies) {
      const newCompetencies = changes.competencies.currentValue as ICompetencyModel[];
      if (newCompetencies.length) {
        this.sortedCompetencies = [].concat(newCompetencies);
        this.sortedCompetencies.sort(this.sortByName);
      }
    }
  }

  public competencyClicked(item: ICompetencyNode) {
    this.onCompetencyClicked.emit(item);
  }

  public getCompetencies(currentColumn: number) {
    if (!this.sortedCompetencies) {
      return [];
    }

    return this.sortedCompetencies.filter((x, i) => {
      const mod = (i % this.columns);
      if (mod === currentColumn) {
        return x;
      }
    });
  }

  private setScrollSize(): string {
    let scroll = 'scroll';
    switch (this.scrollSize) {
      case 'sm': {
        scroll += ' scroll-sm';
        break;
      }
      case 'md': {
        scroll += ' scroll-md';
        break;
      }
      case 'lg': {
        scroll += ' scroll-lg';
        break;
      }
      case 'xl': {
        scroll += ' scroll-xl';
        break;
      }
      default: {
        return null;
      }
    }
    return scroll;
  }

  private sortByName = (a: ICompetencyModel, b: ICompetencyModel) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }

    if (nameA > nameB) {
      return 1;
    }

    return 0;
  }
}





