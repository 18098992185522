import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {LoadingSpinnerService} from './loading-spinner.service';
import {LoadingBarEventType} from './spinner-event';

@Component({
  selector: 'loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent implements OnInit, OnDestroy {
  public visible = false;
  public progress = 0;
  private subscription: Subscription;

  constructor(private spinnerService: LoadingSpinnerService) {
  }

  public ngOnInit() {
    this.subscription = this.spinnerService.spinnerObservable.subscribe(event => {
      if (event.type === LoadingBarEventType.Visible) {
        this.visible = event.value;
      } else if (event.type === LoadingBarEventType.Progress) {
        this.progress = event.value;
      }
    });
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
