import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Action} from './models/action.model';

@Component({
  selector: 'action',
  template: `
    <button class="action-btn" [ngClass]="action.className" (click)="executeAction()">
      <span *ngIf="action.iconClass"><i [ngClass]="action.iconClass"></i>&nbsp;</span>{{action.displayText}}
    </button>`
})
export class ActionComponent implements OnInit {
  ngOnInit(): void {
    if (!this.action.className) {
      this.action.className = 'action-secondary';
    }
  }

  @Input() action: Action;
  @Output() actionClicked: EventEmitter<any> = new EventEmitter<any>();

  executeAction() {
    this.action.actionFunction();
    this.actionClicked.emit(null);
  }
}
