import {Component, Input, OnInit, ViewEncapsulation, EventEmitter, Output, SimpleChanges} from '@angular/core';
import {OrgUnitItem} from '../../core/interfaces';
import {OrgUnitHelperService} from './org-unit-helper.service';
import {isNullOrUndefined} from 'util';
import { IUserAuthModel } from 'app/core/interfaces/user.interface';
import { Router } from '@angular/router';


@Component({
  selector: 'display-user-org-units',
  styleUrls: ['./edit-org-units.component.scss'],
  encapsulation: ViewEncapsulation.None,
  template: `  <h3 style="display: inline-block;">Users With Access</h3> <i style="padding-left: 15px; font-size: 28px; display:inline-block; color:#333F48;" class="fa fa-question-circle-o center-block" [ngbTooltip]="tipContent" container="body"></i>
  <div class="row">
    <div class="col">
      <div class="scroll scroll-lg">
          <div *ngFor="let user of users">
            <div class="clickable hierarchy-node-text" [ngClass]="{'selected': user.highlight == true}" (click)="navigateToUser(user.userId)" *ngIf="(
                user.levelId < 3 && user.orgUnitIds.includes(selectedOrgUnitId)) || (user.levelId > 2 && user.childOrgUnitIds.includes(selectedOrgUnitId)
              ) || (selectedOrgUnit == null || isSelected == false)">
              <div>
                ({{user.childOrgUnitIds.length}}) {{user.first}} {{user.last}} - {{user.topOrgName}} - L {{user.levelId}} 
              </div>
            </div>
          </div>         
      </div>
    </div>
  </div>
  <ng-template #tipContent>
    <table style="margin:auto; width:100%;">
      <tr><b class="tableSelected">NAME</b> = User has access to View and Submit Request for selected location</tr>
      <tr><b class="tableNotSelected">NAME</b> = User can View only for selected location</tr>
      <tr>(#) = How many locations a User has permission to view</tr>
      <tr>L # = permission level</tr>
    </table>
  </ng-template>`
})
export class DisplayUserOrgUnitsComponent implements OnInit {
  @Input() users: IUserAuthModel[] = [];
  @Input() isSelected: boolean;
  @Input() organizations: OrgUnitItem[] = [];
  private _selectedOrgUnit: OrgUnitItem;
  @Input() set selectedOrgUnit(value: OrgUnitItem) {
    //TODO Move template filtering here.
    this._selectedOrgUnit = value;
    this.initTopTierOrg();
    if(this.users != null) {
      this.users = this.users.sort((a, b) => (a.topOrgTier - b.topOrgTier || a.username.localeCompare(b.username)));
      if(value != null && value.isSelected){
        this.users.forEach(user => {
          user.highlight = user.orgUnitIds.includes(value.orgUnitId);
        })
      } else {
        this.users.forEach(user => {
          user.highlight = false;
        })
      }
    }
  }
  get selectedOrgUnit(): OrgUnitItem {
    return this._selectedOrgUnit;
  }

  public ngOnChanges(changes: SimpleChanges) {
    //This absurd hack is currently required to trigger the accessor
    if ('selectedOrgUnit' in changes) {
        this.selectedOrgUnit = this.selectedOrgUnit;
     }
     //This even more absurd hack is here because changing the isSelected property isn't enough to trigger the above change.
     if ('isSelected' in changes) {
      this.selectedOrgUnit = this.selectedOrgUnit;
   }
  }  



  public levelName = '';
  public nameExists = false;
  public rootOrgUnit: OrgUnitItem;

  constructor(private router: Router) {  }
  get selectedOrgUnitId() {
    if(this.selectedOrgUnit == null || this.selectedOrgUnit.isSelected == false)
      return null;
    else
      return this.selectedOrgUnit.orgUnitId; 
  }

  initTopTierOrg(){
    if (this.rootOrgUnit == null)
      return; // We got here too early.

    for(var i = 0; i < this.users.length; i++){
      this.users[i].topOrgName = null;
      this.users[i].topOrgTier = null;
      if(this.users[i].orgUnitIds == null || this.users[i].orgUnitIds.length == 0) {
        this.users[i].topOrgName = 'No Org Units Assigned'; 
        this.users[i].topOrgTier = Number.POSITIVE_INFINITY;
      } else if(this.users[i].orgUnitIds.includes(this.rootOrgUnit.orgUnitId)){
        this.users[i].topOrgName = this.rootOrgUnit.name;
        this.users[i].topOrgTier = 0;
      } else {
       this.getTopTierOrg(1, this.users[i], this.selectedOrgUnit, this.rootOrgUnit.children);
      }
    }
  }

  //Find the highest tier orgUnit for a given user and the selected orgUnit (different selected branch may return different results)
  getTopTierOrg(tierCount: number, user: IUserAuthModel, selectedOrgUnit: OrgUnitItem, orgUnitsToEval: OrgUnitItem[]): boolean{      
    var foundSelectedOrg = false;
    for(var i = 0; i < orgUnitsToEval.length; i++){
      //We'll use this to locate the top tier orgunit if we find a selected branch of the hierarchy.
      var cacheTierOrg = null;

      if(user.orgUnitIds.includes(orgUnitsToEval[i].orgUnitId)){
        cacheTierOrg = orgUnitsToEval[i];

        //This is the first we've found, take it.
        if(user.topOrgTier == null ){
          user.topOrgName = orgUnitsToEval[i].name;
          user.topOrgTier = tierCount;
        }

        if(selectedOrgUnit != null){
          if(orgUnitsToEval[i].orgUnitId == selectedOrgUnit.orgUnitId){
           //This is the branch we care about.
           user.topOrgName = orgUnitsToEval[i].name;
           user.topOrgTier = tierCount;
           foundSelectedOrg = true;
           break;
          }
        }
      }
      //Keep going.
      if(orgUnitsToEval[i].children != null){
        if(this.getTopTierOrg(tierCount + 1, user, selectedOrgUnit, orgUnitsToEval[i].children)){
          foundSelectedOrg = true;
          //If this isn't null, then we found a higher tier on this branch, we'll use that.
          if(cacheTierOrg != null) {
            user.topOrgName = cacheTierOrg.name;
            user.topOrgTier = tierCount;
          }
          break;
        }
      }  
    }
    return foundSelectedOrg;
  }

  ngOnInit(): void {
    this.rootOrgUnit = this.organizations.find(o => o.isRoot === true);
      this.initTopTierOrg();
      this.users = this.users.sort((a, b) => (a.topOrgTier - b.topOrgTier || a.username.localeCompare(b.username))); // (a.topOrgTier > b.topOrgTier) ? 1 : -1)
  }
   
  navigateToUser(id: number){
    this.router.navigate(['/users/view', id])
  }
}
