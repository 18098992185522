import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'upload-error-dialog',
  templateUrl: './upload-error-dialog.component.html',
  styleUrls: ['./upload-error-dialog.component.scss']
})
export class UploadErrorDialogComponent {
  @Input() public title: string;
  @Input() public body: string;
  @Output() public onConfirm = new EventEmitter<boolean>();

  constructor(public activeModal: NgbActiveModal) {
  }

  public confirm(shouldExit: boolean) {
    this.activeModal.close();
    this.onConfirm.emit(shouldExit);
  }
}
