import {Injectable} from '@angular/core';
import {IEmailModel} from '../core/interfaces/email.interface';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {EmailService} from '../core/email.service';
import {Observable} from 'rxjs';

@Injectable()
export class EmailResolver implements Resolve<IEmailModel> {
  constructor(private emailService: EmailService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<IEmailModel> {
    const id = +route.params['id'];
    return this.emailService.getEmail(id);
  }
}

@Injectable()
export class EmailListResolver implements Resolve<IEmailModel[]> {
  constructor(private emailService: EmailService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<IEmailModel[]> {
    return this.emailService.getEmails();
  }
}
