import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {IUserBillingContactModel} from '../../../core/interfaces/billing-contact.interface';
import {Injectable} from '@angular/core';
import {Utility} from '../../../core/utility';
import {ISelectItemModel, IUserSelectItemModel} from '../../../core/interfaces/select-item.interface';
import {UserService} from '../../../core/user.service';
import {EditBillingContactDialogComponent} from '../../../billing-contacts/edit-billing-contact-dialog/edit-billing-contact-dialog.component';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {SectionBase} from './base.section';
import {EditAssessmentRequestModel} from '../../edit-assessment-request.model';
import {AppService} from '../../../core/app.service';
import {PsValidators} from '../../../core/validators/count.validator';
import {IEditUserModel} from '../../../core/interfaces/edit-user.interface';

@Injectable()
export class BillingContactSection extends SectionBase {
  private _billingContacts: IUserBillingContactModel[];
  private _states: ISelectItemModel[];
  private _countries: ISelectItemModel[];
  private _modal: NgbModalRef;

  constructor(private fb: UntypedFormBuilder,
              private modalService: NgbModal,
              private userService: UserService,
              private appService: AppService) {
    super();
  }

  public get hasBillingContact() {
    const fg = this.formGroup.value
    if (fg != null && fg.billingContactId != null)
      return false;
    else
      return true;
  }

  public get billingContactControl(): UntypedFormControl {
    return this.formGroup.controls['billingContact'] as UntypedFormControl;
  }

  public get billingContacts(): IUserBillingContactModel[] {
    return this._billingContacts || [];
  }

  public get isInvalid(){
    const fg = this.formGroup.value;
    if(!this.formGroup.touched)
      return false;

    if (fg != null && fg.billingContact && fg.billingContact.billingContactId != null)
      return false;
    else
      return true;
  }

  public init({assessmentRequest, states, countries}: { assessmentRequest: EditAssessmentRequestModel, states: ISelectItemModel[], countries: ISelectItemModel[] }) {
    super.setAssessmentRequest(assessmentRequest);
    this.formGroup = this.buildFormGroup();

    if (!states) {
      throw new Error('states must be specified');
    }

    this._states = states;

    if (!countries) {
      throw new Error('countries must be specified');
    }

    this._countries = countries;

    const billingContact = this.assessmentRequest.billingContactId
      ? this.billingContacts.find((bc) => bc.billingContactId === this.assessmentRequest.billingContactId)
      : null;

    if (billingContact) {
      this.billingContactControl.setValue(billingContact);
    }

    if (this.assessmentRequest.requestedByUserId) {
      this.retrieveBillingContacts(this.assessmentRequest.requestedByUserId, false, this.assessmentRequest.billingContactId);
    }

    this.appService.onLoggingOut.subscribe(() => {
      if (!this._modal) {
        return;
      }
      this._modal.dismiss('logging out');
    });
  }

  public get formValue(): any {
    const values = this.formGroup.value;

    return {
      billingContactId: values.billingContact ? values.billingContact.billingContactId : null
    };
  }

  public onClickAddBillingContact(requestedByUser: IEditUserModel, orgUnitName: string) {
    this._modal = this.modalService.open(EditBillingContactDialogComponent, {size: 'lg'});

    const instance = this._modal.componentInstance as EditBillingContactDialogComponent;
    instance.user = requestedByUser;
    instance.states = this._states;
    instance.countries = this._countries;
    instance.orgUnitName = orgUnitName;
    //instance.onSelectCountry(instance.billingContact.address.countryId);
    instance.onUpdate.subscribe(() => {
      this.retrieveBillingContacts(requestedByUser.userId, true);
    });
  }

  public onClickEditBillingContact(requestedByUser: IEditUserModel) {
    this._modal = this.modalService.open(EditBillingContactDialogComponent, {size: 'lg'});

    const instance = this._modal.componentInstance as EditBillingContactDialogComponent;
    instance.user = requestedByUser;
    instance.states = this._states;
    instance.countries = this._countries;
    instance.billingContact = this.formGroup.value.billingContact as IUserBillingContactModel;
    instance.onSelectCountry(instance.billingContact.address.countryId);
    instance.onUpdate.subscribe(() => {
        this.retrieveBillingContacts(requestedByUser.userId, false, this.formGroup.value.billingContact.billingContactId);
    });
  }

  public retrieveBillingContacts(userId: number, reset: boolean, initialBillingContactId: number = null) {
    this.userService.getBillingContacts(userId).subscribe((billingContacts: IUserBillingContactModel[]) => {
      billingContacts.forEach((bc) => {
        bc.displayName = Utility.buildDisplayName(bc.firstName, bc.lastName);

        if (bc.address) {
          bc.address.phone = bc.address.phoneMobile || bc.address.phoneWork;


          
          const country = this._countries.find((c) => c.id === bc.address.countryId);
          bc.address.country = country ? country.name : null;

          if(country.id == 196){
            const state = this._states.find((s) => s.id === bc.address.stateId);
            const stateName = state ? state.name : null;
            bc.address.cityStateZip = this.formatCityStateZip(bc.address.city, stateName, bc.address.zipCode);
          } else{
            bc.address.cityStateZip = this.formatCityStateZip(bc.address.city, bc.address.province, bc.address.zipCode);
          }
        }
      });

      if (!this.assessmentRequest.hasParent) {
        //if (billingContacts && billingContacts.length) {
          this.billingContactControl.enable();
        //} else {
          //this.billingContactControl.disable();
        //}
      }

      this._billingContacts = billingContacts;
      if (initialBillingContactId && !this.assessmentRequest.isNew) {
        //We don't want to use the billing contact
        const billingContact = this.billingContacts.find((bc) => bc.billingContactId === initialBillingContactId);
        this.billingContactControl.setValue(billingContact);
      } else if (reset) {
        let defaultBillingContact = billingContacts.find((bc) => bc.isDefault);

        if (!defaultBillingContact && billingContacts.length === 1) {
          defaultBillingContact = billingContacts[0];
        }

        if (defaultBillingContact) {
          this.billingContactControl.setValue(defaultBillingContact);
        } else { //Oh wow - can we refactor this mess?
          this.billingContactControl.setValue(-1);
          this.billingContactControl.setErrors({'incorrect': true});
        }
      } else if (billingContacts.length === 1) {
        this.billingContactControl.setValue(billingContacts[0]);
      } else {
        this.billingContactControl.setValue(-1);
        this.billingContactControl.setErrors({'incorrect': true});
      }
    });
  }

  protected buildFormGroup(): UntypedFormGroup {
    return this.fb.group({
      billingContact: [{value: null}, PsValidators.hasBillingContact.bind(this)], // {value: null, disabled: this.assessmentRequest.isNew}
    });
  }

  private formatCityStateZip(city: string, state: string, zipCode: string): string {
    let cityStateZip = city;

    if (cityStateZip && state) {
      cityStateZip += ', ';
    }

    if (state) {
      cityStateZip += state;
    }

    if (cityStateZip && zipCode) {
      cityStateZip += ' ';
    }

    if (zipCode) {
      cityStateZip += zipCode;
    }

    return cityStateZip;
  }
}
