import {Injectable} from '@angular/core';
import {AssessmentRequestService} from './assessment-request.service';
import {ClientService} from './client.service';
import {UserService} from './user.service';
import {CompetencyService} from './competency.service';
import {PositionFunctionService} from './position-function.service';
import {PositionLevelService} from './position-level.service';

@Injectable()
export class CacheService {
  constructor(private assessmentRequestService: AssessmentRequestService,
              private clientService: ClientService,
              private userService: UserService,
              private competencyService: CompetencyService,
              private positionFunctionService: PositionFunctionService,
              private positionLevelService: PositionLevelService) {
  }

  public clear(): void {
    this.assessmentRequestService.clearCache();
    this.clientService.clearCache();
    this.userService.clearCache();
    this.competencyService.clearCache();
    this.positionFunctionService.clearCache();
    this.positionLevelService.clearCache();
  }
}
