<div class="ps-table-filter" #dd="ngbDropdown" ngbDropdown>
  <a ngbDropdownToggle class="ps-table-filter-title ps-table-filter-opener">
    {{filterTitle}}&nbsp;<i class="fa fa-caret-down"></i>
  </a>
  <div class="dropdown-menu ps-table-filter-dd" (click)="$event.stopPropagation()">
    <label class="form-control-label">Filter:
      <div class="pull-right" (click)="dd.close()"><i class="fa fa-close"></i></div>
    </label>
    <input id="filterText" class="form-control" name="filterText" [(ngModel)]="filterText" type="text"/>
    <div class="text-right" style="font-size: 12px"><a href="javascript:void(0)" class="text-primary" (click)="applyFilter();dd.close();">Apply</a></div>
  </div>
</div>
