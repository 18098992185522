import {IEditPositionModel} from '../interfaces/edit-position.interface';
import {ICompetencyModel} from '../interfaces/competency.interface';
import {CompetencyModel} from './competency.model';
import {IClientCompetenciesModel} from '../interfaces/client-competencies.interface';

export class EditPositionModel implements IEditPositionModel {
  public positionId?: number;
  public orgUnitId?: number;
  public orgUnitName: string;
  public clientId: number;
  public clientName: string;
  public createdBy: string;
  public createdDate: Date;
  public description: string;
  public isActive: boolean;
  public noJobDescription: boolean;
  public jobDescriptionAttachmentId: number;
  public jobDescriptionFileName: string;
  public name: string;
  public ownerId: string;
  public positionLevelName: string;
  public competencies: ICompetencyModel[];
  public clientCompetencies: ICompetencyModel[];
  public customCompetency: string;
  public isConfidential: boolean;

  constructor(obj?: IEditPositionModel) {
    if (obj.positionId) {
      this.positionId = obj.positionId;
    }

    this.orgUnitId = obj.orgUnitId;
    this.orgUnitName = obj.orgUnitName;
    this.clientId = obj.clientId;
    this.clientName = obj.clientName;
    this.createdBy = obj.createdBy;
    this.createdDate = obj.createdDate;
    this.description = obj.description;
    this.isActive = obj.isActive;
    this.noJobDescription = obj.noJobDescription;
    this.jobDescriptionAttachmentId = obj.jobDescriptionAttachmentId;
    this.jobDescriptionFileName = obj.jobDescriptionFileName;
    this.name = obj.name;
    this.ownerId = obj.ownerId;
    this.positionLevelName = obj.positionLevelName;
    this.competencies = obj.competencies ? obj.competencies.map((c) => new CompetencyModel(c)) : [];
    this.clientCompetencies = obj.clientCompetencies;
    this.customCompetency = obj.customCompetency;
    this.isConfidential = obj.isConfidential;
  }
}
