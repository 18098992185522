import {Component, ContentChild, Directive, Input, OnInit, HostBinding, EventEmitter} from '@angular/core';
import {FormControlName, ValidationErrors} from '@angular/forms';

let nextUniqueId = 0;

@Directive({
  selector: 'ps-hint',
  host: {
    '[class.ps-hint]': 'true',
    '[attr.id]': 'id'
  }
})
export class PsHintDirective {
  @Input() id = 'ps-input-hint-' + nextUniqueId++;
}

@Directive({
  selector: 'input[psInput], textarea[psInput], select[psInput], ps-autocomplete-select[psInput]',
  host: {
    '(focus)': 'onFocus()',
    '(blur)': 'onBlur()'
  }
})
export class PsInputDirective {
  public focused = false;
  public blurEvent = new EventEmitter<void>();

  public onFocus() {
    this.focused = true;
  }
  public onBlur() {
    this.focused = false;
    this.blurEvent.emit();
  }
}

@Component({
  selector: 'ps-input-validation',
  templateUrl: './ps-input-validation.html'
})
export class PsInputValidation implements OnInit {
  @Input() columnSize = 0;
  @Input() errorsOverride: { [key: string]: any };
  @ContentChild(PsInputDirective) _mdInputChild: PsInputDirective;
  @ContentChild(FormControlName) _control: FormControlName;
  @HostBinding('class') _columnClass;

  public ngOnInit() {
    this.columnSize = (this.columnSize < 0 || this.columnSize > 12)
      ? 1
      : this.columnSize;

    this._columnClass = this.columnClass;
  }

  public get valid(): boolean {
    if (!this._control) {
      return true;
    }
    if (this.errorsOverride) {
      return false;
    }
    return this._control.valid;
  }

  public get showError(): boolean {
    if (!this._control) {
      return false;
    }
    return !this.valid && (this._control.dirty || this._control.touched) && !this._control.disabled;
  }

  public hasError(key: string): boolean {
    if (!this._control) {
      return false;
    }

    if (this.errorsOverride && this.errorsOverride[key]) {
      return this.errorsOverride[key];
    }

    return this._control.errors && this._control.errors[key];
  }

  public get showList(): boolean {
    if (!this._mdInputChild) {
      return false;
    }
    return this._mdInputChild.focused && this.showError;
  }

  getError(key: string): any {
    if (!this._control) {
      return null;
    }
    return this._control.errors[key];
  }

  public get columnClass() {
    const cls = this.columnSize === 0
      ? 'col'
      : `col-${this.columnSize}`;
    return cls;
  }
}
