import {Component, Input, OnInit} from '@angular/core';
import {PsListItem} from '../../core/listitem/ps-list-item';
import {OptionsManagerService} from '../../services/options-manager.service';

@Component({
  selector: 'ps-option',
  templateUrl: './ps-option.html'
})
export class PsOptionComponent implements OnInit {
  @Input() value: PsListItem;
  @Input() index: number;
  uniqueId: string;

  constructor(private optionsManager: OptionsManagerService) {

  }

  public selected(value: boolean) {
    this.value.selected = value;
  }

  public activated(value: boolean) {
    this.value.active = value;
  }

  ngOnInit(): void {
    this.uniqueId = 'select-option-' + this.value.id;
  }

  itemClicked(event) {
    this.optionsManager.selectItem(this.index);
  }

  getIsActive(): boolean {
    return this.value.active;
  }

  getIsSelected(): boolean {
    return this.value.selected;
  }

  getTextValue() {
    return this.value.name;
  }
}
