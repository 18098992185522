export class Utility {
  public static deepFreeze(o): any {
    Object.freeze(o);

    Object.getOwnPropertyNames(o).forEach((prop) => {
      if (o.hasOwnProperty(prop)
        && o[prop] !== null
        && (typeof o[prop] === 'object' || typeof o[prop] === 'function')
        && !Object.isFrozen(o[prop])) {
        Utility.deepFreeze(o[prop]);
      }
    });

    return o;
  };

  public static sortByName(a, b) {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }

    if (nameA > nameB) {
      return 1;
    }

    return 0;
  }


  public static buildDisplayName(first: string, last: string) {
    let displayName = '';

    if (last) {
      displayName = last.trim();
    }

    if (first) {
      if (displayName.length) {
        displayName += ', ';
      }

      displayName += first.trim();
    }

    return displayName;
  }

  public static isBlank(str) {
    return (!str || /^\s*$/.test(str));
  }
}
