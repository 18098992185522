<action-bar>
   <action-button [iconClass]="'fa fa-plus'" [className]="'action-primary'" (actionClick)="openAdd()"
   [disabled]="!ownerUserId">Add Non-System Recipient!</action-button>
</action-bar>
<ngb-alert *ngIf="alertMessage" class="alert-overlay"
           type="success" (close)="alertMessage = null">{{ alertMessage }}</ngb-alert>
<div class="row">
  <div class="col table-title">
    <h1>Non-System Recipients</h1>
  </div>
  <div class="col">
    <div class="pull-right">
      Quick Search:
      <input type="text" [(ngModel)]="inputQuickSearch" id="filter-text-box" placeholder="Type here..." (keyup)="onFilterTextBoxChanged()"/>
      <button (click)="resetFilters()">Reset Filters</button>
    </div>
  </div>
</div>
<div class="row" *ngIf="isAdmin">
  <div class="col-6">
    <label class="form-control-label">Client</label>
    <ps-autocomplete-select [placeHolderText]="'Select Client'"
                            [selectedItem]="client"
                            [selectList]="clients" (selectionChanged)="onClientChanged($event)">
    </ps-autocomplete-select>
  </div>
  <div class="col-6">
    <label class="form-control-label">Owner User</label>
    <ps-autocomplete-select [placeHolderText]="'Select Department'"
                            [(selectedItem)]="ownerUser"
                            [selectList]="users" (selectionChanged)="onOwnerUserChanged($event)">
    </ps-autocomplete-select>
  </div>
</div>
<br/>
<div class="row" id="GridWithH1">
  <div class="col" style="Width: 100%;Height:100%;" >
    <ag-grid-angular style="Width: 100%;Height:100%;" class="ag-theme-material" [gridOptions]="gridOptions"></ag-grid-angular>
  </div>
</div>



