<div class="select-filter-container">
  <input #filterInput spellcheck="false" autocomplete="off" role="combobox"
         class="form-control select-input" [(value)]="data"
         (focus)="onFocus($event)" (keydown)="onKeyDown($event)" (keyup)="onKeyUp($event)" [disabled]="disabled"/>
  <i class="placeholder-text" *ngIf="(!selectedItem && showPlaceHolder)" (click)="openPanel()">
    {{placeHolderText}}&nbsp;<i class="sf-input-icon fa fa-caret-down"></i>
  </i>
  <i *ngIf="selectedItem && showPlaceHolder" class="active-text" #activeText [id]="id" [hidden]="disabled"(click)="openPanel()">
    &nbsp;{{selectedItem?.name}}&nbsp;<i class="sf-input-icon fa fa-caret-down"></i>
  </i>
  <ps-options [hidden]="listHidden" (selectionChanged)="onSelectionChanged($event)"
              [ngClass]="getListClass()" role="listbox" #selectOptions>
    <ps-option *ngFor="let item of filtered; let i = index" [value]="item" [index]="i">
      {{item.name}}
    </ps-option>
  </ps-options>
</div>
