import { Component, OnInit, Input, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {AssessmentRequestListItemModel, ARQuickAdditionalServiceModel} from '../assessment-request-list-item.model';
import {GridOptions, RowNode, Column, SelectionChangedEvent} from "ag-grid-community";
import {NgbTooltip} from '@ng-bootstrap/ng-bootstrap';
import {CheckboxViewRendererComponent} from './checkbox-view-renderer.component';
import {FilterHiringOutcomePollHiddenComponent} from './filter-hiring-outcome-poll-hidden.component';
import {FilterHiringOutcomeComponent} from '../filter-hiring-outcome/filter-hiring-outcome.component';
import {AssessmentRequestService} from '../../core/assessment-request.service';
import {IHiringOutcomeUpdate} from '../../core/interfaces/hiring-outcome-update.interface';
import {AuthService} from '../../auth/auth.service';
import {HiringOutcome} from '../../core/enums/hiring-outcome.enum';
import { HiredOfferModalComponent } from './hired-offer-modal.component';

@Component({
  selector: 'app-hiring-outcome-poll-modal',
  templateUrl: './hiring-outcome-poll-modal.component.html',
  styleUrls: ['./hiring-outcome-poll-modal.component.scss'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})

export class HiringOutcomePollModalComponent implements OnInit {
  public gridOptions: GridOptions;
  public model: any;
  public inputQuickSearch: string;
  //Stores changes made that are to be commited to server.
  public cachedOutcomeUpdates = new Map<number, IHiringOutcomeUpdate>();
  private preventRemindMe = false;
  public cancelButtonText: string = "Remind Me Later"
  private source: string;

  constructor(public dialogRef: MatDialogRef<HiringOutcomePollModalComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any, 
    public dialog: MatDialog,
    private assessmentRequestService: AssessmentRequestService,
    private router: Router,
    private authService: AuthService,
  private activatedRoute: ActivatedRoute) {
    if(this.data.manualModal)
      this.cancelButtonText = "Cancel";
    this.gridOptions = <GridOptions>
    {
      columnDefs: this.createColumnDefs(),
      rowHeight: 48, // recommended row height for material design data grids,
      headerHeight: 38,
      rowBuffer: 20,
      animateRows: true,
      pagination: true,
      rowSelection: "single",
      suppressCellFocus: true,
      suppressMenuHide: true,
      getRowClass: (event) => this.rowBackgroundColor(event),
      context: { componentParent: this },   //Make this component (and its members) available to child components.
      paginationPageSize: 100,
      onSortChanged: () => this.onColumnChanged(),
      onColumnResized: () => this.onColumnChanged(),
      defaultColDef: {
        resizable: true,
        sortable: true
      }
    }
  }

  onResize(event) {
    localStorage.setItem('outcomeWindowWidth', JSON.stringify(event.target.innerWidth));
    this.gridOptions.api.sizeColumnsToFit();
  }

  public exportDataAsCsv(){
    var containsConfidential = false;
    //Use AfterFilter - otherwise you'll operate on rows that will not actually be exported.
    this.gridOptions.api.forEachNodeAfterFilter(node => {if(node.data.isConfidential === true){containsConfidential = true;}})
    var fileName = 'Hiring Export' + (containsConfidential?' CONFIDENTIAL-DATA-PRESENT':'');
    var columnKeys = ['candidateName','position','clientName','orgUnitName','requestedByName', 'lastStatusChangeDate','decision'];
    if(containsConfidential){
      columnKeys.unshift('isConfidential');
    }
    var params = {processCellCallback: null, fileName: fileName, columnKeys};
    params.processCellCallback = function(params) {
      if(params.column.colDef.headerName == "Decision"){
        switch(params.node.data.hiringOutcome){
          case HiringOutcome.Hired:
            return "Hired";
          case HiringOutcome.NotHired:
            return "Not Hired";
          case HiringOutcome.Pending:
            return "Pending";
          default:
            return "Not Answered"
      }
     } else if(params.column.colDef.headerName == "Confidential") {
        return params.node.data.isConfidential?'Confidential':'';
     } else {
        return params.value;
      }
    };    
    this.gridOptions.api.exportDataAsCsv(params);
  }
  
  public resetFilters()
  {
    const earlyThreshold = new Date(2018,0,1);
    //earlyThreshold.setDate(earlyThreshold.getDate()+14);
    //We'll use this date to make sure that they aren't so old we aren't asking about them anymore.
    const lateThreshold = new Date();
    lateThreshold.setDate(lateThreshold.getDate()-60); 
    // for (let column of this.gridOptions.columnApi.getAllColumns()) 
    // {
    //   this.gridOptions.api.destroyFilter(column);
    // }
    //Now cleanup the quick search -> reset the input element
    this.inputQuickSearch = '';
    //and reset the grid quickfilter
    this.gridOptions.api.setQuickFilter('');
    //and set the default status filter
    this.gridOptions.api.setFilterModel({0:{}, decision: {pending: true, outcomeHired: false, outcomeNotHired: false, showUnanswered: false}, lastStatusChangeDate:{ dateFrom:this.getDateForAGGrid(earlyThreshold), type:"greaterThan", filterType:"date"}});
    //reset the default sort.
    this.gridOptions.columnApi.resetColumnState();
    //finaly raise the events...
    this.gridOptions.api.onSortChanged();
    this.gridOptions.api.onFilterChanged();
    this.gridOptions.api.sizeColumnsToFit();

    console.log("Filtered data:");
    this.gridOptions.api.forEachNodeAfterFilter(node => {
        console.log(node.data);
    });
  }

  private getDateForAGGrid(date: Date): string{
    const output: string = date.getFullYear() + "-" + (date.getMonth()+1) + "-" + date.getDate();
    return output;
  }

  public onColumnChanged() {
    localStorage.setItem('gridOutcomeColumnState' + this.getImpersonatedUserId(), JSON.stringify(this.gridOptions.columnApi.getColumnState())); //get/applyColumnState()
  }

  public onFilterChanged(event) {
    localStorage.setItem('gridOutcomeFilterModel' + this.getImpersonatedUserId(), JSON.stringify(this.gridOptions.api.getFilterModel()));
  }

  public onFilterTextBoxChanged() {
    this.gridOptions.api.setQuickFilter(this.inputQuickSearch);
  }
  
  //Dialog close calls this, we handle remindme logic here to catch people who click outside modal to close it.
  public onDialogClosed()
  {
    //onDone sets this to true to prevent the remind-me behavior.
    if(!this.preventRemindMe)
      var nextOutcomeModalTime = new Date();
      nextOutcomeModalTime.setDate(nextOutcomeModalTime.getDate()+1); 
      localStorage.setItem('gridARnextOutcomeModalTime'+this.getImpersonatedUserId(), nextOutcomeModalTime.toString()); 
      
      
      //TODO: This could also be driven by a Drop down to let the user select how long they'd like to wait.
  }

  public getImpersonatedUserId(): string
  {
    return (this.authService.impersonatedUser != null) ? this.authService.impersonatedUser.userId.toString() : this.authService.userId.toString();
  }

  public onDone()
  {
    var toBeSaved: IHiringOutcomeUpdate[] = [];
    var toBeOffered: IHiringOutcomeUpdate[] = [];
    for (let entry of Array.from(this.cachedOutcomeUpdates.entries())) {
      if(entry[1].hiringOutcome == 2){
        //hasAnyAdditionalService
        if(!entry[1].hasAnyAdditionalService){
        toBeOffered.push(entry[1]);
        }
      }
      toBeSaved.push(entry[1]);
    }

    this.assessmentRequestService.saveHiringOutcomeModal(toBeSaved).subscribe(() => {
    });
    this.preventRemindMe = true;

    if(toBeOffered.length > 0){
      var toBeOfferedRequests: ARQuickAdditionalServiceModel[] = [];
      for (let i = 0; i < this.data.assessmentRequestList.length; i++){
        if(toBeOffered.some(x => x.assessmentRequestId == this.data.assessmentRequestList[i].assessmentRequestId)){
          toBeOfferedRequests.push(new ARQuickAdditionalServiceModel(this.data.assessmentRequestList[i]));
        }
      }
      this.dialog.open(HiredOfferModalComponent, {
        width: '85%',
        height: '85%',
        data: { toBeOfferedRequests }
      });
    }
    this.dialogRef.close();  
  }

  private createColumnDefs() {
    return [
      {
        headerName: "HiddenFilter",
        filterFramework: FilterHiringOutcomePollHiddenComponent,
        hide: true
      },
      {
        headerName: "Confidential",
        field: "isConfidential",
        hide: true
      },
      {
        headerName: "Candidate",
        field: "candidateName",
        menuTabs: ['filterMenuTab'],
        filter: "agTextColumnFilter",
        icons: {
          sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
          sortDescending: '<i class="fa fa-sort-alpha-desc"/>',
          filter: '<i class="fa fa-search"/>',
          menu: '<i class="fa fa-filter"/>'
        },
        cellRenderer: (params) => this.candidateCellRenderer(params)
      },
      {
        headerName: "Position",
        field: "position",
        menuTabs: ['filterMenuTab'],
        filter: "agTextColumnFilter",
        icons: {
          sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
          sortDescending: '<i class="fa fa-sort-alpha-desc"/>',
          filter: '<i class="fa fa-search"/>',
          menu: '<i class="fa fa-filter"/>'
        }
      },
      {
        headerName: "Client",
        field: "clientName",
        menuTabs: ['filterMenuTab'],
        filter: "agTextColumnFilter",
        hide: !this.data.admin,
        icons: {
          sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
          sortDescending: '<i class="fa fa-sort-alpha-desc"/>',
          filter: '<i class="fa fa-search"/>',
          menu: '<i class="fa fa-filter"/>'
        }
      },
      {
        headerName: "Department",
        field: "orgUnitName",
        menuTabs: ['filterMenuTab'],
        filter: "agTextColumnFilter",
        hide: !this.data.manualModal,
        icons: {
          sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
          sortDescending: '<i class="fa fa-sort-alpha-desc"/>',
          filter: '<i class="fa fa-search"/>',
          menu: '<i class="fa fa-filter"/>'
        }
      },
      {
        headerName: "Requested By",
        field: "requestedByName",
        menuTabs: ['filterMenuTab'],
        filter: "agTextColumnFilter",
        icons: {
          sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
          sortDescending: '<i class="fa fa-sort-alpha-desc"/>',
          filter: '<i class="fa fa-search"/>',
          menu: '<i class="fa fa-filter"/>'
        }
      },
      {
        headerName: "Completed Date",
        field: "lastStatusChangeDate",
        filter: "agDateColumnFilter",
        menuTabs: ['filterMenuTab'],
        valueFormatter: this.dateCellFormatter,
        sort: 'desc',
        icons: {
          sortAscending: '<i class="fa fa-arrow-up"/>',
          sortDescending: '<i class="fa fa-arrow-down"/>',
          filter: '<i class="fa fa-search"/>',
          menu: '<i class="fa fa-filter"/>'
        }
      },
      {
        headerName: "Decision",
        valueFormatter: "value",
        colId:"decision",
        menuTabs: ['filterMenuTab'],
        filterFramework: FilterHiringOutcomeComponent,
        cellRendererFramework: CheckboxViewRendererComponent,
        icons: {
          sortAscending: '<i class="fa fa-arrow-up"/>',
          sortDescending: '<i class="fa fa-arrow-down"/>',
          filter: '<i class="fa fa-search"/>',
          menu: '<i class="fa fa-filter"/>'
        },
        width: 260,
        minWidth:260,
        resizable: false
      }

    ];
  }

  public QueueOutcomeDecisionUpdate(assessmentRequestId: number, hiringOutcome: number, hasAnyAdditionalService: boolean)
  {
    //Build the cache of items to be saved to the DB.
    this.cachedOutcomeUpdates.set(assessmentRequestId, {assessmentRequestId, hiringOutcome, hasAnyAdditionalService, source: this.source} as IHiringOutcomeUpdate );
  }
  
  //Move this to shared?
  private dateCellFormatter(params) {
    if(params.value.getFullYear() != 1)
      return `${(params.value.getMonth() + 1)}/${params.value.getDate()}/${params.value.getFullYear()}`;
    else
      return 'Not Available';
  }

  public rowBackgroundColor(event) : string{
    if (event.node.rowIndex % 2 === 0) {
      return 'shaded-row';
    }
    else
      return null;
  }

  private candidateCellRenderer(params) {
    const element = document.createElement('span');
    element.innerHTML = `
      <strong class="aniborder">
        ${params.data.isConfidential ? '<i class="fa fa-lock" ngbTooltip="Confidential Request" container="body"></i>' : ''}
        ${params.value}
      </strong>
    `;
    return element;
  }
  

  public ngAfterViewInit()
  {   
    //Register the DialogClose handler.
    this.dialogRef.afterClosed().subscribe(result => {
      this.onDialogClosed();
    });
    this.gridOptions.api.setRowData(this.data.assessmentRequestList);
    this.loadGridPreferences();
  }

  public loadGridPreferences() {
    //Handle loading saved Sort, Filter, and Column settings.
    var columnState = localStorage.getItem('gridOutcomeColumnState' + this.getImpersonatedUserId());  //gridColumnState'+this.getImpersonatedUserId(), JSON.stringify(this.gridOptions.columnApi.getColumnState()
    if (columnState != null) {
      this.gridOptions.columnApi.applyColumnState(JSON.parse(columnState));
      //Prevent poor column saved states when a user's window size changes between sessions.
      if (+localStorage.getItem('outcomeWindowWidth') != window.innerWidth) {
        this.gridOptions.api.sizeColumnsToFit();
        localStorage.setItem('outcomeWindowWidth', JSON.stringify(window.innerWidth));
      }
    }
    else {
      this.gridOptions.api.sizeColumnsToFit();
      this.resetFilters();
    }
    var filterModel = localStorage.getItem('gridOutcomeFilterModel' + this.getImpersonatedUserId());
    if (filterModel != null) {
      const thisModel = JSON.parse(filterModel);
      this.gridOptions.api.setFilterModel(thisModel);
    }
    else
      this.resetFilters();
  }
  
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.source = params['source'] || 'portal';
    });
  }

}
