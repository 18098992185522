export class OrgUnitItem {
  orgUnitId?: number;
  clientId: number;
  name: string;
  parentId: number;
  children: OrgUnitItem[];
  isEditing = false;
  isNew = false;
  isDirty = false;
  isTouched = false;
  isDeleted = false;
  isSelected = false;
  isRoot = false;
  showChildren = true;
  isActive = false;

  constructor() {
    this.name = '';
    this.isDeleted = false;
    this.parentId = null;
    this.isEditing = false;
    this.isRoot = false;
    this.children = [];
  }
}




