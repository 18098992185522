import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AccountService} from '../../core';
import {AuthService} from '../../auth/auth.service';
import {ChangePasswordRequest, ChangePasswordResponse} from '../../core/models/change-password.models';
import {PsValidators} from '../../core/validators/password.validator';

@Component({
  selector: 'change=password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  encapsulation: ViewEncapsulation.None 
})
export class ChangePasswordComponent implements OnInit {
  public changePasswordForm: UntypedFormGroup;
  public showSuccess = false;
  public errorMessage: string;
  public username: string;

  constructor(private _form: UntypedFormBuilder,
              private _accountService: AccountService,
              private _authService: AuthService) {
  }

  ngOnInit(): void {
    this.changePasswordForm = this._form.group({
      currentPassword: [null, Validators.required],
      newPassword: [null, [Validators.required,
        PsValidators.password('^((?=.*[a-z])|(?=.*[A-Z]))(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*_\\-=])(?=.{6,})')]],
      confirmPassword: [null, [Validators.required, PsValidators.passwordCompare('newPassword')]]
    });

    this.username = this._authService.userName;
  }

  public newPasswordChange() {
    const confirmPassword = this.getControl('confirmPassword');
    confirmPassword.updateValueAndValidity();
  }

  public getControl(key: string): UntypedFormControl {
    return this.changePasswordForm.get(key) as UntypedFormControl;
  }

  public submit() {
    if (this.changePasswordForm.invalid) {
      return;
    }
    const model = new ChangePasswordRequest();
    model.currentPassword = this.changePasswordForm.controls['currentPassword'].value;
    model.newPassword = this.changePasswordForm.controls['newPassword'].value;
    model.confirmPassword = this.changePasswordForm.controls['confirmPassword'].value;
    this._accountService.changePassword(model).subscribe((res: ChangePasswordResponse) => {
      if (res.success) {
        this.showSuccess = true;
        this.resetForm();
      } else {
        this.showSuccess = false;
        this.getControl('currentPassword').setValue(null);
        this.errorMessage = res.message;
      }
    });
  }

  private resetForm() {
    this.changePasswordForm.reset();
  }
}
