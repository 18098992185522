export enum AssessmentRequestStatus {
  Unknown = 0,
  NotSubmitted = 1,
  Submitted = 5,
  InProcess = 6,
  Complete = 7,
  Deleted = 8,
  InProcessTestingStarted = 12,
  InProcessTestingComplete = 9,
  Canceled = 10,
  InProcessReportsUploaded = 11,

}
