
import {defer as observableDefer, Observable} from 'rxjs';
import {map, publishReplay, refCount, take} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ServiceBase} from './service-base';
import {AuthHttpService} from '../auth/auth-http.service';
import {IPositionLevelModel} from './interfaces/position-level.interface';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class PositionLevelService extends ServiceBase {
  private static ApiUrl = 'api/position-level';
  private data$: Observable<IPositionLevelModel[]>;

  constructor(private http: HttpClient) {
    super();
  }

  public get(): Observable<IPositionLevelModel[]> {
    if (!this.data$) {
      this.data$ = observableDefer(() => this.http.get(PositionLevelService.ApiUrl+'?sortByName=false')).pipe(
        map((res: Response) => (res || []) as IPositionLevelModel[]),
        publishReplay(1),
        refCount(),
        take(1)
      );
    }

    return this.data$;
  }

  public clearCache() {
    this.data$ = null;
  }
}
