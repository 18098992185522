import {NgModule} from '@angular/core';
import {ListAssessmentRequestComponent} from './list-assessment-request/list-assessment-request.component';
import {EditAssessmentRequestComponent} from './edit-assessment-request/edit-assessment-request.component';
import {ViewAssessmentRequestComponent} from './view-assessment-request/view-assessment-request.component';
import {AdditionalServiceAssessmentRequestResolver, AdditionalServiceAttachmentResolver, AdditionalServiceReportTypeResolver, AssessmentLevelAttachmentResolver, AssessmentLevelReportTypeResolver, AssessmentRequestListResolver, AssessmentRequestResolver, ViewAssessmentRequestResolver} from './resolvers.service';
import {RouterModule, Routes} from '@angular/router';
import {SecureLayoutComponent} from '../shared/secure-layout/secure-layout.component';
import {PiquantTableModule} from '../piquant-table/piquant-table.module';
import {AuthGuard} from '../auth/auth.guard';
import {AdditionalServiceReferencesResolver, AssessmentLevelReferencesResolver, AssessmentTestingScheduleResolver, ClientSelectItemsResolver, CountrySelectItemsResolver, EmailTemplateSelectItemsResolver, LanguageSelectItemsResolver, StateSelectItemsResolver, UserSelectItemsResolver} from '../core/resolvers.service';
import {SharedModule} from '../shared/shared.module';
import {PositionsModule} from '../positions/positions.module';
import {ViewFeedbackRecipientComponent} from './view-assessment-request/view-feedback-recipient.component';
import {CompetenciesModule} from '../competencies/competencies.module';
import {EditAssessmentRequestModel} from './edit-assessment-request.model';
import {OrgUnitsModule} from '../org-units/org-units.module';
import {FeedbackModule} from '../feedback/feedback.module';
import {SiteUtilityService} from '../core/site-utility.service';
import {AssessmentRequestPermissionsService} from './assessment-request-permissions.service';
import {DeleteAssessmentRequestDialogComponent} from './delete-assessment-request-dialog/delete-assessment-request-dialog.component';
import {StatusChangeRequestDialogComponent} from './status-change-assessment-request-dialog/status-change-assessment-request-dialog.component';
import {BillingContactsModule} from '../billing-contacts/billing-contacts.module';
import {EditTestingScheduleComponent} from './edit-testing-schedule/edit-testing-schedule.component';
import {EditTestingScheduleGuard} from './edit-testing-schedule/edit-testing-schedule.guard';
import {UploadReportsComponent} from './upload-reports/upload-reports.component';
import {DownloadReportsComponent} from './download-reports/download-reports.component';
import {UploadReportsGuard} from './upload-reports/upload-reports.guard';
import {DownloadReportsGuard} from './download-reports/download-reports.guard';
import {CanDeactivateComponentGuard} from '../shared/can-deactivate-component.guard';
import {UploadReportDialogComponent} from './upload-report-dialog/upload-report-dialog.component';
import {ConfirmNoResumeDialogComponent} from './confirm-no-resume-dialog/confirm-no-resume-dialog.component';
import {SelectAdditionalServiceTypeDialogComponent} from './additional-services/select-additional-service-type-dialog/select-additional-service-type-dialog.component';
import {FormInvalidComponent} from './edit-assessment-request/form-invalid.component';
import {AgGridModule} from 'ag-grid-angular';
import { ViewButtonRendererComponent } from './view-button-renderer/view-button-renderer.component';
import { DetailCellRendererComponent } from './detail-cell-renderer/detail-cell-renderer.component';
import { RendererActionBarComponent } from './renderer-action-bar/renderer-action-bar.component';
import { FilterGridStatusComponent } from './filter-grid-status/filter-grid-status.component';
import { HiringOutcomePollModalComponent } from './hiring-outcome-poll-modal/hiring-outcome-poll-modal.component';
import { ManageMfaModalComponent } from './manage-mfa-modal/manage-mfa-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CheckboxViewRendererComponent} from './hiring-outcome-poll-modal/checkbox-view-renderer.component';
import {FilterHiringOutcomePollHiddenComponent} from './hiring-outcome-poll-modal/filter-hiring-outcome-poll-hidden.component';
import { FilterHiringOutcomeComponent } from './filter-hiring-outcome/filter-hiring-outcome.component';
import { DuplicateCandidateDialogComponent } from './duplicate-candidate-dialog/duplicate-candidate-dialog.component';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AddJobDescriptionModalComponent} from './add-job-description-modal/add-job-description-modal.component';
import {ProxyReportDialogComponent} from './proxy-report-dialog/proxy-report-dialog.component';
import {EmailReportDialogComponent} from './email-report-dialog/email-report-dialog.component';
import {SetStatusDialogComponent} from './set-status-dialog/set-status-dialog.component';
import {AdditionServiceDuplicateDialogComponent} from './edit-assessment-request/additional-service-duplicate-dialog/additional-service-duplicate-dialog.component';
import { NgxMaskModule } from 'ngx-mask';
import {EditProfileResolver} from '../users/resolvers.service';
import { HiredOfferModalComponent } from './hiring-outcome-poll-modal/hired-offer-modal.component';

const routes: Routes = [
  {
    path: 'assessment-requests', component: SecureLayoutComponent, canActivateChild: [AuthGuard], children: [
    {
      path: 'view/:id',
      component: ViewAssessmentRequestComponent,
      resolve: {
        assessmentRequest: ViewAssessmentRequestResolver
      }
    },
    {
      path: 'edit/:id',
      component: EditAssessmentRequestComponent,
      resolve: {
        clients: ClientSelectItemsResolver,
        languages: LanguageSelectItemsResolver,
        users: UserSelectItemsResolver,
        assessmentRequest: AssessmentRequestResolver,
        assessmentLevelReferences: AssessmentLevelReferencesResolver,
        additionalServiceReferences: AdditionalServiceReferencesResolver,
        states: StateSelectItemsResolver,
        countries: CountrySelectItemsResolver
      },
      canDeactivate: [CanDeactivateComponentGuard]
    },
    {
      path: 'add',
      component: EditAssessmentRequestComponent,
      resolve: {
        clients: ClientSelectItemsResolver,
        languages: LanguageSelectItemsResolver,
        users: UserSelectItemsResolver,
        assessmentRequest: 'newAssessmentRequestResolver',
        assessmentLevelReferences: AssessmentLevelReferencesResolver,
        additionalServiceReferences: AdditionalServiceReferencesResolver,
        states: StateSelectItemsResolver,
        countries: CountrySelectItemsResolver
      },
      canDeactivate: [CanDeactivateComponentGuard]
    },
    {
      path: 'copy/:id',
      component: EditAssessmentRequestComponent,
      resolve: {
        clients: ClientSelectItemsResolver,
        languages: LanguageSelectItemsResolver,
        users: UserSelectItemsResolver,
        assessmentRequest: AssessmentRequestResolver,
        assessmentLevelReferences: AssessmentLevelReferencesResolver,
        additionalServiceReferences: AdditionalServiceReferencesResolver,
        states: StateSelectItemsResolver,
        countries: CountrySelectItemsResolver
      },
      canDeactivate: [CanDeactivateComponentGuard]
    },
    {
      path: 'additional-service/selection-promotion/:id',
      component: EditAssessmentRequestComponent,
      resolve: {
        clients: ClientSelectItemsResolver,
        languages: LanguageSelectItemsResolver,
        users: UserSelectItemsResolver,
        assessmentRequest: AdditionalServiceAssessmentRequestResolver,
        assessmentLevelReferences: AssessmentLevelReferencesResolver,
        additionalServiceReferences: AdditionalServiceReferencesResolver,
        states: StateSelectItemsResolver,
        countries: CountrySelectItemsResolver
      },
      canDeactivate: [CanDeactivateComponentGuard]
    },
    {
      path: 'additional-service/development-feedback/:id',
      component: EditAssessmentRequestComponent,
      resolve: {
        clients: ClientSelectItemsResolver,
        languages: LanguageSelectItemsResolver,
        users: UserSelectItemsResolver,
        assessmentRequest: AdditionalServiceAssessmentRequestResolver,
        assessmentLevelReferences: AssessmentLevelReferencesResolver,
        additionalServiceReferences: AdditionalServiceReferencesResolver,
        states: StateSelectItemsResolver,
        countries: CountrySelectItemsResolver
      },
      canDeactivate: [CanDeactivateComponentGuard]
    },
    {
      path: 'additional-service/upgrade-request/:id',
      component: EditAssessmentRequestComponent,
      resolve: {
        clients: ClientSelectItemsResolver,
        languages: LanguageSelectItemsResolver,
        users: UserSelectItemsResolver,
        assessmentRequest: AdditionalServiceAssessmentRequestResolver,
        assessmentLevelReferences: AssessmentLevelReferencesResolver,
        additionalServiceReferences: AdditionalServiceReferencesResolver,
        states: StateSelectItemsResolver,
        countries: CountrySelectItemsResolver
      },
      canDeactivate: [CanDeactivateComponentGuard]
    },
    // {
    //   path: 'additional-service/development-feedback/:id',
    //   component: CreateDevelopmentFeedbackComponent,
    //   resolve: {
    //     assessmentRequest: AdditionalServiceAssessmentRequestResolver,
    //     additionalServiceReferences: AdditionalServiceReferencesResolver
    //   },
    //   canDeactivate: [CanDeactivateComponentGuard]
    // },
    {
      path: 'testing-schedule/:id/:candidateName',
      component: EditTestingScheduleComponent,
      canActivate: [EditTestingScheduleGuard],
      resolve: {
        testingSchedule: AssessmentTestingScheduleResolver,
        emailTemplates: EmailTemplateSelectItemsResolver
      }
    },
    {
      path: 'testing-schedule/:id',
      component: EditTestingScheduleComponent,
      canActivate: [EditTestingScheduleGuard],
      resolve: {
        testingSchedule: AssessmentTestingScheduleResolver,
        emailTemplates: EmailTemplateSelectItemsResolver
      }
    },
    {
      path: 'upload-reports/:id',
      component: UploadReportsComponent,
      canActivate: [UploadReportsGuard],
      resolve: {
        assessmentRequest: AssessmentRequestResolver,
        assessmentLevelReferences: AssessmentLevelReferencesResolver,
        additionalServiceReferences: AdditionalServiceReferencesResolver,
        assessmentLevelAttachments: AssessmentLevelAttachmentResolver,
        additionalServiceAttachments: AdditionalServiceAttachmentResolver,
        assessmentLevelReportTypes: AssessmentLevelReportTypeResolver,
        additionalServiceReportTypes: AdditionalServiceReportTypeResolver,
        testingSchedule: AssessmentTestingScheduleResolver,
      }
    },
    {
      path: 'download-reports/:id',
      component: DownloadReportsComponent,
      canActivate: [DownloadReportsGuard],
      resolve: {
        assessmentLevelAttachments: AssessmentLevelAttachmentResolver,
        additionalServiceAttachments: AdditionalServiceAttachmentResolver,
        testingSchedule: AssessmentTestingScheduleResolver,
        additionalServiceReferences: AdditionalServiceReferencesResolver
      }
    },
    {
      path: '',
      component: ListAssessmentRequestComponent,
      resolve: {
        assessmentRequests: AssessmentRequestListResolver
      }
    },
    {
      path: 'exit-impersonate',
      component: ListAssessmentRequestComponent,
      resolve: {
        assessmentRequests: AssessmentRequestListResolver
      }
    },
    {
      path: 'hiring-outcome/:source',
      component: ListAssessmentRequestComponent,
      resolve: {
        assessmentRequests: AssessmentRequestListResolver
      }
    },
    {
      path: 'exit-offer-modal',
      component: ListAssessmentRequestComponent,
      resolve: {
        assessmentRequests: AssessmentRequestListResolver
      }
    },
    {
      path: 'manage-mfa',
      component: ListAssessmentRequestComponent,
      resolve: {
        assessmentRequests: AssessmentRequestListResolver,
        user: EditProfileResolver,
      }
    }

  ]
  }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        SharedModule,
        PiquantTableModule,
        PositionsModule,
        CompetenciesModule,
        OrgUnitsModule,
        FeedbackModule,
        BillingContactsModule,
        AgGridModule.withComponents([DetailCellRendererComponent, ViewButtonRendererComponent, RendererActionBarComponent, FilterGridStatusComponent, CheckboxViewRendererComponent, FilterHiringOutcomeComponent, FilterHiringOutcomePollHiddenComponent]),
        MatDialogModule,
        MatInputModule,
        MatAutocompleteModule,
        BrowserAnimationsModule,
        NgxMaskModule.forRoot()
    ],
    exports: [],
    declarations: [
        ListAssessmentRequestComponent,
        EditAssessmentRequestComponent,
        ViewAssessmentRequestComponent,
        ViewFeedbackRecipientComponent,
        DeleteAssessmentRequestDialogComponent,
        StatusChangeRequestDialogComponent,
        EditTestingScheduleComponent,
        UploadReportsComponent,
        DownloadReportsComponent,
        UploadReportDialogComponent,
        SelectAdditionalServiceTypeDialogComponent,
        ConfirmNoResumeDialogComponent,
        FormInvalidComponent,
        ViewButtonRendererComponent,
        DetailCellRendererComponent,
        RendererActionBarComponent,
        FilterGridStatusComponent,
        HiringOutcomePollModalComponent,
        HiredOfferModalComponent,
        ManageMfaModalComponent,
        AddJobDescriptionModalComponent,
        CheckboxViewRendererComponent,
        FilterHiringOutcomePollHiddenComponent,
        FilterHiringOutcomeComponent,
        DuplicateCandidateDialogComponent,
        ProxyReportDialogComponent,
        EmailReportDialogComponent,
        SetStatusDialogComponent,
        AdditionServiceDuplicateDialogComponent
    ],
    providers: [
        SiteUtilityService,
        AssessmentRequestResolver,
        AssessmentRequestListResolver,
        ViewAssessmentRequestResolver,
        {
            provide: 'newAssessmentRequestResolver',
            useValue: EditAssessmentRequestModel.Create
        },
        AssessmentRequestPermissionsService,
        EditTestingScheduleGuard,
        UploadReportsGuard,
        DownloadReportsGuard,
        AssessmentLevelAttachmentResolver,
        AdditionalServiceAttachmentResolver,
        AssessmentLevelReportTypeResolver,
        AdditionalServiceReportTypeResolver,
        AdditionalServiceAssessmentRequestResolver,
        DuplicateCandidateDialogComponent,
        NgbActiveModal,
        EditProfileResolver
    ]
})
export class AssessmentRequestsModule {
}
