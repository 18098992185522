import {NgbAccordionModule, NgbAlertModule, NgbCollapseModule, NgbDatepickerModule, NgbDropdownModule, NgbModalModule, NgbPaginationModule, NgbPopoverModule, NgbProgressbarModule, NgbTimepickerModule, NgbTooltipModule, NgbTypeaheadModule} from '@ng-bootstrap/ng-bootstrap';
import {ModuleWithProviders, NgModule} from '@angular/core';

const NGB_MODULES = [
  NgbAccordionModule, NgbAlertModule, NgbCollapseModule, NgbDropdownModule, NgbModalModule, NgbPaginationModule,
  NgbPopoverModule, NgbProgressbarModule, NgbTooltipModule, NgbTypeaheadModule, NgbDatepickerModule,
  NgbTimepickerModule
];

@NgModule({
  imports: [
    NgbAlertModule, NgbCollapseModule, NgbProgressbarModule,
    NgbTooltipModule, NgbTypeaheadModule, NgbAccordionModule,
    NgbDropdownModule, NgbModalModule, NgbPaginationModule,
    NgbPopoverModule, NgbProgressbarModule,
    NgbTooltipModule, NgbDatepickerModule, NgbTimepickerModule
  ],
  exports: NGB_MODULES
})
export class CNgbRootModule {
}

@NgModule({imports: NGB_MODULES, exports: NGB_MODULES})
export class CustomNgbModule {
  static forRoot(): ModuleWithProviders<CNgbRootModule> {
    return {ngModule: CNgbRootModule};
  }
}
