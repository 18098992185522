<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
  <span class="close-icon" aria-label="Close" (click)="confirm(false)" style="cursor: pointer;">
    &times;
  </span>
</div>
<div class="modal-body">
  <p>{{body}}</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="confirm(true)">{{affirmativeButtonText}}</button>
  <button type="button" class="btn btn-default" (click)="confirm(false)">{{negativeButtonText}}</button>
</div>
