import {IAddressModel} from '../interfaces/address.interface';

export class AddressModel implements IAddressModel {
  public addressId?: number;
  public address1: string;
  public address2: string;
  public city: string;
  public stateId: number;
  public zipCode: string;
  public countryId: number;
  public province: string;
  public phoneHome: string;
  public phoneWork: string;
  public phoneMobile: string;

  constructor(obj?: IAddressModel) {
    if (!obj) {
      return;
    }

    if (obj.addressId) {
      this.addressId = obj.addressId;
    }

    this.address1 = obj.address1;
    this.address2 = obj.address2;
    this.city = obj.city;
    this.stateId = obj.stateId;
    this.zipCode = obj.zipCode;
    this.countryId = obj.countryId;
    this.province = obj.province;
    this.phoneHome = obj.phoneHome;
    this.phoneWork = obj.phoneWork;
    this.phoneMobile = obj.phoneMobile
  }
}
