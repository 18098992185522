import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AssessmentRequestService} from '../../core/assessment-request.service';

@Component({
  selector: 'app-delete-assessment-request-dialog',
  templateUrl: './delete-assessment-request-dialog.component.html',
  styleUrls: ['./delete-assessment-request-dialog.component.scss']
})
export class DeleteAssessmentRequestDialogComponent implements OnInit {
  @Input() public assessmentRequestId: number;
  @Input() public candidateName: string;
  @Output() public onDelete = new EventEmitter<number>();

  constructor(public activeModal: NgbActiveModal,
              public assessmentRequestService: AssessmentRequestService) {
  }

  public ngOnInit() {
    if (!this.assessmentRequestId) {
      throw new Error('assessmentRequestId must be specified');
    }

    if (!this.candidateName) {
      throw new Error('candidateName must be specified');
    }
  }

  public onConfirm() {
    this.assessmentRequestService.delete(this.assessmentRequestId)
      .subscribe(() => {
        if (this.onDelete) {
          this.onDelete.emit(this.assessmentRequestId);
        }

        this.activeModal.close();
      });
  }

  public onCancel() {
    this.activeModal.dismiss();
  }
}
