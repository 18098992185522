import {NgModule} from '@angular/core';
import {ActionBarComponent} from './action-bar.component';
import {ActionComponent} from './action.component';
import {ActionGroupComponent} from './action-group/action-group.component';
import {ActionSecurityService} from './action.service';
import {ActionButtonComponent} from './action-button/action-button.component';
import {CommonModule} from '@angular/common';
import {CustomNgbModule} from '../custom-ngb.module';
import {ActionBarTextComponent} from './action-bar-text/action-bar-text.component';
import {ActionBarControlComponent} from './action-bar-control/action-bar-control';

@NgModule({
  imports: [CommonModule, CustomNgbModule],
  declarations: [
    ActionBarComponent,
    ActionButtonComponent,
    ActionComponent,
    ActionGroupComponent,
    ActionBarTextComponent,
    ActionBarControlComponent
  ],
  exports: [
    ActionBarComponent,
    ActionComponent,
    ActionGroupComponent,
    ActionButtonComponent,
    ActionBarTextComponent,
    ActionBarControlComponent
  ],
  providers: [ActionSecurityService]
})
export class ActionBarModule {
}
