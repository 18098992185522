import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ISelectItemModel, IUserSelectItemModel} from '../../../core/interfaces/select-item.interface';
import {IOrgUnitModel} from '../../../core/interfaces/org-unit.interface';
import {Injectable} from '@angular/core';
import {ClientService} from '../../../core/client.service';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {SectionBase} from './base.section';
import {EditAssessmentRequestModel} from '../../edit-assessment-request.model';
import {AuthService} from '../../../auth/auth.service';
import {AppService} from '../../../core/app.service';
import {isNullOrUndefined} from 'util';

type IClientChange = (client: ISelectItemModel) => void;
type IUserChange = (user: IUserSelectItemModel) => void;
type IOrgUnitChange = (orgUnitId: number) => void;
type IOrgUnitInit = () => void;

@Injectable()
export class ClientSection extends SectionBase {
  private _users: IUserSelectItemModel[];
  private _clients: ISelectItemModel[];
  private _orgUnits: IOrgUnitModel[];
  private _onClientChange: IClientChange;
  private _onRequestedByChange: IUserChange;
  private _onOrgUnitChange: IOrgUnitChange;
  private _onOrgUnitInit: IOrgUnitInit;
  private _modal: NgbModalRef;

  constructor(private fb: UntypedFormBuilder,
              private clientService: ClientService,
              private authService: AuthService,
              private appService: AppService) {
    super();
  }

  public get clientControl() {
    return this.formGroup.controls['client'] as UntypedFormControl;
  }

  public get orgUnitControl() {
    return this.formGroup.controls['orgUnit'] as UntypedFormControl;
  }

  public get requestedByUserControl() {
    return this.formGroup.controls['requestedBy'] as UntypedFormControl;
  }

  public get costCenterControl() {
    return this.formGroup.controls['costCenter'] as UntypedFormControl;
  }

  public get generalLedgerControl() {
    return this.formGroup.controls['generalLedger'] as UntypedFormControl;
  }

  public get activityCenterControl() {
    return this.formGroup.controls['activityCenter'] as UntypedFormControl;
  }

  public get customCandidateIdControl() {
    return this.formGroup.controls['customCandidateId'] as UntypedFormControl;
  }

  public get customPoControl() {
    return this.formGroup.controls['customPo'] as UntypedFormControl;
  }

  public get costCenterOwnerControl() {
    return this.formGroup.controls['costCenterOwner'] as UntypedFormControl;
  }

  public get companyCodeControl() {
    return this.formGroup.controls['companyCode'] as UntypedFormControl;
  }

  public get orgUnits(): IOrgUnitModel[] {
    return this._orgUnits || [];
  }

  public get orgUnit(): IOrgUnitModel {
    const orgUnitId  = this.orgUnitControl.value;
    return orgUnitId
      ? this.orgUnits.find((ou) => ou.orgUnitId == orgUnitId)
      : null;
  }

  public init({
                assessmentRequest,
                users,
                clients,
                onClientChange,
                onRequestedByChange,
                onOrgUnitChange,
                onOrgUnitInit,
              }: {
    assessmentRequest: EditAssessmentRequestModel,
    users: IUserSelectItemModel[],
    clients: ISelectItemModel[],
    onClientChange?: IClientChange,
    onRequestedByChange?: IUserChange,
    onOrgUnitChange?: IOrgUnitChange,
    onOrgUnitInit?: IOrgUnitInit
  }) {
    super.setAssessmentRequest(assessmentRequest);
    this.formGroup = this.buildFormGroup();

    if (!users) {
      throw new Error('users must be specified');
    }

    this._users = users;

    if (!clients) {
      throw new Error('clients must be specified');
    }

    this._clients = clients;

    this._onClientChange = onClientChange;
    this._onRequestedByChange = onRequestedByChange;
    this._onOrgUnitChange = onOrgUnitChange;
    this._onOrgUnitInit = onOrgUnitInit;

    const client = this.assessmentRequest.clientId
      ? this._clients.find((c) => c.id === this.assessmentRequest.clientId)
      : null;

    if (client) {
      this.clientControl.setValue(client);
    }

    this.orgUnitControl.setValue(this.assessmentRequest.orgUnitId);

    const requestedByUser = this.assessmentRequest.requestedByUserId
      ? this._users.find((u) => u.id === this.assessmentRequest.requestedByUserId)
      : null;

    if (requestedByUser) {
      this.requestedByUserControl.setValue(requestedByUser);
    }
    this.costCenterControl.setValue(this.assessmentRequest.costCenterText);
    this.generalLedgerControl.setValue(this.assessmentRequest.generalLedger);
    this.activityCenterControl.setValue(this.assessmentRequest.activityCenter);
    this.customCandidateIdControl.setValue(this.assessmentRequest.customCandidateId);
    this.customPoControl.setValue(this.assessmentRequest.customPo);
    this.costCenterOwnerControl.setValue(this.assessmentRequest.costCenterOwner);
    this.companyCodeControl.setValue(this.assessmentRequest.companyCode);

    if (!this.clientControl.value) {
      this.clientControl.valueChanges.subscribe((newClient) => this.onClientChange(newClient));
    }

    if (!this.requestedByUserControl.value.value) {
      this.requestedByUserControl.valueChanges.subscribe((newRequestedByUser) => this.onRequestedByUserChange(newRequestedByUser));
    }

    if (!this.orgUnitControl.value) {
      this.orgUnitControl.valueChanges.subscribe((newOrgUnitId) => this.onOrgUnitChange(newOrgUnitId));
    }

    if (this.assessmentRequest.clientId) {
      this.getOrgUnits(this.assessmentRequest.clientId, this.assessmentRequest.orgUnitId);
    }

    // client users must select an org unit, if they have one
    if (this.authService.isRoleClient && this.authService.orgUnitIds && this.authService.orgUnitIds.length) {
      this.orgUnitControl.setValidators(Validators.required);
    }

    if (this.assessmentRequest.hasParent) {
      //Um - this makes the form (but not nescessarily the parent form) invalid...
      //this.formGroup.disable();
    }


    this.appService.onLoggingOut.subscribe(() => {
      if (!this._modal) {
        return;
      }
      this._modal.dismiss('logging out');
    });

  }

  public get formValue(): any {
    const values = this.formGroup.getRawValue();
    return {
      clientId: values.client ? values.client.id : null,
      orgUnitId: values.orgUnit,
      requestedByUserId: values.requestedBy ? values.requestedBy.id : null,
      costCenterText: values.costCenter,
      generalLedger: values.generalLedger,
      activityCenter: values.activityCenter,
      customCandidateId: values.customCandidateId,
      customPo: values.customPo,
      costCenterOwner: values.costCenterOwner,
      companyCode: values.companyCode
    };
  }

  protected buildFormGroup(): UntypedFormGroup {
    return this.fb.group({
      client: [{value: null, disabled: !this.assessmentRequest.isNew}, Validators.required], //, disabled: !this.assessmentRequest.isNew
      orgUnit: [{value: null}], //, disabled: !this.assessmentRequest.isNew
      requestedBy: [{value: null}, Validators.required], //, disabled: !this.assessmentRequest.isNew
      costCenter: [null, Validators.maxLength(45)],
      generalLedger: [null, Validators.maxLength(45)],
      activityCenter: [null, Validators.maxLength(45)],
      customCandidateId: [null, Validators.maxLength(45)],
      customPo: [null, Validators.maxLength(45)],
      costCenterOwner: [null, Validators.maxLength(45)],
      companyCode: [null, Validators.maxLength(45)],
    });
  }

  private onClientChange(newClient: ISelectItemModel) {
    const requestedByUserControl = this.requestedByUserControl;
    requestedByUserControl.reset();

    this.orgUnitControl.reset();

    if (newClient) {
      this.getOrgUnits(newClient.id, null);
      requestedByUserControl.enable();
    } else {
      requestedByUserControl.disable();
    }

    if (this._onClientChange) {
      this._onClientChange(newClient);
    }
  }
  public specialOrgUnitHandling
  public getOrgUnits(clientId: number, orgUnitId: number | null) {
    this.clientService.getOrgUnits(clientId, orgUnitId).subscribe((orgUnits: IOrgUnitModel[]) => {
      this._orgUnits = orgUnits;
      this.handleOrgUnitValidity(this._orgUnits);
      if (this._onOrgUnitInit) {
        this._onOrgUnitInit();
      }
    });
  }

  private handleOrgUnitValidity(orgUnits: IOrgUnitModel[]) {
    if (isNullOrUndefined(orgUnits)) {
      return;
    }

    if (this._orgUnits.length === 0) {
      this.orgUnitControl.setValue(null);
      this.orgUnitControl.setValidators(null);
      this.orgUnitControl.updateValueAndValidity();
      return;
    }

    // if (this._orgUnits.length === 1) {
    //   this.orgUnitControl.setValue(this._orgUnits[0].orgUnitId);
    // }

    this.orgUnitControl.enable(); //LA032 Requests that OrgUnit be modifiable - gaurds above prevent this control from being enabled too early.
    //this.orgUnitControl.setValidators(Validators.required);
    this.orgUnitControl.updateValueAndValidity();
  }

  private onRequestedByUserChange(newRequestedByUser: IUserSelectItemModel) {
    if (this._onRequestedByChange) {
      this._onRequestedByChange(newRequestedByUser);
    }
  }

  private onOrgUnitChange(newOrgUnitId: number) {
    if (this._onOrgUnitChange) {
      this._onOrgUnitChange(newOrgUnitId);
    }
  }
}
