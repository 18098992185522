import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';
import { AccountService } from '../../core/account.service';
import { PsValidators } from '../../core/validators/password.validator';
import { ChangePasswordTokenRequest, ChangePasswordTokenResponse } from '../../core/models/change-password-token.models';

@Component({
  selector: 'forgot-password-reset',
  templateUrl: './forgot-password-reset.component.html',
  styleUrls: ['./forgot-password-reset.component.scss'],
  encapsulation: ViewEncapsulation.None 
})
export class ForgotPasswordResetComponent implements OnInit {
  public invalid = true;
  public resetSuccess = false;
  public resetFailed = false;
  public failedComplexity = false;
  public errorMessage = "";
  public changePasswordForm: UntypedFormGroup;
  private _token: string;

  constructor(private _route: ActivatedRoute,
    private _router: Router,
    private _fb: UntypedFormBuilder,
    private _authService: AuthService) { }

  public ngOnInit() {
    this._route.queryParams.subscribe((params: Params) => {
      this._token = params['token'];
      if (isNullOrUndefined(this._token)) {
        this.invalid = true;
        return;
      }

      // Validate Token with server.
      this._authService.validateForgotPasswordToken(this._token).subscribe((data: any) => {
        this.invalid = !data.success;
        if (!this.invalid) {
          this.buildForm();
        }
      });
    });
  }

  public newPasswordChange() {
    const confirmPassword = this.getControl('confirmPassword');
    confirmPassword.updateValueAndValidity();
  }

  public getControl(key: string): UntypedFormControl {
    return this.changePasswordForm.get(key) as UntypedFormControl;
  }

  public submitReset() {
    if (this.changePasswordForm.invalid) {
      return;
    }
    const model = new ChangePasswordTokenRequest();
    model.token = this._token;
    model.username = this.changePasswordForm.controls['username'].value;
    model.newPassword = this.changePasswordForm.controls['newPassword'].value;
    model.confirmPassword = this.changePasswordForm.controls['confirmPassword'].value;
    var good = this.validatePassword(model.newPassword, '^((?=.*[a-z])|(?=.*[A-Z]))(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*_\\-=])(?=.{6,})');
    this.failedComplexity = !good;
    if (good) {
      this._authService.setPassword(model).subscribe((res: ChangePasswordTokenResponse) => {
        if (res.success) {
          this.resetSuccess = true;
          this.errorMessage = null;
          this.invalid = false;
          this.resetFailed = false;

        } else {
          this.invalid = false;
          this.resetFailed = true;
          this.errorMessage = res.message;
        }
      });
    }
  }

  private validatePassword(password: string, pattern: string): boolean {
    const regEx = new RegExp(pattern);
    const match = regEx.test(password);
    return match;
  };

  private buildForm() {
    this.changePasswordForm = this._fb.group({
      username: [null, Validators.required],
      newPassword: [null, [Validators.required,
        PsValidators.password('^((?=.*[a-z])|(?=.*[A-Z]))(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*_\\-=])(?=.{6,})')]],
      confirmPassword: [null, [Validators.required, PsValidators.passwordCompare('newPassword')]]
    });
  }

}
