
import {defer as observableDefer, Observable} from 'rxjs';
import {map, publishReplay, refCount, take} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ServiceBase} from './service-base';
import {AuthHttpService} from '../auth/auth-http.service';
import {IStateModel} from './interfaces/state.interface';
import {ISelectItemModel} from './interfaces/select-item.interface';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class StateService extends ServiceBase {
  private static ApiUrl = 'api/states';
  private data$: Observable<ISelectItemModel[]>;

  constructor(private http: HttpClient) {
    super();
  }

  public get(): Observable<IStateModel[]> {
    return this.http.get<IStateModel[]>(StateService.ApiUrl);
  }

  public getSelectItems(): Observable<ISelectItemModel[]> {
    if (!this.data$) {
      const url = StateService.ApiUrl + '/list';

      this.data$ = observableDefer(() => this.http.get(url)).pipe(
        map((res: Response) => (res || []) as ISelectItemModel[]),
        publishReplay(1),
        refCount(),
        take(1)
      );
    }

    return this.data$;
  }
}
