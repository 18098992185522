import {Injectable} from '@angular/core';
import {AuthHttpService} from '../auth/auth-http.service';
import {Observable} from 'rxjs';
import {map, publishReplay, refCount, take, debounceTime} from 'rxjs/operators';
import {IPositionListItemModel} from './interfaces/position-list-item.interface';
import {ServiceBase} from './service-base';
import {IEditPositionModel} from './interfaces/edit-position.interface';
import {EditPositionModel} from './models/edit-position.model';
import {ICompetencyModel} from './interfaces/competency.interface';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class PositionService extends ServiceBase {
  private static ApiUrl = 'api/position';

  constructor(private http: HttpClient) {
    super();
  }

  public getListItems(clientId?: number): Observable<IPositionListItemModel[]> {
    let url = PositionService.ApiUrl;

    if (clientId) {
      url += `?clientId=${clientId}`;
    }

    return this.http.get<IPositionListItemModel[]>(url);
  }

  public getPositionList(clientId?: number): Observable<IPositionListItemModel[]> {
    const url = `${PositionService.ApiUrl}/positionList`;

    return this.http.get<IPositionListItemModel[]>(url);
  }

  public get(id: number): Observable<IEditPositionModel> {
    const url = `${PositionService.ApiUrl}/${id}`;

    return this.http.get<IEditPositionModel>(url).pipe(
      map(position => { return new EditPositionModel(position) as IEditPositionModel;})
    );
  }

  public save(position: EditPositionModel): Observable<EditPositionModel> {
    return position.positionId
      ? this.update(position)
      : this.add(position);
  }

  private add(position: EditPositionModel): Observable<EditPositionModel> {
    const json = JSON.stringify(position);
    return this.http.post<EditPositionModel>(PositionService.ApiUrl, json, {headers: this.jsonHeaders});
  }

  private update(position: EditPositionModel): Observable<EditPositionModel> {
    const url = `${PositionService.ApiUrl}/${position.positionId}`;
    const json = JSON.stringify(position);

    return this.http.put<EditPositionModel>(url, json, {headers: this.jsonHeaders});
  }

  public updatePositionStatus(position: EditPositionModel): Observable<IEditPositionModel> {
    const url = `${PositionService.ApiUrl}/updatePositionStatus/${position.positionId}`;
    const json = JSON.stringify(position);

    return this.http
      .put<IEditPositionModel>(url, json, {headers: this.jsonHeaders});
  }

  public checkPositionNameExists(name: string, clientId: number): Observable<boolean> {
    const url = `${PositionService.ApiUrl}/checkname?name=${name}&clientId=${clientId}`;
    return this.http.get<boolean>(url).pipe(
      debounceTime(250)
    );
  }

  public getFunctions(clientId: number): Observable<string[]> {
    const url = `${PositionService.ApiUrl}/function/${clientId}`;

    return this.http.get<string[]>(url);
  }

  public getCompetencies(positionId: number): Observable<ICompetencyModel[]> {
    const url = `${PositionService.ApiUrl}/${positionId}/competencies`;

    return this.http.get<ICompetencyModel[]>(url);
  }

}
