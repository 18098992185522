import {Injectable} from '@angular/core';
import {DataSource} from '../data/data-source';
import {Column, FilterConfig} from '../models';
import {Subject} from 'rxjs';
import {isNullOrUndefined} from 'util';

@Injectable()
export class PsTableFilterService {
  private _dataSource: DataSource;
  private _columns: Column[];
  private _filters: { [key: string]: FilterConfig } = {};
  private resetSubject = new Subject();
  private removeFilter = new Subject<string>();

  public resetFilters$ = this.resetSubject.asObservable();
  public removeFilter$ = this.removeFilter.asObservable();

  public get dataSource(): DataSource {
    return this._dataSource;
  }

  public set dataSource(value: DataSource) {
    this._dataSource = value;
  }

  public set columns(value: Column[]) {
    this._columns = value;
  }

  public getColumn(columnName: string): Column {
    if (!this._columns) {
      return null;
    }
    return this._columns.find((c) => c.id.toLowerCase() === columnName.toLowerCase());
  }

  public addPendingFilter(columnName: string, filter: FilterConfig) {
    this._filters[columnName] = filter;
  }

  public removePendingFilter(columnName: string) {
    const filter = this._filters[columnName];
    if (isNullOrUndefined(filter)) {
      return;
    }
    this._dataSource.removeFilter(filter, true);
    delete this._filters[columnName];
    this.removeFilter.next(columnName);
  }

  public clearPendingFilters() {
    this._filters = {};
    this.applyPendingFilters();
    this.resetSubject.next();
  }

  public getAppliedFilters(): FilterConfig[] {
    return this._dataSource.getFilterConfigs();
  }

  public getAppliedFilter(columnName: string): FilterConfig {
    return this._filters[columnName];
  }

  public applyPendingFilters() {
    const propNames = Object.getOwnPropertyNames(this._filters);
    const pending = propNames.map((p) => {
      const filter = this._filters[p];
      return filter;
    });
    const applied = this._dataSource.getFilterConfigs();
    const removed = applied.filter(e => {
      const idx = pending.findIndex(p => {
        return p.field.toLowerCase() === e.field.toLowerCase();
      });
      return idx === -1;
    });
    // Add or existing filters
    const added = pending.filter(e => {
      return applied.indexOf(e) === -1 || applied.indexOf(e) > -1;
    });
    this._dataSource.removeFilters(removed, false);
    this._dataSource.addFilters(added, true);
  }
}
