<h1 class="logo">
  <a [routerLink]="['/']"><img src="../assets/la-logo.png" alt="Leadership Alliance"/></a>
  <span class="sr-only">Leadership Alliance</span>
</h1>
<div class="left-nav">
  <div class="nav-collapse text-right">
    <a class="btn-nav-collapse">
      <i class="fa fa-lg "></i>
      <span class="sr-only">Collapse Menu</span>
    </a>
  </div>
  <menu class="nav flex-column">
    <li class="nav-item" [routerLinkActive]="['active']">
      <a class="nav-link" [routerLink]="['/assessment-requests']">
        <i class="fa fa-file-text-o fa-lg"></i>
        Assessment Requests
      </a>
    </li>
    <li class="nav-item" [routerLinkActive]="['active']" *ngIf="isAdmin">
      <a class="nav-link" [routerLink]="['/competencies']">
        <i class="fa fa-cubes fa-lg"></i>
        Competencies
      </a>
    </li>
    <li class="nav-item" [routerLinkActive]="['active']">
      <a class="nav-link" [routerLink]="['/positions']">
        <i class="fa fa-briefcase fa-lg"></i>
        Positions
      </a>
    </li>
    <li class="nav-item" [routerLinkActive]="['active']" *ngIf="isAdmin">
      <a class="nav-link" [routerLink]="['/users']">
        <i class="fa fa-users fa-lg"></i>
        Users
      </a>
    </li>
    <li class="nav-item" [routerLinkActive]="['active']" *ngIf="isAdmin">
      <a class="nav-link" [routerLink]="['/clients']">
        <i class="fa fa-address-book-o fa-lg"></i>
        Clients
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link nav-toggle" (click)="toggle('feedback')">
        <i class="fa fa-address-card-o fa-lg"></i>
        Recipients of Results
      </a>
      <div class="menu collapse" [ngbCollapse]="feedback">
        <a class="nav-link" [routerLink]="['/feedback-recipients']" [routerLinkActive]="['active']">Set Defaults</a>
        <a class="nav-link" [routerLink]="['/feedback-recipients/non-system']" [routerLinkActive]="['active']">Non-System Recipients</a>
      </div>
    </li>
    <li class="nav-item" [routerLinkActive]="['active']" *ngIf="isAdmin">
      <a class="nav-link" [routerLink]="['/emails']">
        <i class="fa fa-envelope-o fa-lg"></i>
        Emails
      </a>
    </li>
    <li class="nav-item" [routerLinkActive]="['active']" *ngIf="isAdmin">
        <a class="nav-link" [routerLink]="['/api-admin']">
          <i class="fa fa fa-cogs fa-lg"></i>
          Api Admin
        </a>
      </li>
    <li class="nav-item" [routerLinkActive]="['active']" *ngIf="isAdmin">
      <a class="nav-link" [routerLink]="['/out-of-office']">
        <i class="fa fa fa-bullhorn fa-lg"></i>
          Out Of Office
      </a>
    </li>
    <li class="nav-item" [routerLinkActive]="['active']">
      <a class="nav-link" [routerLink]="['/assessment-requests/hiring-outcome', 'portal']">
        <i class="fa fa-handshake-o fa-lg"></i>
        Hiring Outcome
      </a>
    </li>
    <li class="nav-item" [routerLinkActive]="['active']" *ngIf="isAdmin==false">
      <a class="nav-link" [routerLink]="['/help']">
        <i class="fa fa-question-circle-o fa-lg"></i>
        Help
      </a>
    </li>
  </menu>
</div>
