import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ICompetencyNode} from '../competency-node.interface';
import {CompetencyNode} from '../competency-node.model';
import {IClientCompetenciesModel} from '../../core/interfaces/client-competencies.interface';
import {ICompetencyModel} from '../../core/interfaces/competency.interface';
import {isNullOrUndefined} from 'util';

@Component({
  selector: 'competency-tree',
  templateUrl: './competency-tree.component.html',
  styleUrls: ['competency-tree.component.scss']
})
export class CompetencyTreeComponent implements OnInit {
  @Input() public clientCompetencies: IClientCompetenciesModel;
  @Input() public competencyIdsToExclude: number[];
  @Output() public onCompetencyClicked = new EventEmitter<ICompetencyModel>();

  private competencyNodes: CompetencyNode[];

  public ngOnInit() {
    this.competencyNodes = this.buildCompetencyHierarchy(this.clientCompetencies);
  }

  public competencyClicked(node: ICompetencyNode) {
    if (node.isCompetency) {
      const competencyListItem = node.data as ICompetencyModel;
      this.onCompetencyClicked.emit(competencyListItem);
    }
  }

  public get visibleCompetencyNodes() {
    return this.getVisibleCompetencyNodes(this.competencyNodes);
  }

  public printNodes(nodes: ICompetencyNode[]): string {
    let str = '';

    nodes.forEach((n) => {
      const parentId = n.parent ? n.parent.id : 'none';
      str += `Id: ${n.id}, Name: ${n.name}, Parent: ${parentId}, IsCompetency: ${n.isCompetency}, IsExpanded: ${n.isExpanded}\n`;

      if (n.children && n.children.length && n.isExpanded) {
        str += this.printNodes(n.children);
      }
    });

    return str;
  }

  private getVisibleCompetencyNodes(nodes: ICompetencyNode[]): ICompetencyNode[] {
    const filteredNodes = [];

    for (const node of nodes) {
      if (!node.isCompetency || this.competencyIdsToExclude.indexOf(node.id) === -1) {
        filteredNodes.push(node);

        if (node.children && node.children.length) {
          node.children = this.getVisibleCompetencyNodes(node.children);
        }
      }
    }

    return filteredNodes;
  }

  private buildCompetencyHierarchy(clientCompetencies: IClientCompetenciesModel): CompetencyNode[] {
    if (!clientCompetencies) {
      return [];
    }

    const nodes = [] as CompetencyNode[];

    clientCompetencies.competencies.forEach((c) => {

    });



    // clientCompetencies.factors.forEach((factor) => {
    //   const childClusterIds = clientCompetencies.factorClusters.filter((fc) => fc.factorId === factor.factorId);
    //
    //   if (childClusterIds.length) {
    //     const factorNode = new CompetencyNode({
    //       id: factor.factorId,
    //       name: factor.name,
    //       description: factor.description,
    //       data: factor,
    //       parent: null,
    //       children: [] as ICompetencyNode[],
    //       isExpanded: false
    //     } as ICompetencyNode);
    //
    //     // add node for each child cluster
    //     childClusterIds.forEach((childCluster) => {
    //       const cluster = clientCompetencies.clusters.find((c) => c.clusterId === childCluster.clusterId);
    //
    //       if (cluster) {
    //         const childCompetencyIds = clientCompetencies.clusterCompetencies.filter((cc) => cc.clusterId === cluster.clusterId);
    //
    //         if (childCompetencyIds.length) {
    //           const clusterNode = new CompetencyNode({
    //             id: cluster.clusterId,
    //             name: cluster.name,
    //             description: cluster.description,
    //             data: cluster,
    //             parent: factorNode,
    //             children: [] as CompetencyNode[],
    //             isExpanded: false
    //           } as ICompetencyNode);
    //
    //           // add node for each child competency
    //           childCompetencyIds.forEach((childCompetency) => {
    //             const competency = clientCompetencies.competencies.find((c) => c.competencyId === childCompetency.competencyId);
    //
    //             if (competency) {
    //               const competencyNode = new CompetencyNode({
    //                 id: competency.competencyId,
    //                 name: competency.name,
    //                 description: competency.description,
    //                 data: competency,
    //                 parent: clusterNode,
    //                 isExpanded: false
    //               } as ICompetencyNode);
    //
    //               clusterNode.children.push(competencyNode);
    //             }
    //           });
    //
    //           factorNode.children.push(clusterNode);
    //         }
    //       }
    //     });
    //
    //     nodes.push(factorNode);
    //   }
    // });

    return nodes;
  }
}

