import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'quick-filter-input',
  template: `
    <div class="quick-filter">
      <input type="text" placeholder="Quick Filter" [(ngModel)]="value" (keyup)="onKeyUp($event)"/><i class="fa fa-search"></i>
    </div>`
})
export class QuickFilterInputComponent {
  @Output() public onChanged = new EventEmitter<any>();
  public value: string;
  private timeout: any;
  private delay = 300;

  public onKeyUp(event) {
    if (event.which === 13) {
      this.onChanged.emit(this.value);
      // ignore tab component
    } else if (event.which !== 9) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.onChanged.emit(this.value);
      }, this.delay);
    }
    this.onChanged.emit(null);
    return false;
  }
}
