import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {AssessmentRequestService} from '../core/assessment-request.service';
import {Observable} from 'rxjs';
import {EditAssessmentRequestModel} from './edit-assessment-request.model';
import {AssessmentRequestListItemModel} from './assessment-request-list-item.model';
import {IViewAssessmentRequest} from './view-assessment-request.interface';
import {SiteUtilityService} from '../core/site-utility.service';
import {AssessmentRequestStatus} from '../core/enums/assessment-request-status.enum';
import {IAdditionalServiceAttachmentModel, IAssessmentLevelAttachmentModel} from '../core/interfaces/attachment-base.interface';
import {ISelectItemModel} from '../core/interfaces/select-item.interface';
import {AssessmentLevelReferenceService} from '../core/assessment-level-reference.service';
import {AdditionalServiceReferenceService} from '../core/additional-service-reference.service';

@Injectable()
export class AssessmentRequestResolver implements Resolve<EditAssessmentRequestModel> {
  constructor(private assessmentRequestService: AssessmentRequestService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<EditAssessmentRequestModel> {
    const id = +route.params['id'];
    return this.assessmentRequestService.get(id);
  }
}

@Injectable()
export class ViewAssessmentRequestResolver implements Resolve<IViewAssessmentRequest> {
  constructor(private assessmentRequestService: AssessmentRequestService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<IViewAssessmentRequest> {
    const id = +route.params['id'];
    return this.assessmentRequestService.getView(id);
  }
}

@Injectable()
export class AssessmentRequestListResolver implements Resolve<AssessmentRequestListItemModel[]> {
  constructor(private assessmentRequestService: AssessmentRequestService, private siteUtility: SiteUtilityService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<AssessmentRequestListItemModel[]> {
    const statuses = this.siteUtility.isMobileWidth
      ? [AssessmentRequestStatus.Complete]
      : [];

    return this.assessmentRequestService.getAll(statuses);
  }
}

@Injectable()
export class AdditionalServiceAttachmentResolver implements Resolve<IAdditionalServiceAttachmentModel[]> {
  constructor(private assessmentRequestService: AssessmentRequestService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<IAdditionalServiceAttachmentModel[]> {
    const id = +route.params['id'];
    return this.assessmentRequestService.getAdditionalServiceAttachments(id);
  }
}

@Injectable()
export class AssessmentLevelAttachmentResolver implements Resolve<IAssessmentLevelAttachmentModel[]> {
  constructor(private assessmentRequestService: AssessmentRequestService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<IAssessmentLevelAttachmentModel[]> {
    const id = +route.params['id'];
    return this.assessmentRequestService.getAssessmentLevelAttachments(id);
  }
}

@Injectable()
export class AssessmentLevelReportTypeResolver implements Resolve<ISelectItemModel[]> {
  constructor(private assessmentLevelRefService: AssessmentLevelReferenceService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<ISelectItemModel[]> {
    return this.assessmentLevelRefService.getTypes();
  }
}

@Injectable()
export class AdditionalServiceReportTypeResolver implements Resolve<ISelectItemModel[]> {
  constructor(private additionalServiceRefService: AdditionalServiceReferenceService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<ISelectItemModel[]> {
    return this.additionalServiceRefService.getTypes();
  }
}

@Injectable()
export class AdditionalServiceAssessmentRequestResolver implements Resolve<EditAssessmentRequestModel> {
  constructor(private assessmentRequestService: AssessmentRequestService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<EditAssessmentRequestModel> {
    const id = +route.params['id'];
    return this.assessmentRequestService.getAdditionalService(id);
  }
}
