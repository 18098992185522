import {Injectable} from '@angular/core';
import {ServiceBase} from './service-base';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {OrgUnitItem} from './interfaces/';
import {AuthHttpService} from '../auth/auth-http.service';
import {IOrgUnitModel} from './interfaces/org-unit.interface';
import {ISelectItemModel} from './interfaces/select-item.interface';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class OrgUnitService extends ServiceBase {
  private static OrgUnitApiUrl = 'api/org-units';

  constructor(private http: HttpClient) {
    super();
  }

  public getOrgUnits(clientId: number,  isActive: boolean = true): Observable<OrgUnitItem[]> {
    const url = `${OrgUnitService.OrgUnitApiUrl}?clientid=${clientId}&isActive=${isActive}`;
    return this.http.get<OrgUnitItem[]>(url);
  }

  public getOrgUnitsList(clientId: number): Observable<ISelectItemModel[]> {
    const url = `${OrgUnitService.OrgUnitApiUrl}/list?clientid=${clientId}`;
    return this.http.get<ISelectItemModel[]>(url);
  }

  public getOrgUnitsByUserId(userId: number): Observable<OrgUnitItem[]> {
    const url = `${OrgUnitService.OrgUnitApiUrl}/by-user?userid=${userId}`;
    return this.http.get<OrgUnitItem[]>(url);
  }

  public getOrgUnitsForUserId(userId: number): Observable<OrgUnitItem[]> {
    const url = `${OrgUnitService.OrgUnitApiUrl}/for-user?userid=${userId}`;
    return this.http.get<OrgUnitItem[]>(url);
  }

  public addOrgUnit(clientId: number, name: string, parentId?: number): Observable<OrgUnitItem> {
    const url = `${OrgUnitService.OrgUnitApiUrl}`;
    const json = JSON.stringify({clientId: clientId, name: name, parentId: parentId});
    return this.http.post<OrgUnitItem>(url, json, {headers: this.jsonHeaders});
  }

  public updateOrgUnit(orgUnitId: number, name: string): Observable<OrgUnitItem> {
    const url = `${OrgUnitService.OrgUnitApiUrl}/${orgUnitId}`;
    return this.http.put<OrgUnitItem>(url, `"${name}"`, {headers: this.jsonHeaders});
  }

  public deleteOrgUnit(orgUnitId: number): Observable<boolean> {
    const url = `${OrgUnitService.OrgUnitApiUrl}/${orgUnitId}`;
    return this.http.delete(url).pipe(
        map(() => {
        return true;
      })
    );
  }
}
