import {IUserModel} from '../interfaces/user.interface';

export class UserModel implements IUserModel {

  public userId: number;
  public clientId: number;
  public username: string;
  public first: string;
  public last: string;
  public middle: string;
  public email: string;
  public phoneWorkCountryCode: number;
  public phoneWork: string;
  public phoneMobileCountryCode: number;
  public phoneMobile: string;
  public clientName: string;
  public userLevelId: number;
  public isActive: boolean;
  public roleId: UserRole;
  public levelId?: number;
  public position: string;
  public addressId: number;
  public address1: string;
  public address2: string;
  public city: string;
  public state: string;
  public province: string;
  public countryId: number;
  public zipCode: string;
}

export enum UserRole {
  Client = 1,
  Admin = 2,
  Consultant = 3
}
