import {JwtHelper} from './jwt-helper';

export class AccessTokenDecoder {
  private _decodedAccessToken: any;

  public static isRoleAdmin(roleName: string): boolean {
    return roleName === 'Admin';
  }

  public static isRoleClient(roleName: string): boolean {
    return roleName === 'Client';
  }

  constructor(accessToken: string) {
    this._decodedAccessToken = this.decodeToken(accessToken);
  }

  public get userId(): number {
    const propName = 'user_id';
    return this.getClaimValue(propName);
  }

  public get username(): string {
    const propName = 'sub';
    return this.getClaimValue(propName);
  }

  public get displayName(): string {
    const propName = 'display_name';
    return this.getClaimValue(propName);
  }

  public get roleName(): string {
    const propName = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';
    return this.getClaimValue(propName);
  }

  public get clientId(): number {
    const propName = 'client_id';
    return this.getClaimValue(propName);
  }

  public get clientName(): string {
    const propName = 'client_name';
    return this.getClaimValue(propName);
  }

  public get levelId(): number {
    const propName = 'user_level_id';
    return this.getClaimValue(propName);
  }

  public get lastOutcomePollDate(): Date {
    const propName = 'last_outcome_poll_date';
    return new Date(this.getClaimValue(propName));
  }

  public get orgUnitIds(): number[] {
    const propName = 'org_unit_ids';
    const orgUnitIds = this.getClaimValue(propName);

    if (orgUnitIds) {
      // convert comma delimited strings to array of numbers
      return orgUnitIds.split(',')
        .filter((str) => str)
        .map((str) => +str);
    }

    return null;
  }

  public get expiresAt(): Date {
    const propName = 'exp';
    const exp: number = this.getClaimValue(propName);

    if (exp) {
      const date = new Date(0);
      date.setUTCSeconds(exp);
      return date;
    }

    return null;
  }

  public get shouldRefreshToken(): boolean {
    const oneMinute = new Date(this.expiresAt);
    oneMinute.setMinutes(oneMinute.getMinutes() - 1)
    return this.isExpired(oneMinute);
  }

  public get isTokenExpired(): boolean {
    return this.isExpired(this.expiresAt);
  }

  public get isRoleAdmin(): boolean {
    return AccessTokenDecoder.isRoleAdmin(this.roleName);
  }

  public get isRoleClient(): boolean {
    return AccessTokenDecoder.isRoleClient(this.roleName);
  }

  private getClaimValue(propName: string): any {
    return this._decodedAccessToken ? this._decodedAccessToken[propName] : null;
  }

  private decodeToken(accessToken: string): any {
    if (!accessToken) {
      throw new Error('Invalid argument; accessToken must be specified');
    }

    const helper = new JwtHelper();
    const decodedAccessToken = helper.decodeToken(accessToken);
    return Object.freeze(decodedAccessToken);
  }

  private isExpired(dt: Date): boolean {
    if (!dt) {
      return true;
    }

    try {
      const now = new Date();
      return now > dt;
    } catch (e) {
      console.error(e);
      return true;
    }
  }
}
