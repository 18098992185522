import {Injectable} from '@angular/core';
import {OrgUnitItem} from '../../core/interfaces/org-unit-item.interface';
import {Subject} from 'rxjs';
import {isBoolean} from 'util';

@Injectable()
export class OrgUnitHelperService {
  public orgUnits: OrgUnitItem[] = [];
  public editItem: OrgUnitItem;
  public selectedItem: OrgUnitItem;

  // Observable Sources
  public listChangedSource = new Subject<OrgUnitItem>();
  public editItemSource = new Subject<OrgUnitItem>();
  public selectItemSource = new Subject<OrgUnitItem>();
  public onNameExistsSource = new Subject<boolean>();

  public onEditItemChanged$ = this.editItemSource.asObservable();
  public onListChanged$ = this.listChangedSource.asObservable();
  public onSelectionChanged$ = this.selectItemSource.asObservable();
  public onNameExists$ = this.onNameExistsSource.asObservable();

  private newId = -1

  // Source Events
  public editItemChange(node: OrgUnitItem) {
    this.editItem = node;
    this.editItemSource.next(node);
  }

  public checkNameExists(name: string) {
     const exists = this.checkNameExistsInArray(name, this.orgUnits);
     this.onNameExistsSource.next(exists);
     return exists;
  }

  private checkNameExistsInArray(name: string, orgUnits: OrgUnitItem[]): boolean {
    let exists = false;
    for (const ou of orgUnits) {
      if (ou.name.toLocaleLowerCase() !== name.toLocaleLowerCase()) {
        exists = this.checkNameExistsInArray(name, ou.children);
        if (exists) {
          break;
        }
        continue;
      }
      exists = true;
      break;
    }
    return exists;
  }


  public getNewId() {
    const id = this.newId;
    this.newId--;
    return id;
  }

  public listChanged(node: OrgUnitItem) {
    this.listChangedSource.next(node);
  }

  public itemSelectChange(node: OrgUnitItem) {
    if (this.selectedItem && this.selectedItem.orgUnitId === node.orgUnitId) {
      this.selectedItem = null;
    } else {
      this.selectedItem = node;
    }
    this.selectItemSource.next(node);
  }

}
