import {IOrgUnitNode} from '../../../../org-units/org-unit-node.interface';
import {IOrgUnitModel} from '../../../../core/interfaces/org-unit.interface';
import {OrgUnitItem} from '../../../../core/interfaces/org-unit-item.interface';
import {isNullOrUndefined} from 'util';

export class HierarchyNode {
  id: any;
  name: string;
  children: HierarchyNode[];
  selected: boolean;
  active: boolean;
  canBeSelected: boolean;

  public static createArray(nodes: OrgUnitItem[], selectedIds: number[]): HierarchyNode[] {
    if (isNullOrUndefined(nodes)) {
      throw new Error('argument null exception. "nodes" cannot be null or undefined.')
    }
    if (nodes.length === 0) {
      return [];
    }
    const ouNodes = HierarchyNode.createOrgUnitNodes(<OrgUnitItem[]>nodes, null);
    return HierarchyNode.createNodes(ouNodes, selectedIds);
  }

  public static createArrayFromModel(nodes: IOrgUnitModel[], selectedIds: number[]) {
    if (isNullOrUndefined(nodes)) {
      throw new Error('argument null exception. "nodes" cannot be null or undefined.')
    }
    if (nodes.length === 0) {
      return [];
    }
    const ouNodes = HierarchyNode.createOrgUnitNodesFromModel(nodes, null);
    return HierarchyNode.createNodes(ouNodes, selectedIds);
  }

  private static createNode(id: number, name: string, children: IOrgUnitNode[], selectedIds: number[], isActive: boolean): HierarchyNode {
    const node = new HierarchyNode();
    const selectedIdx = selectedIds.indexOf(id);

    node.id = id;
    node.name = name;
    node.selected = selectedIdx !== -1;
    node.children = this.createNodes(children, selectedIds);
    node.canBeSelected = true;
    node.active = isActive;
    
    return node;
  }

  private static createNodes(nodes: IOrgUnitNode[], selectedIds: number[]): HierarchyNode[] {
    return nodes.map((e) => {
      return HierarchyNode.createNode(e.id, e.data.name, e.children, selectedIds, e.isActive);
    });
  }

  private static createOrgUnitNodes(nodes: OrgUnitItem[], parent: IOrgUnitNode): IOrgUnitNode[] {
    return nodes.filter((ou) => {
      return ou.parentId === (parent === null ? null : parent.id);
    }).map((ou) => {
      const node = {
        id: ou.orgUnitId,
        parent: parent,
        children: null,
        data: ou,
        isActive: ou.isActive,
        selected: false
      } as IOrgUnitNode;

      node.children = HierarchyNode.createOrgUnitNodes(ou.children, node);
      return node;
    });
  }

  // From Model
  private static createOrgUnitNodesFromModel(nodes: IOrgUnitModel[], parent: IOrgUnitNode): IOrgUnitNode[] {
    return nodes.filter((ou) => {
      return ou.parentOrgUnitId === (parent === null ? null : parent.id);
    }).map((ou) => {
      const node = {
        id: ou.orgUnitId,
        parent: parent,
        children: null,
        data: ou,
        isActive: false,
        selected: false
      } as IOrgUnitNode;

      node.children = HierarchyNode.createOrgUnitNodesFromModel(nodes, node);
      return node;
    });
  }


  constructor() {
    this.selected = false;
    this.active = false;
    this.canBeSelected = true;
  }

}
