import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SecureLayoutComponent} from '../shared/secure-layout/secure-layout.component';
import {AuthGuard} from '../auth/auth.guard';
import {ChangePasswordComponent} from './change-password/change-password.component';
import {SharedModule} from '../shared/shared.module';
import {EditProfileComponent} from './edit-profile/edit-profile.component';
import {EditProfileResolver} from '../users/resolvers.service';
import {CountrySelectItemsResolver, StateSelectItemsResolver} from '../core/resolvers.service';
import { NgxMaskModule } from 'ngx-mask';

const routes: Routes = [
  {
    path: 'self-service', component: SecureLayoutComponent, canActivateChild: [AuthGuard],
    children: [{
      path: 'change-password',
      component: ChangePasswordComponent
    }, {
      path: 'edit-profile',
      component: EditProfileComponent,
      resolve: {
        user: EditProfileResolver,
        states: StateSelectItemsResolver,
        countries: CountrySelectItemsResolver
      }
    }]
  }
];

@NgModule({
  declarations: [
    ChangePasswordComponent,
    EditProfileComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    SharedModule, 
    NgxMaskModule.forRoot()
  ],
  exports: [
    ChangePasswordComponent,
    EditProfileComponent
  ],
  providers: [
    EditProfileResolver,
    StateSelectItemsResolver,
    CountrySelectItemsResolver
  ]
})
export class SelfServiceModule {
}


