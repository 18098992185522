import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {IPositionLevelModel} from '../../core/interfaces/position-level.interface';
import {IEditPositionModel} from '../../core/interfaces/edit-position.interface';
import {EditPositionBaseComponent} from '../edit-position-base/edit-position-base.component';
import {IPositionFunctionModel} from '../../core/interfaces/position-function.interface';
import {IPositionDescriptionOverrideModel} from '../../core/interfaces/position-description-override.interface';
import {AuthService} from '../../auth/auth.service';
import {OrgUnitItem} from '../../core/interfaces/org-unit-item.interface';
import {ISelectItemModel} from '../../core/interfaces/select-item.interface';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadErrorDialogComponent } from 'app/shared/upload-error-dialog/upload-error-dialog.component';

@Component({
  selector: 'edit-position',
  templateUrl: './edit-position.component.html',
  styleUrls: ['./edit-position.component.css']
})
export class EditPositionComponent implements OnInit {
  @ViewChild(EditPositionBaseComponent) public child;

  public position: IEditPositionModel;
  public positionLevels: IPositionLevelModel[];
  public positionFunctions: IPositionFunctionModel[];
  public positionDescriptionOverrides: IPositionDescriptionOverrideModel[];
  public userOrgUnits: ISelectItemModel[];
  public activeStatus: string;
  public confidentialButtonText: string;
  private _modal: NgbModalRef;
  
  constructor(private route: ActivatedRoute, 
    private router: Router, 
    private authService: AuthService,
    private modalService: NgbModal) {
  }

  public ngOnInit() {
    this.route.data.subscribe((data: {
      position: IEditPositionModel,
      positionLevels: IPositionLevelModel[],
      positionFunctions: IPositionFunctionModel[],
      positionDescriptionOverrides: IPositionDescriptionOverrideModel[],
      userOrgUnits: ISelectItemModel[]
    }) => {
      if (data) {
        this.position = data.position;
        this.positionLevels = data.positionLevels;
        this.positionFunctions = data.positionFunctions;
        this.positionDescriptionOverrides = data.positionDescriptionOverrides;
        this.userOrgUnits = data.userOrgUnits;
        this.activeStatus = this.position.isActive === false ? 'Activate' : 'Deactivate';
      }
    });
  }

  public onCancel() {
    this.router.navigate(['positions']);
  }

  public onSave() {
    if (this.child.canSave) {
      this.child.save().subscribe((result) => {
        if (result) {
          this.router.navigate(['/positions']);
        } else {
          alert('Save failed');
        }
      });
    }
  }

  public updatePositionStatus() {
    this.child.updatePositionStatus().subscribe((result) => {
      if (result) {
        this.router.navigate(['/positions']);
      } else {
        alert('Deactivate failed');
      }
    })

  }
}
