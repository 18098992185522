export class PsListItem {
  public id: any;
  public name: string;
  public selected: boolean;
  public active: boolean;

  constructor(id?: any, name?: string, selected?: boolean, active?: boolean) {
    this.id = id;
    this.name = name;
    this.selected = selected;
    this.active = active;
  }
}
