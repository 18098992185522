import {Injectable} from '@angular/core';
import {IUserModel, IUserAuthModel} from '../core/interfaces/user.interface';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {UserService} from '../core/user.service';
import {Observable} from 'rxjs';
import {IEditUserModel} from '../core/interfaces/edit-user.interface';

@Injectable()
export class UserResolver implements Resolve<IUserModel> {
  constructor(private userService: UserService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<IUserModel> {
    const id = +route.params['id'];
    return this.userService.getUser(id);
  }
}

@Injectable()
export class EditUserResolver implements Resolve<IEditUserModel> {
  constructor(private userService: UserService) { }

  public resolve(route: ActivatedRouteSnapshot): Observable<IEditUserModel> {
    const id = +route.params['id'];
    return this.userService.getUserForEdit(id);
  }
}

@Injectable()
export class EditProfileResolver implements Resolve<IEditUserModel> {
  constructor(private userService: UserService) { }

  public resolve(route: ActivatedRouteSnapshot): Observable<IEditUserModel> {
      return this.userService.getProfileForEdit();
  }
}


@Injectable()
export class UserListResolver implements Resolve<IUserModel[]> {
  constructor(private userService: UserService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<IUserModel[]> {
    return this.userService.getUsers();
  }
}

@Injectable()
export class UserByClientListResolver implements Resolve<IUserAuthModel[]> {
  constructor(private userService: UserService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<IUserAuthModel[]> {
    const id = +route.params['id'];
    return this.userService.getUsersWithOrgsByClientId(id, true);
  }
}
