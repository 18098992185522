import {AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn} from '@angular/forms';

export module PsValidators {
  export function password(pattern: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const val = control.value;
      const regEx = new RegExp(pattern);
      const match = regEx.test(val);
      return !match ? {'password': {val}} : null;
    };
  }

  export function compare(compareControl: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const val = control.value;
      const compare = control.parent.get(compareControl);
      const compareVal = compare.value;

      return val !== compareVal ? {'nomatch': {val}} : null;
    };
  }


  export function passwordCompare(compareControl: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const val = control.value;
      const parent = control.parent as UntypedFormGroup;
      if (!parent) {
        return null;
      }
      const compare = parent.get(compareControl) as UntypedFormControl;
      if (!compare) {
        return null;
      }
      return val !== compare.value ? {'passwordcompare': {val}} : null;
    };
  }
}
