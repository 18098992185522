import {IBillingContactModel} from '../interfaces/billing-contact.interface';
import {AddressModel} from './address.model';
import {IAddressModel} from '../interfaces/address.interface';
import {Utility} from '../utility';

export class BillingContactModel implements IBillingContactModel {
  public billingContactId?: number;
  public firstName: string;
  public middleName: string;
  public lastName: string;
  public email: string;
  public address: IAddressModel;
  public orgUnitName: string;

  public get displayName(): string {
    return Utility.buildDisplayName(this.firstName, this.lastName);
  }

  constructor(obj?: IBillingContactModel) {
    if (!obj) {
      this.address = new AddressModel();
      return;
    }

    if (obj.billingContactId) {
      this.billingContactId = obj.billingContactId;
    }

    this.firstName = obj.firstName;
    this.middleName = obj.middleName;
    this.lastName = obj.lastName;
    this.email = obj.email;

    if (obj.address) {
      this.address = new AddressModel(obj.address);
    }
  }
}

