import { Component, OnInit } from '@angular/core';
import {AgRendererComponent} from 'ag-grid-angular';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-clients-view-button-renderer',
  template: `<button *ngIf="!isRetrying && allowRetry()" 
            class="btn btn-sm btn-secondary" 
            (click)="onClickRetry(params?.data?.id)">
      Retry ({{params?.data?.responseId}})
    </button>
    <span *ngIf="isRetrying">
      <i class="fa fa-spinner fa-spin"></i> Retrying...
    </span>
    <strong *ngIf="!allowRetry()">{{params?.data?.responseId}}</strong>`
})
export class ViewButtonRendererComponent implements AgRendererComponent {

  constructor() { }
  public params:any;
  public isRetrying: boolean = false;
  agInit(params:any):void {
      this.params = params;
  }
  public refresh(params:any):boolean
  {
    return true;
  }

  public allowRetry() {
    var allow: boolean = true;
    if(this.params.data){
      if(!this.params.data.apiCallTypeCanRetry)
        allow = false;
      if(this.params.data.retryId && this.params.data.retryId != 0)
        allow = false;
      if(this.params.data.responseId && this.params.data.responseId == 200)
        allow = false;
    }

    return allow;
  }

  public onClickRetry(positionId: number): void {
    this.isRetrying = true;
      this.params.context.componentParent.onClickRetry(positionId).pipe(
      finalize(() => {
        this.isRetrying = false; 
      })
    ).subscribe(
      () => {
      }
    );
  }
  

}
