import {NotFoundComponent} from './shared/not-found/not-found.component';
import {ModuleWithProviders} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const appRoutes: Routes = [
  {path: '', redirectTo: '/assessment-requests', pathMatch: 'full'},
  {path: 'clients', loadChildren: () => import('./clients/clients.module').then(m => m.ClientsModule)},
  {path: 'users', loadChildren: () => import('./users/users.module').then(m => m.UsersModule)},
  {path: 'emails', loadChildren: () => import('./emails/email.module').then(m => m.EmailsModule)},
  {path: 'api-admin', loadChildren: () => import('./api-admin/api-admin.module').then(m => m.ApiAdminModule)},
  {path: 'out-of-office', loadChildren: () => import('./out-of-office/out-of-office.module').then(m => m.OutOfOfficeModule)},
  {path: 'feedback-users', loadChildren: () => import('./feedback/feedback.module').then(m => m.FeedbackModule)},
  {path: '**', component: NotFoundComponent}
];

export const appRoutingProviders: any[] = [];

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forRoot(appRoutes);
