import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, FormControl, Validators} from '@angular/forms';
import {EditUserModel} from '../../users/edit-user.model';
import {PsListItem} from '../../shared/enhanced-controls/core/listitem/ps-list-item';
import {ISelectItemModel} from '../../core/interfaces/select-item.interface';
import {UserService} from '../../core/user.service';
import {smsRequiresPhoneMobile} from '../../core/validators/smsRequiresPhoneMobile.validator';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'edit-profile',
  templateUrl: './edit-profile.component.html'
})
export class EditProfileComponent implements OnInit {
  private user: EditUserModel;
  public countries: PsListItem[];
  public states: ISelectItemModel[];
  public editProfileForm: UntypedFormGroup;
  public isUSA: boolean;

  constructor(private fb: UntypedFormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private userService: UserService) { }

  public ngOnInit(): void {
    this.route.data.subscribe((data: any) => {
      const user = data.user as EditUserModel;
      this.user = user || new EditUserModel();
      this.states = data.states as ISelectItemModel[];
      this.countries = data.countries as PsListItem[];
    });

    if (!this.user.userId) {
      this.user.countryId = 196;

    }

    this.isUSA = this.isUSofA(this.user.countryId);
    this.editProfileForm = this.buildForm(this.user);
  }

  public cancelEdit() {
    this.router.navigate(['/']);
  }

  public get disableSMS_MFA() : boolean {
    var countryCode = this.editProfileForm.get('address.phonemobilecountrycode').value;
    var phoneMobile = this.editProfileForm.get('address.phonemobile').value
    if( !countryCode || !phoneMobile || countryCode == "" ||  phoneMobile== ""){
      if(this.editProfileForm.get('mfa.mfaType').value != 1){
        this.editProfileForm.controls['mfa'].patchValue({mfaType: 1});
      }
      return true;
    } else {
      return false;
    }
  }

  public onSubmit() {
    if (!this.editProfileForm.valid || this.editProfileForm.pristine) {
      return;
    }

    const profile = new EditUserModel(this.user);
    profile.email = this.editProfileForm.get('userinfo.email').value;
    profile.first = this.editProfileForm.get('userinfo.firstname').value;
    profile.middle = this.editProfileForm.get('userinfo.middlename').value;
    profile.last = this.editProfileForm.get('userinfo.lastname').value;
    profile.address1 = this.editProfileForm.get('address.address1').value;
    profile.address2 = this.editProfileForm.get('address.address2').value;
    profile.city = this.editProfileForm.get('address.city').value;
    profile.stateId = this.editProfileForm.get('address.state').value;
    profile.province = this.editProfileForm.get('address.province').value;
    profile.countryId = isNullOrUndefined(this.editProfileForm.get('address.country').value) ? null : this.editProfileForm.get('address.country').value.id;
    profile.zipCode = this.editProfileForm.get('address.zipcode').value;
    profile.phoneWorkCountryCode = this.editProfileForm.get('address.phoneworkcountrycode').value;
    profile.phoneWork = this.editProfileForm.get('address.phonework').value;
    profile.phoneMobileCountryCode = this.editProfileForm.get('address.phonemobilecountrycode').value;
    profile.phoneMobile = this.editProfileForm.get('address.phonemobile').value;
    profile.mfaType = this.editProfileForm.get('mfa.mfaType').value;
    if (this.isUSA) {
      profile.province = null;
    } else {
      profile.stateId = null;
    }

    this.userService.saveProfile(profile).subscribe(() => {
      this.router.navigate(['/']);
    });
  }

  public onSelectCountry(country): void {
    this.isUSA = this.isUSofA(country.id);
  }

  private isUSofA(countryId: number): boolean {
    return countryId === 196;
  }

  private buildForm(editUserModel: EditUserModel): UntypedFormGroup {
    const country = this.countries.find(o => o.id === editUserModel.countryId);
    const formGroup = this.fb.group({
      userinfo: this.fb.group({
        username: this.fb.control(editUserModel.username, Validators.compose([Validators.required, Validators.maxLength(200)])),
        email: this.fb.control(editUserModel.email, Validators.compose([Validators.required, Validators.maxLength(254)])),
        firstname: this.fb.control(editUserModel.first, Validators.compose([Validators.required, Validators.maxLength(200)])),
        middlename: this.fb.control(editUserModel.middle, Validators.maxLength(1)),
        lastname: this.fb.control(editUserModel.last, Validators.compose([Validators.required, Validators.maxLength(200)])),
        
      }),
      address: this.fb.group({
        address1: this.fb.control(editUserModel.address1, Validators.maxLength(200)),
        address2: this.fb.control(editUserModel.address2, Validators.maxLength(200)),
        city: this.fb.control(editUserModel.city, Validators.maxLength(100)),
        state: this.fb.control(editUserModel.stateId),
        province: this.fb.control(editUserModel.province, Validators.maxLength(200)),
        country: this.fb.control(country),
        zipcode: this.fb.control(editUserModel.zipCode),
        phoneworkcountrycode: this.fb.control(editUserModel.phoneWorkCountryCode, Validators.maxLength(3)),
        phonework: this.fb.control(editUserModel.phoneWork, Validators.maxLength(20)),
        phonemobilecountrycode: this.fb.control(editUserModel.phoneMobileCountryCode, Validators.maxLength(3)),
        phonemobile: this.fb.control(editUserModel.phoneMobile, Validators.maxLength(20))
      }),
      mfa: this.fb.group({
        mfaType: this.fb.control(editUserModel.mfaType)
      })
       
    },
    {validators: smsRequiresPhoneMobile });

    return formGroup;
  }
}
