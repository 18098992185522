import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ISelectItemModel, IUserSelectItemModel} from './interfaces/select-item.interface';
import {ServiceBase} from './service-base';
import {AuthHttpService} from '../auth/auth-http.service';
import {IEmailModel} from './interfaces/email.interface';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class EmailService extends ServiceBase {
  private static ApiUrl = 'api/email';
  private data$: Observable<ISelectItemModel[]>;
  private cacheDuration: number = 1000 * 60 * 5;

  constructor(private http: HttpClient) {
    super();
  }

  public getEmails(): Observable<IEmailModel[]> {
    const url = EmailService.ApiUrl + '/list';
    return this.http.get<IEmailModel[]>(url);
  }

  public getEmail(id: number): Observable<IEmailModel> {
    const url = EmailService.ApiUrl + '/' + id;
    return this.http.get<IEmailModel>(url);
  }
}
