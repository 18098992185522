<div class="jump-to" *ngIf="showNav">
  <strong>Go To Section</strong>
  <ul class="jump-to-list">
    <li *ngFor="let section of getSections()" [ngClass]="{'has-danger': section.showingErrors }">
      <a [routerLink]="['./']" fragment="{{section.sectionId}}">{{section.sectionTitle}}</a>
    </li>
  </ul>
</div>
<form novalidate [ngClass]="{'content-affixed-right': showNav}">
  <ng-content></ng-content>
</form>
