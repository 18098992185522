
import {share, debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable, Observer} from 'rxjs';



import {LoadingBarEvent, LoadingBarEventType} from './spinner-event';

@Injectable()
export class LoadingSpinnerService {
  private requestCount = 0;
  private spinnerObserver: Observer<LoadingBarEvent>;
  public spinnerObservable: Observable<LoadingBarEvent>;

  private _step = 0.25;
  private _interval = 100; // In milliseconds.
  private _intervalCounterId: any = 0;
  private _isVisible = false;
  private _progress = 0; // Percent

  constructor() {
    this.spinnerObservable = new Observable<LoadingBarEvent>(observer => this.spinnerObserver = observer).pipe(
      distinctUntilChanged(),
      debounceTime(10),
      share(),);
  }

  private set progress(value: number) {
    this._progress = value;
    const event = new LoadingBarEvent();
    event.type = LoadingBarEventType.Progress;
    event.value = this._progress;
    this.emitEvent(event);
  }

  private get progress(): number {
    return this._progress;
  }

  private set visible(value: boolean) {
    this._isVisible = value;
    const event = new LoadingBarEvent();
    event.type = LoadingBarEventType.Visible;
    event.value = value;
    this.emitEvent(event);
  }

  private get visible(): boolean {
    return this._isVisible;
  }

  public start(): void {
    this.requestCount++;
    if (this.visible) {
      return;
    }
    this.visible = true;
    this._intervalCounterId = setInterval(() => {
      // Increment the progress and update view component
      this.progress = this.progress + this._step;
      // If the progress is 100% - call complete
      if (this.progress >= 100) {
        this.complete();
      }
    }, this._interval);
  }

  public stop(): void {
    if (this.requestCount > 0) {
      this.requestCount--;
    }
    if (this.requestCount === 0) {
      if (this._intervalCounterId) {
        clearInterval(this._intervalCounterId);
        this._intervalCounterId = null;
      }
    }
  }

  public reset(): void {
    this.stop();
    this.progress = 0;
    this.visible = false;
  }

  public complete() {
    if (this.requestCount > 1) {
      this.stop();
    } else {
      this.stop();
      this.progress = 100;
      setTimeout(() => {
        // Hide it away
        this.visible = false;
        setTimeout(() => {
          // Drop to 0
          this.progress = 0;
        }, 250);
      }, 250);
    }
  }


  private emitEvent(event: LoadingBarEvent) {
    if (this.spinnerObserver) {
      this.spinnerObserver.next(event);
    }
  }
}
