<action-bar>
  <action-button [className]="'action-default'" [iconClass]="'fa fa-chevron-left'" (actionClick)="onClickBack()">Save and Back</action-button>
  <action-button [className]="'action-primary'" [iconClass]="'fa fa-star'" (actionClick)="onClickComplete()" [disabled]="disableComplete" [ngbTooltip]="(disableComplete) ? 'Required: Consultant Assigned and Report Attached or Verbal Complete.' : ''" container="body">Mark Assessment Complete</action-button>
</action-bar>

<div class="card" *ngIf="attachments && attachments.length">
  <h4 class="card-header">Existing Reports: {{assessmentRequest.candidate.displayName}}</h4>
  <div class="card-body">
    <table class="table table-striped">
      <thead>
      <tr>
        <th>Assessment Type</th>
        <th>Report Type</th>
        <th>Name</th>
        <th>Action</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let attachment of attachments">
        <td>{{attachment.referenceName}}</td>
        <td>{{attachment.typeName}}</td>
        <td>
          <a (click)="AttachmentService.download(attachment.attachmentId)" class="NameLink">{{attachment.filename}}</a>
        </td>
        <td>
          <button type="button" class="btn btn-link" (click)="onClickDelete(attachment)"><span class="fa fa-remove"></span></button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="card">
  <h4 class="card-header">Add Report: {{assessmentRequest.candidate.displayName}}</h4>
  <div class="card-body">
    <div class="row" *ngIf="assessmentLevelReferences && assessmentLevelReferences.length && !assessmentRequest.parentAssessmentRequestId">
      <div class="col">
        <table class="table table-striped">
          <thead>
          <tr>
            <th>Assessment Level</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let assessmentLevelReference of assessmentLevelReferences; trackBy: trackByAssessmentLevelReferenceId">
            <tr *ngIf="isValidAssessmentLevel(assessmentLevelReference)">
              <td>{{assessmentLevelReference.name}}</td>
              <td>
                <button type="button" class="btn btn-sm btn-primary" (click)="onClickUploadAssessmentLevel(assessmentLevelReference, $event)">
                  <span class="fa fa-cloud-upload"></span> Upload
                </button>
              </td>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row" *ngIf="anyValidAdditionalService()">
      <div class="col">
        <table class="table table-striped">
          <thead>
          <tr>
            <th>Additional Service</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let additionalServiceReference of additionalServiceReferences; trackBy: trackByAdditionalServiceReferenceId">
            <tr *ngIf="isValidAdditionalService(additionalServiceReference)">
              <td>{{additionalServiceReference.name}}</td>
              <td>
                <span style="margin-right:12px;" class="text-success" *ngIf="verbalFeedbackComplete(additionalServiceReference)"><i class="fa fa-check"></i>Feed back delivered {{testingSchedule.feedbackDate ? (testingSchedule.feedbackDate | date: 'MM/dd/yyyy') : "Date Not Entered"}}</span>
                <button type="button" class="btn btn-sm btn-primary" *ngIf="isVerbalFeedback(additionalServiceReference) && verbalFeedbackComplete(additionalServiceReference)"
                        (click)="onClickVerbalFeedbackNotComplete(additionalServiceReference, $event)">
                  <span class="fa fa-undo"></span> Mark Feedback Not Delivered
                </button>
                <button type="button" class="btn btn-sm btn-primary" *ngIf="isVerbalFeedback(additionalServiceReference) && !verbalFeedbackComplete(additionalServiceReference)"
                        (click)="onClickVerbalFeedbackComplete(additionalServiceReference, $event)">
                  <span class="fa fa-check"></span> Mark Feedback Delivered
                </button>

                <button type="button" class="btn btn-sm btn-primary" *ngIf="!isVerbalFeedback(additionalServiceReference)"
                        (click)="onClickUploadAdditionalService(additionalServiceReference, $event)">
                  <span class="fa fa-cloud-upload"></span> Upload
                </button>
              </td>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

