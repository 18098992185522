import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'gender'})
export class GenderPipe implements PipeTransform {

  transform(value: string): string {
    if (value) {
      switch (value.toLowerCase()) {
        case 'm':
          return 'Male';
        case 'f':
          return 'Female';
      }
    }

    return '';
  }
}
