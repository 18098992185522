<div class="ps-table-container">
  <header>
    <div class="row ps-table-title-container">
      <div class="col-md-6 col-sm-12">
        <div class="ps-table-title" *ngIf="grid.getSetting('showTitle')" class="ps-table-title">{{grid.getSetting('title')}}</div>
      </div>
      <div class="col-md-6 col-sm-12">
        <div class="ps-table-filter-container">
          <button type="button" *ngIf="grid.getSetting('filter.advancedFilter')"  class="btn btn-primary-clean" (click)="toggleAdvancedFilter()">
            <i class="fa fa-sliders"></i>&nbsp;Advanced Filter
          </button>
          <ps-table-quick-filter [source]="dataSource" *ngIf="grid.getSetting('filter.quickFilter')"></ps-table-quick-filter>
        </div>
      </div>
    </div>
    <div *ngIf="showAdvancedFilter" [ngClass]="{'show': showAdvancedFilter}" class="fade">
      <ng-content select="ps-advanced-filters"></ng-content>
    </div>
  </header>
  <table class="ps-table table" [id]="grid.getSetting('tableId')" [ngClass]="grid.getSetting('tableClass')">
    <thead>
    <tr class="ps-table-headers">
      <th *ngFor="let column of grid.getVisibleColumns()" class="ps-table-th {{ column.id }}" [ngClass]="column.columnClass">
        <div class="ps-table-header">
          <ps-table-title [source]="dataSource" [column]="column" (sort)="sort($event)"></ps-table-title>
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let row of grid.getRows()">
      <tr class="ps-table-row" (click)="onUserSelectRow(row)" (dblclick)="grid.viewAction(row)" [ngClass]="{selected: row.isSelected}">
        <td *ngFor="let cell of row.getCells()">
          <button *ngIf="cell.isViewLink()" class="btn btn-link" (click)="grid.viewAction(row)" [innerHTML]="cell.getValue()"></button>
          <span *ngIf="!cell.isViewLink()" [innerHTML]="cell.getValue()"></span>
        </td>
      </tr>
      <tr *ngIf="grid.getActions().length > 0" class="ps-table-action-row" [hidden]="!row.isSelected" (dblclick)="grid.viewAction(row)">
        <td [attr.colspan]="grid.getColumns().length" class="text-right">
          <button *ngFor="let action of grid.getActions(row)" [ngClass]="action.actionClass" class="ps-table-action-link"
                  (click)="action.action(row)">
            <i *ngIf="action.iconClass" [ngClass]="action.iconClass"></i>
            {{action.name}}
          </button>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
  <ps-table-pager *ngIf="grid.getSetting('pager.display')"
                  [source]="dataSource"
                  [perPage]="grid.getSetting('pager.perPage')"
                  (changePage)="changePage($event)">
  </ps-table-pager>
</div>

