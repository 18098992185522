<div class="alert alert-danger" role="alert" *ngIf="loginFailed">
  {{failureMessage}}
</div>
<form *ngIf="!mfaRequired" novalidate [formGroup]="form" (ngSubmit)="login()">
  <div class="form-group" [ngClass]="{'has-danger': usernameControl.touched && usernameControl.invalid}">
    <label for="username" class="form-control-label">Username</label>
    <input autofocus type="text" class="form-control" id="username" formControlName="username" autocomplete="off" [ngClass]="{'form-control-danger': usernameControl.touched && usernameControl.invalid}"/>
    <div *ngIf="usernameControl.touched && usernameControl.hasError('required')" class="form-control-feedback">Username is required</div>
  </div>
  <div class="form-group" [ngClass]="{'has-danger': passwordControl.touched && passwordControl.invalid}">
    <label for="password" class="form-control-label">Password</label>
    <input type="password" class="form-control" id="password" formControlName="password" autocomplete="off" [ngClass]="{'form-control-danger': passwordControl.touched && passwordControl.invalid}"/>
    <div *ngIf="passwordControl.touched && passwordControl.hasError('required')" class="form-control-feedback">Password is required</div>
  </div>
  <div class="row">
    <div class="col">
      <button type="submit" class="btn btn-primary" style="position: relative; top: 13px;">Login</button>
    </div>
    <div class="col text-right">
      <a class="nav-link" [routerLink]="['/forgot-password']">Forgot Password?</a>
    </div>
  </div>
</form>
<div *ngIf="mfaRequired">
  <div class="panel panel-default">
    <div class="panel-heading">
      <h4 class="panel-title">Two Factor Authentication {{mfaFirstTime ? " Set-Up" : ""}}</h4>
      <div *ngIf="mfaFirstTime">
        At Leadership Alliance, we are concerned about security. In order to protect your data, we have begun using multi-factor authentication. Please click the “Send Verification” button below to receive an {{deliveryItem}} with a code. Once you enter the code below, you will be able to set up your contact preferences. 
      </div>
      <div *ngIf="!mfaFirstTime">
        Click the “Send Verification” button below and we will send you a {{deliveryItem}}. Enter the code below and click verify. Once you verify this browser, we won’t ask you again for 3 months.
      </div>
    </div>
    &nbsp;
    <div class="row">
      <div class="col">
        <div>
          <button id="sendButton" [attr.disabled]="mfaRequestSucceeded||inProgress ? 'true' : null" class="btn btn-primary" (click)="requestMutliFactorToken()">{{mfaRequestSucceeded||inProgress ? "Sent" : "Send Verification" }}</button>
        </div>
      </div>
    </div>
    &nbsp;
    &nbsp;
    <div>
      <label for="verificationToken" class="form-control-label">Please check your {{deliveryVector}} and enter the code below.</label>
      <input [(ngModel)]="mfaToken" type="text" class="form-control" id="verificationToken" (keyup.enter)="verifyMultiFactorToken()"/>
    </div>
  </div>
  &nbsp;
  <div class="row">
    <div class="col">
      <button [attr.disabled]="!mfaRequestSucceeded||inProgress ? 'true' : null"  class="btn btn-primary" (click)="verifyMultiFactorToken()">Verify</button>
    </div>
  </div>
  &nbsp;
  <div *ngIf="mfaFirstTime">Once you verify this browser, we won’t ask you again for 3 months.</div>
</div>

