<div class="content container-fluid">
  <div class="row">
    <div class="col-sm-8 offset-sm-2  col-md-6 offset-md-3 col-lg-4 offset-lg-4">
      <img src="../assets/la-logo.png" alt="Leadership Alliance"/>
    </div>
  </div>
  <div class="row" style="margin-top: 12px">
    <div class="col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>




