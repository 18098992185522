import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AssessmentRequestService} from '../../core/assessment-request.service';
import {AssessmentRequestStatus} from '../../core/enums/assessment-request-status.enum';

@Component({
  selector: 'app-status-change-assessment-request-dialog',
  templateUrl: './status-change-assessment-request-dialog.component.html',
  styleUrls: ['./status-change-assessment-request-dialog.component.scss']
})
export class StatusChangeRequestDialogComponent implements OnInit {
  @Input() public assessmentRequestId: number;
  @Input() public candidateName: string;
  @Output() public onUpdate = new EventEmitter<number>();

  constructor(public activeModal: NgbActiveModal,
              public assessmentRequestService: AssessmentRequestService) {
  }

  public ngOnInit() {
    if (!this.assessmentRequestId) {
      throw new Error('assessmentRequestId must be specified');
    }

    if (!this.candidateName) {
      throw new Error('candidateName must be specified');
    }
  }

  public onConfirm() {
    this.assessmentRequestService.setStatus(this.assessmentRequestId, AssessmentRequestStatus.InProcess)
      .subscribe(() => {
        if (this.onUpdate) {
          this.onUpdate.emit(this.assessmentRequestId);
        }

        this.activeModal.close();
      });
  }

  public onCancel() {
    this.activeModal.dismiss();
  }
}
