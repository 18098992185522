import { Component, OnInit } from '@angular/core';
import {ICellRendererAngularComp} from "ag-grid-angular";

@Component({
  selector: 'app-renderer-action-bar',
  templateUrl: './renderer-action-bar.component.html',
  styleUrls: ['./renderer-action-bar.component.scss']
})
export class RendererActionBarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    
  }

  agInit(params: any): void {
    //this.parentRecord = params.node.parent.data;
  }

  onButtonClick() {
    window.alert('Sample button pressed!!');
  }
}
