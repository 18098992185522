import { Component, OnInit } from '@angular/core';
import {AgRendererComponent} from 'ag-grid-angular';
import {AttachmentService} from '../../core/attachment.service';

@Component({
  selector: 'app-job-description-renderer',
  template: '<a *ngIf="params?.data?.jobDescriptionAttachmentId" class="btn btn-sm btn-secondary" (click)="attachmentService.download(params?.data?.jobDescriptionAttachmentId)" _ngcontent-c12="" ngbTooltip="{{params?.data?.jobDescriptionFileName}}" placement="left" container="body"><span class="fa fa-cloud-download" _ngcontent-c12=""></span>{{params?.data?.jobDescriptionFileName}}</a>'
})
export class JobDescriptionRendererComponent implements AgRendererComponent {

  constructor(private attachmentService: AttachmentService) { }

  public params:any;
  
  agInit(params:any):void {
      this.params = params;
  }
  public refresh(params:any):boolean
  {
    return true;
  }
}
