import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IOrgUnitNode} from '../org-unit-node.interface';

@Component({
  selector: 'org-unit-node',
  templateUrl: 'org-unit-node.component.html',
  styleUrls: ['org-unit-node.component.scss']
})
export class OrgUnitNodeComponent {
  @Input() public node: IOrgUnitNode;
  @Output() public onClicked = new EventEmitter<IOrgUnitNode>();

  public get isActive(): boolean {
    return this.node.isActive;
  }

  public onNodeClicked(node: IOrgUnitNode) {
    this.onClicked.emit(node);
  }
}
