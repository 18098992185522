<a [routerLink]="['/emails']">Return to List</a>

<div *ngIf="email">
  <form>

    <div class="row">
      <div class="col">
        <div class="card" id="client-info">
          <h4 class="card-header">
            View Email
          </h4>
          <div class="card-body">

            <div class="row">
              <div class="col">
                <label class="form-control-label">Id</label>
                <div class="static-text">{{email.emailQueueId}}</div>
              </div>
              <div class="col">
                <label class="form-control-label">From</label>
                <div class="static-text">{{email.fromEmail}}</div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <label class="form-control-label">Sent</label>
                <div class="static-text">{{createDate}}</div>
              </div>
              <div class="col">
                <label class="form-control-label">To</label>
                <div class="static-text">{{email.toEmail}}</div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <label class="form-control-label">Subject</label>
                <div class="static-text">{{email.subject}}</div>
              </div>
              <div class="col">
                <label class="form-control-label">Status</label>
                <div class="static-text">{{emailStatus}}</div>
              </div>
            </div>

            <div class="row" style="top: 275px; position: absolute; width: 100%;">
                <label class="form-control-label">Content:</label>
                <div innerHTML={{email.content}}></div>
            </div>

          </div>
        </div>
      </div>
    </div>

  </form>

</div>

