import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import {ITableAction, ITableSettings, Row} from '../../piquant-table/models';

@Component({
  selector: 'app-detail-cell-renderer',
  templateUrl: './detail-cell-renderer.component.html' //template: `<h1 style="padding: 20px;">My Custom Detail</h1>`,
})
export class DetailCellRendererComponent implements ICellRendererAngularComp { 
    public actionTable: ITableAction;
    public params:any;  

    agInit(params: any): void {
        this.params = params;
        this.actionTable = this.invokeBuildActions()
    }

    refresh(params: any): boolean {
        return false;
    }

    public invokeBuildActions(): ITableAction {
        return this.params.context.componentParent.buildTableActions();
    }
}