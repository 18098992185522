<div class="ps-table-filter" #dd="ngbDropdown" ngbDropdown>
  <a ngbDropdownToggle class="ps-table-filter-title ps-table-filter-opener">
    {{filterTitle}}&nbsp;<i class="fa fa-caret-down"></i>
  </a>
  <div class="dropdown-menu ps-table-filter-dd" (click)="$event.stopPropagation()"
       style="min-width: 512px; left: inherit; right: 0;">
    <label class="form-control-label">Date Range:
      <div class="pull-right" (click)="dd.close()"><i class="fa fa-close"></i></div>
    </label>
    <div class="row">
      <div class="col">
        <label class="form-control-label">From:&nbsp;<span *ngIf="fromDate">
          {{displayFromDate}}&nbsp;<i class="fa fa-trash-o text-danger" (click)="clearFrom()"></i></span>
        </label>
        <div>
          <ngb-datepicker #from [(ngModel)]="fromDate" (change)="valueChanged(from.value)"></ngb-datepicker>
        </div>
      </div>
      <div class="col">
        <label class="form-control-label">To: <span *ngIf="toDate">
          {{displayToDate}}&nbsp;<i class="fa fa-trash-o text-danger" (click)="clearTo()"></i></span>
        </label>
        <div>
          <ngb-datepicker #from [(ngModel)]="toDate"></ngb-datepicker>
        </div>
      </div>
    </div>
    <div>
      <div class="text-right" style="font-size: 12px"><a href="javascript:void(0)" class="text-primary" (click)="applyFilter();dd.close();">Apply</a></div>
    </div>
  </div>
</div>
