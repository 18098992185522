import { Component, OnInit, ViewChild} from '@angular/core';
import {AgRendererComponent} from 'ag-grid-angular';
import {NgbTooltip, NgbPopover} from '@ng-bootstrap/ng-bootstrap';
import { NgbPopoverWindow } from '@ng-bootstrap/ng-bootstrap/popover/popover';
import {AssessmentRequestService} from '../../core/assessment-request.service';
import {AssessmentRequestStatus} from '../../core/enums/assessment-request-status.enum';
import {ActivatedRoute, Router} from '@angular/router';
import {ITableAction, ITableSettings, Row} from '../../piquant-table/models';

@Component({
  selector: 'app-view-button-renderer',
  templateUrl: './view-button-renderer.component.html',
})


export class ViewButtonRendererComponent implements AgRendererComponent {
  listener: () => void;

  public params: any;
  public name: string;
  public ngbWorkAroundLock: boolean = false;
  private actionTable: ITableAction;

  constructor(private router: Router) {

  }

  agInit(params: any): void {
    this.params = params;
    this.name = params.data.name;
    this.actionTable = this.invokeBuildActions();
  }


  public invokeBuildActions(): ITableAction {
    return this.params.context.componentParent.buildTableActions();
  }

  public refresh(params: any): boolean {
    return true;
  }
}
