import {Component} from '@angular/core';
import {AuthService} from '../../auth/auth.service';
import {CacheService} from '../../core/cache.service';
import {UserService} from '../../core/user.service';
import {IUserAuthModel} from '../../core/interfaces/user.interface';
import {ActivatedRoute, Router} from '@angular/router';
import {AssessmentRequestService} from '../../core/assessment-request.service';

@Component({
  selector: 'global-nav',
  templateUrl: 'global-nav.component.html'
})
export class GlobalNavComponent {
  constructor(private authService: AuthService,
              private cacheService: CacheService,
              private router: Router,
              private assessmentRequestService: AssessmentRequestService) { }


  public signOut() {
    this.cacheService.clear();
    this.authService.logout();
  }

  public stopImpersonating() {
    this.cacheService.clear();
    this.authService.impersonatedUser = null;

  
    if (this.router.isActive('/assessment-requests', true)) {
      //Angular 5.1 includes onSameUrlNavigation which would be better than this cheese.
      this.router.navigate(['/assessment-requests/exit-impersonate']);
    } else {
      this.router.navigate(['/']);
    }

  }

  public get titleInfo(): string {
    let title = 'Name: ' + this.displayName;
    title += '\r\nUser Name: ' + this.userName;
    title += '\r\nRole: ' + this.roleName;
    if (this.isImpersonating) {
      title += '\r\nImpersonating: ' + this.impersonatedUserName;
    }

    return title;
  }

  public get userName(): string {
    return this.authService.loggedInUser.username;
  }

  public get displayName(): string {
    return this.authService.loggedInUser.displayName;
  }

  public get roleName(): string {
    const loggedInUser = this.authService.loggedInUser;
    return loggedInUser.isRoleAdmin ? loggedInUser.roleName : this.authService.clientName;
  }

  public get isAdmin(): boolean {
    return this.authService.loggedInUser.isRoleAdmin;
  }

  public get isLoggedIn(): boolean {
    return this.authService.isValidLogin;
  }

  public get isImpersonating(): boolean {
    return this.isAdmin && !!this.authService.impersonatedUser;
  }

  public get impersonatedUserName(): string {
    const user = this.authService.impersonatedUser;
    let name: string = null;

    if (user) {
      name = user.displayName;

      if (user.clientName) {
        name += ` (${user.clientName})`;
      }
    }

    return name;
  }
}
