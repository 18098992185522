import {IEditAssessmentRequestModel} from './edit-assessment-request.interface';
import {ICompetencyModel} from '../core/interfaces/competency.interface';
import {CompetencyModel} from '../core/models/competency.model';
import {AssessmentPurpose} from '../core/enums/assessment-purpose.enum';
import {TestingLocation} from '../core/enums/testing-location.enum';
import {ICandidateModel} from '../core/interfaces/candidate.interface';
import {CandidateModel} from '../core/models/candidate.model';
import {IFeedbackUserModel} from '../core/interfaces/feedback-user.interface';
import {IClientSettingsModel} from '../core/interfaces/client-settings.interface';

export class EditAssessmentRequestModel implements IEditAssessmentRequestModel {
  public assessmentRequestId?: number;
  public assessmentId?: number;
  public parentAssessmentRequestId?: number;
  public billingContactId?: number;
  public statusId: number;

  // client
  public clientId: number;
  public clientName: string;
  public costCenterId: number;
  public costCenterName: string;
  public costCenterText: string;
  public generalLedger: string;
  public activityCenter: string;
  public customCandidateId: string;
  public customPo: string;
  public costCenterOwner: string;
  public companyCode: string;
  public requestedByUserId: number;
  public requestedByUserDisplayName: string;
  public orgUnitId: number;
  public orgUnitName: string;

  // candidate
  public candidate: ICandidateModel;
  public candidateNoResume: boolean;
  public resumeAttachmentId: number;
  public originalFilename: string;
  public isConfidential: boolean;
  public confidentialReason: string;

  // assessment purpose
  public assessmentPurpose: AssessmentPurpose;
  public positionId: number;
  public requestingManager: string;
  public positionReportsTo: string;
  public directReports: number;
  public customCompetencies: string;
  public purposeNotes: string;
  public competencies: ICompetencyModel[] = [];

  // services requested
  public assessmentLevelReferenceId: number;
  public additionalServiceReferenceIds: number[] = [];
  public orginalAdditionalServiceReferenceIds: number[] = [];
  public serviceNotes: string;
  public additionalService: number;

  // feedback
  public feedbackUsers: IFeedbackUserModel[] = [];

  // location
  public testingLocation: TestingLocation;
  public candidateZipCode: string;
  public permissionToContact?: boolean;
  public sendInstructionsName: string;
  public sendInstructionsEmail: string;

  public consultantUserId?: number;

  public clientSettings?: IClientSettingsModel;
  public anyAssessmentLevelReports: boolean;

  public createdDate?: Date;
  public submittedDate?: Date;

  public get isNew(): boolean {
    return !this.assessmentRequestId;
  }

  public get hasParent(): boolean {
    return !!this.parentAssessmentRequestId;
  }

  public static Create(): EditAssessmentRequestModel {
    return new EditAssessmentRequestModel();
  }

  constructor(obj?: IEditAssessmentRequestModel) {
    if (!obj) {
      this.candidate = new CandidateModel();
      return;
    }

    this.assessmentRequestId = obj.assessmentRequestId;
    this.assessmentId = obj.assessmentId;
    this.parentAssessmentRequestId = obj.parentAssessmentRequestId;
    this.billingContactId = obj.billingContactId;
    this.statusId = obj.statusId;

    // client
    this.clientId = obj.clientId;
    this.clientName = obj.clientName;
    this.costCenterId = obj.costCenterId;
    this.costCenterName = obj.costCenterName;
    this.costCenterText = obj.costCenterText;
    this.generalLedger = obj.generalLedger;
    this.activityCenter = obj.activityCenter;
    this.customCandidateId = obj.customCandidateId;
    this.customPo = obj.customPo;
    this.costCenterOwner = obj.costCenterOwner;
    this.companyCode = obj.companyCode;
    this.requestedByUserId = obj.requestedByUserId;
    this.requestedByUserDisplayName = obj.requestedByUserDisplayName;
    this.orgUnitId = obj.orgUnitId;
    this.orgUnitName = obj.orgUnitName;

    // candidate
    this.candidate = obj.candidate ? new CandidateModel(obj.candidate) : new CandidateModel();

    this.candidateNoResume = obj.candidateNoResume;
    this.resumeAttachmentId = obj.resumeAttachmentId;
    this.originalFilename = obj.originalFilename;
    this.isConfidential = obj.isConfidential;
    this.confidentialReason = obj.confidentialReason;

    // assessment purpose
    this.assessmentPurpose = obj.assessmentPurpose as AssessmentPurpose;
    this.positionId = obj.positionId;
    this.requestingManager = obj.requestingManager;
    this.positionReportsTo = obj.positionReportsTo;
    this.directReports = obj.directReports;
    this.customCompetencies = obj.customCompetencies;
    this.purposeNotes = obj.purposeNotes;
    this.competencies = obj.competencies ? obj.competencies.map((c) => new CompetencyModel(c)) : [];

    // services requested
    this.assessmentLevelReferenceId = obj.assessmentLevelReferenceId;
    this.additionalServiceReferenceIds = obj.additionalServiceReferenceIds || [];
    this.serviceNotes = obj.serviceNotes;
    this.additionalService = obj.additionalService;

    // feedback
    this.feedbackUsers = obj.feedbackUsers || [];
    // location
    this.testingLocation = obj.testingLocation;
    this.candidateZipCode = obj.candidateZipCode;
    this.permissionToContact = obj.permissionToContact;
    this.sendInstructionsName = obj.sendInstructionsName;
    this.sendInstructionsEmail = obj.sendInstructionsEmail;
    this.consultantUserId = obj.consultantUserId;
    // custom fields
    this.clientSettings = obj.clientSettings;
    this.anyAssessmentLevelReports = obj.anyAssessmentLevelReports;

    this.submittedDate = obj.submittedDate;
    this.createdDate = obj.createdDate;
  }
}
