
import {defer as observableDefer, Observable} from 'rxjs';
import {map, publishReplay, refCount, take} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ServiceBase} from './service-base';
import {AuthHttpService} from '../auth/auth-http.service';
import {ICountryModel} from './interfaces/country.interface';
import {ISelectItemModel} from './interfaces/select-item.interface';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class CountryService extends ServiceBase {
  private static ApiUrl = 'api/country';
  private data$: Observable<ISelectItemModel[]>;

  constructor(private http: HttpClient) {
    super();
  }

  public get(): Observable<ICountryModel[]> {
    return this.http.get<ICountryModel[]>(CountryService.ApiUrl);
  }

  public getSelectItems(): Observable<ISelectItemModel[]> {
    if (!this.data$) {
      const url = CountryService.ApiUrl + '/list';

      this.data$ = observableDefer(() => this.http.get(url)).pipe(
        map((res: Response) => {
          const countries = (res || []) as ISelectItemModel[];
          return this.sortSelectItems(countries, 196); // 196 is the country_id for 'United States Of America'
        }),
        publishReplay(1),
        refCount(),
        take(1)
      );
    }

    return this.data$;
  }
}
