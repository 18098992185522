export interface ICompetencyModel {
  competencyId?: number;
  parentId?: number;
  competencyType: string;
  name: string;
  isActive: boolean;
  description: string;
  number: string;
  competencySetId: number;
  competencySetName: string;
  createdBy: string;
  createdDate: Date;
  children: ICompetencyModel[];
}

export class EditCompetencySetModel {
  competencySetId: number;
  name: string;
  description: string;
  isActive: boolean;
  competencies: EditCompetencyModel[];
}

export class EditCompetencyModel {
  competencyId: number;
  parentId: number;
  competencyType: string;
  competencySetId: number;
  name: string;
  isActive: boolean;
  description: string;
  children: EditCompetencyModel[];
  isNew = false;
  isDirty = false;
  createdBy: string;
  createdDate: Date;
  isDeleted = false;
}

export class CompetencySelectListItem implements ICompetencyModel {
  competencyId: number;
  parentId: number;
  competencyType: string;
  name: string;
  isActive: boolean;
  description: string;
  number: string;
  competencySetId: number;
  competencySetName: string;
  createdBy: string;
  createdDate: Date;
  children: CompetencySelectListItem[];
  showChildren = false;
  selected = false;
  editing = false;
  isNew = false;
  isDirty = false;
  isDeleted = false;
  isTouched = false;
  assignedAssessments = 0;
  assignedPositions = 0;
}
