import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {CompetencySet} from '../../core/models/competency-set.model';
import {CompetencySelectListItem, EditCompetencyModel, EditCompetencySetModel, ICompetencyModel} from '../../core/interfaces/competency.interface';
import {isNullOrUndefined} from 'util';
import {CompetencyListService} from './competency-list.service';
import {Subscription} from 'rxjs';
import {CompetencySetService} from '../../core/competency-set.service';

@Component({
  selector: 'edit-competency-set',
  templateUrl: './edit-competency-set.component.html',
  styleUrls: ['../../../styles/form.scss'],
  providers: [CompetencyListService]
})
export class EditCompetencySetComponent implements OnInit, OnDestroy {
  public csForm: UntypedFormGroup;
  public competencyName = '';
  public listChangedSub: Subscription;
  private detail: CompetencySet;

  constructor(private _router: Router,
              private _route: ActivatedRoute,
              private _fb: UntypedFormBuilder,
              private _listService: CompetencyListService,
              private _competencySetService: CompetencySetService) {
  }

  public cancel() {
    this._router.navigate(['/competencies']);
  }

  public ngOnInit() {
    this.listChangedSub = this._listService.onListChanged$.subscribe((c) => {
      this.setFormDirty();
    });

    this._route.data.subscribe((data: any) => {
      this.init(data.competencySet as CompetencySet);
    });
  }

  public ngOnDestroy() {
    this.listChangedSub.unsubscribe();
  }

  public onSubmit() {
     if (!this.csForm.valid) {
       return;
     }

    const model = new EditCompetencySetModel();
    model.competencySetId = this.detail.competencySetId || null;
    model.name = this.csForm.get('set.name').value;
    model.description = this.csForm.get('set.description').value;
    model.competencies = this.buildCompetencyHierarchy();


    if (model.competencySetId) {
      this._competencySetService.update(model).subscribe((data: CompetencySet) => {
        //this.init(data);
        this._router.navigate(['/competencies']);
      });
    } else {
      this._competencySetService.insert(model).subscribe((data: CompetencySet) => {
        //this.init(data);
        this._router.navigate(['/competencies']);
      });
    }
  }

  public addCompetency() {
    const comp = new CompetencySelectListItem();
    comp.competencyType = 'C'
    comp.name = this.competencyName.trim() || 'untitled';
    this.addItem(comp);
  }

  public addFolder() {
    const folder = new CompetencySelectListItem();
    folder.competencyType = 'G'
    folder.name = this.competencyName.trim() || 'untitled folder';
    this.addItem(folder);
  }

  private init(competencySet: CompetencySet) {
    this.detail = competencySet || new CompetencySet();
    this._listService.competencies = this.detail.competencies.sort(this.sort) as CompetencySelectListItem[];
    this.buildForm(this.detail);
  }

  private buildCompetencyHierarchy(parentId: number = null): EditCompetencyModel[] {
    const editItems: EditCompetencyModel[] = [];
    for (const comp of this._listService.competencies.filter((c) => c.parentId === parentId)) {
      if (comp.isTouched) {
        if (comp.isNew && comp.isDeleted) {
          continue;
        }
        const editItem = new EditCompetencyModel();
        editItem.isNew = comp.isNew;
        editItem.isDirty = comp.isDirty;
        editItem.competencyId = comp.competencyId;
        editItem.parentId = comp.parentId;
        editItem.name = comp.name;
        editItem.description = comp.description;
        editItem.competencyType = comp.competencyType;
        editItem.isActive = comp.isActive;
        editItem.isDeleted = comp.isDeleted;
        editItem.children = this.buildCompetencyHierarchy(comp.competencyId);
        editItems.push(editItem);
      }
    }
    return editItems;
  }

  private setFormDirty() {
    this.csForm.markAsDirty();
  }

  private addItem(item: CompetencySelectListItem) {
    item.isActive = true;
    item.isDirty = true;
    item.isNew = true;
    item.isTouched = true;
    item.assignedPositions = 0;
    item.assignedAssessments = 0;
    item.parentId = null;
    item.competencyId = this._listService.getNewCompetencyId()

    const selectedItem = this._listService.selectedItem;
    if (!isNullOrUndefined(selectedItem)) {
      if (selectedItem.competencyType === 'C') {
        item.parentId = selectedItem.parentId;
      } else {
        item.parentId = selectedItem.competencyId;
        selectedItem.showChildren = true;
      }
    }

    this.touchParents(item);

    const index = isNullOrUndefined(selectedItem)
      ? this._listService.competencies.length - 1
      : this._listService.competencies.findIndex((e) => e.competencyId === selectedItem.competencyId);

    this._listService.competencies.splice(index, 0, item);
    this.setFormDirty();
    this.competencyName = '';
  }

  public get parentCompetencies() {
    const avail = this._listService.competencies.filter(e => {
      if (this._listService.showInactive) {
        return isNullOrUndefined(e.parentId);
      } else {
        if (e.isDirty) {
          return isNullOrUndefined(e.parentId);
        }

        return isNullOrUndefined(e.parentId) && e.isActive;
      }
    });
    return avail;
  }

  public get showInactive() {
    return this._listService.showInactive;
  }

  public set showInactive(value: boolean) {
    this._listService.showInactive = value;
  }

  private touchParents(competency: CompetencySelectListItem) {
    const parent = this._listService.competencies.find((e) => e.competencyId === competency.parentId);
    if (!parent || parent.isTouched) {
      return;
    }
    parent.isTouched = true;

    if (!isNullOrUndefined(parent.parentId)) {
      this.touchParents(parent);
    }
  }

  private buildForm(detail: CompetencySet) {
    this.csForm = this._fb.group({
      set: this._fb.group({
        name: this._fb.control(detail.name, Validators.compose([Validators.required, Validators.maxLength(100)])),
        description: this._fb.control(detail.description, Validators.compose([Validators.required, Validators.maxLength(100)])),
        isActive: this._fb.control(detail.isActive)
      })
    });
  }

    private sort(c1: ICompetencyModel, c2: ICompetencyModel) {
    if (c1.competencyType.toUpperCase() === 'G') {
      return -1;
    }
    if (c1.competencyType.toUpperCase() === 'C') {
      return 1;
    }
    return 0;
  }
}
