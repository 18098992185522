<div class="site-wrapper">
    
  <main-nav class="left-nav-wrapper"></main-nav>
  <div class="content-wrapper">
      
    <header>
      <global-nav></global-nav>
    </header>
    <div class="content container-fluid">

      <router-outlet></router-outlet>
    </div>
  </div>
</div>
