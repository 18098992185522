import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Injectable} from '@angular/core';
import {IFeedbackUserModel} from '../../../core/interfaces/feedback-user.interface';
import {DefaultFeedbackUsersModel, IFeedbackUserSelectItem, ISelectItemModel, IUserSelectItemModel} from '../../../core/interfaces/select-item.interface';
import {FeedbackUserService} from '../../../core/feedback-user.service';
import {AddNewFeedbackUserDialogComponent} from '../../../feedback/add-new-feedback-user-dialog/add-new-feedback-user-dialog.component';
import {AddExistingFeedbackUserDialogComponent} from '../../../feedback/add-existing-feedback-user-dialog/add-existing-feedback-user-dialog.component';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {SectionBase} from './base.section';
import {EditAssessmentRequestModel} from '../../edit-assessment-request.model';
import {AppService} from '../../../core/app.service';
import {PsValidators} from '../../../core/validators/count.validator';
import {AuthService} from '../../../auth/auth.service';

type ISaveSuccess = (message: string) => void;

@Injectable()
export class FeedbackSection extends SectionBase {
  private _states: ISelectItemModel[];
  private _countries: ISelectItemModel[];
  private _modal: NgbModalRef;
  private _onDefaultsAdded: ISaveSuccess;
  public clientId = null;
  public requestedByUserId = null;
  private servicesRequestedSection: any;

  constructor(private fb: UntypedFormBuilder,
              private modalService: NgbModal,
              private feedbackUserService: FeedbackUserService,
              private appService: AppService,
              private authService: AuthService) {
    super();
  }

  public get feedbackUsersControl() {
    return this.formGroup.controls['feedbackUsers'] as UntypedFormControl;
  }

  public get hasFeedbackUsers(): boolean {
    const feedbackUserValue = this.feedbackUsersControl.value;
    return feedbackUserValue ? feedbackUserValue.length > 0 : false;
  }

  public get isInvalid(): boolean {
    if(this.servicesRequestedSection)
      if(this.servicesRequestedSection.formValue.assessmentLevelReferenceId == 5)
        return false;

    if (!this.feedbackUsersControl) {
      return true;
    }

    if(!this.formGroup.touched)
    {
      return false;
    }

    var foundVerbal: boolean = false;
    if (this.feedbackUsersControl.value) {
      this.feedbackUsersControl.value.forEach((r) => {
        if(r.verbal)
          foundVerbal = true;
      });
      if (!foundVerbal )
        return true;
      else
        return false;
    } 
    else
    return true;

    //return !this.hasFeedbackUsers && (this.feedbackUsersControl.dirty || this.feedbackUsersControl.touched);
  }

  public init({assessmentRequest, states, countries, onDefaultsAdded, servicesRequestedSection}: {
    assessmentRequest: EditAssessmentRequestModel, 
    states: ISelectItemModel[], countries: ISelectItemModel[], 
    onDefaultsAdded?: ISaveSuccess, servicesRequestedSection: any
  }) {
    super.setAssessmentRequest(assessmentRequest);
    this.formGroup = this.buildFormGroup();
    this.servicesRequestedSection = servicesRequestedSection;
    if(this.assessmentRequest.hasParent)
    this.formGroup.disable();

    if (!states) {
      throw new Error('states must be specified');
    }

    this._states = states;
    if (!countries) {
      throw new Error('countries must be specified');
    }
    this._countries = countries;
    this._onDefaultsAdded = onDefaultsAdded;

    this.feedbackUsersControl.setValue(this.assessmentRequest.feedbackUsers);
    this.appService.onLoggingOut.subscribe(() => {
      if (!this._modal) {
        return;
      }
      this._modal.dismiss('logging out');
    });
  }

  public get formValue(): any {
    const values = this.formGroup.value;

    return {
      feedbackUsers: values.feedbackUsers
    };
  }

  public onUpdatedFeedbackUsers(users: IFeedbackUserModel[]) {
    if (users) {
      this.feedbackUsersControl.setValue(users);
      this.feedbackUsersControl.markAsTouched();
      this.feedbackUsersControl.markAsDirty();
    }
  }

  public showAddExistingFeedbackUserDialog(requestedByUser: IUserSelectItemModel) {
    const requestedByUserId = requestedByUser.id;
    this.feedbackUserService.getAvailable(requestedByUserId).subscribe((data: IFeedbackUserSelectItem[]) => {
      this._modal = this.modalService.open(AddExistingFeedbackUserDialogComponent, {size: 'lg'});

      const instance = this._modal.componentInstance as AddExistingFeedbackUserDialogComponent;
      instance.availableFeedbackUsers = data;
      instance.assessmentId = this.assessmentRequest.assessmentId;
      instance.currentFeedbackUsers = this.feedbackUsersControl.value;
      //instance.feedbackUserSection = this;
      instance.requestedByUser = requestedByUser;
      instance.states = this._states;
      instance.countries = this._countries;

      instance.onUsersSelected.subscribe((selectedUsers: IFeedbackUserSelectItem[]) => {
        if (selectedUsers && selectedUsers.length) {
          const defaults = this.feedbackUsersControl.value.concat([]);
          selectedUsers.forEach((user: any) => {
            //We need to save these
            if (user.setDefault) {
              defaults.push(user);
            }
            //Strip Written Feeback if Confidential
            if(this.assessmentRequest.isConfidential)
              user.written = false;
          });

          if (defaults.length !== this.feedbackUsersControl.value.length) {
            defaults.forEach((user: any) => {
              user.id = user.userId;
              //delete(user.userId);
            });
            const model = new DefaultFeedbackUsersModel();
            model.clientId = (this.clientId) ? this.clientId : this.authService.clientId;
            model.ownerUserId = (this.requestedByUserId) ? this.requestedByUserId : this.authService.userId;
            model.defaultUsers = defaults;

            this.feedbackUserService.saveDefaults(model).subscribe(() => {
              this._onDefaultsAdded('Default Feedback Recipient Successfully Saved!');
            });
          }
          const val = this.feedbackUsersControl.value.concat(selectedUsers);
          this.feedbackUsersControl.setValue(val);
          this.feedbackUsersControl.markAsTouched();
          this.feedbackUsersControl.markAsDirty();
        }
      });

      instance.onAddNewFeedbackUser.subscribe((newUser: IFeedbackUserModel) => {
        const val = this.feedbackUsersControl.value.concat([newUser]);
        this.feedbackUsersControl.setValue(val);
        this.feedbackUsersControl.markAsTouched();
        this.feedbackUsersControl.markAsDirty();
      });


    });
  }

  public onClickAddNewFeedbackRecipient(requestedByUser: IUserSelectItemModel) {
    this._modal = this.modalService.open(AddNewFeedbackUserDialogComponent, {size: 'lg'});
    const instance = this._modal.componentInstance as AddNewFeedbackUserDialogComponent;
    instance.requestedByUser = requestedByUser;
    instance.states = this._states;
    instance.countries = this._countries;
    instance.onAddNewFeedbackUser.subscribe((newUser: IFeedbackUserModel) => {
      const val = this.feedbackUsersControl.value.concat([newUser]);
      this.feedbackUsersControl.setValue(val);
      this.feedbackUsersControl.markAsDirty();
    });
  }

  protected buildFormGroup(): UntypedFormGroup {
    return this.fb.group({feedbackUsers: null});
  }
}
