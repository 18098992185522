import {Component, Input} from '@angular/core';

@Component({
    selector: 'action-bar-text',
    template: `
      <div [ngClass]="className" class="action-bar-text" style="padding-left: 10px"><ng-content></ng-content></div>
    `
})
export class ActionBarTextComponent {
  @Input() public className: any;
}
