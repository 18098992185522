import {Component, Input} from '@angular/core';
import {isNullOrUndefined} from 'util';

@Component({
  selector: 'ps-static-text',
  template: `<div class="static-text">{{displayText}}</div>`
})
export class PsStaticTextComponent {
  @Input() public text: string;
  @Input() public nullText = 'None provided';

  public get displayText() {
    return isNullOrUndefined(this.text) ? this.nullText : this.text
  }
}
