import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {ISelectItemModel, IUserSelectItemModel} from './interfaces/select-item.interface';
import {ClientService} from './client.service';
import {CountryService} from './country.service';
import {StateService} from './state.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {LanguageService} from './language.service';
import {UserService} from './user.service';
import {IPositionListItemModel} from './interfaces/position-list-item.interface';
import {PositionService} from './position.service';
import {IPositionLevelModel} from './interfaces/position-level.interface';
import {PositionLevelService} from './position-level.service';
import {IEditPositionModel} from './interfaces/edit-position.interface';
import {EditPositionModel} from './models/edit-position.model';
import {IClientCompetenciesModel} from './interfaces/client-competencies.interface';
import {CompetencySetService} from './competency-set.service';
import {ICompetencyModel} from './interfaces/competency.interface';
import {CompetencyService} from './competency.service';
import {IPositionFunctionModel} from './interfaces/position-function.interface';
import {IPositionDescriptionOverrideModel} from './interfaces/position-description-override.interface';
import {PositionFunctionService} from './position-function.service';
import {PositionDescriptionOverrideService} from 'app/core/position-description-override.service';
import {UserLevelService} from './user-level.service';
import {RoleService} from './role.service';
import {IAssessmentLevelReferenceModel} from './interfaces/assessment-level-reference.interface';
import {AssessmentLevelReferenceService} from './assessment-level-reference.service';
import {IAdditionalServiceReferenceModel} from './interfaces/additional-service-reference.interface';
import {AdditionalServiceReferenceService} from './additional-service-reference.service';
import {OrgUnitItem} from './interfaces/org-unit-item.interface';
import {OrgUnitService} from './org-units.service';
import {IAssessmentTestingScheduleModel} from './interfaces/assessment-testing-schedule.interface';
import {AssessmentRequestService} from './assessment-request.service';
import {EmailTemplateService} from './email-template.service';
import {ICompetencySetDetail} from './interfaces/competency-set-detail.interface';
import {CompetencySet, CompetencySetListItem} from './models/competency-set.model';
import {IOrgUnitModel} from './interfaces/org-unit.interface';
import {AuthService} from '../auth/auth.service';

@Injectable()
export class ClientSelectItemsResolver implements Resolve<ISelectItemModel[]> {
  constructor(private clientService: ClientService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<ISelectItemModel[]> {
    return this.clientService.getSelectItems();
  }
}

@Injectable()
export class ClientSelectItemsNoImpersonationResolver implements Resolve<ISelectItemModel[]> {
  constructor(private clientService: ClientService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<ISelectItemModel[]> {
    return this.clientService.getSelectItemsWithoutImpersonation();
  }
}

@Injectable()
export class LanguageSelectItemsResolver implements Resolve<ISelectItemModel[]> {
  constructor(private languageService: LanguageService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<ISelectItemModel[]> {
    return this.languageService.getSelectItems();
  }
}

@Injectable()
export class CountrySelectItemsResolver implements Resolve<ISelectItemModel[]> {
  constructor(private countryService: CountryService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<ISelectItemModel[]> {
    return this.countryService.getSelectItems();
  }
}

@Injectable()
export class StateSelectItemsResolver implements Resolve<ISelectItemModel[]> {
  constructor(private stateService: StateService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<ISelectItemModel[]> {
    return this.stateService.getSelectItems();
  }
}


@Injectable()
export class UserSelectItemsResolver implements Resolve<IUserSelectItemModel[]> {
  constructor(private userService: UserService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<IUserSelectItemModel[]> {
    return this.userService.getSelectItems();
  }
}

@Injectable()
export class UserSelectItemsNoImpersonationResolver implements Resolve<IUserSelectItemModel[]> {
  constructor(private userService: UserService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<IUserSelectItemModel[]> {
    return this.userService.getSelectItemsWithoutImpersonation();
  }
}

@Injectable()
export class PositionListItemsResolver implements Resolve<IPositionListItemModel[]> {
  constructor(private positionService: PositionService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<IPositionListItemModel[]> {
    return this.positionService.getListItems();
  }
}

@Injectable()
export class PositionListResolver implements Resolve<IPositionListItemModel[]> {
  constructor(private positionService: PositionService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<IPositionListItemModel[]> {
    return this.positionService.getPositionList();
  }
}

@Injectable()
export class PositionLevelResolver implements Resolve<IPositionLevelModel[]> {
  constructor(private positionLevelService: PositionLevelService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<IPositionLevelModel[]> {
    return this.positionLevelService.get();
  }
}

@Injectable()
export class PositionResolver implements Resolve<IEditPositionModel> {
  constructor(private positionService: PositionService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<IEditPositionModel> {
    const id = +route.params['id'];
    return this.positionService.get(id);
  }
}

@Injectable()
export class NewPositionResolver implements Resolve<IEditPositionModel> {
  constructor(private clientService: ClientService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<IEditPositionModel> {
    const clientId = +route.params['id'];

    return this.clientService.getCompetencies(clientId).pipe(
      map((result) => {
        const clientCompetencies = result as ICompetencyModel[];

        return new EditPositionModel({
          clientId,
          clientCompetencies,
          isActive: true
        } as IEditPositionModel);
      })
    );
  }
}

@Injectable()
export class CompetencySetsResolver implements Resolve<CompetencySetListItem[]> {
  constructor(private competencySetService: CompetencySetService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<CompetencySetListItem[]> {
    return this.competencySetService.getList();
  }
}

@Injectable()
export class CompetencySetResolver implements Resolve<CompetencySet> {
  constructor(private competencySetService: CompetencySetService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<CompetencySet> {
    const id = route.params['id'];
    return this.competencySetService.get(id, true);
  }
}

@Injectable()
export class CompetenciesResolver implements Resolve<ICompetencyModel[]> {
  constructor(private competencyService: CompetencyService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<ICompetencyModel[]> {
    const id = route.params['id'];
    return this.competencyService.getByCompetencySet(id);
  }
}

@Injectable()
export class PositionFunctionsResolver implements Resolve<IPositionFunctionModel[]> {
  constructor(private positionFunctionService: PositionFunctionService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<IPositionFunctionModel[]> {
    return this.positionFunctionService.get();
  }
}

@Injectable()
export class PositionDescriptionOverridesResolver implements Resolve<IPositionDescriptionOverrideModel[]> {
  constructor(private positionDescriptionOverrideService: PositionDescriptionOverrideService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<IPositionDescriptionOverrideModel[]> {
    return this.positionDescriptionOverrideService.get();
  }
}

@Injectable()
export class RoleSelectItemsResolver implements Resolve<ISelectItemModel[]> {
  constructor(private roleService: RoleService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<ISelectItemModel[]> {
    return this.roleService.getSelectItems();
  }
}

@Injectable()
export class AssessmentLevelReferencesResolver implements Resolve<IAssessmentLevelReferenceModel[]> {
  constructor(private assessmentLevelReferenceService: AssessmentLevelReferenceService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<IAssessmentLevelReferenceModel[]> {
    return this.assessmentLevelReferenceService.getList();
  }
}

@Injectable()
export class UserLevelSelectItemsResolver implements Resolve<ISelectItemModel[]> {
  constructor(private userLevelService: UserLevelService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<ISelectItemModel[]> {
    return this.userLevelService.getSelectItems();
  }
}

@Injectable()
export class AdditionalServiceReferencesResolver implements Resolve<IAdditionalServiceReferenceModel[]> {
  constructor(private additionalServiceReferenceService: AdditionalServiceReferenceService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<IAdditionalServiceReferenceModel[]> {
    return this.additionalServiceReferenceService.getList();
  }
}

@Injectable()
export class OrgUnitResolver implements Resolve<OrgUnitItem[]> {
  constructor(private orgUnitService: OrgUnitService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<OrgUnitItem[]> {
    const clientId = +route.params['id'];
    const isActive = route.data.isActiveForOrgUnit;
    return this.orgUnitService.getOrgUnits(clientId, isActive);
  }
}

@Injectable()
export class OrgUnitByUserResolver implements Resolve<OrgUnitItem[]> {
  constructor(private orgUnitService: OrgUnitService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<OrgUnitItem[]> {
    const userId = +route.params['id'];
    return this.orgUnitService.getOrgUnitsByUserId(userId);
  }
}

@Injectable()
export class OrgUnitByUserPositionResolver implements Resolve<ISelectItemModel[]> {
  constructor(private orgUnitService: OrgUnitService, private auth: AuthService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<ISelectItemModel[]> {
    if (this.auth.isRoleAdmin) {
      // if user is an admin, return all org units for the given client
      const clientId = +route.queryParams['clientId'] || +route.params['id'];
      return this.orgUnitService.getOrgUnitsList(clientId);
    }

    // if user is not an admin, only return org units assigned to the user in org_unit_user table
    return this.orgUnitService.getOrgUnitsForUserId(this.auth.userId).pipe(
      map(orgUnits =>
      orgUnits.map(ou => {
        return {
          id: ou.orgUnitId,
          name: ou.name
        } as ISelectItemModel;
      }))
    );
  }
}

@Injectable()
export class AssessmentTestingScheduleResolver implements Resolve<IAssessmentTestingScheduleModel> {
  constructor(private assessmentRequestService: AssessmentRequestService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<IAssessmentTestingScheduleModel> {
    const assessmentRequestId = +route.params['id'];
    return this.assessmentRequestService.getTestingSchedule(assessmentRequestId);
  }
}

@Injectable()
export class EmailTemplateSelectItemsResolver implements Resolve<ISelectItemModel[]> {
  constructor(private emailTemplateService: EmailTemplateService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<ISelectItemModel[]> {
    return this.emailTemplateService.getSelectItems();
  }
}

@Injectable()
export class EmailTemplateSelectAssessmentItemsResolver implements Resolve<ISelectItemModel[]> {
  constructor(private emailTemplateService: EmailTemplateService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<ISelectItemModel[]> {
    return this.emailTemplateService.getSelectItems();
  }
}

