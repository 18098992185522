
import {defer as observableDefer, Observable} from 'rxjs';
import {map, publishReplay, refCount, take} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {AuthHttpService} from '../auth/auth-http.service';
import {ServiceBase} from './service-base';
import {ISelectItemModel} from './interfaces/select-item.interface';
import {ICompetencySetDetail} from './interfaces/competency-set-detail.interface';
import {CompetencySet, CompetencySetListItem} from './models/competency-set.model';
import {EditCompetencySetModel} from './interfaces/competency.interface';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class CompetencySetService extends ServiceBase {
  private static ApiUrl = 'api/competency-set';
  private data$: Observable<CompetencySetListItem[]>;
  private cacheDuration: number = 1000 * 60 * 10;

  constructor(private http: HttpClient) {
    super();
  }

  public getList(): Observable<CompetencySetListItem[]> {
    this.data$ = observableDefer(() => this.http.get(CompetencySetService.ApiUrl)).pipe(
      map((res) => res ? res as CompetencySetListItem[] : []),
      publishReplay(1, this.cacheDuration),
      refCount(),
      take(1)
    );

    return this.data$;
  }

  public get(id: number, includeInactive: boolean = false): Observable<CompetencySet> {
    const url = `${CompetencySetService.ApiUrl}/${id}?includeInactive=${includeInactive}`;
    return this.http.get<CompetencySet>(url);
  }

  public getDetail(competencySetId: number): Observable<ICompetencySetDetail> {
    const url = `${CompetencySetService.ApiUrl}/${competencySetId}/detail`;
    return this.http.get<ICompetencySetDetail>(url);
  }

  public update(model: EditCompetencySetModel): Observable<CompetencySet> {
    const url = `${CompetencySetService.ApiUrl}/${model.competencySetId}`;
    const json = JSON.stringify(model);
    return this.http.put<CompetencySet>(url, json, {headers: this.jsonHeaders});
  }

  public insert(model: EditCompetencySetModel): Observable<CompetencySet> {
    const url = `${CompetencySetService.ApiUrl}`;
    const json = JSON.stringify(model);
    return this.http.post<CompetencySet>(url, json, {headers: this.jsonHeaders});
  }
}
