import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CompetencySelectListItem} from '../../core/interfaces/competency.interface';

@Component({
  selector: 'delete-competency-confirm',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">Delete {{typeName}}?</h4>
      <span class="close-icon" aria-label="Close" (click)="activeModal.dismiss('Cross click')" style="cursor: pointer;">
        &times;
      </span>
    </div>
    <div class="modal-body">
      <p>Are you sure want to delete the {{typeName}}:<br/><strong>{{competency.name}}</strong></p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="activeModal.close('Yes click')">Yes</button>
      <button type="button" class="btn btn-default" (click)="activeModal.dismiss('No click')">No</button>
    </div>`
})
export class DeleteCompetencyDialogComponent {
  @Input() competency: CompetencySelectListItem;

  public get typeName(): string {
    return this.competency.competencyType === 'C'
      ? 'Competency' : 'Competency Group'
  }

  constructor(public activeModal: NgbActiveModal) {

  }
}
