import {Injectable} from '@angular/core';
import {ServiceBase} from './service-base';
import {AssessmentRequestService} from './assessment-request.service';
import {AuthHttpService} from '../auth/auth-http.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient, HttpResponse} from '@angular/common/http';

@Injectable()
export class SecureFileDownload extends ServiceBase {

  constructor(private http: HttpClient) {
    super();
  }

  public DownloadFile(id: any) {
  }

  public DownloadAssessmentRequest(id: number): Observable<Blob> {
    const url = `${AssessmentRequestService.ApiUrl}/download?id=${id}`;
    return this.http.get<Blob>(url, { responseType: 'blob' as 'json' });
  }
}
