import {Injectable} from '@angular/core';
import {ServiceBase} from './service-base';
import {AuthHttpService} from '../auth/auth-http.service';
import {IBillingContactModel} from './interfaces/billing-contact.interface';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class BillingContactService extends ServiceBase {
  private static ApiUrl = 'api/billing-contact';

  constructor(private http: HttpClient) {
    super();
  }

  public add(billingContact: IBillingContactModel): Observable<IBillingContactModel> {
    const json = JSON.stringify(billingContact);

    return this.http.post<IBillingContactModel>(BillingContactService.ApiUrl, json, {headers: this.jsonHeaders});
  }

  public delete(billingContactId: number): Observable<IBillingContactModel> {
    const url = `${BillingContactService.ApiUrl}/delete/${billingContactId}`;

    return this.http.delete<IBillingContactModel>(url);
  }

  public setdefault(billingContact: IBillingContactModel): Observable<IBillingContactModel> {
    const url = `${BillingContactService.ApiUrl}/set-default/${billingContact.billingContactId}`;
    const json = JSON.stringify(billingContact);
    return this.http.put<IBillingContactModel>(url, json, {headers: this.jsonHeaders});
  }

  public update(billingContact: IBillingContactModel): Observable<IBillingContactModel> {
    const url = `${BillingContactService.ApiUrl}/${billingContact.billingContactId}`;
    const json = JSON.stringify(billingContact);

    return this.http.put<IBillingContactModel>(url, json, {headers: this.jsonHeaders});
  }
}
