import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {DataSource} from '../data/data-source';

@Component({
  selector: 'ps-table-quick-filter',
  styleUrls: ['./quickfilter.component.scss'],
  template: `
    <div class="ps-table-quick-filter">
      <input type="text" placeholder="Quick Filter" [(ngModel)]="query" (keyup)="_filter($event)"/><i class="fa fa-search"></i>
    </div>`
})
export class QuickFilterComponent implements AfterViewInit {
  @Input() source: DataSource;
  @Output() filter = new EventEmitter<any>();

  public query = '';
  private timeout: any;
  private delay = 300;

  ngAfterViewInit(): void {
    this.source.onChanged().subscribe(() => {
      const query = this.source.getQuickFilter();
      if (!query) {
        this.query = '';
      }
    });
  }

  _filter(event): boolean {
    if (event.which === 13) {
      this.addFilter();
      // ignore tab component
    } else if (event.which !== 9) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.addFilter();
      }, this.delay);
    }
    this.filter.emit(null);
    return false;
  }

  addFilter(): void {
    this.source.addQuickFilter(this.query);
  }
}
