import {ICandidateModel} from '../interfaces/candidate.interface';
import {Utility} from '../utility';

export class CandidateModel implements ICandidateModel {
  public candidateId?: number;
  public position: string;
  public firstName: string;
  public middleName: string;
  public lastName: string;
  public email: string;
  public phoneWork: string;
  public phoneMobile: string;
  public gender: string;
  public languageId: number;
  public languageName: string;
  public resumeAttachmentId: number;
  public originalFilename: string;
  public candidateType: string;
  public otherLanguage: string;

  constructor(obj?: ICandidateModel) {
    if (!obj) {
      return;
    }

    if (obj.candidateId) {
      this.candidateId = obj.candidateId;
    }

    this.position = obj.position;
    this.firstName = obj.firstName;
    this.middleName = obj.middleName;
    this.lastName = obj.lastName;
    this.email = obj.email;
    this.phoneWork = obj.phoneWork;
    this.phoneMobile = obj.phoneMobile;
    this.gender = obj.gender;
    this.languageId = obj.languageId;
    this.languageName = obj.languageName;
    this.resumeAttachmentId = obj.resumeAttachmentId;
    this.originalFilename = obj.originalFilename;
    this.candidateType = obj.candidateType;
    this.otherLanguage = obj.otherLanguage;
  }

  public get displayName(): string {
    return Utility.buildDisplayName(this.firstName, this.lastName);
  }
}
