
import {forkJoin as observableForkJoin, Observable} from 'rxjs';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {IPositionListItemModel} from '../../../core/interfaces/position-list-item.interface';
import {Injectable} from '@angular/core';
import {IClientCompetenciesModel} from '../../../core/interfaces/client-competencies.interface';
import {IPositionFunctionModel} from '../../../core/interfaces/position-function.interface';
import {IPositionLevelModel} from '../../../core/interfaces/position-level.interface';
import {IPositionDescriptionOverrideModel} from '../../../core/interfaces/position-description-override.interface';
import {EditPositionModalComponent} from '../../../positions/edit-position-modal/edit-position-modal.component';
import {EditPositionModel} from '../../../core/models/edit-position.model';
import {IEditPositionModel} from '../../../core/interfaces/edit-position.interface';
import {PositionService} from '../../../core/position.service';
import {PositionLevelService} from '../../../core/position-level.service';
import {ClientService} from '../../../core/client.service';
import {PositionFunctionService} from '../../../core/position-function.service';
import {PositionDescriptionOverrideService} from '../../../core/position-description-override.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {SectionBase} from './base.section';
import {EditAssessmentRequestModel} from '../../edit-assessment-request.model';
import {ICompetencyModel} from '../../../core/interfaces/competency.interface';
import {AppService} from '../../../core/app.service';
import {AddJobDescriptionModalComponent} from '../../add-job-description-modal/add-job-description-modal.component';
import {ISelectItemModel} from '../../../core/interfaces/select-item.interface';

type IPositionChange = (position: IPositionListItemModel) => void;
type IUploadDescription = (positionId: number, attachmentId: number, fileName: string) => void;

@Injectable()
export class AssessmentPurposeSection extends SectionBase {
  private _positions: IPositionListItemModel[];
  private _onPositionChange: IPositionChange;
  private _modal: NgbModalRef;

  constructor(private fb: UntypedFormBuilder,
              private positionService: PositionService,
              private clientService: ClientService,
              private positionLevelService: PositionLevelService,
              private positionFunctionService: PositionFunctionService,
              private positionDescriptionOverrideService: PositionDescriptionOverrideService,
              private modalService: NgbModal,
              private appService: AppService) {
    super();
  }

  public get positions(): IPositionListItemModel[] {
    return this._positions || [];
  }

  public get purposeControl(): UntypedFormControl {
    return this.formGroup.controls['purpose'] as UntypedFormControl;
  }

  public get confidentialReasonControl(): UntypedFormControl {
    return this.formGroup.controls['confidentialReason'] as UntypedFormControl;
  }

  public get positionControl(): UntypedFormControl {
    return this.formGroup.controls['position'] as UntypedFormControl;
  }

  public get requestingManagerControl(): UntypedFormControl {
    return this.formGroup.controls['requestingManager'] as UntypedFormControl;
  }

  public get positionReportsToControl(): UntypedFormControl {
    return this.formGroup.controls['positionReportsTo'] as UntypedFormControl;
  }

  public get directReportsControl(): UntypedFormControl {
    return this.formGroup.controls['directReports'] as UntypedFormControl;
  }

  public init({assessmentRequest, onPositionChange, onPositionDescriptionUpload}: {
    assessmentRequest: EditAssessmentRequestModel,
    onPositionChange?: IPositionChange,
    onPositionDescriptionUpload?: IUploadDescription}) {

    super.setAssessmentRequest(assessmentRequest);
    this.formGroup = this.buildFormGroup();
    this._onPositionChange = onPositionChange;

    this.purposeControl.setValue(this.assessmentRequest.assessmentPurpose);
    this.requestingManagerControl.setValue(this.assessmentRequest.requestingManager);
    this.positionReportsToControl.setValue(this.assessmentRequest.positionReportsTo);
    this.directReportsControl.setValue(this.assessmentRequest.directReports);
    this.confidentialReasonControl.setValue(this.assessmentRequest.confidentialReason);
    let postitionSetValueLock = false;
    if (this.assessmentRequest.clientId) {
      this.positionService.getListItems(this.assessmentRequest.clientId)
        .subscribe((positions) => {
          this._positions = positions;
          const position = this.assessmentRequest.positionId
            ? this._positions.find((p) => p.positionId === this.assessmentRequest.positionId)
            : null;

          if (position) {
            postitionSetValueLock = true;
            this.positionControl.setValue(position);
          }
        });
    }
    this.appService.onLoggingOut.subscribe(() => {
      if (!this._modal) {
        return;
      }
      this._modal.dismiss('logging out');
    });

    this.positionControl.valueChanges.subscribe((newPosition) => {
      if (this._onPositionChange) {

        //Implementing this lock because we setValue runs async without exposing an observable, could also try emitEvent=false but there's a risk of breaking stuff elsewhere.
        //The setValue call above is triggering this modal even durring Init - this should catch the first change event.
        if(postitionSetValueLock){
          postitionSetValueLock = false;
        } else {
          this._onPositionChange(newPosition);
          if (newPosition && newPosition.positionId && !newPosition.jobDescriptionAttachmentId) {
            this._modal = this.modalService.open(AddJobDescriptionModalComponent, {size: 'sm'});
            const instance = this._modal.componentInstance as AddJobDescriptionModalComponent;

            instance.position = newPosition;

            instance.onDescriptionAdded.subscribe((descriptionAdded: any) => {
              onPositionDescriptionUpload(descriptionAdded.positionId, descriptionAdded.attachmentId, descriptionAdded.fileName);
            });
          }
        }
      }
    }
  );
  }

  public get formValue(): any {
    const values = this.formGroup.value;
    const position: IPositionListItemModel = values.position;

    return {
      assessmentPurpose: values.purpose,
      confidentialReason: values.confidentialReason,
      positionId: position ? position.positionId : null,
      requestingManager: values.requestingManager,
      positionReportsTo: values.positionReportsTo,
      directReports: values.directReports
    };
  }

  public onClientChange(clientId: number) {
    this.positionService.getListItems(clientId).subscribe((items) => {this._positions = items;});
    
    //this.positionControl.setValue(-1); //Part of a bug fix for IE11/Edge possible related to angular issue #10010 -> Null might be better...
  }

  public showAddPositionDialog(clientId: number, orgUnitId: number, userOrgUnits: ISelectItemModel[], onAddPosition: IPositionChange, readOnlyPosition: boolean, copy: IEditPositionModel, confidential: boolean) {
    const clientCompetencies$ = this.clientService.getCompetencies(clientId);
    const positionFunctions$ = this.positionFunctionService.get();
    const positionLevels$ = this.positionLevelService.get();
    const positionDescriptionOverrides$ = this.positionDescriptionOverrideService.get();

    observableForkJoin(clientCompetencies$, positionFunctions$, positionLevels$, positionDescriptionOverrides$).subscribe((result) => {
      const clientCompetencies = result[0] as ICompetencyModel[];
      const functions = result[1] as IPositionFunctionModel[];
      const positionLevels = result[2] as IPositionLevelModel[];
      const descriptionOverrides = result[3] as IPositionDescriptionOverrideModel[];

      this._modal = this.modalService.open(EditPositionModalComponent, {size: 'lg'});
      const instance = this._modal.componentInstance as EditPositionModalComponent;
      if(copy != null){
        instance.position = new EditPositionModel({
          clientId: clientId,
          orgUnitId: orgUnitId,
          clientCompetencies: clientCompetencies,
          competencies: copy.competencies,
          customCompetency: copy.customCompetency,
          jobDescriptionAttachmentId: copy.jobDescriptionAttachmentId,
          jobDescriptionFileName: copy.jobDescriptionFileName,
          noJobDescription: copy.noJobDescription,
          positionLevelName: copy.positionLevelName,
          name: copy.name,
          description: copy.description,
          isActive: true,
          isConfidential: true
        } as IEditPositionModel);
        instance.copy = true;
      } else {
        instance.position = new EditPositionModel({
          clientId: clientId,
          orgUnitId: orgUnitId,
          clientCompetencies: clientCompetencies,
          competencies: [],
          isActive: true,
          isConfidential: confidential
        } as IEditPositionModel);
      }
      instance.positionLevels = positionLevels;
      instance.positionFunctions = functions;
      instance.positionDescriptionOverrides = descriptionOverrides;
      // Apparently this either needs to be length>1 or null.
      instance.userOrgUnits = userOrgUnits;
      instance.readOnlyPosition = readOnlyPosition;
      instance.confidential = confidential;
      instance.onCanceled.subscribe(() => {
        onAddPosition(null);
      });
      if (onAddPosition) {
        instance.onPositionSaved.subscribe((newPosition: IEditPositionModel) => {
          if (!newPosition) {
            onAddPosition(null);
            return;
          }

          this.positionService.getListItems(clientId).subscribe((items) => {
            this._positions = items;

            const selectedPos = this._positions.find((p) => p.positionId === newPosition.positionId);

            if (selectedPos) {
              onAddPosition(selectedPos);
            }
          });
        });
      }
    });
  }
  
  protected buildFormGroup(): UntypedFormGroup {    
    return this.fb.group({
      purpose: null,
      position: null,
      confidentialReason: null,
      requestingManager: [null, Validators.maxLength(100)],
      positionReportsTo: [null, Validators.maxLength(100)],
      directReports: [null, Validators.maxLength(45)]
    });
  }
}
