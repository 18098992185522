import {Injectable} from '@angular/core';
import {ServiceBase} from './service-base';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AuthHttpService} from '../auth/auth-http.service';
import {ProxySubmitItem} from './interfaces/assessment-request-proxy.interface';
import {DefaultFeedbackUsersModel} from './interfaces/select-item.interface';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class AssessmentRequestProxyService extends ServiceBase {
  private static ApiUrl = 'api/assessment-request-proxy';

  constructor(private http: HttpClient) {
    super();
  }

  public getProxyUsers(assessmentRequestId: number): Observable<any[]> {
    const url = `${AssessmentRequestProxyService.ApiUrl}/${assessmentRequestId}`;
    return this.http.get<any[]>(url);
  }

  public saveProxyList(model: ProxySubmitItem): Observable<void> {
    const url = `${AssessmentRequestProxyService.ApiUrl}/users`;
    const json = JSON.stringify(model);

    return this.http.post<void>(url, json, {headers: this.jsonHeaders});
  }

}
