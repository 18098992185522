import {Component, OnInit} from '@angular/core'
import {AuthService} from '../../auth/auth.service';
import {DefaultFeedbackUsersModel, IFeedbackUserSelectItem, ISelectItemModel} from '../../core/interfaces/select-item.interface';
import {isNullOrUndefined} from 'util';

import {ClientService} from '../../core/client.service';
import {FeedbackUserService} from '../../core/feedback-user.service';
import {Utility} from '../../core/utility';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {UserService} from '../../core/user.service';
import {Router} from '@angular/router';
import {ClientModel} from '../../clients/client.model';

@Component({
  selector: 'manage-feedback-user',
  templateUrl: './manage-feedback-user.component.html'
})
export class ManageFeedbackUserComponent implements OnInit {
  public clients: ISelectItemModel[] = [];
  public client: ISelectItemModel = null;
  public users: ISelectItemModel[] = [];
  public ownerUser: ISelectItemModel = null;

  public fbUsers: IFeedbackUserSelectItem[] = [];
  public alertMessage: string;
  private _saveSuccess = new Subject<string>();

  constructor(private authService: AuthService,
              private userService: UserService,
              private clientService: ClientService,
              private feedbackUserService: FeedbackUserService,
              private router: Router) {
  }

  public ngOnInit() {
    if (this.isAdmin) {
      this.clientService.getSelectItems().subscribe((data: ISelectItemModel[]) => {
        this.clients = data;
      });
    } else {
      this.client = {id: this.authService.clientId, name: this.authService.clientName, selected: true} as ISelectItemModel;
      this.ownerUser = {id: this.authService.userId, name: this.authService.user.displayName, selected: true} as ISelectItemModel;
      this.feedbackUserService.getAvailableForClient(this.authService.clientId, this.authService.userId).subscribe(data => {
        this.fbUsers = data;
      });
    }
    this._saveSuccess.subscribe((message) => this.alertMessage = message);
    debounceTime.call(this._saveSuccess, 2000).subscribe(() => this.alertMessage = null);
  }

  public submit() {
    if (this.assignedUsers.length === 0) {
      return;
    }

    const model = new DefaultFeedbackUsersModel();
    model.clientId = this.client.id;
    model.ownerUserId = this.ownerUser.id;
    model.defaultUsers = this.assignedUsers;

    this.feedbackUserService.saveDefaults(model).subscribe(() => {
      this.availableUsers.forEach(u => { u.selected = false; });
      this.showSaveSuccess();
    });
  }

  public cancel() {
    this.router.navigate(['assessment-requests']);
  }

  public onClientChanged(selectedClient: ISelectItemModel) {
    if (isNullOrUndefined(selectedClient)) {
      this.resetOwnerUsers();
      this.fbUsers = [];
      this.client = null;
    } else {
      this.client = selectedClient;
      this.loadOwnerUsers(selectedClient.id);
    }
  }

  public onOwnerUserChanged(selectedUser: ISelectItemModel) {
    if (isNullOrUndefined(selectedUser)) {
      this.ownerUser = null;
      this.loadFeedBackUsers();
    } else {
      this.ownerUser = selectedUser;
      this.loadFeedBackUsers(this.ownerUser.id);
    }
  }

  public verbalChanged($event, item: IFeedbackUserSelectItem) {
    item.verbal = !item.verbal;
  }
  public writtenChanged($event, item: IFeedbackUserSelectItem) {
    item.written = !item.written;
  }

  public get availableUsers(): IFeedbackUserSelectItem[] {
    return this.fbUsers.filter(u => !u.selected).sort(Utility.sortByName);
  }

  public get assignedUsers(): IFeedbackUserSelectItem[] {
    return this.fbUsers.filter(u => u.selected).sort(Utility.sortByName);
  }

  public get isAdmin(): boolean {
    return this.authService.isRoleAdmin;
  }

  public itemClicked(clickedItem: IFeedbackUserSelectItem) {
    clickedItem.selected = !clickedItem.selected;
  }

  private loadOwnerUsers(clientId: number) {
    this.userService.getUsersByClientId(clientId, true).subscribe(data => {
      this.users = data;
    });
  }

  private loadFeedBackUsers(ownerUserid: number = null) {
    this.fbUsers = [];
    this.feedbackUserService.getAvailableForClient(this.client.id, ownerUserid).subscribe(data => {
      this.fbUsers = data;
    });
  }

  private resetOwnerUsers() {
    this.users = [];
    this.ownerUser = null;
  }

  private showSaveSuccess() {
    this._saveSuccess.next('Default Feedback Recipient Successfully Saved!');
    setTimeout(() => {
        this.router.navigate(['assessment-requests']);
      },
      2000);
  }
}
