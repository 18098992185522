export interface ISelectItemModel {
  id: number;
  name: string;
  selected: boolean;
}

export interface IUserSelectItemModel extends ISelectItemModel {
  clientId: number;
  clientName?: string;
}

export interface IFeedbackUserSelectItem extends ISelectItemModel {
  clientId: number;
  userId: number;
  assessmentId?: number;
  email: string;
  phoneMobile?: string;
  phoneWork?: string;
  verbal: boolean;
  written: boolean;
  isSystem: boolean;
  setDefault: boolean;
}

export class DefaultFeedbackUsersModel {
  clientId: number;
  ownerUserId?: number = null;
  defaultUsers: IFeedbackUserSelectItem[];
}
