import {Component, Input} from '@angular/core';
import {HierarchyNode} from '../../shared/enhanced-controls/core/hierarchynode/ps-hierarchy-node';
import {IOrgUnitNode} from '../org-unit-node.interface';
import {IOrgUnitModel} from '../../core/interfaces/org-unit.interface';

@Component({
  selector: 'assign-user-org-unit',
  templateUrl: './assign-user-org-unit.html'
})
export class AssignUserOrgUnitComponent {
  @Input() public orgUnits: IOrgUnitModel[];

  public orgUnitNodes: IOrgUnitNode[];
  public nodes: HierarchyNode[] = [];
  public selectedNodes: HierarchyNode[];

}
