<action-bar>
  <action-button [className]="'action-danger'" [iconClass]="'fa fa-ban'" (actionClick)="onClickCancel()">Cancel</action-button>
</action-bar>

<div class="card">
  <h4 class="card-header">Download Reports</h4>
  <div class="card-body">
    <table class="table table-striped" *ngIf="attachments && attachments.length; else noReports">
      <thead>
      <tr>
        <th>Assessment Type</th>
        <th>Report Type</th>
        <th>Filename</th>
        <th>Date</th>
        <th>Action</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let attachment of attachments">
        <td>{{attachment.referenceName}}</td>
        <td>{{attachment.typeName}}</td>
        <td>{{attachment.filename}}</td>
        <td>{{formatDate(attachment.createdDate)}}</td>
        <td>
          <button type="button" class="btn btn-secondary btn-sm" (click)="downloadAttachment(attachment.attachmentId, assessmentRequestId)" [disabled]="disableDownload"><span class="fa fa-cloud-download"></span> Download</button>
          <div style="display:inline-block" ngbTooltip="{{disableEmailTooltipText(attachment)}}" container="body"><button [disabled]="attachment.isConfidential || clientLevelDisable" type="button" class="btn btn-primary btn-sm" (click)="showEmailReportDialog(attachment)"><span class="fa fa-envelope"></span> E-Mail</button></div>
        </td>
      </tr>
      </tbody>
    </table>
    <ng-template #noReports>
      <i>This assessment has no reports.</i>
    </ng-template>

    <tbody style="margin-left:11px;display:block;" >
      <ng-container *ngFor="let additionalServiceReference of additionalServiceReferences">
        <tr *ngIf="isValidAdditionalService(additionalServiceReference)">
          <td>{{additionalServiceReference.name}}</td>
          <td>
            <span style="margin-left: 20px;" class="text-success" *ngIf="verbalFeedbackComplete(additionalServiceReference)"><i class="fa fa-check"></i>Feed back delivered     {{testingSchedule.feedbackDate ? (testingSchedule.feedbackDate | date: 'MM/dd/yyyy') : (isAdmin?"Date Not Entered":"")}}</span>
          </td>
        </tr>
      </ng-container>
      </tbody>
  </div>
</div>
