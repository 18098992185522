import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {ICompetencyModel} from '../../core/interfaces/competency.interface';
import {ITableSettings} from '../../piquant-table/models/table-settings';
import {LocalDataSource} from '../../piquant-table/data/local-datasource';
import {CompetencySetListItem} from '../../core/models/competency-set.model';
import {Row} from '../../piquant-table/models/row';
import {GridOptions, RowNode, Column, SelectionChangedEvent} from "ag-grid-community";
import {AuthService} from '../../auth/auth.service';
import {ViewButtonRendererComponent} from '../view-button-renderer/view-button-renderer.component';
import {ITableAction} from '../../piquant-table/models';

@Component({
  selector: 'app-list-competency',
  templateUrl: './list-competency.component.html',
  styleUrls: ['./list-competency.component.css'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class ListCompetencyComponent implements OnInit {
  public competencies: ICompetencyModel[];
  public tableSettings: ITableSettings;
  public competencySets: LocalDataSource = new LocalDataSource();
  public inputQuickSearch: string;
  public gridOptions: GridOptions;
  public selectedNode: string;
  public selectedPaginationOption = 2;
  public paginationOptions = [
   // {id: 0, name: "Auto"},
    {id: 0, name: "10"},
    {id: 1, name: "50"},
    {id: 2, name: "100"},
    {id: 3, name: "500"}
  ];

  constructor(private route: ActivatedRoute, private router: Router, private authService: AuthService,) {
    this.gridOptions = <GridOptions>
    {
      columnDefs: this.createColumnDefs(),
      rowHeight: 48, // recommended row height for material design data grids,
      headerHeight: 38,
      animateRows: true,
      pagination: true,
      rowSelection: "single",
      suppressCellFocus: true,
      suppressMenuHide: true,
      getRowClass: (event) => this.rowBackgroundColor(event),
      onSelectionChanged: (event) => this.onSelectionChanged(event), 
      onSortChanged: () => this.onColumnChanged(),
      onColumnResized: () => this.onColumnChanged(),
      onFilterChanged: (event) => this.onFilterChanged(event),
      context: { componentParent: this },   //Make this component (and its members) available to child components.
      defaultColDef: {
        resizable: true,
        sortable: true
      }
    }
  }

  onResize(event) {
    localStorage.setItem('competencyWindowWidth', JSON.stringify(event.target.innerWidth));
    this.gridOptions.api.sizeColumnsToFit();
  }

  public rowBackgroundColor(event) : string{
    if (event.node.rowIndex % 2 === 0) {
      return 'shaded-row';
    }
    else
      return null;
  }

  public setPageSize(paginationOption)
  { 
    //Current version of AG Grid appears to have problems with autopagination (at least if it's turned on at runtime)... disabling.
    // if(paginationOption != 0)
    // {
      this.gridOptions.paginationAutoPageSize = false;
      this.gridOptions.api.paginationSetPageSize(Number( (this.paginationOptions[paginationOption]) ? this.paginationOptions[paginationOption].name : 100));
    // }
    // else
    // {
    //   this.gridOptions.paginationAutoPageSize = true;
    //   //this.gridOptions.api.doLayout();
    //   this.gridOptions.api.redrawRows();
    // }
  }

  public onFilterTextBoxChanged() {
      this.gridOptions.api.setQuickFilter(this.inputQuickSearch);
  }

  public resetFilters()
  {
    //Reset Pagination Option
    this.onChangePaginationOption(2);
    //each column has to have filters removed.
    for (let column of this.gridOptions.columnApi.getAllColumns()) 
    {
      this.gridOptions.api.destroyFilter(column);
    }
    //Now cleanup the quick search -> reset the input element
    this.inputQuickSearch = '';
    //and reset the grid quickfilter
    this.gridOptions.api.setQuickFilter('');  
    //reset the default sort.
    this.gridOptions.columnApi.resetColumnState();
    //finaly raise the events...
    this.gridOptions.api.onSortChanged();
    this.gridOptions.api.onFilterChanged();
    this.gridOptions.api.sizeColumnsToFit();
  }

  public onChangePaginationOption(option)
  {
    this.selectedPaginationOption = option; //event.target.value;
    this.setPageSize(this.selectedPaginationOption);
    localStorage.setItem('gridCompetencyPagination'+this.getImpersonatedUserId(), this.selectedPaginationOption.toString()); 
  }

  public onColumnChanged() {
    localStorage.setItem('gridCompetencyColumnState' + this.getImpersonatedUserId(), JSON.stringify(this.gridOptions.columnApi.getColumnState())); //get/applyColumnState()
  }
  
  public onFilterChanged(event) {
    localStorage.setItem('gridCompetencyFilterModel' + this.getImpersonatedUserId(), JSON.stringify(this.gridOptions.api.getFilterModel())); 
  }

  public onSelectionChanged(event: SelectionChangedEvent)
  {
    
  }

 private createColumnDefs() {
    return [
      // {
      //   headerName: "Id",
      //   field: "competencySetId",
      //   filter: "agTextColumnFilter",
      //   hide: this.isRoleClient
      // },
      {
        headerName: "Name",
        field: "name",
        valueFormatter: "value",
        filter: "agTextColumnFilter",
        menuTabs: ['filterMenuTab'],
        cellRendererFramework: ViewButtonRendererComponent,
        sort: 'asc',
        icons: {
          sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
          sortDescending: '<i class="fa fa-sort-alpha-desc"/>',
          filter: '<i class="fa fa-search"/>',
          menu: '<i class="fa fa-filter"/>'
        }
      },
      {
        headerName: "Active Competencies",
        field: "assignedCompetenciesCount",
        filter: "agNumberColumnFilter",
        menuTabs: ['filterMenuTab'],
        icons: {
          sortAscending: '<i class="fa fa-arrow-up"/>',
          sortDescending: '<i class="fa fa-arrow-down"/>',
          filter: '<i class="fa fa-search"/>',
          menu: '<i class="fa fa-filter"/>'
        }
      },
      {
        headerName: "Clients Assigned To",
        field: "assignedToClientsCount",
        filter: "agNumberColumnFilter",
        menuTabs: ['filterMenuTab'],
        icons: {
          sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
          sortDescending: '<i class="fa fa-sort-alpha-desc"/>',
          filter: '<i class="fa fa-search"/>',
          menu: '<i class="fa fa-filter"/>'
        }
      }
    ];
  }

  public get isRoleClient() {
    return this.authService.isRoleClient;
  }

  private createRowData() {
    //if(this.gridOptions != null)
      //this.gridOptions.columnApi.setColumnVisible("Client", !this.isRoleClient);
    return this.competencySets;
  }
  public ngOnInit() {
    // this.route.data.subscribe((data: any) => {
    //   const competencySets = data.competencySets as CompetencySetListItem[];
      //this.initTable(competencySets);

    //});
  }

  public ngAfterViewInit()
  {
    this.route.data.subscribe((data: any) => {
      this.gridOptions.api.setRowData(data.competencySets as CompetencySetListItem[]);
      this.competencySets.load(data.competencySets as CompetencySetListItem[]);
    });
    this.loadGridPreferences();
  }

  public loadGridPreferences() {
    //Handle loading saved Sort, Filter, and Column settings.
    var columnState = localStorage.getItem('gridCompetencyColumnState' + this.getImpersonatedUserId());  //gridColumnState'+this.getImpersonatedUserId(), JSON.stringify(this.gridOptions.columnApi.getColumnState()
    if (columnState != null) {
      this.gridOptions.columnApi.applyColumnState(JSON.parse(columnState));
      //Prevent poor column saved states when a user's window size changes between sessions.
      if (+localStorage.getItem('competencyWindowWidth') != window.innerWidth) {
        this.gridOptions.api.sizeColumnsToFit();
        localStorage.setItem('competencyWindowWidth', JSON.stringify(window.innerWidth));
      }
    }
    else {
      this.gridOptions.api.sizeColumnsToFit();
      this.resetFilters();
    }
    var filterModel = localStorage.getItem('gridCompetencyFilterModel' + this.getImpersonatedUserId());
    if (filterModel != null) {
      const thisModel = JSON.parse(filterModel);
      this.gridOptions.api.setFilterModel(thisModel);
      
    }
    else
      this.resetFilters();
    //Also attempt to load pagination selection
    var paginationOption = localStorage.getItem('gridCompetencyPagination' + this.getImpersonatedUserId());
    if (paginationOption != null)
      this.onChangePaginationOption(Number(paginationOption));
  }

  public getImpersonatedUserId(): string
  {
    return (this.authService.impersonatedUser != null) ? this.authService.impersonatedUser.userId.toString() : this.authService.userId.toString();
  }

  public gotoNewSet() {
    this.router.navigate(['/competencies/add-competency-set']);
  }

  private buildTableActions(): ITableAction[] {
    return [
      {
        name: 'Edit',
        action: (row: any) => {
          this.router.navigate(['/competencies/set', row.getData().competencySetId]);
        },
        iconClass: 'fa fa-edit',
        actionClass: 'btn btn-link',
        isRowVisible: (row: any) => {
          return true;
        }
      }, {
        name: 'Delete',
        action: (row: any) => {
          //const cSet = this.getCompetencySet(row);
          //Oops - looks like this was disabled before the grid conversion for some reason...
          // this.showDeleteConfirmationDialog(assessmentRequest);
        },
        actionClass: 'text-danger btn btn-link',
        iconClass: 'fa fa-trash-o',
        isRowVisible: (row: any) => {
          return true;
        }
      },
    ];
  }

  private getCompetencySet(row: Row): CompetencySetListItem {
    return row.getData() as CompetencySetListItem;
  }
}
