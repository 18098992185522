import {IEditUserModel} from '../core/interfaces/edit-user.interface';

export class EditUserModel implements IEditUserModel {
  public userId: number;
  public clientId: number;
  public clientName: string;
  public username: string;
  public first: string;
  public middle: string;
  public last: string;
  public email: string;
  public position: string;
  public addressId: number;
  public address1: string;
  public address2: string;
  public city: string;
  public stateId: number;
  public province: string;
  public countryId: number;
  public roleId: number;
  public zipCode: string;
  public phoneWorkCountryCode: number;
  public phoneWork: string;
  public phoneMobileCountryCode: number;
  public phoneMobile: string;
  public mfaType: number;
  public createdDate: string;
  public createdBy: string;
  public isActive: boolean;
  public isLocked: boolean;
  public lastLoginDate: string;
  public userLevelId: number;
  public orgUnitIds: number[] = [];

  constructor(obj?: IEditUserModel) {
    if (!obj) {
      return;
    }

    if (obj.userId) {
      this.userId = obj.userId;
    }

    this.username = obj.username;
    this.first = obj.first;
    this.middle = obj.middle;
    this.last = obj.last;
    this.email = obj.email;
    this.position = obj.position;
    this.addressId = obj.addressId;
    this.address1 = obj.address1;
    this.address2 = obj.address2;
    this.city = obj.city;
    this.stateId = obj.stateId;
    this.province = obj.province;
    this.countryId = obj.countryId;
    this.zipCode = obj.zipCode;
    this.roleId = obj.roleId;
    this.phoneWorkCountryCode = obj.phoneWorkCountryCode;
    this.phoneWork = obj.phoneWork;
    this.phoneMobileCountryCode = obj.phoneMobileCountryCode;
    this.phoneMobile = obj.phoneMobile;
    this.mfaType = obj.mfaType;
    this.isActive = obj.isActive;
    this.isLocked = obj.isLocked;
    this.createdDate = obj.createdDate;
    this.createdBy = obj.createdBy;
    this.lastLoginDate = obj.lastLoginDate;
    this.userLevelId = obj.userLevelId;
    this.orgUnitIds = obj.orgUnitIds;
  }
}
