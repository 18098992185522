import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {IAdditionalServiceReferenceModel} from '../../../core/interfaces/additional-service-reference.interface';
import {Injectable} from '@angular/core';
import {IAssessmentLevelReferenceModel} from '../../../core/interfaces/assessment-level-reference.interface';
import {SectionBase} from './base.section';
import {EditAssessmentRequestModel} from '../../edit-assessment-request.model';

@Injectable()
export class ServicesRequestedSection extends SectionBase {
  private _assessmentLevelReferences: IAssessmentLevelReferenceModel[];
  private _additionalServiceReferences: IAdditionalServiceReferenceModel[];

  constructor(private fb: UntypedFormBuilder) {
    super();
  }

  public get assessmentLevelReferenceControl() {
    return this.formGroup.controls['assessmentLevelReference'] as UntypedFormControl;
  }

  public get additionalServiceReferencesArray() {
    return this.formGroup.controls['additionalServiceReferences'] as UntypedFormArray;
  }

  public get notesControl() {
    return this.formGroup.controls['notes'] as UntypedFormControl;
  }

  public init({
                assessmentRequest,
                assessmentLevelReferences,
                additionalServiceReferences
              }: {
    assessmentRequest: EditAssessmentRequestModel,
    assessmentLevelReferences: IAssessmentLevelReferenceModel[],
    additionalServiceReferences: IAdditionalServiceReferenceModel[]
  }) {
    super.setAssessmentRequest(assessmentRequest);
    this.formGroup = this.buildFormGroup();

    if (!assessmentLevelReferences) {
      throw new Error('assessmentLevelReferences must be specified');
    }

    this._assessmentLevelReferences = assessmentLevelReferences;

    if (!additionalServiceReferences) {
      throw new Error('additionalServiceReferences must be specified');
    }

    this._additionalServiceReferences = additionalServiceReferences;

    this.assessmentLevelReferenceControl.setValue(this.assessmentRequest.assessmentLevelReferenceId);

    this._additionalServiceReferences.forEach((r) => {
      const hasService = this.assessmentRequest.additionalServiceReferenceIds.indexOf(r.additionalServiceReferenceId) > -1;
      this.additionalServiceReferencesArray.push(new UntypedFormControl(hasService));
    });

    

    this.notesControl.setValue(this.assessmentRequest.serviceNotes);
  }


  public get formValue(): any {
    const values = this.formGroup.value;
    const additionalServiceReferenceIds = [];

    if (this._additionalServiceReferences) {
      for (let i = 0; i < this._additionalServiceReferences.length; i++) {
        const ref = this._additionalServiceReferences[i];
        const checked = values.additionalServiceReferences[i];
        if (checked) {
          additionalServiceReferenceIds.push(ref.additionalServiceReferenceId);
        }
      }
    }
    
    return {
      assessmentLevelReferenceId: values.assessmentLevelReference,
      additionalServiceReferenceIds: additionalServiceReferenceIds,
      serviceNotes: values.notes
    };
  }

  public trackByAssessmentLevelReferenceId(index, assessmentLevelReference: IAssessmentLevelReferenceModel) {
    return assessmentLevelReference.assessmentLevelReferenceId;
  }

  public setCopy() {
    this.formGroup.controls['assessmentLevelReference'].enable();
  }

  protected buildFormGroup(): UntypedFormGroup {
    var disableAssessmentLevel = false;
    if(this.assessmentRequest.additionalServiceReferenceIds.length > 0){
      this.assessmentRequest.additionalServiceReferenceIds.forEach((r) => {
        if(r == 4){
          disableAssessmentLevel = false;
        }else{
          disableAssessmentLevel = this.assessmentRequest.anyAssessmentLevelReports || this.assessmentRequest.additionalService == 1 || this.assessmentRequest.hasParent; 
        }
      });
    } else {
      disableAssessmentLevel = this.assessmentRequest.anyAssessmentLevelReports || this.assessmentRequest.additionalService == 1 || this.assessmentRequest.hasParent; 
    }

    return this.fb.group({
      assessmentLevelReference: [{value: null, disabled: (disableAssessmentLevel)}, Validators.required],
      additionalServiceReferences: this.fb.array([]),
      notes: [null, Validators.maxLength(8000)]
    });
  }
}
