<!-- <ps-table [settings]="psTableSettings" [dataSource]="emails"></ps-table> -->
<div class="row">
    <div class="col table-title">
      <h1>Api History</h1>
    </div>
    <div class="col">
      <div class="pull-right">
        Quick Search:
        <input type="text" [(ngModel)]="inputQuickSearch" id="filter-text-box" placeholder="Type here..." (keyup)="onFilterTextBoxChanged()"/>
        <button (click)="resetFilters()">Reset Filters</button>
      </div>
    </div>
  </div>
  
  <div class="row" id="GridWithH1">
    <div class="col" style="Width: 100%;Height:100%;" >
      <ag-grid-angular style="Width: 100%;Height:100%;" class="ag-theme-material" [gridOptions]="gridOptions"></ag-grid-angular>
    </div>
  </div>
  <div class="gridFooter" >
    <span>Rows per page:</span>
    <select style="display:inline;" [ngModel]="selectedPaginationOption" (change)="onChangePaginationOption($event.target.value)"> <!--[(ngModel)]="selectedPaginationOption"-->
      <option *ngFor="let option of paginationOptions" [value]="option.id">{{option.name}}</option>
    </select>
  </div>
