<div class="modal-header">
  <h4 class="modal-title">Two Factor Authentication Set-Up – Choosing your preference</h4>
</div>
<div class="modal-body">
  <div>
    <p>Two Step Verification is additional security for logging into your account. Please select your preferred
      way for us to contact you when you log into a new device or browser. (We recommend SMS/Text
      Message).
    </p>

    <label class="heading">Authenticate Using:</label>

    <label class="form-check-label">
      <input [(ngModel)]="userData.mfaType" type="radio" [value]="1" name="mfaType">
      E-Mail
    </label>
    <label class="form-check-label">
      <input [(ngModel)]="userData.mfaType" type="radio" [value]="2" name="mfaType">
      SMS Text Message
    </label>
    <div>
      <label for="MobileNumbers" class=" heading">Enter Mobile Number</label>
      &nbsp;
      <div id="MobileNumbers" style="display:flex">
        <div class="field">
            <label for="CountryCode" class="">Country Code:</label>
            <input style="width:29px" [(ngModel)]="userData.phoneMobileCountryCode" type="text" id="CountryCode" [specialCharacters]="[]"  mask="999990" />
          </div>
        <div *ngIf="userData.phoneMobileCountryCode == 1" class="field">
          <label for="PhoneNumber" class="">Phone Number:</label>
          <input [(ngModel)]="userData.phoneMobile" type="text" id="PhoneNumber" mask="(000) 000-0000"/>
        </div>
        <div *ngIf="userData.phoneMobileCountryCode != 1" class="field">
          <label for="PhoneNumber" class="">Phone Number (Please Exclude Leading Zero):</label>
          <input [(ngModel)]="userData.phoneMobile" type="text" id="PhoneNumber" [specialCharacters]="[]" mask="999999999999"/>
        </div>
      </div>
      <p>Please enter a country code in the first field (United States is “1”) and then your mobile phone number with area code.</p>
  </div>
  </div>
</div>
<div class="modal-footer">
    <button [disabled]="userData.mfaType == 2 && ((userData.phoneMobile == '' || !userData.phoneMobile) || (userData.phoneMobileCountryCode == 0 || !userData.phoneMobileCountryCode))" style="margin-left:20px" type="button" class="btn btn-primary" (click)="onDone()"><span class="fa fa-check"></span>Save</button>
</div>
