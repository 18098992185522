<div class="modal-header">
  <h4 class="modal-title">Duplicate Candidate Detected</h4>
  <span class="close-icon" aria-label="Close" (click)="onCancel()" style="cursor: pointer;">
    &times;
  </span>
</div>
<div class="modal-body">
  <p>Candidate(s) were found with the same name and email.</p>
  <div class="container">
    <div class="row">
      <div class="col-md-4 font-weight-bold">Requester Information:</div>
      <div class="col-md-4 font-weight-bold">Candidate Information:</div>
    </div>
    <div class="row" *ngFor="let candidate of duplicateCandidates">
      <div class="col-md-4">
        <p>
          {{candidate.requesterName}}<br/>
          {{candidate.requesterEmail}}
        </p>
      </div>
      <div class="col-md-4">
        <p>
          {{candidate.candidateDepartment}} - {{candidate.candidateName}}<br/>
          {{candidate.candidateEmail}}
        </p>
      </div>
    </div>
  </div>
  <br/>
  <p>Would you like to continue?</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="onYes()"><span class="fa fa-ban"></span> Continue</button>
  <button type="button" class="btn btn-danger" (click)="onNo()"><span class="fa fa-trash"></span> Exit</button>
</div>
