import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IPositionLevelModel} from '../../core/interfaces/position-level.interface';
import {NgbActiveModal, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {IPositionListItemModel} from '../../core/interfaces/position-list-item.interface';
import {PositionService} from '../../core/position.service';
import {AttachmentService} from '../../core/attachment.service';
import {ICompetencyModel} from '../../core/interfaces/competency.interface';
import {Observable} from 'rxjs';

import {UploadErrorDialogComponent} from '../../shared/upload-error-dialog/upload-error-dialog.component';

@Component({
  selector: 'app-add-job-description-modal',
  templateUrl: './add-job-description-modal.component.html',
  styleUrls: ['./add-job-description-modal.component.scss']
})
export class AddJobDescriptionModalComponent implements OnInit {
  @Input() public position: IPositionListItemModel;
  @Output() public onDescriptionAdded = new EventEmitter<any>();
  private _modal: NgbModalRef;

  constructor(public activeModal: NgbActiveModal, private attachmentService: AttachmentService, private modalService: NgbModal, private positionService: PositionService) {  }

  public ngOnInit() {

  }

  public onUploadJobDescriptionFileChange(event) {
    const fileList: FileList = event.target.files;

    if (fileList.length) {
      const file: File = fileList[0];

      this.attachmentService.upload(file)
        .subscribe((attachmentId) => {
          this.position.jobDescriptionAttachmentId = attachmentId;
          this.positionService.get(this.position.positionId).subscribe((position) => {
            position.jobDescriptionAttachmentId = attachmentId;
            this.positionService.save(position).subscribe(() => {
              this.onDescriptionAdded.emit({positionId: this.position.positionId, attachmentId: attachmentId, fileName: file.name});
            });
          });
          event.target.value = '';
          this.activeModal.dismiss();
        },
          error => {
            this._modal = this.modalService.open(UploadErrorDialogComponent);
            const instance = this._modal.componentInstance as UploadErrorDialogComponent;
            instance.title = 'Error Uploading File';
            instance.body = `The file you are uploading exceeds the maximum file size.<br/><br/>Please contact us at <a href='tel:314-993-8008'>314-993-8008</a> or <a href='mailto:testing@leadershipall.com'>testing@leadershipall.com</a>.`;

            instance.onConfirm.subscribe((isConfirmed) => {
              if (isConfirmed) {
                event.target.value = '';
              }
            });
      });
    }
  }

  public onCancel() {
    this.activeModal.dismiss();
  }
}
