import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AuthService} from '../auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'forgot-password',
  templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent implements OnInit {
  public form: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder,
              private _authService: AuthService,
              private _router: Router) { }

  public ngOnInit() {
    this.buildForm();
  }

  public get usernameControl(): UntypedFormControl {
    return this.form.controls['username'] as UntypedFormControl;
  }

  public submit() {
    this._authService.requestForgotPasswordToken(this.usernameControl.value)
      .subscribe(() => {
          this._router.navigate(['forgot-password/sent']);
        },
        (error) => {
          console.error(error);
        });
  }

  private buildForm() {
    this.form = this._fb.group({
      username: this._fb.control('', Validators.required)
    })
  }

}
