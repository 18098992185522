
import {throwError as observableThrowError, Observable} from 'rxjs';

import {tap, catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {AuthConfig} from './auth-config';
import {Router} from '@angular/router';
import {LoadingSpinnerService} from '../shared/loading-spinner/loading-spinner.service';
import {AuthService} from './auth.service';
import { Location } from '@angular/common';

import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent} from '@angular/common/http';

@Injectable()
export class AuthHttpService implements HttpInterceptor {
  constructor(//backend: XHRBackend,
              //defaultOptions: RequestOptions,
              private router: Router,
              private loadingBarService: LoadingSpinnerService,
              private authService: AuthService,
              private location: Location) {
    //super();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler):   Observable<HttpEvent<any>> {
    this.loadingBarService.start();
    var authReq = req;
    if (this.authService.loggedInUser) {
      let newHeaders = req.headers;
      newHeaders = newHeaders.set('Authorization', `Bearer ${this.authService.accessToken}`);
      if (this.authService.loggedInUser && this.authService.loggedInUser.isRoleAdmin) {
        const impersonatedUser = this.authService.impersonatedUser;
        if (impersonatedUser) {
          newHeaders = newHeaders.append('X-LA-Impersonated-User-ID', impersonatedUser.userId.toString());
        }
      }
      authReq = req.clone({headers: newHeaders});
    }

    return next.handle(authReq).pipe(
      tap(() => this.loadingBarService.complete()))
      .pipe(
        map(res => {return res as HttpResponse<any>}),
        catchError((res: Response) => {
          this.loadingBarService.complete();
  
          // if unauthorized, bounce the user back to the login screen
          if (res.status === 401) {
            this.authService.clearState();
            const splitPath = this.location.path().split('returnUrl=');
            const urlResult = decodeURIComponent(splitPath[splitPath.length - 1]);
            this.router.navigate([AuthConfig.LoginRouteUrl], { queryParams: {returnUrl: urlResult}});
          }
  
          return observableThrowError(res);
        })
    );
  }
 
}
