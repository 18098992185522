<h1 class="logo hidden-md-up">
  <a [routerLink]="['/']"><img src="../assets/la-logo.png" alt="Leadership Alliance"/></a>
  <span class="sr-only">Leadership Alliance</span>
</h1>
<div class="global-nav" ngbDropdown [autoClose]="true" #globalnav="ngbDropdown" *ngIf="isLoggedIn">
  <a *ngIf="isImpersonating" class="text-muted icon-button" (click)="stopImpersonating()" title="Stop Impersonating">
    <i class="fa fa-eye-slash"></i>
  </a>
  <a class="dd-toggle" (click)="$event.stopPropagation(); globalnav.toggle();" [title]="titleInfo">
    <i class="global-nav-icon fa fa-user-circle-o"></i>
    <div class="welcome hidden-sm-down"><strong>Welcome:</strong>&nbsp;{{displayName}}&nbsp;({{roleName}})</div>
    <div class="impersonate hidden-sm-down" *ngIf="isImpersonating">
      Viewing As: {{impersonatedUserName}}
    </div>
  </a>
  <ul class="dropdown-menu" ngbDropdownMenu >
    <li class="hidden-md-up">
      <a>
        <strong>Welcome:</strong>&nbsp;{{userName}}&nbsp;({{roleName}})
      </a>
    </li>
    <li class="hidden-sm-down">
      <a [routerLink]="['/self-service/edit-profile']"><i class="fa fa-pencil-square-o fa-fw"></i>&nbsp;Edit Profile</a>
    </li>
    <li class="hidden-sm-down">
      <a [routerLink]="['/self-service/change-password']"><i class="fa fa-key fa-fw"></i>&nbsp;Change Password</a>
    </li>
    <li class="hidden-sm-down" *ngIf="isAdmin==false">
      <a [routerLink]="['/help']"><i class="fa fa-question-circle-o fa-fw"></i>&nbsp;Help</a>
    </li>
    <li>
      <a class="text-danger" (click)="signOut()"><i class="fa fa-sign-out fa-fw"></i> Sign Out</a>
    </li>
  </ul>
</div>
