
import {defer as observableDefer, Observable} from 'rxjs';
import {map, publishReplay, refCount, take} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ServiceBase} from './service-base';
import {AuthHttpService} from '../auth/auth-http.service';
import {IAdditionalServiceReferenceModel} from './interfaces/additional-service-reference.interface';
import { HttpClient} from '@angular/common/http';


import {ISelectItemModel} from './interfaces/select-item.interface';

@Injectable()
export class AdditionalServiceReferenceService extends ServiceBase {
  private static ApiUrl = 'api/additional-service-reference';
  private data$: Observable<IAdditionalServiceReferenceModel[]>;

  constructor(private http: HttpClient) {
    super();
  }

  public getList(): Observable<IAdditionalServiceReferenceModel[]> {
    if (!this.data$) {
      this.data$ = observableDefer(() => this.http.get(AdditionalServiceReferenceService.ApiUrl)).pipe(
        map((res: Response) => (res || []) as IAdditionalServiceReferenceModel[]),
        publishReplay(1),
        refCount(),
        take(1)
      );
    }

    return this.data$;
  }

  public get(id: number): Observable<IAdditionalServiceReferenceModel> {
    const url = `${AdditionalServiceReferenceService.ApiUrl}/${id}`;
    return this.http.get<IAdditionalServiceReferenceModel>(url);
  }

  public getTypes(): Observable<ISelectItemModel[]> {
    const url = `${AdditionalServiceReferenceService.ApiUrl}/list`;
    return this.http.get<ISelectItemModel[]>(url);
  }
}
