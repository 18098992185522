import { Component, ViewChild, ViewContainerRef} from '@angular/core';
import {IFilterAngularComp} from "ag-grid-angular";
import {EmailStatus} from '../../core/enums/email-status.enum';
import {IAfterGuiAttachedParams, IDoesFilterPassParams, IFilterParams, RowNode} from "ag-grid-community";

@Component({
  selector: 'app-filter-grid-status',
  templateUrl: './filter-grid-status.component.html',
  styleUrls: ['./filter-grid-status.component.scss']
})

export class FilterApiStatusComponent implements IFilterAngularComp  {

  constructor() { }

  private params: IFilterParams;
  private valueGetter: (rowNode: RowNode) => any;
  public text: string = '';
  public unknown: boolean;// = false;
  public queued: boolean;// = false;
  public sent: boolean;// = false;
  public resent: boolean;// = false;
  public failed: boolean;// = false;
  public showAll: boolean;// = true;
  
  //@ViewChild('input', {read: ViewContainerRef}) public input;

  agInit(params: IFilterParams): void {
      this.params = params;
      //this.valueGetter = params.valueGetter;
  }

  isFilterActive(): boolean {
    return true;
  }

  //returns bool to indicate whether the row will appear in result list.
  doesFilterPass(params: IDoesFilterPassParams): boolean {
      switch (params.node.data.status) {
        case EmailStatus.Unknown:
          return this.unknown || this.showAll;
        case EmailStatus.Queued: 
          return this.queued || this.showAll;
        case EmailStatus.Sent:
          return this.sent || this.showAll;
        case EmailStatus.Resent:
          return this.resent || this.showAll;
        case EmailStatus.Failed:
          return this.failed || this.showAll;
        default:
          return true;
      }
  }

  getModel(): any {
      return {unknown: this.unknown, queued: this.queued, sent: this.sent, resent: this.resent, failed: this.failed, showAll: this.showAll};
  }

  setModel(model: any): void {
    //Not much effort to ensure this is a 'valid' state coming in but... it can only go so far wrong.
    if(model != null) {
      this.unknown = model.unknown || false;
      this.queued = model.queued || false;
      this.sent = model.sent || false;
      this.resent = model.resent || false;
      this.failed = model.failed || false;
      this.showAll = model.showAll || false;
    }
    else
      this.showAll = true;  
  }

  public getModelAsString(model: any): any {
    return "test";
  }

  onChange(event): void {
    
    //If the option was enabled
    if(event.target.checked) {
      //and it was either deleted or showAll
      if(event.target.id != "showAll")
        //turn showAll off
        this.showAll = false;
      //If we turned showAll on, we need to uncheck the others (except deleted?)  
      if(event.target.id == "showAll") {
        this.unknown = false;
        this.queued = false;
        this.sent = false;
        this.resent = false;
        this.failed = false;
      }
    }
    else      
      if(!this.unknown && !this.queued && !this.sent && !this.resent && !this.failed)
      {
        this.showAll = true; //If all other filters are false, we should probably turn showall back on (unlikely the user actually wants to see nothing.)
        if(event.target.id == "showAll") //Also, If this is coming in on the showall checkbox, we need to set the target too!
        event.target.checked = true; 
      }
    this.params.filterChangedCallback();
  }
}
