import {NgModule} from '@angular/core';
import {EditBillingContactDialogComponent} from './edit-billing-contact-dialog/edit-billing-contact-dialog.component';
import {SharedModule} from '../shared/shared.module';

@NgModule({
    imports: [SharedModule],
    exports: [EditBillingContactDialogComponent],
    declarations: [EditBillingContactDialogComponent]
})
export class BillingContactsModule {
}
