import {Component, Input} from '@angular/core';
import {IFeedbackRecipientsListItem} from '../view-assessment-request.interface';

@Component({
  selector: 'view-feedback-recipient',
  template: `
    <div class="col">{{recipient.name}}</div>
    <div class="col">Email: {{recipient.email}}</div>
    <div class="col">Phone: {{recipient.phoneWork}}</div>
    <div class="col">Verbal: {{recipient.verbal | bool:"YN"}}</div>
    <div class="col">Written: {{recipient.written | bool:"YN"}}</div>`
})
export class ViewFeedbackRecipientComponent {
  @Input() recipient: IFeedbackRecipientsListItem;
}
