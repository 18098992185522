import { Component, OnInit } from '@angular/core';
import {AgRendererComponent} from 'ag-grid-angular';

@Component({
  selector: 'description-renderer',
  template: '<div [ngbTooltip]="params?.data?.responseString" container="body"><span>{{params?.data?.responseString}}</span></div>'
})
export class DescriptionRendererComponent implements AgRendererComponent {

  constructor() { }
  public params:any;
  
  agInit(params:any):void {
      this.params = params;
  }
  public refresh(params:any):boolean
  {
    return true;
  }

}
