import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IClientCompetenciesModel} from '../../core/interfaces/client-competencies.interface';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ICompetencyModel} from '../../core/interfaces/competency.interface';

@Component({
  selector: 'edit-competencies',
  templateUrl: './edit-competencies.component.html',
  styleUrls: ['edit-competencies.component.scss']
})
export class EditCompetenciesComponent implements OnInit {
  @Input() public clientCompetencies: ICompetencyModel[];
  @Input() public competencies: ICompetencyModel[];
  @Output() public onUpdateCompetencies: EventEmitter<ICompetencyModel[]> = new EventEmitter<ICompetencyModel[]>();

  constructor(public activeModal: NgbActiveModal) { }

  public ngOnInit(): void {
    if (!this.clientCompetencies) {
      throw new Error('clientCompetencies is null');
    }

    if (!this.competencies) {
      throw new Error('competencies is null');
    }
  }

  public onSelectedCompetenciesChanged(selectedCompetencies: ICompetencyModel[]) {
    this.competencies = selectedCompetencies;
  }

  public onClose() {
    this.onUpdateCompetencies.emit(this.competencies);
    this.activeModal.close();
  }
}
