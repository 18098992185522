<div class="modal-header">
  <h4 class="modal-title">Assessment Request Status Change</h4>
  <span class="close-icon" aria-label="Close" (click)="onCancel()" style="cursor: pointer;">
    &times;
  </span>
</div>
<div class="modal-body">
  <p>Are you sure you want to mark this assessment request as In-Process for candidate {{candidateName}}?</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="onConfirm()"><span class="fa fa-undo"></span> Change</button>
  <button type="button" class="btn btn-cancel" (click)="onCancel()"><span class="fa fa-ban"></span> Cancel</button>
</div>
