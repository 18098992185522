import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CompetencySelectListItem} from '../../core/interfaces/competency.interface';

@Component({
  selector: 'confirm-edit-dialog',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">Warning Editing Competency</h4>
      <span class="close-icon" aria-label="Close" (click)="activeModal.close('Cross click')" style="cursor: pointer;">
        &times;
      </span>
    </div>
    <div class="modal-body">
      <p>When editing a competency name be careful that you do not change the meaning of the competency.</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-default" (click)="activeModal.close('Okay click')">Okay</button>
    </div>`
})
export class ConfirmEditDialogComponent {

  constructor(public activeModal: NgbActiveModal) {

  }
}
