import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {IAdditionalServiceAttachmentModel, IAssessmentLevelAttachmentModel} from '../../core/interfaces/attachment-base.interface';
import {ISelectItemModel} from '../../core/interfaces/select-item.interface';

export interface IReportFileModel {
  reportTypeId: number;
  description: string;
  file: File;
}

@Component({
  selector: 'app-upload-report-dialog',
  templateUrl: './upload-report-dialog.component.html',
  styleUrls: ['./upload-report-dialog.component.scss']
})
export class UploadReportDialogComponent implements OnInit {
  @Input() public report: IAssessmentLevelAttachmentModel | IAdditionalServiceAttachmentModel;
  @Input() public reportTypes: ISelectItemModel[];
  @Output() public onUpload = new EventEmitter<IReportFileModel>();

  public reportType: ISelectItemModel;
  public description: string;

  constructor(public activeModal: NgbActiveModal) {
  }

  public ngOnInit() {
    if (!this.report) {
      throw new Error('report input parameter must be specified');
    }
  }

  public onClickCancel() {
    this.activeModal.dismiss();
  }

  public onClickUpload(event) {
    const fileList: FileList = event.target.files;

    if (fileList.length) {
      const reportFile = {
        reportTypeId: this.reportType.id,
        description: this.description,
        file: fileList[0]
      } as IReportFileModel;

      this.onUpload.emit(reportFile);
      this.activeModal.close();
    }
  }

  public get title(): string {
    return this.report.referenceName;
  }

  private get isAssessmentLevelReport(): boolean {
    const assessmentLevel = this.report as IAssessmentLevelAttachmentModel;
    return !!assessmentLevel.assessmentLevelId;
  }

  private get isAdditionalServiceReport(): boolean {
    const additionalService = this.report as IAdditionalServiceAttachmentModel;
    return !!additionalService.additionalServiceId;
  }
}
