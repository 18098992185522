import { Component, OnInit } from '@angular/core';
import {AgRendererComponent} from 'ag-grid-angular';

@Component({
  selector: 'app-view-button-renderer',
  template: '<a [routerLink]="[\'view\', params?.data?.positionId]"><strong><i *ngIf="showConfidentialLock" class="fa fa-lock" ngbTooltip="The position has been marked confidential, and cannot be viewed by any other User" container="body"></i>  {{params?.data?.description}}</strong></a>'
})
export class ViewButtonRendererComponent implements AgRendererComponent {

  constructor() { }

  public params:any;
  public showConfidentialLock: boolean = false; 
  
  agInit(params:any):void {
      this.params = params;
      this.showConfidentialLock = params.data.isConfidential;
  }
  public refresh(params:any):boolean
  {
    return true;
  }
}
