import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {IEditPositionModel} from '../../core/interfaces/edit-position.interface';

@Component({
  selector: 'app-view-position',
  templateUrl: './view-position.component.html',
  styleUrls: ['./view-position.component.css']
})
export class ViewPositionComponent implements OnInit {
  public position: IEditPositionModel;

  constructor(private route: ActivatedRoute, public router: Router) {

  }

  public onClose() {
    this.router.navigate(['/positions']);
  }

  public gotoEdit() {
    this.router.navigate(['/positions/edit', this.position.positionId]);
  }

  public ngOnInit() {
    this.route.data.subscribe((data: any) => {
      this.position = data.position as IEditPositionModel;
    });
  }
}
