import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';

@Component({
  selector: 'additional-service-duplicate-dialog',
  templateUrl: './additional-service-duplicate-dialog.component.html',
  styleUrls: ['./additional-service-duplicate-dialog.component.scss']
})
export class AdditionServiceDuplicateDialogComponent implements OnInit {
  @Input() public disableVerbal: boolean;
  @Input() public disableWritten: boolean;
  public description: string;

  constructor(public activeModal: NgbActiveModal,
    private router: Router            
    ) {
  }

  public ngOnInit(): void {
    if(this.disableWritten){
      this.description = 'The original request already included a Written Development Feedback. Please contact Client Services at 314-993-8008 or <a href="mailto:testing@leadershipall.com">testing@leadershipall.com</a> with questions.';
    }
    if(this.disableVerbal){
      this.description = 'The original request already included a Verbal Development Feedback. Please contact Client Services at 314-993-8008 or <a href="mailto:testing@leadershipall.com">testing@leadershipall.com</a> with questions.';
    }
    if(this.disableVerbal && this.disableVerbal){
      this.description = 'The original request already included both a Verbal Development Feedback and Written Development Feedback. Please contact Client Services at 314-993-8008 or <a href="mailto:testing@leadershipall.com">testing@leadershipall.com</a> with questions.';
    }
  }

  public goBack() {
    this.router.navigate(['/assessment-requests'])
    .then(() => this.activeModal.close());
  }

  public onDismiss() {
    if(this.disableVerbal && this.disableWritten){
      this.router.navigate(['/assessment-requests'])
      .then(() => this.activeModal.close());
    } else {
      this.activeModal.dismiss();
    }
  }
}
