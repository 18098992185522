import {Injectable} from '@angular/core';
import {IOutOfOfficeModel} from '../core/interfaces/out-of-office.interface';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {OutOfOfficeService} from '../core/out-of-office.service';
import {Observable} from 'rxjs';

@Injectable()
export class OutOfOfficeResolver implements Resolve<IOutOfOfficeModel[]> {
  constructor(private outOfOfficeService: OutOfOfficeService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<IOutOfOfficeModel[]> {
    return this.outOfOfficeService.getData();
  }
}
