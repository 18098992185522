import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'delete-org-unit-confirm',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">Delete Org Unit?</h4>
        <span class="close-icon" aria-label="Close" (click)="activeModal.dismiss('Cross click')" style="cursor: pointer;">
          &times;
        </span>
    </div>
    <div class="modal-body">
      <p>Are you sure want to delete the org unit: {{name}}?</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="activeModal.close('Yes click')">Yes</button>
      <button type="button" class="btn btn-default" (click)="activeModal.dismiss('No click')">No</button>
    </div>`
})
export class DeleteOrgUnitConfirmComponent {
  @Input() name: string;

  constructor(public activeModal: NgbActiveModal) {
  }
}
