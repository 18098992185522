
import {mergeMap} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {IAssessmentLevelReferenceModel} from '../../core/interfaces/assessment-level-reference.interface';
import {IAdditionalServiceReferenceModel} from '../../core/interfaces/additional-service-reference.interface';
import {IAdditionalServiceAttachmentModel, IAssessmentLevelAttachmentModel, IAttachmentBaseModel} from '../../core/interfaces/attachment-base.interface';
import {AssessmentRequestService} from '../../core/assessment-request.service';
import {AssessmentRequestStatus} from '../../core/enums/assessment-request-status.enum';
import {IAssessmentTestingScheduleModel} from '../../core/interfaces/assessment-testing-schedule.interface';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationDialogComponent} from '../../shared/confirmation-dialog/confirmation-dialog.component';
import {IReportFileModel, UploadReportDialogComponent} from '../upload-report-dialog/upload-report-dialog.component';
import {ISelectItemModel} from '../../core/interfaces/select-item.interface';
import {Location} from '@angular/common';
import {AppService} from '../../core/app.service';
import {AdditionalServiceReferenceTypes} from '../../core/enums/additional-service-types.enum';
import {EditAssessmentRequestModel} from '../edit-assessment-request.model';
import {UploadErrorDialogComponent} from '../../shared/upload-error-dialog/upload-error-dialog.component';
import {AttachmentService} from '../../core/attachment.service';

@Component({
  selector: 'app-upload-reports',
  templateUrl: './upload-reports.component.html',
  styleUrls: ['./upload-reports.component.scss']
})
export class UploadReportsComponent implements OnInit {
  public assessmentLevelReferences: IAssessmentLevelReferenceModel[];
  public additionalServiceReferences: IAdditionalServiceReferenceModel[];
  public assessmentRequest: EditAssessmentRequestModel;
  private _assessmentLevelAttachments: IAssessmentLevelAttachmentModel[];
  private _additionalServiceAttachments: IAdditionalServiceAttachmentModel[];
  private _assessmentRequestId: number;

  private _assessmentLevelReportTypes: ISelectItemModel[];
  private _additionalServiceReportTypes: ISelectItemModel[];
  private _modal: NgbModalRef;
  public testingSchedule: IAssessmentTestingScheduleModel;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private modalService: NgbModal,
              private assessmentRequestService: AssessmentRequestService,
              private location: Location,
              private appService: AppService,
              private AttachmentService: AttachmentService) {
  }

  public ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      const id = params['id'];
      this._assessmentRequestId = +id;
    });

    this.activatedRoute.data.subscribe((data: any) => {
      this.assessmentRequest = data.assessmentRequest;
      this.assessmentLevelReferences = data.assessmentLevelReferences || [];
      this.additionalServiceReferences = data.additionalServiceReferences || [];
      this._assessmentLevelAttachments = data.assessmentLevelAttachments || [];
      this._additionalServiceAttachments = data.additionalServiceAttachments || [];
      this._assessmentLevelReportTypes = data.assessmentLevelReportTypes || [];
      this._additionalServiceReportTypes = data.additionalServiceReportTypes || [];
      this.testingSchedule = data.testingSchedule;
    });

    this.appService.onLoggingOut.subscribe(() => {
      if (!this._modal) {
        return;
      }
      this._modal.dismiss('logging out');
    });
  }

  public onClickCancel() {
    this.router.navigate(['assessment-requests']);
  }

  public onClickUploadAssessmentLevel(assessmentLevelRef: IAssessmentLevelReferenceModel, event) {
    const assessmentLevel = this._assessmentLevelAttachments.find((ala) => ala.assessmentLevelReferenceId === assessmentLevelRef.assessmentLevelReferenceId);

    if (!assessmentLevel) {
      throw new Error(`Can't find assessment level for AssessmentLevelReferenceId ${assessmentLevelRef.assessmentLevelReferenceId}`);
    }

    this._modal = this.modalService.open(UploadReportDialogComponent);

    const instance = this._modal.componentInstance as UploadReportDialogComponent;
    instance.report = assessmentLevel;
    instance.reportTypes = this._assessmentLevelReportTypes;

    instance.onUpload.subscribe((reportFile: IReportFileModel) => {
      this.assessmentRequestService.saveAssessmentLevelAttachment(this._assessmentRequestId, assessmentLevel.assessmentLevelId, reportFile.reportTypeId, reportFile.description, reportFile.file).pipe(
        mergeMap(() => this.assessmentRequestService.getAssessmentLevelAttachments(this._assessmentRequestId)))
        .subscribe(
          (ala) => this._assessmentLevelAttachments = ala,
          error => {
            this._modal = this.modalService.open(UploadErrorDialogComponent);
            const errorDialogInstance = this._modal.componentInstance as UploadErrorDialogComponent;
            errorDialogInstance.title = 'Error Uploading File';
            errorDialogInstance.body = `The file you are uploading exceeds the maximum file size.`;

            errorDialogInstance.onConfirm.subscribe((isConfirmed) => {
              if (isConfirmed) {
                event.target.value = '';
              }
            });
        });
    });
  }

  public onClickUploadAdditionalService(additionalServiceRef: IAdditionalServiceReferenceModel, event) {
    const additionalService = this._additionalServiceAttachments.find((asa) => asa.additionalServiceReferenceId === additionalServiceRef.additionalServiceReferenceId);

    if (!additionalService) {
      throw new Error(`Can't find additional service for AdditionalServiceReferenceId ${additionalServiceRef.additionalServiceReferenceId}`);
    }

    this._modal = this.modalService.open(UploadReportDialogComponent);
    const instance = this._modal.componentInstance as UploadReportDialogComponent;
    instance.report = additionalService;
    instance.reportTypes = this._additionalServiceReportTypes;

    instance.onUpload.subscribe((reportFile: IReportFileModel) => {
      this.assessmentRequestService.saveAdditionalServiceAttachment(this._assessmentRequestId, additionalService.additionalServiceId, reportFile.reportTypeId, reportFile.description, reportFile.file).pipe(
        mergeMap(() => this.assessmentRequestService.getAdditionalServiceAttachments(this._assessmentRequestId)))
        .subscribe((asa) => this._additionalServiceAttachments = asa);
    });
  }

  public onClickVerbalFeedbackComplete(additionalServiceRef: IAdditionalServiceReferenceModel, event) {
    const additionalService = this._additionalServiceAttachments.find((asa) => asa.additionalServiceReferenceId === additionalServiceRef.additionalServiceReferenceId);
    this.assessmentRequestService.saveVerbalDelivered(additionalService.additionalServiceId).subscribe(() => {
      additionalService.isComplete = true;
    });
  }

  public onClickVerbalFeedbackNotComplete(additionalServiceRef: IAdditionalServiceReferenceModel, event) {
    const additionalService = this._additionalServiceAttachments.find((asa) => asa.additionalServiceReferenceId === additionalServiceRef.additionalServiceReferenceId);
    this.assessmentRequestService.saveVerbalNotDelivered(additionalService.additionalServiceId).subscribe(() => {
      additionalService.isComplete = false;
    });
  }

  public onClickDelete(attachment) {
    if (attachment.assessmentLevelAttachmentId) {
      this.showDeleteAssessmentLevelConfirmationDialog(attachment);
    } else if (attachment.additionalServiceAttachmentId) {
      this.showDeleteAdditionalServiceConfirmationDialog(attachment);
    }
  }

  public verbalFeedbackComplete(additionalService: IAdditionalServiceReferenceModel): boolean {
    if (additionalService.additionalServiceReferenceId !== AdditionalServiceReferenceTypes.VerbalDevelopmentFeedback) {
      return false;
    }
    return this._additionalServiceAttachments.some((as) => as.additionalServiceReferenceId === additionalService.additionalServiceReferenceId && as.isComplete);
  }

  public get disableComplete(): boolean {
    const verbal = this._additionalServiceAttachments.filter((as) => as.additionalServiceReferenceId === AdditionalServiceReferenceTypes.VerbalDevelopmentFeedback);
    let verbalComplete = false;
    if (verbal && verbal.length) {
      verbalComplete = verbal.some((v) => v.isComplete);
    }
    return ((this.attachments.length === 0 && !verbalComplete) || (!this.assessmentRequest.consultantUserId || this.assessmentRequest.consultantUserId == 0));
  }

  public onClickComplete() {
    this.showMarkCompleteConfirmationDialog();
  }

  public trackByAssessmentLevelReferenceId(index, assessmentLevelReference: IAssessmentLevelReferenceModel): number {
    return assessmentLevelReference.assessmentLevelReferenceId;
  }

  public trackByAdditionalServiceReferenceId(index, additionalServiceReference: IAdditionalServiceReferenceModel): number {
    return additionalServiceReference.additionalServiceReferenceId;
  }

  public get attachments(): IAttachmentBaseModel[] {
    const attachments = [].concat(this._assessmentLevelAttachments.filter((ala) => ala.attachmentId));
    return attachments.concat(this._additionalServiceAttachments.filter((asa) => asa.attachmentId));
  }

  public isValidAssessmentLevel(assessmentLevelReference: IAssessmentLevelReferenceModel): boolean {
    return this._assessmentLevelAttachments && this._assessmentLevelAttachments.some((ala) => ala.assessmentLevelReferenceId === assessmentLevelReference.assessmentLevelReferenceId);
  }

  public anyValidAdditionalService(): boolean {
    if (!this._additionalServiceAttachments) {
      return false;
    }

    if (!this.additionalServiceReferences || this.additionalServiceReferences.length === 0) {
      return false;
    }
    let some = false;
    for (const asr of this.additionalServiceReferences) {
      some = this._additionalServiceAttachments.some((asa) => asa.additionalServiceReferenceId === asr.additionalServiceReferenceId);
      if (!some) {
        continue;
      }
      break;
    }
    return some;
  }

  public isValidAdditionalService(additionalServiceReference: IAdditionalServiceReferenceModel): boolean {
    return this._additionalServiceAttachments && this._additionalServiceAttachments.some((asa) => asa.additionalServiceReferenceId === additionalServiceReference.additionalServiceReferenceId);
  }

  public isVerbalFeedback(additionalServiceReference: IAdditionalServiceReferenceModel): boolean {
    return additionalServiceReference.additionalServiceReferenceId === AdditionalServiceReferenceTypes.VerbalDevelopmentFeedback
  }

  public onClickBack() {
    this.location.back();
  }

  private showMarkCompleteConfirmationDialog() {
    this._modal = this.modalService.open(ConfirmationDialogComponent);

    const instance = this._modal.componentInstance as ConfirmationDialogComponent;
    instance.title = 'Mark Assessment Request Complete';
    instance.body = `Are you sure you want to set the status of this assessment request to complete?`;

    instance.onConfirm.subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.assessmentRequestService.setStatus(this._assessmentRequestId, AssessmentRequestStatus.Complete)
          .subscribe(() => {
            this.assessmentRequestService.clearCache();
            this.router.navigate(['assessment-requests']);
          });
      }
    });
  }

  private showDeleteAssessmentLevelConfirmationDialog(assessmentLevel: IAssessmentLevelAttachmentModel) {
    this._modal = this.modalService.open(ConfirmationDialogComponent);

    const instance = this._modal.componentInstance as ConfirmationDialogComponent;
    instance.title = 'Delete Assessment Level Report';
    instance.body = `Are you sure you want to delete the assessment level report '${assessmentLevel.filename}'?`;

    instance.onConfirm.subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.deleteAssessmentLevelAttachment(assessmentLevel);
      }
    });
  }

  private showDeleteAdditionalServiceConfirmationDialog(additionalService: IAdditionalServiceAttachmentModel) {
    this._modal = this.modalService.open(ConfirmationDialogComponent);

    const instance = this._modal.componentInstance as ConfirmationDialogComponent;
    instance.title = 'Delete Additional Service Report';
    instance.body = `Are you sure you want to delete the additional service report '${additionalService.filename}'?`;

    instance.onConfirm.subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.deleteAdditionalServiceAttachment(additionalService);
      }
    });
  }

  private deleteAssessmentLevelAttachment(assessmentLevel: IAssessmentLevelAttachmentModel) {
    this.assessmentRequestService.deleteAssessmentLevelAttachment(assessmentLevel.assessmentLevelAttachmentId).pipe(
      mergeMap(() => this.assessmentRequestService.getAssessmentLevelAttachments(this._assessmentRequestId)))
      .subscribe((ala) => this._assessmentLevelAttachments = ala);
  }

  private deleteAdditionalServiceAttachment(additionalService: IAdditionalServiceAttachmentModel) {
    this.assessmentRequestService.deleteAdditionalServiceAttachment(additionalService.additionalServiceAttachmentId).pipe(
      mergeMap(() => this.assessmentRequestService.getAdditionalServiceAttachments(this._assessmentRequestId)))
      .subscribe((asa) => this._additionalServiceAttachments = asa);
  }
}
