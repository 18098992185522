import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataSource} from '../data/data-source';
import {Column} from '../models';

@Component({
  selector: 'ps-table-title',
  template: `
    <a href="#" *ngIf="column.isSortable" (click)="_sort($event, column)"
       class="ps-table-sort-link sort" [ngClass]="currentDirection">
       {{ column.title }}
    </a>
    <span class="ps-table-sort" *ngIf="!column.isSortable">{{ column.title }}</span>
  `
})
export class TitleComponent implements OnInit {

  @Input() column: Column;
  @Input() source: DataSource;

  @Output() sort = new EventEmitter<any>();

  currentDirection = '';

  ngOnInit(): void {
    this.source.onChanged().subscribe((elements) => {
      const sortConf = this.source.getSort();

      if (sortConf.length > 0 && sortConf[0]['field'] === this.column.id) {
        this.currentDirection = sortConf[0]['direction'];
      } else {
        this.currentDirection = '';
      }

      sortConf.forEach((fieldConf) => {

      });
    });
  }

  _sort(): boolean {
    this.changeSortDirection();
    this.source.setSort([
      {
        field: this.column.id,
        direction: this.currentDirection,
        compare: this.column.getCompareFunction()
      }
    ]);
    this.sort.emit(null);
    return false;
  }

  changeSortDirection(): string {
    if (this.currentDirection) {
      const newDirection = this.currentDirection === 'asc' ? 'desc' : 'asc';
      this.currentDirection = newDirection;
    } else {
      this.currentDirection = this.column.sortDirection || 'asc';
    }
    return this.currentDirection;
  }
}
