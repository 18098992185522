import {NgModule} from '@angular/core';
import {ListFeedbackUserComponent} from './list-feedback-user/list-feedback-user.component';
import {SharedModule} from '../shared/shared.module';
import {AddExistingFeedbackUserDialogComponent} from './add-existing-feedback-user-dialog/add-existing-feedback-user-dialog.component';
import {AddNewFeedbackUserDialogComponent} from './add-new-feedback-user-dialog/add-new-feedback-user-dialog.component';
import {ManageFeedbackUserComponent} from './manage-feedback-user/manage-feedback-user.component';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../auth/auth.guard';
import {SecureLayoutComponent} from '../shared/secure-layout/secure-layout.component';
import {ManageExternalRecipientsComponent} from './manage-external-recipients/manage-external-recipients.component';
import {PiquantTableModule} from '../piquant-table/piquant-table.module';
import {AgGridModule} from 'ag-grid-angular';
import { DetailCellRendererComponent } from './detail-cell-renderer/detail-cell-renderer.component';
import { ViewButtonRendererComponent } from './view-button-renderer/view-button-renderer.component';
import { FilterUsersStatusComponent } from './filter-grid-status/filter-grid-status.component';

const routes: Routes = [{
    path: 'feedback-recipients', component: SecureLayoutComponent, canActivateChild: [AuthGuard], children: [
    {
      path: '',
      component: ManageFeedbackUserComponent
    },
    { path: 'non-system',
      component: ManageExternalRecipientsComponent
    }
  ]}
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        SharedModule,
        AgGridModule.withComponents([DetailCellRendererComponent, ViewButtonRendererComponent]),
        PiquantTableModule
    ],
    exports: [
        ListFeedbackUserComponent,
        AddExistingFeedbackUserDialogComponent,
        AddNewFeedbackUserDialogComponent,
        ManageFeedbackUserComponent,
        ManageExternalRecipientsComponent
    ],
    declarations: [
        ListFeedbackUserComponent,
        AddExistingFeedbackUserDialogComponent,
        AddNewFeedbackUserDialogComponent,
        ManageFeedbackUserComponent,
        ManageExternalRecipientsComponent,
        ViewButtonRendererComponent,
        DetailCellRendererComponent,
        FilterUsersStatusComponent
    ]
})
export class FeedbackModule {
}
