import {ICompetencyModel} from '../interfaces/competency.interface';

export class CompetencyModel implements ICompetencyModel {

  public competencyId?: number;
  public parentId?: number;
  public competencyType: string;
  public name: string;
  public isActive: boolean;
  public description: string;
  public number: string;
  public competencySetId: number;
  public competencySetName: string;
  public createdBy: string;
  public createdDate: Date;
   public children: ICompetencyModel[];

  constructor(obj?: ICompetencyModel) {
    if (!obj) {
      return;
    }

    if (obj.competencyId) {
      this.competencyId = obj.competencyId;
    }

    this.parentId = obj.parentId;
    this.competencyType = obj.competencyType;
    this.name = obj.name;
    this.isActive = obj.isActive;
    this.description = obj.description;
    this.number = obj.number;
    this.competencySetId = obj.competencySetId;
    this.createdBy = obj.createdBy;
    this.createdDate = obj.createdDate;
    this.competencySetName = obj.competencySetName;
    this.children = obj.children;
  }
}
