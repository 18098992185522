import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateChild, RouterStateSnapshot} from '@angular/router';
import {AuthService} from './auth.service';

@Injectable()
export class AuthGuard implements CanActivateChild {
  constructor(private authService: AuthService) {
  }

  public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.shouldRefreshToken) {
      this.authService.refresh().subscribe(() => {
        if (!this.authService.isValidLogin) {
          this.authService.logout();
          return false;
        }
      });
    } else if (!this.authService.isValidLogin) {
      // if not logged in, redirect to login page with the return url
      this.authService.logout(true);
      return false;
    }
    return true;
  }
}
