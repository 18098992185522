import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {ActionBarSettings} from './models/action-bar-settings';
import {Action} from './models/action.model';
import {ActionGroup} from './models/action-group.model';
import {ActionSecurityService} from './action.service';

@Component({
  selector: 'action-bar',
  templateUrl: 'action-bar.component.html',
  styleUrls: ['action-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActionBarComponent implements OnInit {
  @Input() settings: ActionBarSettings;
  defaultSettings: ActionBarSettings = {
    actions: [],
    actionGroups: [],
    class: ''
  };

  constructor(private security: ActionSecurityService) {
  }

  ngOnInit(): void {
    this.initSettings();
  }

  getActions(): Action[] {
    return this.settings.actions.filter((e: Action) => {
      return this.security.checkCanLoad(e.canLoad);
    });
  }

  getActionGroups(): ActionGroup[] {
    return this.settings.actionGroups.filter((e: ActionGroup) => {
      return this.security.checkCanLoad(e.canLoad);
    });
  }

  private initSettings() {
    if (this.settings === null || this.settings === undefined) {
      this.settings = this.defaultSettings;
    }
  }
}
