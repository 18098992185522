
import {defer as observableDefer, Observable} from 'rxjs';
import {map, publishReplay, refCount, take} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ServiceBase} from './service-base';
import {AuthHttpService} from '../auth/auth-http.service';
import {IPositionDescriptionOverrideModel} from './interfaces/position-description-override.interface';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class PositionDescriptionOverrideService extends ServiceBase {
  private static ApiUrl = 'api/position-description-override';
  private data$: Observable<IPositionDescriptionOverrideModel[]>;

  constructor(private http: HttpClient) {
    super();
  }

  public get(): Observable<IPositionDescriptionOverrideModel[]> {
    if (!this.data$) {
      this.data$ = observableDefer(() => this.http.get(PositionDescriptionOverrideService.ApiUrl)).pipe(
        map((res: Response) => (res || []) as IPositionDescriptionOverrideModel[]),
        publishReplay(1),
        refCount(),
        take(1)
      );
    }

    return this.data$;
  }
}
