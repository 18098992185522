<div style="padding: 15px; width: 100%;">
  <div class="row">
    <h2 style="display:inline; position:relative; top:-5px;">Additional Services</h2>
  </div>
  <div class="row" style="padding-bottom:25px;">
    <div class="row"></div>
    <h5>Would you like Chapman & Co. to provide on-boarding feedback for any of the following hire{{upgradeRequests.length > 1?'s':''}}?</h5>
    <div class="row">
    Please select "Written", "Verbal" or both to indicate how you would like feedback to be delivered. (Additional fees will apply.)
  </div>
  <hr>
  <form [formGroup]="form" (ngSubmit)="submit()" class="fullwidth mt-1">
    <div [formArrayName]="'candidates'" class="fullwidth">
      <div class="row" *ngFor="let option of candidatesArray.controls; let i = index">
        <div [formGroupName]="i" class="fullwidth" style="padding-bottom:10px;">
          <div class="row">
            <div class="col-4">
              <div class="row"><h4>{{option.controls.candidateName.value}}</h4></div>
              <div class="row" style="margin-left: 1px;">{{option.controls.orgUnitName.value}}</div>
            </div> 
            <div class="col-2">
              <div class="row">
                <input class="col-1" [id]="'writtenCheck' + i" type="checkbox" formControlName="writtenFeedback" (change)="feedBackChanged(i)"> 
                <label class="form-control-label col-1" [for]="'writtenCheck' + i">Written</label>
              </div>
              <div class="row">
                <input class="col-1" [id]="'verbalCheck' + i" type="checkbox" formControlName="verbalFeedback" (change)="feedBackChanged(i)"> 
                <label class="form-control-label col-1" [for]="'verbalCheck' + i">Verbal</label>
              </div>
            </div>
            
            <div class="col-6" *ngIf="option.controls.writtenFeedback.value || option.controls.verbalFeedback.value">
              <div class="row">
                <div class="col-4 field">
                  <label class="form-control-label" [for]="'work-phone' + i">Phone </label>
                  <span ngbTooltip="Has this number changed?" container="body"><span style="margin-left:5px;" class="fa fa-exclamation-triangle text-warning"></span></span>
                  <input formControlName="updatedPhone" type="tel" class="form-control" [id]="'work-phone' + i" maxlength="50">
                </div>
                <div class="col-4 field">
                  <ps-input-validation>
                    <label class="form-control-label" [for]="'email' + i">Email </label>
                    <span ngbTooltip="Has this email changed?" container="body"><span style="margin-left:5px;" class="fa fa-exclamation-triangle text-warning"></span></span>
                    <input psInput formControlName="updatedEmail" type="email" class="form-control" [id]="'email' + i" maxlength="254">
                  </ps-input-validation>
                </div>
                <div class="col-4">
                  <ps-input-validation>
                    <label class="form-control-label" [for]="'position' + i">Position </label>
                    <span ngbTooltip="Has their position changed?" container="body"><span style="margin-left:5px;" class="fa fa-exclamation-triangle text-warning"></span></span>
                    <input psInput formControlName="position" type="text" class="form-control" [id]="'position' + i" maxlength="254">
                  </ps-input-validation>
                </div>
              </div>
            </div> 
          </div>
        </div>
        <hr>
      </div>
    </div>
    <div class="row" style="position: absolute; bottom: 10%; width: 50%;">
      <button [disabled]="form.invalid || wait" style="margin-left:20px" type="submit" class="btn btn-primary"><span class="fa fa-check"></span>Done</button>
      <button type="button" class="btn btn-secondary" (click)="dialogRef.close()"><span class="fa fa-ban"></span>{{cancelButtonText}}</button>
    </div>
  </form>

</div>