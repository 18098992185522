import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal, NgbAlert} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ISelectItemModel, IFeedbackUserSelectItem, IUserSelectItemModel} from '../../core/interfaces/select-item.interface';
import {IFeedbackUserModel} from '../../core/interfaces/feedback-user.interface';
import {FeedbackUserService} from '../../core/feedback-user.service';
import {IAdditionalUserModel} from '../../core/interfaces/additional-user.interface';
import {isNullOrUndefined} from 'util';
//import {} from '../../core/interfaces/select-item.interface';
//import {FeedbackSection} from '../../assessment-requests/edit-assessment-request/sections/feedback.section';

@Component({
  selector: 'add-new-feedback-user-dialog',
  templateUrl: './add-new-feedback-user-dialog.component.html',
  styleUrls: ['./add-new-feedback-user-dialog.component.scss']
})
export class AddNewFeedbackUserDialogComponent implements OnInit {
  private static defaultCountryId = 196; // country_id for 'United States Of America'

  @Input() public states: ISelectItemModel[] = [];
  @Input() public countries: ISelectItemModel[] = [];
  @Input() public requestedByUser: IUserSelectItemModel;
  @Input() public editAdditionalUser: IAdditionalUserModel = null;
  @Input() public alerts: Array<IAlert> = [];
  //@Input() public feedbackUserSection: FeedbackSection;

  @Output() public onAddNewFeedbackUser = new EventEmitter<IFeedbackUserModel>();

  public form: UntypedFormGroup;

  constructor(public activeModal: NgbActiveModal,
              private fb: UntypedFormBuilder,
              private feedbackUserService: FeedbackUserService) {
  }

  public ngOnInit() {
    this.form = this.buildForm();
  }

  public onSave() {
    if (!this.form.valid) {
      console.warn('form is invalid!');
      return;
    }

    for (const i of this.alerts){
      this.closeAlert(i);
    }

    this.feedbackUserService.saveAdditionalUser(this.additionalUser)
      .subscribe((addUser: IAdditionalUserModel) => {
        const newUser = {
          userId: addUser.additionalUserId,
          name: addUser.name,
          stateName: addUser.stateName,
          stateId: addUser.stateId,
          countryId: addUser.countryId,
          countryName: addUser.countryName,
          phone: addUser.phone,
          email: addUser.email,
          isSystem: false,
          verbal: false,
          written: false
        } as IFeedbackUserModel;
        this.onAddNewFeedbackUser.emit(newUser);
        this.onDismiss();
      },
        error => {this.alerts.push({
          id: 1,
          type: 'danger',
          message: 'Unable to save.  A system user with the same Email or First/Last name exist.  Please update and try again.'
        })}
      );
  }

  public onDismiss() {

    // If we came from the AddExisting Dialog, go back to it. Otherwise just dismiss.
    // if(this.feedbackUserSection != null)
    //   this.feedbackUserSection.showAddExistingFeedbackUserDialog(this.requestedByUser);

    this.activeModal.dismiss();
  }

  public closeAlert(alert: IAlert) {
    const index: number = this.alerts.indexOf(alert);
    this.alerts.splice(index, 1);
  }


  public get additionalUser(): IAdditionalUserModel {
    const values = this.form.value;
    const addUser = {
      name: values.name,
      email: values.email,
      phone: values.phone,
      titlePos: values.title,
      stateId: isNullOrUndefined(values.state) ? null : values.state.id,
      countryId: values.country.id,
      ownerUserId: this.requestedByUser.id,
      isActive: true,
    } as IAdditionalUserModel;

    addUser.additionalUserId = this.editAdditionalUser ? this.editAdditionalUser.additionalUserId : null;
    return addUser;
  }

  private buildForm(): UntypedFormGroup {
    const stateControl = new UntypedFormControl();

    const country = this.editAdditionalUser
      ? this.countries.find((c) => c.id === this.editAdditionalUser.countryId)
      : this.countries.find((c) => c.id === AddNewFeedbackUserDialogComponent.defaultCountryId);
    const countryControl = new UntypedFormControl(country);

    if(this.editAdditionalUser) {
      const id = this.editAdditionalUser ? this.editAdditionalUser.stateId : null;
      const state =  this.states.find((s) => s.id === id);
      stateControl.setValue(state);
    }

    countryControl.valueChanges.subscribe((c) => {
      if (c && c.id === AddNewFeedbackUserDialogComponent.defaultCountryId) {
        stateControl.enable();
      } else {
        stateControl.reset();
        stateControl.disable();
      }
    });

    if (this.editAdditionalUser) {
      return this.fb.group({
        name: [this.editAdditionalUser.name, [Validators.required, Validators.maxLength(1000)]],
        email: [this.editAdditionalUser.email, [Validators.required, Validators.maxLength(1000)]],
        phone: [this.editAdditionalUser.phone, [Validators.required, Validators.maxLength(50)]],
        title: [this.editAdditionalUser.titlePos, Validators.maxLength(100)],
        country: countryControl,
        state: stateControl
      });
    } else {
      return this.fb.group({
        name: [null, [Validators.required, Validators.maxLength(1000)]],
        email: [null, [Validators.required, Validators.maxLength(1000)]],
        phone: [null, [Validators.required, Validators.maxLength(50)]],
        title: [null, Validators.maxLength(100)],
        country: countryControl,
        state: stateControl
      });
    }
  }
}

export interface IAlert {
  id: number;
  type: string;
  message: string;
}
