<div class="modal-header">
  <h4 class="modal-title">Upload Job Description</h4>
  <span class="close-icon" aria-label="Close" (click)="onCancel()" style="cursor: pointer;">
    &times;
  </span>
</div>
<div class="modal-body">
  <div>
    <p>This position has no job description, would you like to upload one now?</p>
    <label class="btn btn-sm btn-primary btn-file">
      <span class="fa fa-cloud-upload"></span> Upload Job Description <input type="file" hidden (change)="onUploadJobDescriptionFileChange($event)" accept=".pdf,.doc,.docx">
    </label>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-danger" (click)="onCancel()">No Thanks</button>
</div>
