import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {OrgUnitHelperService} from './org-unit-helper.service';
import {OrgUnitItem} from '../../core/interfaces';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {DeleteOrgUnitConfirmComponent} from './delete-org-unit-confirm.component';
import {isNullOrUndefined} from 'util';
import {AppService} from '../../core/app.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'edit-org-unit-node',
  template: `
    <div class="hierarchy-list-item" [id]="orgUnit.orgUnitId" [ngClass]="{'deleted': orgUnit.isDeleted}">
      <div class="hierarchy-item" (click)="clicked($event);orgUnitClicked(orgUnit)" [ngClass]="{'selected': orgUnit.isSelected, 'no-children': !orgUnit.children || !orgUnit.children.length}">
        <i class="fa clickable" style="font-size: 18px; width: 12px;" *ngIf="orgUnit.children && orgUnit.children.length"         
           [ngClass]="orgUnit.showChildren ? 'fa-caret-down' : 'fa-caret-right'"
           (click)="showChildrenClick($event)">
        </i>
        <i class="fa fa-folder-o"></i>
        <span class="text" *ngIf="!orgUnit.isEditing">
          {{orgUnit.name}}&nbsp;
          <i *ngIf="!orgUnit.isActive" class="fa fa-ban text-muted" aria-hidden="true" title="Disabled"></i>
          <i class="fa fa-exclamation-triangle text-warning" *ngIf="orgUnit.isDirty"></i>
          </span>
        <span class="input-group" *ngIf="orgUnit.isEditing">
          <input type="text" class="form-control" (click)="$event.stopPropagation()" [(ngModel)]="editName" [value]="editName" />
          <span class="input-group-btn">
            <button class="btn btn-outline-danger" type="button" title="Cancel" (click)="cancelEditClick($event)"><i class="fa fa-close"></i></button>
            <button class="btn btn-outline-success" type="button" title="Save" (click)="confirmEditClick($event)"><i class="fa fa-check"></i></button>
          </span> 
        </span>
        <div class="hierarchy-actions" *ngIf="!readOnly && !orgUnit.isEditing">
        <mat-slide-toggle [(checked)]="orgUnit.isActive" (change)="activateOrDeactivateOrg($event)" (click)="stopEventPropagation($event)"
        [matTooltip]="orgUnit.isActive ? 'Slide to deactivate' : 'Slide to activate'" matTooltipPosition="right">
        </mat-slide-toggle>
        <i class="clickable fa fa-fw  fa-pencil" title="Edit" (click)="editClick($event)"></i>
        </div>
        </div>
      <div class="children" [style.display]="orgUnit.showChildren && orgUnit.children.length? 'block': 'none'">
        <edit-org-unit-node [selectedOrgUnitId]="selectedOrgUnitId" (expanded)="onExpand()" (editSelected)="orgUnitClicked($event)" *ngFor="let unit of orgUnit.children" [orgUnit]="unit" [readOnly]="readOnly"></edit-org-unit-node>
      </div>
    </div>
  `
})
export class EditOrgUnitNodeComponent implements OnInit {
  @Input() public orgUnit: OrgUnitItem;
  @Input() public readOnly: false;
  @Input() selectedOrgUnitId: number;
  @Output() editSelected: EventEmitter<any> = new EventEmitter();
  @Output() expanded: EventEmitter<any> = new EventEmitter();
  public editName: string;
  private _modal: NgbModalRef;

  constructor(private _helperService: OrgUnitHelperService,
              private modalService: NgbModal,
              private appService: AppService) {
  }

  public onExpand(){
    this.orgUnit.showChildren = true;
    this.expanded.emit();
  }

  public orgUnitClicked(orgUnit) {
    this.editSelected.emit(orgUnit)
  }

  public ngOnInit(): void {
    this.editName = this.orgUnit.name;
    if(this.selectedOrgUnitId == null)
      this.orgUnit.showChildren = localStorage.getItem( 'OrgExpanded'+this.orgUnit.orgUnitId) == '1'?true:false;
    //this.orgUnit.isSelected = localStorage.getItem( 'OrgSelected'+this.orgUnit.orgUnitId) == '1'?true:false;
    // if(this.orgUnit.isSelected){
    //   this.orgUnitClicked(this.orgUnit)
    // }
    if(this.selectedOrgUnitId == this.orgUnit.orgUnitId){
      this._helperService.itemSelectChange(this.orgUnit);
      this.orgUnit.isSelected = true;
      //this.onExpand();
      this.expanded.emit();
      this.orgUnitClicked(this.orgUnit);
    }
    this._helperService.onSelectionChanged$.subscribe((e) => this.onItemSelected(e));
    this.appService.onLoggingOut.subscribe(() => {
      if (!this._modal) {
        return;
      }
      this._modal.dismiss('logging out');
    });
  }

  public clicked(event) {
    this._helperService.itemSelectChange(this.orgUnit);
  }

  public showChildrenClick(event) {
    this.orgUnit.showChildren = !this.orgUnit.showChildren;
    if(this.orgUnit.showChildren)
      localStorage.setItem('OrgExpanded'+this.orgUnit.orgUnitId, '1');
    else
      localStorage.removeItem('OrgExpanded'+this.orgUnit.orgUnitId);
    event.stopPropagation();
    return false;
  }

  public editClick(evt) {
    if (this.readOnly) {
      return;
    }
    this.orgUnit.isEditing = true;
    evt.stopPropagation();
    return false;
  }

  public stopEventPropagation(event) {
    event.stopPropagation();
}

  public activateOrDeactivateOrg(event: MatSlideToggleChange) {
    this.orgUnit.isDirty = true;
    this.orgUnit.isActive = event.checked;
    this.orgUnit.isTouched = true;
    this.touchParents(this.orgUnit);
    this._helperService.listChanged(this.orgUnit);
  }

  public deleteClick(evt) {
    if (this.readOnly) {
      return;
    }
    this._modal = this.modalService.open(DeleteOrgUnitConfirmComponent);
    this._modal.componentInstance.name = this.orgUnit.name;
    this._modal.result.then((r) => {
      this.markOrgUnitDeleted(this.orgUnit);
      this._helperService.listChanged(this.orgUnit);
    });
  }

  private markOrgUnitDeleted(orgUnit: OrgUnitItem) {
    orgUnit.isTouched = true;
    orgUnit.isDirty = true;
    orgUnit.isDeleted = true;
    if (orgUnit.children && orgUnit.children.length) {
      for (const ou of orgUnit.children) {
        this.markOrgUnitDeleted(ou);
      }
    }
  }

  public cancelEditClick(evt) {
    evt.stopPropagation();
    this.orgUnit.isEditing = false;
    this.editName = this.orgUnit.name;
  }

  public confirmEditClick(evt) {
    evt.stopPropagation();
    if (this.orgUnit.name.toLocaleLowerCase() === this.editName.trim().toLocaleLowerCase()) {
      this.orgUnit.isEditing = false;
      this.orgUnit.isDirty = false;
      this.orgUnit.isTouched = false;
      return;
    }

    if (this._helperService.checkNameExists(this.editName)) {
      return;
    }
    this.orgUnit.name = this.editName.trim();
    this.orgUnit.isEditing = false;
    this.orgUnit.isDirty = true;
    this.orgUnit.isTouched = true;
    this.touchParents(this.orgUnit);
    this._helperService.listChanged(this.orgUnit);
  }

  private onItemSelected(orgUnit: OrgUnitItem) {
    if (isNullOrUndefined(this._helperService.selectedItem)) {
      this.orgUnit.isSelected = false;
      //localStorage.removeItem('OrgSelected'+this.orgUnit.orgUnitId);
      return;
    }
    
    if (this.orgUnit.orgUnitId === this._helperService.selectedItem.orgUnitId) {
      this.orgUnit.isSelected = true;
      //localStorage.setItem('OrgSelected'+this.orgUnit.orgUnitId, '1');
      return;
    }

    //localStorage.removeItem('OrgSelected'+this.orgUnit.orgUnitId);
    this.orgUnit.isSelected = false;
    if (this.orgUnit.isEditing) {
      this.orgUnit.isEditing = false;
      this.editName = this.orgUnit.name;
    }
  }

  private touchParents(orgUnit: OrgUnitItem) {
    const parent = this._helperService.orgUnits.find((e) => e.orgUnitId === orgUnit.orgUnitId);
    if (!parent || parent.isTouched) {
      return;
    }
    parent.isTouched = true;

    if (!isNullOrUndefined(parent.parentId)) {
      this.touchParents(parent);
    }
  }
}
