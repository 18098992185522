import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IOrgUnitModel} from '../../core/interfaces/org-unit.interface';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {HierarchyNode} from '../../shared/enhanced-controls/core/hierarchynode/ps-hierarchy-node';

@Component({
  selector: 'app-select-org-unit',
  templateUrl: './select-org-unit-dialog.component.html',
  styleUrls: ['./select-org-unit-dialog.component.scss']
})
export class SelectOrgUnitDialogComponent implements OnInit {
  @Input() public orgUnits: IOrgUnitModel[];
  @Input() public initialOrgUnitId: number;
  @Input() public allowedOrgIds: number[];
  @Output() public onSelectOrgUnit: EventEmitter<HierarchyNode> = new EventEmitter<HierarchyNode>();

  public nodes: HierarchyNode[] = [];
  public selectedNode: HierarchyNode;

  constructor(public activeModal: NgbActiveModal) { }

  public ngOnInit() {
    this.nodes = HierarchyNode.createArrayFromModel(this.orgUnits, [this.initialOrgUnitId])

    if (this.initialOrgUnitId) {
      this.selectedNode = this.findNode(this.nodes, this.initialOrgUnitId);
    }
  }

  public setSelected(node: HierarchyNode[]) {
    this.selectedNode = node && node.length > 0 ? node[0] : null;
  }

  public onSelect() {
    this.onSelectOrgUnit.emit(this.selectedNode);
    this.activeModal.close();
  }

  public onCancel() {
    this.activeModal.dismiss();
  }

  private findNode(nodes: HierarchyNode[], id: number): HierarchyNode {
    for (const node of nodes) {
      if (node.id === id) {
        return node;
      }

      const result = this.findNode(node.children, id);

      if (result) {
        return result;
      }
    }

    return null;
  }
}
