import { Component, OnInit } from '@angular/core';
import {AgRendererComponent} from 'ag-grid-angular';

@Component({
  selector: 'app-clients-view-button-renderer',
  template: '<a [routerLink]="[\'view\', params?.data?.emailQueueId]"><strong>{{params?.data?.emailQueueId}}</strong></a>'
})
export class ViewButtonRendererComponent implements AgRendererComponent {

  constructor() { }

  public params:any;
  
  agInit(params:any):void {
      this.params = params;
  }
  public refresh(params:any):boolean
  {
    return true;
  }
}
