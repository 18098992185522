<table class="table table-striped">
  <thead>
  <tr>
    <th></th>
    <th>Name</th>
    <th>Email</th>
    <th>Phone</th>
    <th class="text-center">Verbal</th>
    <th class="text-center">Written</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let feedbackUser of allFeedbackUsers">
    <td>
      <button class="btn btn-remove" (click)="remove(feedbackUser)"><span class="fa fa-remove"></span></button>
    </td>
    <td>{{feedbackUser.name}}</td>
    <td>{{feedbackUser.email}}</td>
    <td>{{feedbackUser.phone}}</td>
    <td class="text-center">
      <div class="form-check">
        <label class="form-check-label">
          <input class="form-check-input" type="checkbox" [(ngModel)]="feedbackUser.verbal" (ngModelChange)="onChange(feedbackUser)">
        </label>
      </div>
    </td>
    <td class="text-center">
      <div class="form-check">
        <label class="form-check-label">
          <div style="display:inline-block;" ngbTooltip="{{disableWritten ? 'For confidential assessments, only the requester will be notified when a report is uploaded':''}}" container="body">
            <input [disabled]="disableWritten" class="form-check-input" type="checkbox" [(ngModel)]="feedbackUser.written" (ngModelChange)="onChange(feedbackUser)">
          </div>
        </label>
      </div>
    </td>
  </tr>
  </tbody>
</table>
