import {PsListItem} from '../shared/enhanced-controls/core/listitem/ps-list-item';
import {AssessmentRequestStatus} from '../core/enums/assessment-request-status.enum';

export class AssessmentRequestStatusGroup {
  private static Groups = [
    new AssessmentRequestStatusGroup([AssessmentRequestStatus.Unknown], -1, 'Unknown'),
    new AssessmentRequestStatusGroup([AssessmentRequestStatus.NotSubmitted], 1, 'Not Submitted'),
    new AssessmentRequestStatusGroup([AssessmentRequestStatus.Submitted], 5, 'Submitted'),
    new AssessmentRequestStatusGroup([AssessmentRequestStatus.InProcess], 6, 'In Process'),
    new AssessmentRequestStatusGroup([AssessmentRequestStatus.Complete], 7, 'Complete'),
    new AssessmentRequestStatusGroup([AssessmentRequestStatus.Deleted], 8, 'Deleted'),
    new AssessmentRequestStatusGroup([AssessmentRequestStatus.Canceled], 10, 'Canceled')
  ];

  public static List: PsListItem[];
  public id: any;
  public statuses: AssessmentRequestStatus[];
  public name: string;

  public static init() {
    AssessmentRequestStatusGroup.Groups.forEach((g) => Object.freeze(g));
    Object.freeze(AssessmentRequestStatusGroup.Groups);

    AssessmentRequestStatusGroup.List = AssessmentRequestStatusGroup.Groups
      .filter((g) => !g.statuses.some((s) => s === AssessmentRequestStatus.Unknown))
      .map((g) => new PsListItem(g.id, g.name));

    Object.freeze(AssessmentRequestStatusGroup.List);
  }

  public static getStatusName(statusId: number): string {
    const status = AssessmentRequestStatusGroup.Groups.find((g) => {
      return g.statuses && g.statuses.some((s) => s === statusId);
    });

    return status ? status.name : null;
  }

  constructor(statuses: AssessmentRequestStatus[], id: any, name: string) {
    this.id = id;
    this.statuses = statuses;
    this.name = name;
  }
}

AssessmentRequestStatusGroup.init();
