import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ActionCanLoad} from '../models/actionCanLoad';

@Component({
  selector: 'action-button',
  template: `
    <button class="action-btn" [disabled]="disabled" [ngClass]="className" (click)="executeAction()" [type]="buttonType">
      <span *ngIf="iconClass"><i [ngClass]="iconClass"></i>&nbsp;</span>
      <ng-content></ng-content>
    </button>`
})
export class ActionButtonComponent {
  @Input() className: string;
  @Input() iconClass: string;
  @Input() gaurd: ActionCanLoad[];
  @Input() disabled: boolean;
  @Input() isSubmit = false;
  @Output() actionClick = new EventEmitter<any>();

  public get cssClasses(): string[] {
    const classes = [this.disabled ? 'action-btn-disabled' : 'action-btn'];

    if (this.className) {
      classes.push(this.className);
    }

    return classes;
  }

  public get buttonType() {
    return this.isSubmit ? 'submit' : 'button';
  }

  public executeAction() {
    if (!this.disabled) {
      this.actionClick.emit();
    }
  }
}
