<ng-template #popContent>
        <div class="ps-table-action-row" >
            <span style="display:inline;">
            <button style="float: right; top: -40px; position: relative" (click)="p.close()"><i class="fa fa-close text-danger"></i></button>
            <ng-container *ngFor="let action of invokeBuildActions()">
                <button *ngIf="action.isRowVisible(params.data)" [ngClass]="action.actionClass" class="ps-table-action-link"
                        (click)="action.action(params.data); p.close()">
                <i *ngIf="action.iconClass" [ngClass]="action.iconClass"></i>
                {{action.name}}
                </button>
            </ng-container>    
            </span>
        </div>
</ng-template>
<div style="width: 0px" container="body" [ngbPopover]="popContent" #p="ngbPopover" popoverTitle="Actions" triggers="manual" placement="bottom top"></div>
<span>
<!--<i style="font-size:14pt; color:#9aa184; padding-right:10px; padding-left:0px;" class="fa fa-bars" container="body" [ngbPopover]="popContent" #p="ngbPopover" popoverTitle="Actions - {{candidateName}}" triggers="manual" (click)="p.open()"></i>-->
<a [routerLink]="['/competencies/set', params.data.competencySetId]" >
    <strong>{{name}}</strong>
</a>
</span>
