<form novalidate [formGroup]="csForm" (ngSubmit)="onSubmit()" *ngIf="csForm">
  <action-bar>
    <action-button [className]="'action-danger'" [iconClass]="'fa fa-ban'" (actionClick)="cancel()">Cancel</action-button>
    <action-button [className]="'action-primary'" [iconClass]="'fa fa-floppy-o'"
                   [disabled]="csForm.invalid || csForm.pristine" [isSubmit]="true">Save
    </action-button>
  </action-bar>
  <ps-sections-container [showNav]="false">
    <ps-section formGroupName="set" [sectionTitle]="'Competency Set Information'" [sectionId]="'competencyset'">
      <div class="form-group row">
        <ps-input-validation>
          <label class="form-control-label" for="name">Name *</label>
          <input psinput class="form-control" type="text" id="name" autocomplete="off" formControlName="name">
        </ps-input-validation>
        <ps-input-validation>
          <label class="form-control-label" for="name">Description *</label>
          <input psinput class="form-control" type="text" id="description" autocomplete="off" formControlName="description">
        </ps-input-validation>
      </div>
    </ps-section>
    <ps-section [sectionTitle]="'Competencies'" [sectionId]="'competencies'">
      <div class="row">
        <div class="col">
          <p class="inline-input-group">
            <input type="text" class="inline-input" placeholder="Competency Name" [value]="competencyName" (input)="competencyName = $event.target.value" />
            <button class="btn btn-link" (click)="addCompetency()"><i class="fa fa-plus"></i> Add Competency</button>
            <button class="btn btn-link" (click)="addFolder()"><i class="fa fa-folder-o"></i> Add Folder</button>
            <label class="form-check-label" style="padding: .5rem .75rem; margin-left: 8px;">
              <input type="checkbox" class="form-check-input" [checked]="showInactive" (change)="showInactive = !showInactive">
              Show Inactive
            </label>
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="competency-list scroll scroll-lg">
            <competency-list-item *ngFor="let comp of parentCompetencies" [competency]="comp"></competency-list-item>
          </div>
        </div>
      </div>
    </ps-section>
  </ps-sections-container>
</form>
