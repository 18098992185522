<form formGroupName="addSetForm">
  <action-bar>
    <action-button [className]="'action-danger'" [iconClass]="'fa fa-ban'">Cancel</action-button>
    <action-button [className]="'action-primary'" [iconClass]="'fa fa-floppy-o'" [disabled]="addSetForm.invalid">Save</action-button>
  </action-bar>
  <h1>Add Competency Set</h1>

</form>


