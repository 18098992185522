import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../auth.service';
import {AuthConfig} from '../auth-config';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import { tick } from '../../../../node_modules/@angular/core/testing';
import { log } from 'console';

@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {
  public form: UntypedFormGroup;
  public loginFailed = false;
  public failureMessage = "Login Failed.";
  public mfaRequired: boolean = false;
  public mfaRequestSucceeded: boolean = false;
  public mfaVerifySucceeded: boolean = false;
  public inProgress: boolean = false;
  public mfaToken: string = '';
  private _mfaType: number = 0;
  public get mfaType(): number {
    return this._mfaType;
  }
  public set mfaType(value: number) {
    switch(value) {
      case 0:
      case 1:
        this._mfaType=1;
        this.deliveryVector="E-Mail";
        this.deliveryItem="E-Mail";
        break;
      case 2:
        this._mfaType=2;
        this.deliveryVector="phone";
        this.deliveryItem="SMS/Text-Message";
        break;
    }
  }
  public mfaFirstTime: boolean = false;
  public deliveryVector: string;
  public deliveryItem: string;
  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: UntypedFormBuilder,
              private authService: AuthService) {
    this.form = this.buildForm();
  }

  private setMfaType(mfaType){
    
  }

  public ngOnInit() {
    // reset login status
    this.authService.clearState();
  }

  public login() {
    debugger;
    this.loginFailed = false;
    let deviceGuid = localStorage.getItem('deviceGuid' + this.usernameControl.value);
    this.authService.login(this.usernameControl.value, this.passwordControl.value, deviceGuid)
      .subscribe(
        () => {
          this.loginFailed = false;
          if(!this.mfaFirstTime ) {
            this.router.navigate([this.returnUrl]);
          } else {
            this.router.navigate(['/assessment-requests/manage-mfa']);
          }
        },
        (error) => {
          console.error(error);
          switch(error.status) {
            case 460:
            case 461:
              //First Time MFA User
              //this.failureMessage = "Device not Autheticated: This device must be authenticated."
              this.mfaRequired = true;
              this.mfaType = 1;
              this.mfaFirstTime = true;
              break;
            case 462:
              //First Time MFA User
              //this.failureMessage = "Device not Autheticated: This device must be authenticated."
              this.mfaRequired = true;
              this.mfaType = 2;
              this.mfaFirstTime = true;
              break;
            case 470:
            case 471:
              //this.failureMessage = "Device not Autheticated: This device must be authenticated."
              this.mfaRequired = true;
              this.mfaType = 1;
              break;
            case 472:
              //this.failureMessage = "Device not Autheticated: This device must be authenticated."
              this.mfaRequired = true; 
              this.mfaType = 2;
              break;
            case 429:
              this.failureMessage = "Login Failed: Too Many Failed Attempts - Try again later.";
              this.loginFailed = true;
              this.passwordControl.setValue("");
              break;
            case 400:
              this.failureMessage = "Login Failed: Invalid username and/or password.";
              this.loginFailed = true;
              this.passwordControl.setValue("");
              break;
            default:
              this.failureMessage = "Login Failed.";
              this.loginFailed = true;
              this.passwordControl.setValue("");
              break;
          }
        });
  }

  public requestMutliFactorToken() {
    this.inProgress = true;
    this.authService.requestMutliFactorToken(this.usernameControl.value, this.passwordControl.value)
    .subscribe(() => {
      this.mfaRequestSucceeded = true;
      this.inProgress = false;
      },
      (error) => {
        console.error(error);
        this.loginFailed = true;
        this.inProgress = false;
        switch(error.status) {
          case 400:
            this.failureMessage = "Login Failed: Invalid username and/or password.";
            break;    
          case 429:
            this.failureMessage = "Login Failed: Too Many Failed Attempts - Try again later.";
            break;
          default:
            this.failureMessage = "Unable to Request Token.";
            break;
        }
      });
  }

  public verifyMultiFactorToken() {
    this.inProgress = true;
    this.authService.verifyMutliFactorToken(this.usernameControl.value, this.passwordControl.value, this.mfaToken)
    .subscribe((deviceGuid: string) => {
      localStorage.setItem("deviceGuid" + this.usernameControl.value, deviceGuid);
      let deviceGuidR = localStorage.getItem('deviceGuid' + this.usernameControl.value);
      this.mfaVerifySucceeded = true;
      this.inProgress = false;
      this.login();
      },
      (error) => {
        console.error(error);
        this.loginFailed = true;
        this.mfaVerifySucceeded = false;
        this.inProgress = false;
        switch(error.status) {
          case 429:
            this.failureMessage = "Login Failed: Too Many Failed Attempts - Try again later.";
            break;
          case 400:
            this.failureMessage = "Login Failed: Invalid username and/or password.";
            break;
          case 401:
            this.failureMessage = "Code does not match. Please check your " + this.deliveryVector +", or have another code sent. "
            break;
          default:
            this.failureMessage = "Login Failed.";
            break;
        }
      });
  }


  public get usernameControl(): UntypedFormControl {
    return this.form.controls['username'] as UntypedFormControl;
  }

  public get passwordControl(): UntypedFormControl {
    return this.form.controls['password'] as UntypedFormControl;
  }

  private buildForm(): UntypedFormGroup {
    return this.fb.group({
      username: this.fb.control('', Validators.required),
      password: this.fb.control('', Validators.required)
    });
  }

  private get returnUrl(): string {
    debugger;
    // get return url from route parameters or default to '/assessment-requests'
    var returnUrl = this.route.snapshot.queryParams['returnUrl'] || AuthConfig.DefaultRouteUrl;
    
    //If a user specifically navigates to /login (instead of being routed there), prevent returnUrl from taking them to the login screen again.
    if(returnUrl.toLowerCase() == '/login'){
      returnUrl = AuthConfig.DefaultRouteUrl;
    }
    const index = returnUrl.lastIndexOf('#');
    return index > 0 ? returnUrl.substring(0, index) : returnUrl;
  }
}
