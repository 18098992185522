<ng-container *ngFor="let n of columnArray">
  <div [ngClass]="{'col': columns > 1, 'select-list': columns <= 1}">
    <div *ngFor="let competency of getCompetencies(n)" (click)="competencyClicked(competency)" class="select-list-item"
         [ngClass]="{'clickable': iconClasses}">
      <span *ngIf="iconClasses && iconSide === 'left'" class="item-icon">
        <i [ngClass]="iconClasses"></i>&nbsp;
      </span>
      <i class="fa fa-cube"></i> {{competency.name}}
      <span *ngIf="iconClasses && iconSide === 'right'" class="item-icon pull-right">
        <i [ngClass]="iconClasses"></i>&nbsp;
      </span>
    </div>
  </div>
</ng-container>

