import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {NgbDateStruct, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AssessmentRequestService} from '../../core/assessment-request.service';
import {IAssessmentTestingScheduleModel} from '../../core/interfaces/assessment-testing-schedule.interface';
import {isValid} from 'date-fns';
import {isDate} from 'lodash';
import {ISelectItemModel} from '../../core/interfaces/select-item.interface';

interface NgbDateTimeStruct {
  date: NgbDateStruct;
  time: NgbTimeStruct;
}

@Component({
  selector: 'app-testing-schedule',
  templateUrl: './edit-testing-schedule.component.html',
  styleUrls: ['./edit-testing-schedule.component.scss']
})
export class EditTestingScheduleComponent implements OnInit {
  private _assessmentRequestId: number;
  private _now: Date;
  public candidateName: String;
  public emailTemplates: ISelectItemModel[];
  public form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private assessmentRequestService: AssessmentRequestService) {
    this._now = new Date();
  }

  public ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this._assessmentRequestId = params['id'];
      this.candidateName = params['candidateName'];
    });

    this.activatedRoute.data.subscribe((data: any) => {
      const emailTemplates = (data.emailTemplates || []) as ISelectItemModel[];

      const noEmailSelectItem = {id: null, name: 'No email'} as ISelectItemModel;
      this.emailTemplates = [noEmailSelectItem].concat(emailTemplates);

      const testingSchedule = data.testingSchedule as IAssessmentTestingScheduleModel;
      this.form = this.buildForm(testingSchedule);
    });
  }

  public clearTesting() {
    this.form.controls.testingDate.setValue(null);
    this.form.controls.testingTime.setValue(null);
    this.form.markAsDirty();
  }

  public clearInterview() {
    this.form.controls.interviewDate.setValue(null);
    this.form.controls.interviewTime.setValue(null);
    this.form.markAsDirty();
  }

  public clearFeedback() {
    this.form.controls.feedbackDate.setValue(null);
    this.form.controls.feedbackTime.setValue(null);
    this.form.markAsDirty();
  }

  public get startDate() {
    const today = this.todayDate;
    return {year: today.year, month: today.month};
  }

  public get todayDate(): NgbDateStruct {
    return {year: this._now.getFullYear(), month: this._now.getMonth() + 1, day: this._now.getDay()};
  }

  public get emailTemplateControl(): UntypedFormControl {
    return this.form.controls['emailTemplate'] as UntypedFormControl;
  }

  public get emailTemplateId(): boolean {
    const selectedEmail = this.emailTemplateControl.value;
    return selectedEmail ? selectedEmail.id : null;
  }

  public onClickCancel() {
    this.router.navigate(['assessment-requests']);
  }

  public onClickSave() {
    if (this.form.invalid || !this._assessmentRequestId) {
      return;
    }

    const testingSchedule = this.formValue as IAssessmentTestingScheduleModel;
    testingSchedule.assessmentRequestId = this._assessmentRequestId;

    this.assessmentRequestService.saveTestingSchedule(testingSchedule)
      .subscribe(() => {
        this.assessmentRequestService.clearCache();
        this.router.navigate(['/assessment-requests']);
      });
  }

  public get allowSave(): boolean {
    return this.form && this.form.valid && this.form.dirty;
  }

  public get formValue(): IAssessmentTestingScheduleModel {
    const value = this.form.getRawValue();
    return {
      testingDate: this.convertToJsDate(value.testingDate, value.testingTime),
      interviewDate: this.convertToJsDate(value.interviewDate, value.interviewTime),
      feedbackDate: this.convertToJsDate(value.feedbackDate, value.feedbackTime),
      notes: value.notes,
      emailTemplateId: value.emailTemplate ? value.emailTemplate.id : null,
      includeNote: value.includeNote
    };
  }

  private buildForm(testingSchedule: IAssessmentTestingScheduleModel): UntypedFormGroup {
    const testingDateTime = this.convertToDateTimeStruct(testingSchedule.testingDate);
    const interviewDateTime = this.convertToDateTimeStruct(testingSchedule.interviewDate);
    const feedbackDateTime = this.convertToDateTimeStruct(testingSchedule.feedbackDate);
    const emailTemplateControl = new UntypedFormControl({value: null, required: true});
    const includeNoteControl = new UntypedFormControl({value: false, disabled: true});

    emailTemplateControl.valueChanges.subscribe((newEmailTemplate) => {
      const emailTemplateSelected = !!(newEmailTemplate && newEmailTemplate.id);

      if (emailTemplateSelected) {
        includeNoteControl.enable();
      } else {
        includeNoteControl.reset();
        includeNoteControl.disable();
      }
    });

    return this.fb.group({
      testingDate: [testingDateTime ? testingDateTime.date : null],
      testingTime: [testingDateTime ? testingDateTime.time : null],
      interviewDate: [interviewDateTime ? interviewDateTime.date : null],
      interviewTime: [interviewDateTime ? interviewDateTime.time : null],
      feedbackDate: [feedbackDateTime ? feedbackDateTime.date : null],
      feedbackTime: [feedbackDateTime ? feedbackDateTime.time : null],
      notes: testingSchedule.notes,
      emailTemplate: emailTemplateControl,
      includeNote: includeNoteControl
    });
  }

  private convertToJsDate(date: NgbDateStruct, time: NgbTimeStruct): Date {
    if (!date) {
      return null;
    }

    const utc = Date.UTC(date.year, date.month - 1, date.day, time ? time.hour : 0, time ? time.minute : 0);
    return new Date(utc);
  }

  private convertToDateTimeStruct(val: Date): NgbDateTimeStruct {
    if (!val) {
      return null;
    }

    const dt: Date = isDate(val) ? val : new Date(val);

    if (isValid(dt)) {
      const date = {
        year: dt.getFullYear(),
        month: dt.getMonth() + 1,
        day: dt.getDate()
      } as NgbDateStruct;

      const time = {
        hour: dt.getHours(),
        minute: dt.getMinutes(),
        second: dt.getSeconds()
      } as NgbTimeStruct;

      return {date: date, time: time} as NgbDateTimeStruct;
    }

    return null;
  }
}
