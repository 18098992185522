
import {defer as observableDefer, Observable} from 'rxjs';
import {map, publishReplay, refCount, take} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ServiceBase} from './service-base';
import {AuthHttpService} from '../auth/auth-http.service';
import {IPositionFunctionModel} from './interfaces/position-function.interface';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class PositionFunctionService extends ServiceBase {
  private static ApiUrl = 'api/position-function';
  private data$: Observable<IPositionFunctionModel[]>;
  private cacheDuration: number = 1000 * 60 * 10;

  constructor(private http: HttpClient) {
    super();
  }

  public get(): Observable<IPositionFunctionModel[]> {
    if (!this.data$) {
      this.data$ = observableDefer(() => this.http.get(PositionFunctionService.ApiUrl)).pipe(
        map((res: Response) => (res || []) as IPositionFunctionModel[]),
        publishReplay(1, this.cacheDuration),
        refCount(),
        take(1)
      );
    }

    return this.data$;
  }

  public clearCache() {
    this.data$ = null;
  }
}
