<action-bar>
  <action-button [disabled]="!isImpersonating" (actionClick)="onCancelImpersonationClick()" [iconClass]="'fa fa-ban'" [className]="'action-danger'">Stop Impersonating</action-button>
</action-bar>

<div class="row">
  <div class="col">
    <h1>Impersonate User</h1>
  </div>
</div>
<div class="row" style="margin-top: 1rem;">
  <div class="col">
    <div class="card" *ngFor="let client of clients">
      <div class="card-body">
        <h4 class="card-title">{{client.name}}</h4>
        <a *ngFor="let user of client.users" (click)="onClickUser(user);" href="javascript:void(0)" class="card-link">{{user.name}}</a>
      </div>
    </div>
  </div>
</div>
