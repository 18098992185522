import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {IPositionListItemModel} from '../../core/interfaces/position-list-item.interface';
import {ISelectItemModel} from '../../core/interfaces/select-item.interface';
import {AuthService} from '../../auth/auth.service';
import {GridOptions} from "ag-grid-community";
import {JobDescriptionRendererComponent} from '../job-description-renderer/job-description-renderer.component';
import {EditButtonRendererComponent} from '../edit-button-renderer/edit-button-renderer.component';
import {ViewButtonRendererComponent} from '../view-button-renderer/view-button-renderer.component';
import {FilterPositionsStatusComponent} from '../filter-grid-status/filter-grid-status.component';

@Component({
  selector: 'app-list-positions',
  templateUrl: './list-positions.component.html',
  styleUrls: ['./list-positions.component.css'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class ListPositionsComponent implements OnInit {

  public gridOptions: GridOptions;
  public inputQuickSearch: string;
  public selectedPaginationOption = 2;
  public paginationOptions = [
    {id: 0, name: "10"},
    {id: 1, name: "50"},
    {id: 2, name: "100"},
    {id: 3, name: "500"}
  ];

  private createColumnDefs() {
    return [
      {
        headerName: "Job Description",
        field: "description",
        menuTabs: ['filterMenuTab'],
        filter: "agTextColumnFilter",
        valueFormatter: "value",
        width: 230,
        cellRendererFramework: ViewButtonRendererComponent,
        icons: {
          sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
          sortDescending: '<i class="fa fa-sort-alpha-desc"/>',
          filter: '<i class="fa fa-search"/>',
          menu: '<i class="fa fa-filter"/>'
        }
      },
      {
        headerName: "Id",
        field: "positionId",
        filter: "agNumberColumnFilter",
        menuTabs: ['filterMenuTab'],
        width: 75,
        icons: {
          sortAscending: '<i class="fa fa-arrow-up"/>',
          sortDescending: '<i class="fa fa-arrow-down"/>',
          filter: '<i class="fa fa-search"/>',
          menu: '<i class="fa fa-filter"/>'
        }
      },
      {
        headerName: "Department",
        field: "orgUnitName",
        filter: "agTextColumnFilter",
        menuTabs: ['filterMenuTab'],
        icons: {
          sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
          sortDescending: '<i class="fa fa-sort-alpha-desc"/>',
          filter: '<i class="fa fa-search"/>',
          menu: '<i class="fa fa-filter"/>'
        }
      },
      {
        headerName: "Level",
        field: "positionLevelName",
        filter: "agTextColumnFilter",
        menuTabs: ['filterMenuTab'],
        icons: {
          sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
          sortDescending: '<i class="fa fa-sort-alpha-desc"/>',
          filter: '<i class="fa fa-search"/>',
          menu: '<i class="fa fa-filter"/>'
        }
      },
      {
        headerName: "Function",
        field: "name",
        filter: "agTextColumnFilter",
        menuTabs: ['filterMenuTab'],
        icons: {
          sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
          sortDescending: '<i class="fa fa-sort-alpha-desc"/>',
          filter: '<i class="fa fa-search"/>',
          menu: '<i class="fa fa-filter"/>'
        }
      },
      {
        headerName: "Created By",
        field: "createdBy",
        filter: "agTextColumnFilter",
        menuTabs: ['filterMenuTab'],
        icons: {
          sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
          sortDescending: '<i class="fa fa-sort-alpha-desc"/>',
          filter: '<i class="fa fa-search"/>',
          menu: '<i class="fa fa-filter"/>'
        }
      },
      {
        headerName: "Created Date",
        field: "createdDate",
        filter: "agDateColumnFilter",
        menuTabs: ['filterMenuTab'],
        valueFormatter: this.dateCellFormatter,
        sort: 'desc',
        width: 120,
        icons: {
          sortAscending: '<i class="fa fa-arrow-up"/>',
          sortDescending: '<i class="fa fa-arrow-down"/>',
          filter: '<i class="fa fa-search"/>',
          menu: '<i class="fa fa-filter"/>'
        }
      },
      {
        headerName: "Job Description",
        field: "description",
        menuTabs: ['filterMenuTab'],
        filter: "agTextColumnFilter",
        valueFormatter: "value",
        suppressSizeToFit: true,
        width: 130,
        cellRendererFramework: JobDescriptionRendererComponent,
        icons: {
          sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
          sortDescending: '<i class="fa fa-sort-alpha-desc"/>',
          filter: '<i class="fa fa-search"/>',
          menu: '<i class="fa fa-filter"/>'
        }
      },
      {
        headerName: "Action",
        field: "value",
        suppressSizeToFit: true,
        menuTabs: [],
        width: 95,
        suppressFilter: true,
        cellRendererFramework: EditButtonRendererComponent,
      },
      {
        headerName: "Active",
        field: "isActiveDescription",
        filterFramework: FilterPositionsStatusComponent,
        menuTabs: ['filterMenuTab'],
        width: 110,
        icons: {
          sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
          sortDescending: '<i class="fa fa-sort-alpha-desc"/>',
          filter: '<i class="fa fa-search"/>',
          menu: '<i class="fa fa-filter"/>'
        }
      }
    ];
  }

  private createRowData() {
    return this.selectedClientPositions;
  }

  public exportDataAsCsv(){
    this.gridOptions.api.exportDataAsCsv();
  }

  // Move this to shared?
  private dateCellFormatter(params) {
    const thisDate: Date = new Date(params.value);
    return `${(thisDate.getMonth() + 1)}/${thisDate.getDate()}/${thisDate.getFullYear()}`;
  }

  public clients: ISelectItemModel[];
  public positions: IPositionListItemModel[];
  public selectedClient: ISelectItemModel;

  constructor(private route: ActivatedRoute, private router: Router, private authService: AuthService)
  {
    this.gridOptions = <GridOptions>{
      columnDefs: this.createColumnDefs(),
      rowHeight: 48, // recommended row height for material design data grids,
      headerHeight: 38,
      suppressCellFocus: true,
      suppressMenuHide: true,
      animateRows: true,
      pagination: true,
      getRowClass: (event) => this.rowBackgroundColor(event),
      onSortChanged: () => this.onColumnChanged(),
      onColumnResized: () => this.onColumnChanged(),
      onFilterChanged: (event) => this.onFilterChanged(event),
      context: { componentParent: this },   // Make this component (and its members) available to child components.
      defaultColDef: {
        resizable: true,
        sortable: true
      }
    }
  }

  onResize(event) {
    localStorage.setItem('positionsWindowWidth', JSON.stringify(event.target.innerWidth));
    this.gridOptions.api.sizeColumnsToFit();
  }

  public rowBackgroundColor(event): string {
    if (event.node.rowIndex % 2 === 0) {
      return 'shaded-row';
    }
    else
      return null;
  }

  public setPageSize(paginationOption) {
    // Current version of AG Grid appears to have problems with autopagination (at least if it's turned on at runtime)... disabling.
    // if(paginationOption != 0)
    // {
      this.gridOptions.paginationAutoPageSize = false;
      this.gridOptions.api.paginationSetPageSize(Number( (this.paginationOptions[paginationOption]) ? this.paginationOptions[paginationOption].name : 100));
    // }
    // else
    // {
    //   this.gridOptions.paginationAutoPageSize = true;
    //   //this.gridOptions.api.doLayout();
    //   this.gridOptions.api.redrawRows();
    // }
  }

  public ngOnInit() {

  }

  public ngAfterViewInit() {
    this.route.data.subscribe((data: any) => {
      this.clients = data.clients as ISelectItemModel[];
      this.positions = data.positions as IPositionListItemModel[];
      if (this.clients && this.clients.length) { // I guess this is checking to see if we actually loaded a list of clients...
        if (this.isAdmin) {
          // Attempt to load saved ID
          const selectedClientId = localStorage.getItem('gridPositionsSelectedClient' + this.getImpersonatedUserId());
          if (selectedClientId != null) {
            this.selectedClient = this.getClientFromId(this.clients, Number(selectedClientId));
          } else { // Nope - just set it to nothing.
            this.selectedClient = null
          }
        } else {
          if (this.authService.impersonatedUser != null) {
            this.selectedClient = this.getClientFromId(this.clients, this.authService.impersonatedUser.clientId);
          } else {
            this.selectedClient = this.getClientFromId(this.clients, this.authService.clientId);
          }
        }
      }


      // The grid should always be ready by the time AfterViewInit goes off.
      this.gridOptions.api.setRowData(this.createRowData());
    });
    this.loadGridPreferences();
  }

  public loadGridPreferences() {
    //Handle loading saved Sort, Filter, and Column settings.
    var columnState = localStorage.getItem('gridPositionsColumnState' + this.getImpersonatedUserId());  //gridColumnState'+this.getImpersonatedUserId(), JSON.stringify(this.gridOptions.columnApi.getColumnState()
    if (columnState != null) {
      this.gridOptions.columnApi.applyColumnState(JSON.parse(columnState));
      //Prevent poor column saved states when a user's window size changes between sessions.
      if (+localStorage.getItem('positionsWindowWidth') != window.innerWidth) {
        this.gridOptions.api.sizeColumnsToFit();
        localStorage.setItem('positionsWindowWidth', JSON.stringify(window.innerWidth));
      }
    }
    else {
      this.gridOptions.api.sizeColumnsToFit();
      this.resetFilters();
    }
    var filterModel = localStorage.getItem('gridPositionsFilterModel' + this.getImpersonatedUserId());
    if (filterModel != null) {
      const thisModel = JSON.parse(filterModel);
      this.gridOptions.api.setFilterModel(thisModel);    
    }
    else
      this.resetFilters();
    //Also attempt to load pagination selection
    var paginationOption = localStorage.getItem('gridPositionsPagination' + this.getImpersonatedUserId());
    if (paginationOption != null)
      this.onChangePaginationOption(Number(paginationOption));
  }

  public getClientFromId(clients: ISelectItemModel[], id: number): ISelectItemModel{
    var output: ISelectItemModel;
    for (let client of clients){
      if (client.id === id) {
        output = client;
      }
    }
    return output;
  }

  public onFilterTextBoxChanged() {
    this.gridOptions.api.setQuickFilter(this.inputQuickSearch);
  }

  public resetFilters() {
    // Special handling for admin Select Client - Not exactly a filter but it currently makes sense to put it here.
    if(this.isAdmin){
      this.selectedClient = null
      this.onSelectClient(null);
    }
    //Reset Pagination Option
    this.onChangePaginationOption(2);
    // each column has to have filters removed.
    for (let column of this.gridOptions.columnApi.getAllColumns())
    {
      this.gridOptions.api.destroyFilter(column);
    }
    // Now cleanup the quick search -> reset the input element
    this.inputQuickSearch = '';
    // and reset the grid quickfilter
    this.gridOptions.api.setQuickFilter('');
    // reset the default sort.
    this.gridOptions.columnApi.resetColumnState();
    // finally raise the events...
    this.gridOptions.api.onSortChanged();
    this.gridOptions.api.onFilterChanged();
    this.gridOptions.api.sizeColumnsToFit();
  }

  public onChangePaginationOption(option) {
    this.selectedPaginationOption = option; // event.target.value;
    this.setPageSize(this.selectedPaginationOption);
    localStorage.setItem('gridPositionsPagination' + this.getImpersonatedUserId(), this.selectedPaginationOption.toString());
  }

  public onColumnChanged() {
    localStorage.setItem('gridPositionsColumnState' + this.getImpersonatedUserId(), JSON.stringify(this.gridOptions.columnApi.getColumnState())); //get/applyColumnState()
  }

  public onFilterChanged(event) {
    localStorage.setItem('gridPositionsFilterModel' + this.getImpersonatedUserId(), JSON.stringify(this.gridOptions.api.getFilterModel()));
  }

  // This event occurs when an admin selects a client -> The grid then needs to be populated.
  public onSelectClient(event): void {
    this.selectedClient = event;

    if (event != null) {
      localStorage.setItem('gridPositionsSelectedClient' + this.getImpersonatedUserId(), event.id.toString());
      this.ngAfterViewInit()
    } else { // if null comes in - let's clear the localStore key
      localStorage.removeItem('gridPositionsSelectedClient' + this.getImpersonatedUserId());
    }

    if (this.positions != null) { // Hard to imagine this being null but...
      this.gridOptions.api.setRowData(this.createRowData());
      this.gridOptions.api.sizeColumnsToFit();
    }
  }

  public get isRoleClient() {
    return this.authService.isRoleClient;
  }

  public getImpersonatedUserId(): string {
    return (this.authService.impersonatedUser != null) ? this.authService.impersonatedUser.userId.toString() : this.authService.userId.toString();
  }

  public onNewClick() {
    this.router.navigate(['/positions', 'add', 'client', this.selectedClient.id]);
  }

  public onClickEdit(positionId: number) {
    this.router.navigate(['/positions', 'edit', positionId], {queryParams: {clientId: this.selectedClient.id}});
  }

  public onJobDescriptionClick(attachmentId: number) {
    alert('JobDescriptionAttachmentId: ' + attachmentId);
  }

  public get selectedClientPositions(): IPositionListItemModel[] {
    if (!this.selectedClient) {
      return [];
    }

    const positions = this.positions.filter((p) => p.clientId === this.selectedClient.id);

    if (this.authService.orgUnitIds === null ) {
      return positions;
    } else {
      // JSG: Added check for position orgunit is null to include positions not assigned to a department in filter.
      return positions.filter((p) => this.authService.orgUnitIds.includes(p.orgUnitId) || p.orgUnitId === null);
    }
  }

  public get isAdmin(): boolean {
    return this.authService.isRoleAdmin;
  }
}
