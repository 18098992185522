import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SecureLayoutComponent} from '../shared/secure-layout/secure-layout.component';
import { CommonModule } from '@angular/common';
import { ViewHelpComponent } from './view-help/view-help.component';
import {AuthGuard} from '../auth/auth.guard';
import {AdminGuard} from '../core/admin.guard';

const routes: Routes = [
  {
    path: 'help', component: SecureLayoutComponent, canActivateChild: [AuthGuard], children: [
    {
      path: '',
      component: ViewHelpComponent,
    }
  ]
  }
];
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  declarations: [ViewHelpComponent]
})
export class HelpModule { }
