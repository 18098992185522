import {Column, IFilter, ITableSettings, Row} from '.';
import {DataSet} from '../data/data-set';
import {DataSource} from '../data/data-source';
import {EventEmitter} from '@angular/core';
import {Deferred, getDeepFromObject} from './table-helpers';
import {Subject, Observable} from 'rxjs';
import {isUndefined} from 'lodash';

export class Grid {
  source: DataSource;
  settings: ITableSettings;
  dataSet: DataSet;
  onSelectRowSource = new Subject<any>();

  constructor(source: DataSource, settings) {
    this.setSettings(settings);
    this.setSource(source);
  }

  getActions(row: Row) {
    return this.settings.actions.filter(a => {
      if (!isUndefined(a.isVisible) && !a.isVisible) {
        return false;
      }

      if (a.isRowVisible && row && !a.isRowVisible(row)) {
        return false;
      }

      return true;
    });
  }

  setSettings(settings: ITableSettings): void {
    this.settings = settings;
    this.dataSet = new DataSet([], this.getSetting('columns'));

    if (this.source) {
      this.source.refresh();
    }
  }

  setSource(source: DataSource): void {
    this.source = this.prepareSource(source);
    this.source.onChanged().subscribe((changes) => this.processDataChange(changes));
  }

  getSetting(name: string, defaultValue?: any): any {
    return getDeepFromObject(this.settings, name, defaultValue);
  }

  getColumns(): Column[] {
    return this.dataSet.getColumns();
  }

  getVisibleColumns(): Column[] {
    return this.dataSet.getVisibleColumns();
  }

  getColumn(name: string): Column {
    const columns = this.dataSet.getColumns();
    return columns.find((c) => c.id.toLowerCase() === name.toLowerCase());
  }

  getRows(): Row[] {
    return this.dataSet.getRows();
  }

  selectRow(row: Row): void {
    this.dataSet.selectRow(row);
  }

  viewAction(row: Row) {
    if (this.settings.viewAction) {
      this.settings.viewAction(row);
    }
  }

  getFilters(): IFilter[] {
    return this.settings.filter.filters;
  }

  multipleSelectRow(row: Row): void {
    this.dataSet.multipleSelectRow(row);
  }

  onSelectRow(): Observable<any> {
    return this.onSelectRowSource.asObservable();
  }

  delete(row: Row, confirmEmitter: EventEmitter<any>): void {
    const deferred = new Deferred();
    deferred.promise.then(() => {
      this.source.remove(row.getData());
    }).catch((err) => {
      // doing nothing
    });

    if (this.getSetting('delete.confirmDelete')) {
      confirmEmitter.emit({
        data: row.getData(),
        source: this.source,
        confirm: deferred
      });
    } else {
      deferred.resolve();
    }
  }

  processDataChange(changes): void {
    if (this.shouldProcessChange(changes)) {
      this.dataSet.setData(changes['elements']);
      if (this.getSetting('selectMode') !== 'multi') {
        const row = this.determineRowToSelect(changes);
        if (row) {
          this.onSelectRowSource.next(row);
        }
      }
    }
  }

  shouldProcessChange(changes): boolean {
    if (['filter', 'sort', 'page', 'remove', 'refresh', 'load', 'paging'].indexOf(changes['action']) !== -1) {
      return true;
    } else if (!this.getSetting('pager.display')) {
      return true;
    }

    return false;
  }

  // TODO: move to selectable? Separate directive
  determineRowToSelect(changes): Row {

    if (['load', 'page', 'filter', 'sort', 'refresh'].indexOf(changes['action']) !== -1) {
      return this.dataSet.select();
    }
    if (changes['action'] === 'remove') {
      if (changes['elements'].length === 0) {
        // we have to store which one to select as the data will be reloaded
        this.dataSet.willSelectLastRow();
      } else {
        return this.dataSet.selectPreviousRow();
      }
    }
    if (changes['action'] === 'append') {
      // we have to store which one to select as the data will be reloaded
      this.dataSet.willSelectLastRow();
    }
    if (changes['action'] === 'add') {
      return this.dataSet.selectFirstRow();
    }
    if (changes['action'] === 'update') {
      return this.dataSet.selectFirstRow();
    }
    if (changes['action'] === 'prepend') {
      // we have to store which one to select as the data will be reloaded
      this.dataSet.willSelectFirstRow();
    }
    return null;
  }

  prepareSource(source): DataSource {
    const initialSource = this.getInitialSort();
    if (initialSource && initialSource['field'] && initialSource['direction']) {
      source.setSort([initialSource], false);
    }
    if (this.getSetting('pager.display') === true) {
      source.setPaging(1, this.getSetting('pager.perPage'), false);
    }

    source.refresh();
    return source;
  }

  getInitialSort() {
    const sortConf = {};
    this.getColumns().forEach((column: Column) => {
      if (column.isSortable && column.defaultSortDirection) {
        sortConf['field'] = column.id;
        sortConf['direction'] = column.defaultSortDirection;
        sortConf['compare'] = column.getCompareFunction();
      }
    });
    return sortConf;
  }

  getSelectedRows(): any[] {
    return this.dataSet.getRows()
      .filter((r) => r.isSelected)
      .map((r) => r.getData());
  }

  selectAllRows(status) {
    this.dataSet.getRows()
      .forEach((r) => r.isSelected = status);
  }
}
