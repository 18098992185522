import {NgModule} from '@angular/core';
import {EditPositionComponent} from './edit-position/edit-position.component';
import {RouterModule, Routes} from '@angular/router';
import {SharedModule} from '../shared/shared.module';
import {AuthGuard} from '../auth/auth.guard';
import {SecureLayoutComponent} from '../shared/secure-layout/secure-layout.component';
import {ListPositionsComponent} from './list-positions/list-positions.component';
import {ClientSelectItemsResolver, NewPositionResolver, OrgUnitByUserPositionResolver, PositionDescriptionOverridesResolver, PositionFunctionsResolver, PositionLevelResolver, PositionListItemsResolver, PositionResolver} from '../core/resolvers.service';
import {ViewPositionComponent} from './view-position/view-position.component';
import {EditPositionBaseComponent} from './edit-position-base/edit-position-base.component';
import {EditPositionModalComponent} from './edit-position-modal/edit-position-modal.component';
import {CompetenciesModule} from '../competencies/competencies.module';
import {AgGridModule} from 'ag-grid-angular';
import { JobDescriptionRendererComponent } from './job-description-renderer/job-description-renderer.component';
import { EditButtonRendererComponent } from './edit-button-renderer/edit-button-renderer.component';
import { ViewButtonRendererComponent } from './view-button-renderer/view-button-renderer.component';
import {PositionListResolver} from '../core';
import { FilterPositionsStatusComponent } from './filter-grid-status/filter-grid-status.component';

const routes: Routes = [
  {
    path: 'positions', component: SecureLayoutComponent, canActivateChild: [AuthGuard], children: [
    {
      path: 'view/:id',
      component: ViewPositionComponent,
      resolve: {
        position: PositionResolver,
        positionLevels: PositionLevelResolver
      }
    },
    {
      path: 'edit/:id',
      component: EditPositionComponent,
      resolve: {
        position: PositionResolver,
        positionLevels: PositionLevelResolver,
        positionFunctions: PositionFunctionsResolver,
        positionDescriptionOverrides: PositionDescriptionOverridesResolver,
        userOrgUnits: OrgUnitByUserPositionResolver
      }
    },
    {
      path: 'add/client/:id',
      component: EditPositionComponent,
      resolve: {
        position: NewPositionResolver,
        positionLevels: PositionLevelResolver,
        positionFunctions: PositionFunctionsResolver,
        positionDescriptionOverrides: PositionDescriptionOverridesResolver,
        userOrgUnits: OrgUnitByUserPositionResolver
      }
    },
    {
      path: '',
      component: ListPositionsComponent,
      resolve: {
        clients: ClientSelectItemsResolver,
        positions: PositionListResolver
      }
    }
  ]
  }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        SharedModule,
        CompetenciesModule,
        AgGridModule.withComponents([JobDescriptionRendererComponent, ViewButtonRendererComponent, EditButtonRendererComponent]) //  EditButtonRendererComponent .withComponents([SquareComponent, CubeComponent]),
    ],
    exports: [
        EditPositionModalComponent
    ],
    providers: [OrgUnitByUserPositionResolver],
    declarations: [
        FilterPositionsStatusComponent,
        EditPositionComponent,
        ListPositionsComponent,
        ViewPositionComponent,
        EditPositionBaseComponent,
        EditPositionModalComponent,
        JobDescriptionRendererComponent,
        EditButtonRendererComponent,
        ViewButtonRendererComponent,
    ]
})



export class PositionsModule {


}
