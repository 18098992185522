<form novalidate [formGroup]="editProfileForm" *ngIf="editProfileForm">
  <action-bar>
    <action-button [iconClass]="'fa fa-ban'" [className]="'action-danger'" (actionClick)="cancelEdit()">Cancel</action-button>
    <action-button [iconClass]="'fa fa-floppy-o'" [className]="'action-primary'"
                   (actionClick)="onSubmit()"
                   [disabled]="editProfileForm.invalid || editProfileForm.pristine">Submit
    </action-button>
  </action-bar>
  <h1>Edit Profile</h1>
  <ps-sections-container [showNav]="false">
    <ps-section formGroupName="userinfo" [sectionTitle]="'User Information'" [sectionId]="'userAdmin'">
      <div class="form-group row">
        <ps-input-validation>
          <label for="firstname">First Name *</label>
          <input psInput type="text" class="form-control" id="firstname" formControlName="firstname" autocomplete="off"/>
        </ps-input-validation>
        <ps-input-validation [columnSize]="1">
          <label for="middlename">MI</label>
          <input psInput type="text" maxlength="1" class="form-control" id="middlename" formControlName="middlename" autocomplete="off" style="text-transform: capitalize;">
        </ps-input-validation>
        <ps-input-validation>
          <label for="lastname">Last Name *</label>
          <input psInput type="text" class="form-control" id="lastname" formControlName="lastname" autocomplete="off">
        </ps-input-validation>
      </div>
      <div class="form-group row">
        <ps-input-validation>
          <label for="email">Email *</label>
          <input psInput type="text" class="form-control" id="email" formControlName="email" autocomplete="off">
        </ps-input-validation>
        <ps-input-validation>
          <label>Username</label>
          <div class="form-control-static">{{editProfileForm.get('userinfo.username').value}}</div>
        </ps-input-validation>
      </div>
    </ps-section>
    <ps-section formGroupName="address" [sectionTitle]="'Address & Phone'" [sectionId]="'addressPhone'">
      <div class="form-group row">
        <ps-input-validation>
          <label for="address1">Address 1</label>
          <input psInput type="text" class="form-control" id="address1" formControlName="address1" autocomplete="off">
        </ps-input-validation>
        <ps-input-validation>
          <label for="address2">Address 2</label>
          <input psInput type="text" class="form-control" id="address2" formControlName="address2" autocomplete="off">
        </ps-input-validation>
      </div>
      <div class="form-group row">
        <ps-input-validation [columnSize]="6">
          <label for="city">City</label>
          <input psInput type="text" class="form-control" id="city" formControlName="city" autocomplete="off">
        </ps-input-validation>
      </div>
      <div class="form-group row">
        <ps-input-validation [columnSize]="6">
          <label for="country">Country</label>
          <ps-autocomplete-select psInput id="country" formControlName="country"
                                  [selectList]="countries" [placeHolderText]="'Country'"
                                  (selectionChanged)="onSelectCountry($event)">
          </ps-autocomplete-select>
        </ps-input-validation>
        <ps-input-validation [columnSize]="3" *ngIf="isUSA">
          <label for="state">State</label>
          <select psInput class="form-control" id="state" formControlName="state" autocomplete="off">
            <option *ngFor="let state of states" [value]="state.id">{{state.name}}</option>
          </select>
        </ps-input-validation>
        <ps-input-validation [columnSize]="3" *ngIf="!isUSA">
          <label for="province">State/Province/Region</label>
          <input psInput type="text" class="form-control" id="province" formControlName="province" autocomplete="off">
        </ps-input-validation>
        <ps-input-validation [columnSize]="3">
          <label for="zipcode">Zip Code</label>
          <input psInput type="text" class="form-control" id="zipcode" formControlName="zipcode" autocomplete="off">
        </ps-input-validation>
      </div>
      <div class="form-group row">
        <ps-input-validation [columnSize]="2">
            <label for="phoneworkcountrycode">Work Country Code</label>
            <input psInput type="text" class="form-control" id="phoneworkcountrycode" formControlName="phoneworkcountrycode" autocomplete="off">
        </ps-input-validation>
        <ps-input-validation>
          <label for="phonework">Work Phone</label>
          <input psInput type="text" class="form-control" id="phonework" formControlName="phonework" autocomplete="off">
        </ps-input-validation>
        <ps-input-validation [columnSize]="2">
            <label for="phoneworkcountrycode">Mobile Country Code</label>
            <input psInput type="text" class="form-control" id="phonemobilecountrycode" formControlName="phonemobilecountrycode" autocomplete="off" [specialCharacters]="[]"  mask="999990">
        </ps-input-validation>
        <ps-input-validation>
          <label for="phonemobile">Mobile Phone{{(!isUSA) ? ' (Please Exclude Leading Zero)' : ''}}</label>
          <input *ngIf="isUSA" psInput type="text" class="form-control" id="phonemobile" formControlName="phonemobile" autocomplete="off" mask="(000) 000-0000">
          <input *ngIf="!isUSA" psInput type="text" class="form-control" id="phonemobile" formControlName="phonemobile" autocomplete="off" [specialCharacters]="[]" mask="999999999999">
        </ps-input-validation>
      </div>
    </ps-section>
    <ps-section formGroupName="mfa" [sectionTitle]="'Multi-factor Authentication'" [sectionId]="'mfaSelect'">
        <div class="form-group row">
          <ps-input-validation>
            <label class="form-control-label">Multi-Factor Authentication Using:</label>
            <div class="form-check">
              <label class="form-check-label">
                <input psInput class="form-check-input" type="radio" [value]="1" formControlName="mfaType">
                E-Mail
              </label>
            </div>
            <div class="form-check">
              <label class="form-check-label">
                <input  psInput class="form-check-input" type="radio" [value]="2" formControlName="mfaType">
                SMS/Text
              </label>
              <div *ngIf="editProfileForm.errors?.smsRequiresPhoneMobile && (editProfileForm.touched || editProfileForm.dirty)" class="cross-validation-error-message alert alert-danger">
                You must enter a Mobile Phone number to select SMS/Text.
              </div>
            </div>
          </ps-input-validation>
        </div>
      </ps-section>
  </ps-sections-container>
</form>
