import {Injectable} from '@angular/core';
import {Subject, Observable} from 'rxjs';
import {CompetencySelectListItem, ICompetencyModel} from '../../core/interfaces/competency.interface';

@Injectable()
export class CompetencyListService {
  public selectItemSource = new Subject<CompetencySelectListItem>();
  public editItemSource = new Subject<CompetencySelectListItem>();
  public onSelectionChanged$ = this.selectItemSource.asObservable();
  public onEditItemChanged$ = this.editItemSource.asObservable();

  public listChangedSource = new Subject<CompetencySelectListItem>();
  public onListChanged$ = this.listChangedSource.asObservable();

  public selectedItem: CompetencySelectListItem;
  public editItem: CompetencySelectListItem;
  public competencies: CompetencySelectListItem[];
  public showInactive = false;

  private currentNewId = 0;

  public getNewCompetencyId() {
    this.currentNewId--;
    return this.currentNewId;
  }

  public itemSelectChange(node: CompetencySelectListItem) {
    if (this.selectedItem && this.selectedItem.competencyId === node.competencyId) {
      this.selectedItem = null;
    } else {
      this.selectedItem = node;
    }
    this.selectItemSource.next(node);
  }

  public editItemChange(node: CompetencySelectListItem) {
    this.editItem = node;
    this.editItemSource.next(node);
  }

  public listChanged(node: CompetencySelectListItem) {
    this.listChangedSource.next(node);
  }
}
