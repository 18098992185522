import {NgModule} from '@angular/core';
import {SharedModule} from '../shared/shared.module';
import {SelectOrgUnitDialogComponent} from './select-org-unit-dialog/select-org-unit-dialog.component';
import {EditOrgUnitsComponent} from './edit-org-unit/edit-org.units.component';
import {EditOrgUnitNodeComponent} from './edit-org-unit/edit-org-unit-node.component';
import {DeleteOrgUnitConfirmComponent} from './edit-org-unit/delete-org-unit-confirm.component';
import {OrgUnitNodeComponent} from './org-unit-node/org-unit-node.component';
import {AssignUserOrgUnitComponent} from './assign-user-org-unit/assign-user-org-unit';
import { DisplayUserOrgUnitsComponent } from './edit-org-unit/display-user-org.units.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
    imports: [
        SharedModule,
        MatSlideToggleModule,
        MatTooltipModule
    ],
    exports: [
        SelectOrgUnitDialogComponent,
        EditOrgUnitsComponent,
        EditOrgUnitNodeComponent,
        OrgUnitNodeComponent,
        DeleteOrgUnitConfirmComponent,
        AssignUserOrgUnitComponent,
        DisplayUserOrgUnitsComponent
    ],
    declarations: [
        SelectOrgUnitDialogComponent,
        EditOrgUnitsComponent,
        EditOrgUnitNodeComponent,
        OrgUnitNodeComponent,
        DeleteOrgUnitConfirmComponent,
        AssignUserOrgUnitComponent,
        DisplayUserOrgUnitsComponent
    ]
})
export class OrgUnitsModule {
}





