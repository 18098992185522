<div class="modal-header">
  <h4 class="modal-title">E-Mail Recipients</h4>
  <span class="close-icon" aria-label="Close" (click)="onDismiss()" style="cursor: pointer;">
    &times;
  </span>
</div>
<div class="modal-body">
  <p>
    Enter an email address, one at a time, to send the password protected report to someone within your organization who is authorized to see this report.
  </p>
  <input type="text" (keydown)="onKeyDown($event)" [(ngModel)]="inputFieldValue" [ngClass]="{'bg-danger': errorWarn}">
  <button type="button" class="btn btn-primary" (click)="onAdd()" style="margin-left: 10px;"><span class="fa fa-plus"></span> Add</button>
  <table style="margin-top: 10px;" class="table table-striped table-sm">
    <tr *ngFor="let email of emails">
      <td style="padding-top: 11px;" >{{email}}</td>
      <td>
            <button id="bootstrap-table-danger" type="button" class="btn btn-danger" (click)="onRemove(email)"><span class="fa fa-trash"></span> Remove</button>
      </td>
    </tr>
  </table>
</div>
<div class="modal-footer">
  <div class="col">
        <span class="pull-right">
            <button type="button" class="btn btn-primary" (click)="onSend()" [disabled]="emails.length < 1"><span class="fa fa-envelope"></span> Send</button>
            <button type="button" class="btn btn-cancel" (click)="onDismiss()"><span class="fa fa-ban"></span> Cancel</button>
      </span>
  </div>
</div>
