import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ICompetencyNode} from '../competency-node.interface';

@Component({
  selector: 'competency-node',
  templateUrl: 'competency-node.component.html',
  styleUrls: ['competency-node.component.scss']
})
export class CompetencyNodeComponent {
  @Input() public node: ICompetencyNode;
  @Output() public onCompetencyClicked = new EventEmitter<ICompetencyNode>();

  public onNodeClicked(node: ICompetencyNode) {
    if (node.isCompetency) {
      this.onCompetencyClicked.emit(node);
    } else {
      node.isExpanded = !node.isExpanded;
    }
  }

  public onChildNodeClicked(node: ICompetencyNode) {
    this.onCompetencyClicked.emit(node);
  }
}
