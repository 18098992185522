import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {IEmailModel} from '../../core/interfaces/email.interface';
import {EmailStatus} from '../../core/enums/email-status.enum';
import {format} from 'date-fns';

@Component({
  selector: 'view-api-entry',
  templateUrl: 'view-api-entry.component.html',
  styleUrls: ['view-api-entry.component.css', '../../../styles/form.scss']
})
export class ViewApiEntryComponent implements OnInit {
  public email: IEmailModel;
  public emailStatus: string;
  public createDate: string;

  constructor(private route: ActivatedRoute) {
  }

  public ngOnInit() {
    this.route.data.subscribe((data: any) => {
      this.email = data.email as IEmailModel;
      this.emailStatus = this.statusDescription(this.email.status);
      this.createDate = format(this.email.createDate, 'M/D/YY h:mm a');
    });
  }

    // todo: refactor
  private statusDescription(status: number): string {
    switch (status) {
      case EmailStatus.Unknown: return 'Unknown';
      case EmailStatus.Queued: return 'Queued';
      case EmailStatus.Sent: return 'Sent';
      case EmailStatus.Resent: return 'Resent';
      case EmailStatus.Failed: return 'Failed';
    }
  }

}
