import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {FeedbackUserService} from '../../core/feedback-user.service';
import {Utility} from '../../core/utility';
import {AssessmentRequestProxyService} from '../../core/assessment-request-proxy.service';
import {ProxySubmitItem} from '../../core/interfaces/assessment-request-proxy.interface';
import {AssessmentRequestService} from '../../core/assessment-request.service';

@Component({
  selector: 'set-status-dialog',
  templateUrl: './set-status-dialog.component.html',
  styleUrls: ['./set-status-dialog.component.scss']
})
export class SetStatusDialogComponent implements OnInit {
  @Input() public assessmentRequest: any;
  @Output() public onUpdate = new EventEmitter<number>();
  public statusOptions: any;
  constructor(public activeModal: NgbActiveModal,
              private modalService: NgbModal,
              public assessmentRequestService: AssessmentRequestService
    ) {
  }

  public ngOnInit(): void {
    

  }

  public onDismiss() {
    this.activeModal.dismiss();
  }

  public onClose() {
    this.assessmentRequestService.setStatus(this.assessmentRequest.assessmentRequestId, this.assessmentRequest.statusId)
      .subscribe(() => {
        if (this.onUpdate) {
          this.onUpdate.emit(this.assessmentRequest.statusId);
        }

        this.activeModal.close();
      });
   
  }
}
