import {IFilterConfig} from '../models';
import {Subject, Observable} from 'rxjs';

export abstract class DataSource {

  protected onChangedSource = new Subject<any>();
  protected onRemovedSource = new Subject<any>();

  abstract getAll(): Promise<any>;

  abstract getElements(): Promise<any>;

  abstract getSort(): any;

  abstract getQuickFilter(): any;

  abstract getFilterConfigs(): IFilterConfig[];

  abstract getPaging(): any;

  abstract count(): number;

  refresh(): void {
    this.emitOnChanged('refresh');
  }

  load(data: Array<any>): Promise<any> {
    this.emitOnChanged('load');
    return Promise.resolve();
  }

  onChanged(): Observable<any> {
    return this.onChangedSource.asObservable();
  }

  onRemoved(): Observable<any> {
    return this.onRemovedSource.asObservable();
  }

  remove(element: any): Promise<any> {
    this.emitOnRemoved(element);
    this.emitOnChanged('remove');
    return Promise.resolve();
  }

  empty(): Promise<any> {
    this.emitOnChanged('empty');
    return Promise.resolve();
  }

  setSort(conf: Array<any>, doEmit?: boolean): void {
    if (doEmit) {
      this.emitOnChanged('sort');
    }
  }

  setFilter(filterConfig: IFilterConfig[], doEmit?: boolean): void {
    if (doEmit) {
      this.emitOnChanged('filter');
    }
  }

  setQuickFilter(query: string, doEmit?: boolean): void {
    if (doEmit) {
      this.emitOnChanged('filter');
    }
  }

  addQuickFilter(query: string, doEmit?: boolean): void {
    if (doEmit) {
      this.emitOnChanged('filter');
    }
  }

  addFilter(filterConfig: IFilterConfig, doEmit?: boolean): void {
    if (doEmit) {
      this.emitOnChanged('filter');
    }
  }

  addFilters(filterConfigs: IFilterConfig[], doEmit?: boolean): void {
    if (doEmit) {
      this.emitOnChanged('filter');
    }
  }

  removeFilter(filter: IFilterConfig, doEmit?: boolean): void {
    if (doEmit) {
      this.emitOnChanged('filter');
    }
  }

  removeFilters(filterConfigs: IFilterConfig[], doEmit?: boolean): void {
    if (doEmit) {
      this.emitOnChanged('filter');
    }
  }

  clearFilters(doEmit?: boolean): void {
    if (doEmit) {
      this.emitOnChanged('filter');
    }
  }

  setPaging(page: number, perPage: number, doEmit?: boolean): void {
    if (doEmit) {
      this.emitOnChanged('paging');
    }
  }

  setPage(page: number, doEmit?: boolean): void {
    if (doEmit) {
      this.emitOnChanged('page');
    }
  }

  protected emitOnRemoved(element: any): void {
    this.onRemovedSource.next(element);
  }

  protected emitOnChanged(action: string): void {
    this.getElements().then((elements) => this.onChangedSource.next({
      action: action,
      elements: elements,
      paging: this.getPaging(),
      quickFilter: this.getQuickFilter(),
      filter: this.getFilterConfigs(),
      sort: this.getSort()
    }));
  }
}
