import {Component, OnInit} from '@angular/core';
import {IApiAdminModel} from '../../core/interfaces/api-admin.interface';
import {ApiAdminModel} from '../../core/models/api-admin.model';
import {ActivatedRoute, Router} from '@angular/router';
import {LocalDataSource} from '../../piquant-table/data/local-datasource';
import {ITableSettings} from '../../piquant-table/models';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {EmailStatus} from '../../core/enums/email-status.enum';
import {GridOptions} from "ag-grid-community";
import {AuthService} from '../../auth/auth.service';
import {ApiAdminService} from '../../core/api-admin.service'
import { IOutOfOfficeModel } from 'app/core/interfaces/out-of-office.interface';
import {NgbDateStruct, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import {isValid} from 'date-fns';
import {isDate} from 'lodash';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {OutOfOfficeService} from '../../core/out-of-office.service';

interface NgbDateTimeStruct {
  date: NgbDateStruct;
  time: NgbTimeStruct;
}


@Component({
  selector: 'edit-out-of-office',
  templateUrl: './edit-out-of-office.component.html',
  styleUrls: ['edit-out-of-office.component.css'],
})


export class EditOutOfOfficeComponent implements OnInit {
  public psTableSettings: ITableSettings;
  public form: UntypedFormGroup;
  public disableSave = false;
  constructor(private fb: UntypedFormBuilder, 
    private route: ActivatedRoute,
    private service: OutOfOfficeService,
    private router: Router,
    ) {
  }

  public get formValue(): IOutOfOfficeModel {
    const value = this.form.getRawValue();
    return {
      startDateTime: this.convertToJsDate(value.startDate, value.startTime),
      endDateTime: this.convertToJsDate(value.endDate, value.endTime),
      title: value.title,
      message: value.message,
      id: value.id,
      isOutOfOffice: null
    };
  }

  public onClickSave() {
    this.disableSave = true;
      this.service.saveData(this.formValue).subscribe(() => {
        this.form.markAsUntouched();
        this.form.markAsPristine();
        this.disableSave = true;
        this.router.navigate(['/assessment-requests']);
      },
      (error) => {this.disableSave = false;}
      );
    
  }
  
  public onClickCancel() {
    this.router.navigate(['assessment-requests']);
  }

  public ngOnInit() {
    this.route.data.subscribe((data: any) => {
      const outOfOfficeData = data.outOfOfficeData as IOutOfOfficeModel;
      this.form = this.buildForm(outOfOfficeData);
    });
  }

  public clear() {
    this.form.controls.startDate.setValue(null);
    this.form.controls.startTime.setValue(null);
    this.form.controls.endDate.setValue(null);
    this.form.controls.endTime.setValue(null);
    this.form.markAsDirty();
  }

  private buildForm(outOfOfficeData: IOutOfOfficeModel): UntypedFormGroup {
    const startDateTime = this.convertToDateTimeStruct(outOfOfficeData.startDateTime);
    const endDateTime = this.convertToDateTimeStruct(outOfOfficeData.endDateTime);

    return this.fb.group({
      startDate: [startDateTime ? startDateTime.date : null],
      startTime: [startDateTime ? startDateTime.time : null],
      endDate: [endDateTime ? endDateTime.date : null],
      endTime: [endDateTime ? endDateTime.time : null],
      title: outOfOfficeData.title,
      message: outOfOfficeData.message,
      id: outOfOfficeData.id
    });
  }

  private convertToJsDate(date: NgbDateStruct, time: NgbTimeStruct): Date {
    if (!date) {
      return null;
    }

    const utc = Date.UTC(date.year, date.month - 1, date.day, time ? time.hour : 0, time ? time.minute : 0);
    return new Date(utc);
  }

  private convertToDateTimeStruct(val: Date): NgbDateTimeStruct {
    if (!val) {
      return null;
    }

    const dt: Date = isDate(val) ? val : new Date(val);

    if (isValid(dt)) {
      const date = {
        year: dt.getFullYear(),
        month: dt.getMonth() + 1,
        day: dt.getDate()
      } as NgbDateStruct;

      const time = {
        hour: dt.getHours(),
        minute: dt.getMinutes(),
        second: dt.getSeconds()
      } as NgbTimeStruct;

      return {date: date, time: time} as NgbDateTimeStruct;
    }

    return null;
  }
}
