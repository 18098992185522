import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {
  @Input() public title: string;
  @Input() public body: string;
  @Input() public affirmativeButtonText: string = 'Yes';
  @Input() public negativeButtonText: string = 'No';
  @Output() public onConfirm = new EventEmitter<boolean>();

  constructor(public activeModal: NgbActiveModal) {
  }

  public confirm(shouldExit: boolean) {
    this.activeModal.close();
    this.onConfirm.emit(shouldExit);
  }
}
