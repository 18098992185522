import { Component, OnInit, ViewChild} from '@angular/core';
import {AgRendererComponent} from 'ag-grid-angular';
import { CandidateModel } from '../../core/models/candidate.model';
import {NgbTooltip, NgbPopover} from '@ng-bootstrap/ng-bootstrap';
import { NgbPopoverWindow } from '@ng-bootstrap/ng-bootstrap/popover/popover';
import {AssessmentRequestService} from '../../core/assessment-request.service';
import {AssessmentRequestPermissionsService} from '../assessment-request-permissions.service';
import {AssessmentRequestListItemModel} from '../assessment-request-list-item.model';
import {AssessmentRequestStatus} from '../../core/enums/assessment-request-status.enum';
import {ActivatedRoute, Router} from '@angular/router';
import {ITableAction, ITableSettings, Row} from '../../piquant-table/models';

@Component({
  selector: 'app-view-button-renderer',
  templateUrl: './view-button-renderer.component.html',
  host: {'(document:click)': 'ngbWorkAroundOnClick($event)'},
})


export class ViewButtonRendererComponent implements AgRendererComponent {
  listener: () => void;
  @ViewChild('p') public popover: NgbPopover;

  public showAdditionalServiceIcon: boolean;
  public showUpgradeServiceIcon: boolean;
  public showAdditionalServiceParentIcon: boolean;
  public showAdditionalServicesCompleteParentIcon: boolean;
  public candidateName: string;
  public ngbWorkAroundLock: boolean = false;
  public showConfidentialLock: boolean = false;
  private actionTable: ITableAction;

  constructor(
    private router: Router,
    private assessmentRequestPermissions: AssessmentRequestPermissionsService,
    //private elementRef: ElementRef
  ) { }

  //NGB Popover cannot close itself when focus is lost - this method is here to make an unfortunate workaround possible - Please move to Angular Material?
  ngbWorkAroundOnClick(event) {
    //Trying a gaurd instead... doesn't appear to be faster than the element check.
    if(this.ngbWorkAroundLock){
      this.ngbWorkAroundLock = false;
      return;
    }

    if(this.popover.isOpen)
    {
      //if (!this.elementRef.nativeElement.contains(event.target)) // or some similar check
        this.popover.close();
    }
   }

  public params:any;  
  agInit(params:any):void {
      this.params = params;
      //var eventName = params.rowIndex.toString() + '-showActions'; //+ params.colDef.field;
      //params.api.eventService.addEventListener('onRowSelected', function (params) { this.showActions(params); });

      this.showAdditionalServiceIcon = params.data.parentAssessmentRequestId && !params.data.isUpgradeService && !params.data.hasCompletedAdditionalService;
      this.showUpgradeServiceIcon = params.data.parentAssessmentRequestId && params.data.isUpgradeService && !params.data.hasCompletedAdditionalService;
      this.showAdditionalServiceParentIcon = params.data.hasAnyAdditionalService && !params.data.hasCompletedAdditionalService;
      this.showAdditionalServicesCompleteParentIcon = params.data.hasCompletedAdditionalService;
      this.showConfidentialLock = params.data.isConfidential;
      this.candidateName = params.data.candidateName;
      this.actionTable = this.invokeBuildActions()
  }

  public ngAfterViewInit()
  {
    //this.params.context.componentParent.registerRenderEvent(this.params.rowIndex, this.showActions);
  }
 
  public showActions(show: any)
  {
    // if(show)
    //   this.popover.open();
    // else
    //   this.popover.close();
  }

  //This opens the NGB Popover that displays actions for a row - This should probably be moved to Angular Materials equiv and get rid of the: host: {'(document:click)': 'ngbWorkAroundOnClick($event)'}, IT'S SLOW!
  public open()
  {
        //if(!this.ngbWorkAroundLock)
    {
      this.ngbWorkAroundLock = true;
      this.popover.open();
    }
  }

  public invokeBuildActions(): ITableAction
  {
    return this.params.context.componentParent.buildTableActions();
  }

  public refresh(params:any):boolean
  {
    return true;
  }
}
