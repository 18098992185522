import {Component, Input} from '@angular/core';
import {Cell} from '../models';

@Component({
  selector: 'ps-table-cell',
  template: `
    <table-cell-view-mode [cell]="cell"></table-cell-view-mode>
  `,
})
export class CellComponent {
  @Input() cell: Cell;
}
