
import {fromEvent as observableFromEvent, interval as observableInterval, Observable, Subscription} from 'rxjs';
import {Injectable, OnDestroy} from '@angular/core';
import {debounceTime} from 'rxjs/operators';
@Injectable()
export class SessionAuthService implements OnDestroy {
  private _sessionTimeOut = 45; // minutes
  private _sessionCheck = 2500; // milliseconds
  private _scrollSub: Subscription;
  private _keyPressSub: Subscription;
  private _clickSub: Subscription;
  private _lastInteraction = new Date();

  public sessionTimeoutObservable: Observable<any>;

  constructor() {
    this.initObservables();
  }

  ngOnDestroy(): void {
    this._scrollSub.unsubscribe();
    this._clickSub.unsubscribe();
    this._keyPressSub.unsubscribe();
  }

  private initObservables() {
    this.sessionTimeoutObservable = observableInterval(this._sessionCheck);
    this._scrollSub = observableFromEvent(window, 'scroll').pipe(
      debounceTime(500))
      .subscribe(() => {
      this.setInteractionDate();
    });
    this._keyPressSub = observableFromEvent(document, 'keypress').subscribe(() => {
      this.setInteractionDate();
    });
    this._clickSub = observableFromEvent(document, 'click').subscribe(() => {
      this.setInteractionDate();
    });
  }

  public get hasTimedOut(): boolean {
    const timeOut = new Date(this._lastInteraction);
    timeOut.setMinutes(this._lastInteraction.getMinutes() + this._sessionTimeOut);
    const now = new Date();
    return timeOut < now;
  }

  private setInteractionDate(): void {
    this._lastInteraction = new Date();
  }
}
