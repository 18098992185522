import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';

@Component({
  selector: 'app-select-additional-service-type-dialog',
  templateUrl: './select-additional-service-type-dialog.component.html',
  styleUrls: ['./select-additional-service-type-dialog.component.scss']
})
export class SelectAdditionalServiceTypeDialogComponent implements OnInit {
  @Input() public assessmentRequestId: number;
  @Input() public candidateName: string;

  constructor(public activeModal: NgbActiveModal,
              private router: Router) {
  }

  public ngOnInit() {
    if (!this.assessmentRequestId) {
      throw new Error('assessmentRequestId must be specified');
    }

    if (!this.candidateName) {
      throw new Error('candidateName must be specified');
    }
  }

  // public onSelectionPromotionClick() {
  //   this.router.navigate(['/assessment-requests/additional-service/selection-promotion', this.assessmentRequestId])
  //     .then(() => this.activeModal.close());
  // }

  public onDevelopmentFeedbackClick() {
    this.router.navigate(['/assessment-requests/additional-service/development-feedback', this.assessmentRequestId])
      .then(() => this.activeModal.close());
  }

  public onUpgradeClick() {
    this.router.navigate(['/assessment-requests/additional-service/upgrade-request/', this.assessmentRequestId])
      .then(() => this.activeModal.close());
  }

  public onCancelClick() {
    this.activeModal.dismiss();
  }
}
