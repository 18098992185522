import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Injectable} from '@angular/core';
import {SectionBase} from './base.section';
import {EditAssessmentRequestModel} from '../../edit-assessment-request.model';

@Injectable()
export class LocationSection extends SectionBase {
  constructor(private fb: UntypedFormBuilder) {
    super();
  }

  public get testingLocationControl() {
    return this.formGroup.controls['testingLocation'] as UntypedFormControl;
  }

  public get candidateZipCodeControl() {
    return this.formGroup.controls['candidateZipCode'] as UntypedFormControl;
  }

  public get permissionToContactControl() {
    return this.formGroup.controls['permissionToContact'] as UntypedFormControl;
  }

  public get sendInstructionsNameControl() {
    return this.formGroup.controls['sendInstructionsName'] as UntypedFormControl;
  }

  public get sendInstructionsEmailControl() {
    return this.formGroup.controls['sendInstructionsEmail'] as UntypedFormControl;
  }

  public init({assessmentRequest}: { assessmentRequest: EditAssessmentRequestModel }) {
    super.setAssessmentRequest(assessmentRequest);
    this.formGroup = this.buildFormGroup();

    this.testingLocationControl.setValue(this.assessmentRequest.testingLocation);
    this.candidateZipCodeControl.setValue(this.assessmentRequest.candidateZipCode);
    this.sendInstructionsNameControl.setValue(this.assessmentRequest.sendInstructionsName);
    this.sendInstructionsEmailControl.setValue(this.assessmentRequest.sendInstructionsEmail);
    this.permissionToContactControl.setValue(this.assessmentRequest.permissionToContact);
  }

  public get formValue(): any {
    const values = this.formGroup.value;

    return {
      testingLocation: values.testingLocation,
      candidateZipCode: values.candidateZipCode,
      permissionToContact: values.permissionToContact,
      sendInstructionsName: values.sendInstructionsName,
      sendInstructionsEmail: values.sendInstructionsEmail
    };
  }

  protected buildFormGroup(): UntypedFormGroup {
    return this.fb.group({
      testingLocation: [{value: null}, Validators.required],
      candidateZipCode: [{value: null, disabled: this.assessmentRequest.hasParent}, Validators.maxLength(9)],
      permissionToContact: [{value: null}, Validators.required],
      sendInstructionsName: [{value: null, disabled: this.assessmentRequest.hasParent}, Validators.maxLength(200)],
      sendInstructionsEmail: [{value: null, disabled: this.assessmentRequest.hasParent}, Validators.maxLength(254)]
    });
  }
}
