<div class="select-filter-container">
  <input #filterInput spellcheck="false" autocomplete="off" role="combobox" class="form-control select-input" [(value)]="data"
         (focus)="onFocus($event)" (blur)="onBlur($event)" (keydown)="onKeyDown($event)"
         (keyup)="onKeyUp($event)" (change)="onChange($event)"/>
  <i (click)="clear()" class="sf-input-icon fa fa-close"></i>
  <ps-options [hidden]="listHidden" (selectionChanged)="onSelectionChanged($event)" #selectOptions>
    <ps-option *ngFor="let item of filtered; let i = index" [value]="item" [index]="i">
      {{item.name}}
    </ps-option>
  </ps-options>
</div>
