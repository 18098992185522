import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {AssessmentRequestsModule} from './assessment-requests/assessment-requests.module';
import {SharedModule} from './shared/shared.module';
import {CoreModule} from './core/core.module';
import {AuthModule} from './auth/auth.module';
import {CompetenciesModule} from './competencies/competencies.module';
import {appRoutingProviders, routing} from './app.routes';
import {PositionsModule} from './positions/positions.module';
import {SelfServiceModule} from './self-service/self-service.module';
import {EmailsModule} from './emails/email.module';
import {HelpModule} from './help/help.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ApiAdminModule } from './api-admin/api-admin.module';
import {OutOfOfficeModule} from './out-of-office/out-of-office.module';
import {AuthHttpService} from './auth/auth-http.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    CoreModule,
    SharedModule,
    AuthModule,
    AssessmentRequestsModule,
    PositionsModule,
    CompetenciesModule,
    EmailsModule,
    ApiAdminModule,
    OutOfOfficeModule,
    SelfServiceModule,
    HelpModule,
    routing,
    NgbModule,
    HttpClientModule
  ],
  providers: [appRoutingProviders, {provide: HTTP_INTERCEPTORS, useClass: AuthHttpService, multi: true}],
  bootstrap: [AppComponent]
})
export class AppModule {
}

