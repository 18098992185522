<div class="modal-header">
  <h4 class="modal-title">Missing Resume</h4>
  <span class="close-icon" aria-label="Close" (click)="onNotNow()" style="cursor: pointer;">
    &times;
  </span>
</div>
<div class="modal-body">
  <p>You did not upload a resume for this candidate. Do you have one available to upload?</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="onYes()"><span class="fa fa-cloud-upload"></span> Yes</button>
  <button type="button" class="btn btn-default" (click)="onNo()"><span class="fa fa-ban"></span> No</button>
  <button type="button" class="btn btn-primary" (click)="onNotNow()"><span class="fa fa-envelope"></span> Not Now – I will email a resume to testing@ccoleadership.com later.</button>
</div>
