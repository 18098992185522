import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ICompetencyModel, CompetencySelectListItem} from '../../core/interfaces/competency.interface';
import {CompetencyListService} from './competency-list.service';
import {Subscription} from 'rxjs';
import {DeleteCompetencyDialogComponent} from './delete-competency-dialog.component';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {isNullOrUndefined} from 'util';
import {ConfirmEditDialogComponent} from './confirm-edit-dialog.component';
import {AppService} from '../../core/app.service';

@Component({
  selector: 'competency-list-item',
  styleUrls: ['../../../styles/form.scss'],
  template: `
    <div class="hierarchy-list-item " [id]="competency.competencyId" [ngClass]="{'in-active': !competency.isActive, 'deleted': competency.isDeleted}">
      <div class="hierarchy-item" (click)="competencyClicked()" [ngClass]="{'selected': competency.selected}">
        <!-- JSG: Place Holder for Drag and Drop <span class="fa fa-ellipsis-v drag-handle"></span> -->
        <i class="fa clickable" style="font-size: 18px; width: 12px;"
           *ngIf="competency.competencyType === 'G'"
           [ngClass]="competency.showChildren ? 'fa-caret-down' : 'fa-caret-right'"
           (click)="showChildrenClick($event)">
        </i>        
        <i class="fa" *ngIf="competency.competencyType === 'C'" style="font-size: 18px; width: 12px;">&nbsp;</i>
        <i class="fa fa-cubes" *ngIf="competency.competencyType === 'G'"> </i>
        <i class="fa fa-cube" *ngIf="competency.competencyType === 'C'"></i>        
        <span class="text" *ngIf="!competency.editing">
          {{competency.name}}&nbsp;<i class="fa fa-exclamation-triangle text-warning" *ngIf="competency.isDirty"></i>
        </span>
        <span class="input-group" *ngIf="competency.editing">
          <input type="text" class="form-control" (click)="$event.stopPropagation()" [value]="competency.name" (input)="competency.name = $event.target.value"/>
          <span class="input-group-btn">
            <button class="btn btn-outline-danger" type="button" title="Cancel" (click)="cancelEditClick($event)"><i class="fa fa-close"></i></button>
            <button class="btn btn-outline-success" type="button" title="Save" (click)="confirmEditClick($event)"><i class="fa fa-check"></i></button>
          </span>
        </span>        
        <div class="hierarchy-actions" *ngIf="!competency.editing">
          <i class="clickable fa fa-fw fa-trash-o" *ngIf="canDelete && !competency.isDeleted" (click)="deleteClick($event)" title="Delete"></i>
          <i class="clickable fa fa-fw  fa-pencil" title="Edit" (click)="editClick($event)"></i>
          <i class="clickable fa fa-fw" (click)="toggleActivation($event)"
             [ngClass]="competency.isActive ? 'fa-toggle-on text-success' : 'fa-toggle-off'" title="Activate/Deactivate"></i>
        </div>

      </div>
      <div *ngIf="getChildCompetencies.length && competency.showChildren" class="children">
        <competency-list-item *ngFor="let comp of getChildCompetencies" [competency]="comp"></competency-list-item>
      </div>
    </div>`
})
export class CompetencyListItemComponent implements OnInit, OnDestroy {
  @Input() public competency: CompetencySelectListItem;
  private selectionChangedSub: Subscription;
  private editChangeSub: Subscription;
  private originalName: string;
  private _modal: NgbModalRef;

  constructor(private _listService: CompetencyListService,
              private _modalService: NgbModal,
              private appService: AppService) {  }

  ngOnInit() {
    this.selectionChangedSub = this._listService.onSelectionChanged$.subscribe((comp: ICompetencyModel) => this.listener(comp));
    this.editChangeSub = this._listService.onEditItemChanged$.subscribe((comp: ICompetencyModel) => this.editListener(comp));
    this.originalName = this.competency.name;

    this.appService.onLoggingOut.subscribe(() => {
      if (!this._modal) {
        return;
      }
      this._modal.dismiss('logging out');
    });
  }

  ngOnDestroy() {
    this.selectionChangedSub.unsubscribe();
    this.editChangeSub.unsubscribe();
  }

  public competencyClicked() {
    this._listService.itemSelectChange(this.competency);
  }

  public showChildrenClick(evt) {
    if (this.competency.competencyId) {
      this.competency.showChildren = !this.competency.showChildren;
    }
    evt.stopPropagation();
    return false;
  }

  public editClick(event) {
    event.stopPropagation();
    this._modal = this._modalService.open(ConfirmEditDialogComponent);
    this._modal.result.then((r) => {
      this.competency.editing = true;
      this.competency.isDirty = true;
      this.competency.isTouched = true;
      this.touchParents(this.competency);
      this._listService.editItemChange(this.competency);
      this._listService.listChanged(this.competency);
    });
  }

  public cancelEditClick(event) {
    event.stopPropagation();
    this.competency.name = this.originalName;
    this.competency.editing = false;
  }

  public confirmEditClick(event) {
    event.stopPropagation();
    this.originalName = this.competency.name;
    this.competency.isDirty = true;
    this.competency.editing = false;
  }

  public deleteClick(event) {
    event.stopPropagation();
    this._modal = this._modalService.open(DeleteCompetencyDialogComponent);
    this._modal.componentInstance.competency = this.competency;
    this._modal.result.then((r) => {
      this.competency.isDeleted = true;
      this.competency.isTouched = true;
      this.competency.isDirty = true;
      this.touchParents(this.competency);
      this._listService.listChanged(this.competency);
    });
  }

  public toggleActivation(evt) {
    this.competency.isActive = !this.competency.isActive;
    this.competency.isDirty = true;
    this.competency.isTouched = true;
    this._listService.listChanged(this.competency);
    if (this.competency.competencyType === 'G' && !this.competency.isActive) {
      const children = this._listService.competencies.filter((c) => c.parentId === this.competency.competencyId);
      this.setChildrenInactive(children)
    } else {
      if (this.competency.isActive && !isNullOrUndefined(this.competency.parentId)) {
        this.setParentActive(this.competency);
      }
    }
    this.touchParents(this.competency);

    evt.stopPropagation();
    return false;
  }
  
  public get canDelete() {
    if (this.competency.competencyType === 'C') {
      return this.competency.assignedAssessments === 0
        && this.competency.assignedPositions === 0;
    } else {
      return (this.competency.assignedAssessments === 0 && this.competency.assignedPositions === 0) && this.getChildCompetencies.length === 0;
    }
  }

  public get getChildCompetencies() {
    return this._listService.competencies.filter(e => {
      if (this._listService.showInactive) {
        return e.parentId === this.competency.competencyId;
      } else {
        return e.parentId === this.competency.competencyId && (e.isActive || e.isDirty);
      }
    })
  }

  private touchParents(competency: CompetencySelectListItem) {
    const parent = this._listService.competencies.find((e) => e.competencyId === competency.parentId);
    if (!parent || parent.isTouched) {
      return;
    }
    parent.isTouched = true;

    if (!isNullOrUndefined(parent.parentId)) {
      this.touchParents(parent);
    }
  }

  private setParentActive(competency: CompetencySelectListItem) {
    const parent = this._listService.competencies.find((e) => e.competencyId === competency.parentId);
    if (!parent || parent.isActive) {
      return;
    }

    parent.isActive = true;
    parent.isDirty = true;
    parent.isTouched = true;
    if (!isNullOrUndefined(parent.parentId)) {
      this.setParentActive(parent);
    }
  }

  private setChildrenInactive(children: CompetencySelectListItem[]) {
    children.forEach((c) => {
      if (c.isActive) {
        c.isActive = false;
        c.isDirty = true;
        c.isTouched = true;
      }
      if (c.competencyType === 'G') {
        const list = this._listService.competencies.filter((child) => child.parentId === c.competencyId);
        this.setChildrenInactive(list);
      }
    });
  }

  private listener(competency: ICompetencyModel) {
    if (this.competency.competencyId === competency.competencyId) {
      this.competency.selected = !this.competency.selected;
    } else {
      this.competency.selected = false;
    }
  }

  private editListener(competency: ICompetencyModel) {
    if (this.competency.competencyId === competency.competencyId) {
      this.competency.editing = true;
      return;
    }

    if (this.competency.editing) {
      this.competency.editing = false;
      this.competency.name = this.originalName;
    }
  }

}
