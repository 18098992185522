import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AssessmentRequestService} from '../../core/assessment-request.service';
import {ActionCanLoad} from '../../shared/action-bar/models/actionCanLoad';
import {IViewAssessmentRequest} from '../view-assessment-request.interface';
import {NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {AssignConsultantComponent} from '../../shared/assign-consultant/assign-consultant.component';
import {AssignConsultantService} from '../../shared/assign-consultant/assign-consultant.service';
import {AssessmentRequestPermissionsService} from '../assessment-request-permissions.service';
import {AssessmentRequestStatus} from '../../core/enums/assessment-request-status.enum';
import {DeleteAssessmentRequestDialogComponent} from '../delete-assessment-request-dialog/delete-assessment-request-dialog.component';
import {StatusChangeRequestDialogComponent} from '../status-change-assessment-request-dialog/status-change-assessment-request-dialog.component';
import {isValid, format} from 'date-fns';
import {isDate} from 'lodash';
import {SelectAdditionalServiceTypeDialogComponent} from '../additional-services/select-additional-service-type-dialog/select-additional-service-type-dialog.component';
import {IClientSettingsModel} from '../../core/interfaces/client-settings.interface';
import {AppService} from '../../core/app.service';
import {SecureFileDownload} from '../../core/secure-file-download.service';
import { ConfirmationDialogComponent } from 'app/shared/confirmation-dialog/confirmation-dialog.component';
import {AttachmentService} from '../../core/attachment.service';

@Component({
  selector: 'view-assessment-request',
  templateUrl: './view-assessment-request.component.html',
  styleUrls: ['view-assessment-request.component.scss', '../../../styles/form.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [AssessmentRequestService, NgbModal, AssignConsultantService]
})
export class ViewAssessmentRequestComponent implements OnInit {
  public model: IViewAssessmentRequest;
  private _modal: NgbModalRef;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private modalService: NgbModal,
              public assessmentRequestPermissions: AssessmentRequestPermissionsService,
              private fileDownloadService: SecureFileDownload,
              private appService: AppService,
              private assessmentRequestService: AssessmentRequestService,
              private AttachmentService: AttachmentService) {
  }

  public ngOnInit() {
    this.route.data.subscribe((data: any) => {
      this.model = data.assessmentRequest as IViewAssessmentRequest;
    });
    this.appService.onLoggingOut.subscribe(() => {
      if (!this._modal) {
        return;
      }
      this._modal.dismiss('logging out');
    });
  }

  public get settings(): IClientSettingsModel {
    return this.model.clientSettings;
  }

  public backToList() {
    this.router.navigate(['assessment-requests']);
  }

  public assignClick() {
    const options: NgbModalOptions = {backdrop: 'static', keyboard: false};
    this._modal = this.modalService.open(AssignConsultantComponent, options);
    this._modal.componentInstance.assessmentRequestInfo = {id: this.model.assessmentRequestId, name: this.model.candidateDisplayName};
  }

  public additionalServices() {
    this.showCreateAdditionalServiceDialog();
  }

  public newAssessment() {
    this.router.navigate(['assessment-requests', 'add']);
  }

  public editAssessment() {
    this.router.navigate(['/assessment-requests/edit', this.model.assessmentRequestId]);
  }

  public copyAssessment() {
    alert('Copy Assessment Not Implemented');
  }

  public deleteAssessment() {
    this.showDeleteConfirmationDialog();
  }

  public uploadReports() {
    this.router.navigate(['/assessment-requests/upload-reports', this.model.assessmentRequestId]);
  }

  public downloadReports() {
    this.router.navigate(['/assessment-requests/download-reports', this.model.assessmentRequestId]);
  }

  // public uploadResume() {
  //   alert('Upload Resume Clicked');
  // }

  // public uploadJobDesc() {
  //   alert('Upload Job Description Clicked');
  // }

  public downloadRequest() {
    this.fileDownloadService.DownloadAssessmentRequest(this.model.assessmentRequestId)
      .subscribe((data) => this.handleDownload(data));
  }

  public get allowEdit(): boolean {
    if (!this.model) {
      return false;
    }

    const status = this.model.statusId as AssessmentRequestStatus;
    return this.assessmentRequestPermissions.allowEdit(status);
  }

  public get allowAttachmentPurge(): boolean {
    return this.assessmentRequestPermissions.allowAttachmentPurge();
  }

  public get allowStatusChange(): boolean {
    if (!this.model) {
      return false;
    }

    const status = this.model.statusId as AssessmentRequestStatus;
    if(status === AssessmentRequestStatus.Complete || status === AssessmentRequestStatus.Deleted)
      return this.assessmentRequestPermissions.allowEdit(status);
    else
      return false;
  }

  public purgeAttachments(){
    this._modal = this.modalService.open(ConfirmationDialogComponent);

    //Confirm Dialog.
    const instance = this._modal.componentInstance as ConfirmationDialogComponent;
    instance.title = 'Delete Reports and Resumes';
    instance.body = `Are you sure that you would like permanantly remove the uploaded reports and Resume from this record?`;

    instance.onConfirm.subscribe((isConfirmed) => {
      if (isConfirmed) {
           //purge-attachments
           this.assessmentRequestService.purgeAttachments(this.model.assessmentRequestId).subscribe(x => { this.router.navigate(['assessment-requests']); });
      }
    });

  }
  
  public disableDownloadReports(){
    return !this.assessmentRequestPermissions.allowDownloadReports(this.model.statusId as AssessmentRequestStatus, this.model.reportsUploaded > 0);
  }

  public disableDownloadAssessementRequest(){
    return !this.assessmentRequestPermissions.allowDownloadAssessmentRequest(this.model.statusId as AssessmentRequestStatus, true);
  }

  public disableDownloadResume(){
    return !this.assessmentRequestPermissions.allowDownloadAssessmentRequest(this.model.statusId as AssessmentRequestStatus, true) || (this.model.candidateNoResume === true || this.model.resumeAttachmentId === null || this.model.resumeAttachmentId === 0);
  }

  public disableDownloadDescription(){
    return (this.model.noJobDescription === true || this.model.jobDescriptionAttachmentId === null || this.model.jobDescriptionAttachmentId === 0);
  }

  public get allowCopy(): boolean {
    if (!this.model) {
      return false;
    }

    const status = this.model.statusId as AssessmentRequestStatus;
    return this.assessmentRequestPermissions.allowEdit(status);
  }

  public get allowDelete(): boolean {
    if (!this.model) {
      return false;
    }

    const status = this.model.statusId as AssessmentRequestStatus;
    return this.assessmentRequestPermissions.allowEdit(status);
  }

  public get allowAdditionalServices(): boolean {
    if (!this.model) {
      return false;
    }

    const status = this.model.statusId as AssessmentRequestStatus;
    return this.assessmentRequestPermissions.allowAdditionalServices(status, this.model.parentAssessmentRequestId);
  }

  public get allowUploadReports(): boolean {
    if (!this.model) {
      return false;
    }

    const status = this.model.statusId as AssessmentRequestStatus;
    return this.assessmentRequestPermissions.allowUploadReports(status);
  }

  public get allowAssignConsultant(): boolean {
    if (!this.model) {
      return false;
    }

    const status = this.model.statusId as AssessmentRequestStatus;
    return this.assessmentRequestPermissions.allowAssignConsultant(status);
  }

  public get allowDownloadReports(): boolean {
    if (!this.model) {
      return false;
    }
    const status = this.model.statusId as AssessmentRequestStatus;
    return this.assessmentRequestPermissions.allowDownloadReports(status, Number(this.model.numberOfDirectReports) > 0);
  }

  public get allowDownloadAssessmentRequest(): boolean {
    if (!this.model) {
      return false;
    }
    const status = this.model.statusId as AssessmentRequestStatus;
    return this.assessmentRequestPermissions.allowDownloadAssessmentRequest(status);
  }

  public get allowDownloadResume(): boolean {
    if (!this.model) {
      return false;
    }
    return this.assessmentRequestPermissions.allowDownloadResume();
  }

  public get allowDownloadJobDescription(): boolean {
    if (!this.model) {
      return false;
    }
    return this.assessmentRequestPermissions.allowDownloadJobDescription();
  }


  public get showTestingSchedule(): boolean {
    const status = this.model.statusId as AssessmentRequestStatus;

    if (status === AssessmentRequestStatus.InProcess || status === AssessmentRequestStatus.Complete) {
      return true;
    }

    if (this.model.testingDate || this.model.interviewDate || this.model.feedbackDate) {
      return true;
    }

    return false;
  }

  public formatDate(val: Date): string {
    if (val) {
      const dt = isDate(val) ? val : new Date(val);

      if (isValid(dt)) {
        return format(dt, 'MMMM d, yyyy h:mm a');
      }
    }

    return null;
  }

  private handleDownload(data: Blob) {
    const url = window.URL.createObjectURL(data);
    window.open(url);
  }

  private showDeleteConfirmationDialog() {
    this._modal = this.modalService.open(DeleteAssessmentRequestDialogComponent);

    const instance = this._modal.componentInstance as DeleteAssessmentRequestDialogComponent;
    instance.assessmentRequestId = this.model.assessmentRequestId;
    instance.candidateName = this.model.candidateDisplayName;

    instance.onDelete.subscribe(() => {
      this.model.statusId = AssessmentRequestStatus.Deleted;
    });
  }

  private showCreateAdditionalServiceDialog() {
    this._modal = this.modalService.open(SelectAdditionalServiceTypeDialogComponent);

    const instance = this._modal.componentInstance as SelectAdditionalServiceTypeDialogComponent;
    instance.assessmentRequestId = this.model.assessmentRequestId;
    instance.candidateName = this.model.candidateDisplayName;
  }

  private showStatusChangeDialog() {
    this._modal = this.modalService.open(StatusChangeRequestDialogComponent);

    const instance = this._modal.componentInstance as StatusChangeRequestDialogComponent;
    instance.assessmentRequestId = this.model.assessmentRequestId;
    instance.candidateName = this.model.candidateDisplayName;
  }
}



export class ActionGuard implements ActionCanLoad {
  canLoad(): boolean {
    return true;
  }
}

