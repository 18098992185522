import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {HierarchyNode} from '../core/hierarchynode/ps-hierarchy-node';

@Injectable()
export class HierarchyManagerService {
  public selectItemSource = new Subject<HierarchyNode>();
  public onSelectionChanged$ = this.selectItemSource.asObservable();
  public multiSelect = false;

  itemSelectChange(node: HierarchyNode) {
    this.selectItemSource.next(node);
  }
}
