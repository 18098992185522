
import {throwError as observableThrowError, Observable, Subscription} from 'rxjs';

import {finalize, tap, map, catchError} from 'rxjs/operators';
import {Injectable, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {ServiceBase} from '../core/service-base';
import {IAuthResponse} from './jwt-helper';
import {AuthConfig} from './auth-config';
import {LoadingSpinnerService} from '../shared/loading-spinner/loading-spinner.service';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Location } from '@angular/common';


import {IUserAuthModel} from '../core/interfaces/user.interface';
import {AccessTokenDecoder} from './access-token-decoder';
import {SessionAuthService} from '../core/sessionAuthService';
import {AppService} from '../core/app.service';
import {ChangePasswordTokenRequest, ChangePasswordTokenResponse} from '../core/models/change-password-token.models';
import { log } from 'console';

@Injectable()
export class AuthService extends ServiceBase implements OnDestroy {

  private static SessionStorageName = 'token';
  private _accessToken: string;
  private _loggedInUser: AccessTokenDecoder;
  private _session: Subscription;
  public impersonatedUser: IUserAuthModel;
  constructor(private router: Router,
              private http: HttpClient,
              private session: SessionAuthService,
              private loadingBarService: LoadingSpinnerService,
              private appService: AppService,
              private location: Location) {
    super();
    if (this.accessToken) {
      this.startSessionTimeout();
    }
  }

  public ngOnDestroy(): void {
  }

  public login(username: string, password: string, deviceGuid: string): Observable<IAuthResponse> {
    this.loadingBarService.start();

    this.clearState();
    username = username.trim();
    const body = new URLSearchParams();
    body.set('username', username);
    body.set('password', password);
    body.set('deviceGuid', deviceGuid);
    let params = new HttpParams();
       params = params.append('username', username);
       params = params.append('password', password);
       params = params.append('deviceGuid', deviceGuid);
    return this.http.post<IAuthResponse>(AuthConfig.TokenApiUrl, params).pipe(
      tap((authResponse: IAuthResponse) => {
        this.processAuthResponse(authResponse);
      }),
      catchError((res: Response) => {
        console.error(res);
        this.logout(true);
        return observableThrowError(res);
      }),
      finalize(() => this.loadingBarService.complete()),);
  }

  public logout(retainPath = false) {
    this.clearState();
    if(retainPath){
      const splitPath = this.location.path().split('returnUrl=');
      const urlResult = decodeURIComponent(splitPath[splitPath.length - 1]);
      this.router.navigate([AuthConfig.LoginRouteUrl], { queryParams: {returnUrl: urlResult}});
    } else {
      this.router.navigate([AuthConfig.LoginRouteUrl]);
    }
  }

  public refresh(): Observable<IAuthResponse> {
    var headers = new HttpHeaders({'Authorization': `Bearer ${this.accessToken}`})
    return this.http.post<IAuthResponse>(AuthConfig.TokenRefreshApiUrl, null,  {headers: headers}).pipe(
      tap((authResponse: IAuthResponse) => {
        this.processAuthResponse(authResponse);
      }),
      catchError((res: Response) => {
        console.error(res);
        this.logout(true);
        return observableThrowError(res);
      }),);
  }

  public requestForgotPasswordToken(username: string) {
    this.loadingBarService.start();
    username = username.trim();

    return this.http.post(AuthConfig.ForgotPasswordApiUrl, {'username' : username}).pipe(
      catchError((res: Response) => {
        console.error(res);
        return observableThrowError(res);
      }),
      finalize(() => this.loadingBarService.complete()),);
  }

  public requestMutliFactorToken(username: string, password:string) {
    this.loadingBarService.start();
    username = username.trim();
    
    return this.http.post(AuthConfig.MutliFactorAuthRequestUrl, {'username': username, 'password': password}).pipe(
      catchError((res: Response) => {
        console.error(res);
        return observableThrowError(res);
      }),
      finalize(() => this.loadingBarService.complete()),);
  }

  public verifyMutliFactorToken(username: string, password:string, token:string) {
    this.loadingBarService.start();
    username = username.trim();
    
    return this.http.post(AuthConfig.MutliFactorAuthVerifyUrl, {'username': username, 'password': password, 'token': token}).pipe(
      catchError((res: Response) => {
        console.error(res);
        return observableThrowError(res);
      }),
      finalize(() => this.loadingBarService.complete()),);
  }

  public validateForgotPasswordToken(token: string) {
    this.loadingBarService.start();
    token = token.trim();

    return this.http.post(AuthConfig.ForgotPasswordApiUrl + '/validate-token', {'token' : token}).pipe(
      catchError((res: Response) => {
        console.error(res);
        return observableThrowError(res);
      }),
      finalize(() => this.loadingBarService.complete()),);
  }

  public setPassword(request: ChangePasswordTokenRequest): Observable<ChangePasswordTokenResponse> {
    const url = `${AuthConfig.ForgotPasswordApiUrl}/set-password-token`;
    return this.http.post<ChangePasswordTokenResponse>(url, JSON.stringify(request), {headers: this.jsonHeaders});
  }

  public get accessToken(): string {
    if (!this._accessToken) {
      this.restoreState();
    }

    return this._accessToken;
  }

  public get loggedInUser(): AccessTokenDecoder {
    if (!this._loggedInUser) {
      this.restoreState();
    }

    return this._loggedInUser;
  }

  public get user(): IUserAuthModel {
    if (this.impersonatedUser) {
      return this.impersonatedUser;
    } else {
      const userModel = {
        userId: this._loggedInUser.userId,
        username: this._loggedInUser.username,
        roleId: this._loggedInUser.isRoleAdmin ? 2 : 1,
        roleName: this._loggedInUser.roleName,
        clientId: this._loggedInUser.clientId,
        clientName: this._loggedInUser.clientName,
        levelId: this._loggedInUser.levelId,
        orgUnitIds: this._loggedInUser.orgUnitIds,
        displayName: this._loggedInUser.displayName,
        lastOutcomePollDate: this._loggedInUser.lastOutcomePollDate
      };
      return userModel as IUserAuthModel;
    }
  }

  public get isValidLogin(): boolean {
    return this.accessToken && !this.loggedInUser.isTokenExpired;
  }

  public get shouldRefreshToken(): boolean {
    return this.loggedInUser && this.loggedInUser.shouldRefreshToken;
  }

  public get lastOutcomePollDate(): Date {
    if (this.impersonatedUser) {
      return this.impersonatedUser.lastOutcomePollDate;
    }

    return this.loggedInUser.lastOutcomePollDate;
  }

  public get userId(): number {
    if (this.impersonatedUser) {
      return this.impersonatedUser.userId;
    }

    return this.loggedInUser.userId;
  }

  public get userName(): string {
    if (this.impersonatedUser) {
      return this.impersonatedUser.username;
    }

    return this.loggedInUser.username;
  }

  public get clientId(): number {
    if (this.impersonatedUser) {
      return this.impersonatedUser.clientId;
    }

    return this.loggedInUser.clientId;
  }

  public get clientName(): string {
    if (this.impersonatedUser) {
      return this.impersonatedUser.clientName;
    }

    return this.loggedInUser.clientName;
  }

  public get orgUnitIds(): number[] {
    if (this.impersonatedUser) {
      return this.impersonatedUser.orgUnitIds;
    }

    return this.loggedInUser.orgUnitIds;
  }

  public get userLevel(): number {
    if (this.impersonatedUser) {
      return this.impersonatedUser.levelId;
    }

    return this.loggedInUser.levelId;
  }

  public get roleName(): string {
    if (this.impersonatedUser) {
      return this.impersonatedUser.roleName;
    }

    return this.loggedInUser.roleName;
  }

  public get isRoleAdmin(): boolean {
    return AccessTokenDecoder.isRoleAdmin(this.roleName);
  }

  public get isRoleClient(): boolean {
    return AccessTokenDecoder.isRoleClient(this.roleName);
  }

  private processAuthResponse(response: IAuthResponse) {
    if (!response) {
      throw new Error('Auth response is null or empty');
    }

    if (!response.access_token) {
      throw new Error('Auth response has no access_token value');
    }

    const expiresAt = new Date();
    expiresAt.setSeconds(expiresAt.getSeconds() + response.expires_in);
    const decodedAccessToken = new AccessTokenDecoder(response.access_token);

    // if we get here, we logged in successfully, but we still need to write the token to local storage
    this.saveState(response.access_token);
    this._accessToken = response.access_token;
    this._loggedInUser = decodedAccessToken;
    this.startSessionTimeout();

    if (!this._accessToken || !this._loggedInUser || this.loggedInUser.isTokenExpired) {
      throw new Error('Token is missing or expired');
    }
  }

  private startSessionTimeout(): void {
    if (this._session) {
      return;
    }

    this._session = this.session.sessionTimeoutObservable.subscribe(() => {
      if (this.session.hasTimedOut) {
        this.appService.loggingOut();
        this.logout(false);
        this.appService.loggedOut();
      }
    });
  }

  private saveState(accessToken: string): void {
    if (!accessToken) {
      throw new Error('Invalid argument; accessToken must be specified');
    }

    sessionStorage.setItem(AuthService.SessionStorageName, accessToken);
  }

  private restoreState(): void {
    this._accessToken = null;
    this._loggedInUser = null;

    const accessToken: string = sessionStorage.getItem(AuthService.SessionStorageName);

    if (accessToken) {
      this._loggedInUser = new AccessTokenDecoder(accessToken);
      this._accessToken = accessToken;
    }
  }

  public clearState(): void {
    this._accessToken = null;
    this._loggedInUser = null;
    this.impersonatedUser = null;
    if (this._session) {
      this._session.unsubscribe();
      this._session = null;
    }

    sessionStorage.removeItem(AuthService.SessionStorageName);
  }
}
