<p *ngIf="!showRootNode && (!nodes || nodes.length === 0)">No Organization Units Available</p>
<div class="hierarchy-node-text level-0" *ngIf="showRootNode">
  <ng-container *ngIf="rootNodeSelectable">
  <label class="custom-control custom-checkbox">
    <input type="checkbox" class="custom-control-input" [checked]="rootSelected" [disabled]="rootSelected" (change)="rootClicked()">
    <span class="custom-control-indicator"></span>
    <span class="custom-control-description">
      <i class="fa fa-folder-o"></i>
        {{rootNodeName}}
    </span>
  </label>
  </ng-container>
  <ng-container *ngIf="!rootNodeSelectable">
    <i class="fa fa-folder-o"></i>
    {{rootNodeName}}
  </ng-container>
</div>
<ps-hierarchy-node (nodeClickedOutput)="onNodeClicked($event)" *ngFor="let node of nodes" [class]="'hierarchy-node'" [node]="node" [level]="1" [readOnly]="readOnly" [text]="node.name"></ps-hierarchy-node>





