
import {defer as observableDefer, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ISelectItemModel} from './interfaces/select-item.interface';
import {ServiceBase} from './service-base';
import {AuthHttpService} from '../auth/auth-http.service';
import {IClientModel} from './interfaces/client.interface';
import {IEditClientModel} from './interfaces/edit-client.interface';
import {IClientListItemModel} from '../clients/client-list-item.model';
import {IOrgUnitModel} from './interfaces/org-unit.interface';
import {map, publishReplay, refCount, take} from 'rxjs/operators';


import {IClientSettingsModel} from './interfaces/client-settings.interface';
import {ICompetencyModel} from './interfaces/competency.interface';
import {OrgUnitItem} from './interfaces/org-unit-item.interface';
import {HttpClient} from '@angular/common/http';
import { AttachmentModel } from './models/attachment.model';

@Injectable()
export class ClientService extends ServiceBase {
  private static ApiUrl = 'api/client';
  private data$: Observable<ISelectItemModel[]>;
  private cacheDuration: number = 1000 * 60 * 5;

  constructor(private http: HttpClient) {
    super();
  }

  public getSelectItems(): Observable<ISelectItemModel[]> {
    if (!this.data$) {
      const url = ClientService.ApiUrl + '/list';

      this.data$ = observableDefer(() => this.http.get(url))
        .pipe(
          map((res: Response) => (res || []) as ISelectItemModel[]),
          publishReplay(1, this.cacheDuration),
          refCount(),
          take(1)
        );
    }

    return this.data$;
  }

  public getSelectItemsWithoutImpersonation(): Observable<ISelectItemModel[]> {
    const url = ClientService.ApiUrl + '/list?impersonate=false';
    return this.http.get<ISelectItemModel[]>(url);
  }

  public getClients(): Observable<IClientModel[]> {
    return this.http.get<IClientModel[]>(ClientService.ApiUrl);
  }

  public getClientList(): Observable<IClientListItemModel[]> {
    return this.http.get<IClientListItemModel[]>(ClientService.ApiUrl);
  }

  public checkClientName(clientname: string): Observable<boolean> {
    const url = ClientService.ApiUrl + '/checkname/' + clientname;
    return this.http.get<boolean>(url);
  }


  public getClient(id: number): Observable<IClientModel> {
    const url = ClientService.ApiUrl + '/' + id;
    return this.http.get<IClientModel>(url);
  }

  public getOrgExport(id: number): Observable<AttachmentModel> {
    const url = ClientService.ApiUrl + '/export/' + id;
    return this.http.get<AttachmentModel>(url);
  }

  public getClientForEdit(id: number): Observable<IEditClientModel> {
    const url = `${ClientService.ApiUrl}/${id}`;
    return this.http.get<IEditClientModel>(url);
  }

  public getCompetencies(clientId: number): Observable<ICompetencyModel[]> {
    const url = `${ClientService.ApiUrl}/${clientId}/competencies`;
    return this.http.get<ICompetencyModel[]>(url);
  }

  public getOrgUnits(clientId: number, orgUnitId: number | null): Observable<IOrgUnitModel[]> {
    const url = `${ClientService.ApiUrl}/org-units/${clientId}/${orgUnitId}`;
    return this.http.get<IOrgUnitModel[]>(url);
  }

  public getSettings(id: number): Observable<IClientSettingsModel> {
    const url = `${ClientService.ApiUrl}/settings/${id}`;
    return this.http.get<IClientSettingsModel>(url);
  }

  public clearCache(): void {
    this.data$ = null;
  }
}
