
import {fromEvent as observableFromEvent, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';


@Injectable()
export class SiteUtilityService {
  private _screenWidth: number;
  private _mobileWidth = 767;

  public resizeEvent$: Observable<number>;

  constructor() {
    this._screenWidth = document.documentElement.clientWidth;
    this.resizeEvent$ = observableFromEvent(window, 'resize').pipe(
      map(() => {
      return document.documentElement.clientWidth;
    })
    );
    this._screenWidth = document.documentElement.clientWidth;
    this.resizeEvent$.subscribe(data => {
      this.screenWidth = data;
    });
  }

  public set screenWidth(value: number) {
    this._screenWidth = value;
  }

  public get screenWidth(): number {
    return this._screenWidth;
  }

  public get isMobileWidth(): boolean {
    return this._screenWidth < this._mobileWidth;
  }
}
