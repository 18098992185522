import {AfterViewInit, Component, ContentChildren, Input, QueryList} from '@angular/core';
import {PsSectionComponent} from './ps-section';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'ps-sections-container',
  templateUrl: './ps-sections-container.html'
})
export class PsSectionsContainerComponent implements AfterViewInit {
  @ContentChildren(PsSectionComponent) sections: QueryList<PsSectionComponent>;
  @Input() showNav: boolean;

  constructor(private route: ActivatedRoute) {
  }

  public ngAfterViewInit(): void {
    if (this.showNav) {
      this.route.fragment.subscribe((f) => {
        this.scrollToLocation(f);
      });
    }
  }

  private scrollToLocation(id: string): void {
    const elem = document.querySelector('#' + id);

    if (elem) {
      const steps = 25;
      const scrollOffset = 117;
      const scrollStart = window.scrollY;
      const elemTop = elem.getBoundingClientRect().top;
      const delta = elemTop - scrollOffset;
      const step = (delta / steps);
      const speed = Math.round(Math.abs(delta) / 400);
      let timer = 0;
      let moveY = scrollStart + step;
      for (let i = 0; i < steps; i++) {
        setTimeout(`window.scrollTo(0, ${moveY})`, timer * speed);
        moveY += step;
        timer++;
      }
    }
  }

  public getSections() {
    return this.sections.filter((e) => {
      return !e.hideInNav;
    });
  }
}
