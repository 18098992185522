import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SharedModule} from '../shared/shared.module';
import {EditOutOfOfficeComponent} from './edit-out-of-office/edit-out-of-office.component';
import {AuthGuard} from '../auth/auth.guard';
import {SecureLayoutComponent} from '../shared/secure-layout/secure-layout.component';
import {OutOfOfficeResolver} from './resolvers.service';
import {AdminGuard} from '../core/admin.guard';

const routes: Routes = [
  {
    path: 'out-of-office', component: SecureLayoutComponent, canActivateChild: [AuthGuard, AdminGuard], children: [
      {
        path: '',
        component: EditOutOfOfficeComponent,
        resolve: {
          outOfOfficeData: OutOfOfficeResolver
        }
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule
  ],
  exports: [],
  declarations: [
    EditOutOfOfficeComponent
  ],
  providers: [
    OutOfOfficeResolver
  ]
})

export class OutOfOfficeModule {
}
