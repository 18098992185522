<div class="ch-breadcrumb">
  <span class="ch-breadcrumb-item" (click)="breadCrumbClick()">Root</span>
  <span *ngFor="let item of breadCrumb" class="ch-breadcrumb-item" (click)="breadCrumbClick(item)">&nbsp;/&nbsp;{{item.name}}</span>
</div>
<div *ngIf="visibleNodes && visibleNodes.length" class="select-list">
  <div class="select-list-item clickable" *ngFor="let node of visibleNodes" (click)="nodeClicked(node)">
    <i class="fa" [ngClass]="{'fa-folder-o': node.competencyType.toUpperCase() === 'G', 'fa-cube': node.competencyType.toUpperCase() === 'C'}"></i>&nbsp;
    {{node.name}}
    <span class="item-icon pull-right" *ngIf="node.competencyType.toUpperCase() === 'C'">&nbsp;<i class="fa fa-chevron-right"></i></span>
  </div>
</div>
