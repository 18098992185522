import {Cell} from './cell';
import {DataSet} from '../data/data-set';
import {Column} from './column';

export class Row {
  isSelected = false;
  isInEditing = false;
  cells: Array<Cell> = [];

  constructor(public index: number, protected data: any, protected _dataSet: DataSet) {
    this.process();
  }

  public getCell(column: Column): Cell {
    return this.cells.find(el => el.getColumn() === column);
  }

  public getCells() {
    return this.cells;
  }

  public getData(): any {
    return this.data;
  }

  public getNewData(): any {
    const values = Object.assign({}, this.data);
    this.getCells().forEach((cell) => values[cell.getColumn().id] = cell.newValue);
    return values;
  }

  public setData(data): any {
    this.data = data;
    this.process();
  }

  public process(): void {
    this.cells = [];
    this._dataSet.getVisibleColumns().forEach((column: Column) => {
      const cell = this.createCell(column);
      this.cells.push(cell);
    });
  }

  public createCell(column: Column): Cell {
    const value = typeof this.data[column.id] === 'undefined' ? '' : this.data[column.id];
    return new Cell(value, this, column, this._dataSet);
  }
}
