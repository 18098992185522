import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ClientService} from './client.service';
import {LanguageService} from './language.service';
import {StateService} from './state.service';
import {CountryService} from './country.service';
import {RoleService} from './role.service';
import {UserLevelService} from './user-level.service';
import {EmailService} from './email.service';
import {ApiAdminService} from './api-admin.service';
import {AdditionalServiceReferencesResolver, AssessmentLevelReferencesResolver, AssessmentTestingScheduleResolver, ClientSelectItemsNoImpersonationResolver,
  ClientSelectItemsResolver, CompetenciesResolver, CompetencySetResolver, CompetencySetsResolver, CountrySelectItemsResolver, EmailTemplateSelectItemsResolver,
  LanguageSelectItemsResolver, NewPositionResolver, PositionDescriptionOverridesResolver, PositionFunctionsResolver, PositionLevelResolver, PositionListItemsResolver,
  PositionListResolver, PositionResolver, RoleSelectItemsResolver, StateSelectItemsResolver, UserLevelSelectItemsResolver, UserSelectItemsNoImpersonationResolver, UserSelectItemsResolver} from './resolvers.service';
import {UserService} from './user.service';
import {AttachmentService} from './attachment.service';
import {PositionService} from './position.service';
import {PositionLevelService} from './position-level.service';
import {CompetencyService} from './competency.service';
import {CompetencySetService} from './competency-set.service';
import {PositionFunctionService} from './position-function.service';
import {PositionDescriptionOverrideService} from './position-description-override.service';
import {AssessmentRequestService} from './assessment-request.service';
import {AssessmentLevelReferenceService} from './assessment-level-reference.service';
import {AdditionalServiceReferenceService} from './additional-service-reference.service';
import {FeedbackUserService} from './feedback-user.service';
import {OrgUnitService} from './org-units.service';
import {DateFormatPipe} from './date-format.pipe';
import {AccountService} from './account.service';
import {BillingContactService} from './billing-contact.service';
import {AssessmentRequestProxyService} from './assessment-request-proxy.service';
import {CacheService} from './cache.service';
import {EmailTemplateService} from './email-template.service';
import {EmailResolver, EmailListResolver} from '../emails/resolvers.service';
import {ApiAdminResolver} from '../api-admin/resolvers.service';
import {SecureFileDownload} from './secure-file-download.service';
import {SessionAuthService} from './sessionAuthService';
import {AdminGuard} from './admin.guard';
import {AppService} from './app.service';
import { OutOfOfficeService } from './out-of-office.service';
import { OutOfOfficeResolver } from '../out-of-office/resolvers.service';

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [],
  declarations: [
    DateFormatPipe
  ],
  providers: [
    ClientService,
    OrgUnitService,
    LanguageService,
    CountryService,
    CountrySelectItemsResolver,
    StateService,
    UserService,
    RoleService,
    UserLevelService,
    LanguageSelectItemsResolver,
    ClientSelectItemsResolver,
    ClientSelectItemsNoImpersonationResolver,
    StateSelectItemsResolver,
    RoleSelectItemsResolver,
    UserLevelSelectItemsResolver,
    UserSelectItemsResolver,
    UserSelectItemsNoImpersonationResolver,
    AttachmentService,
    EmailService,
    ApiAdminService,
    ApiAdminResolver,
    OutOfOfficeService,
    OutOfOfficeResolver,
    EmailResolver,
    EmailListResolver,
    PositionService,
    PositionLevelService,
    PositionListItemsResolver,
    PositionListResolver,
    PositionLevelResolver,
    CompetencyService,
    CompetencySetService,
    PositionResolver,
    NewPositionResolver,
    CompetencySetsResolver,
    CompetencySetResolver,
    CompetenciesResolver,
    PositionFunctionService,
    PositionFunctionsResolver,
    PositionDescriptionOverrideService,
    PositionDescriptionOverridesResolver,
    AssessmentRequestService,
    AssessmentLevelReferenceService,
    AssessmentLevelReferencesResolver,
    AdditionalServiceReferenceService,
    AdditionalServiceReferencesResolver,
    FeedbackUserService,
    BillingContactService,
    AssessmentRequestProxyService,
    AccountService,
    CacheService,
    AssessmentTestingScheduleResolver,
    EmailTemplateService,
    EmailTemplateSelectItemsResolver,
    SecureFileDownload,
    SessionAuthService,
    AdminGuard,
    AppService
  ]
})
export class CoreModule {
}
