export interface IFilter {
  fieldName: string;
  filterValue: any;
  title: string;
  filterDisplay: string;
}

export class Filter implements IFilter {
   fieldName: string;
   filterValue: any;
   title: string;
   filterDisplay: string;
}
