import {Component} from '@angular/core';

@Component({
    selector: 'forgot-password-sent',
    template: `
      <h1>Check your inbox!</h1>
      <hr />
      <p class="lead">We just sent you an email to reset your Chapman and Co Portal password.</p>`
})
export class ForgotPasswordSentComponent {

}
