import {Injectable} from '@angular/core';
import {IApiAdminModel} from '../core/interfaces/api-admin.interface';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {ApiAdminService} from '../core/api-admin.service';
import {Observable} from 'rxjs';

@Injectable()
export class ApiAdminResolver implements Resolve<IApiAdminModel[]> {
  constructor(private apiAdminService: ApiAdminService) {
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<IApiAdminModel[]> {
    return this.apiAdminService.getApiHistory();
  }
}
