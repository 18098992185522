import {Component, ViewEncapsulation} from '@angular/core';
import {PsTableFilterService} from '../../services/table-filter.service';
import {FilterDisplay} from '../../models';

@Component({
  selector: 'ps-advanced-filters',
  template: `
    <div class="ps-table-advanced-filter">
      <div class="filter-line">
        Available Filters:
        <ng-content select="[ps-table-filter]"></ng-content>
      </div>
      <div class="filter-line">
        Current Filters:
        <span class="filter-display" *ngFor="let display of appliedFilters">
              {{display.text}}&nbsp;<i class="fa fa-close close" (click)="removeFilter(display.id)"></i>
            </span>
      </div>
      <div class="text-right">
        <button type="button" class="btn btn-default" (click)="clearFilters()">Clear Filters</button>
      </div>
    </div>`,
  styleUrls: ['./table-filters.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PsAdvancedFiltersComponent {
  constructor(private _filterService: PsTableFilterService) {
  }

  public clearFilters() {
    this._filterService.clearPendingFilters();
  }

  public removeFilter(filterId: any) {
    this._filterService.removePendingFilter(filterId);
  }

  public get appliedFilters(): FilterDisplay[] {
    const filters = this._filterService.getAppliedFilters();
    return filters.map((f) => {
      const display = new FilterDisplay();
      display.id = f.field;
      display.text = `${f.title}: ${f.searchDisplay}`;
      return display;
    });
  }
}
