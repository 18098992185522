<div class="container" style="height:auto;width:auto;">
  Status Filter: 
  <span class="d-block"><input type="checkbox" id="showAll" [checked]="showAll" (change)="showAll = !showAll; onChange($event)" />Show All</span>
  <span class="d-block"><input type="checkbox" [checked]="notSubmitted" (change)="notSubmitted = !notSubmitted; onChange($event)" />Not Submitted</span>
  <span class="d-block"><input type="checkbox" [checked]="submitted" (change)="submitted = !submitted; onChange($event)" />Submitted</span>
  <span class="d-block"><input type="checkbox" [checked]="inProcess" (change)="inProcess = !inProcess; onChange($event)" />In Process</span>
  <span class="d-block"><input type="checkbox" [checked]="inProcessReportsUploaded" (change)="inProcessReportsUploaded = !inProcessReportsUploaded; onChange($event)" />In Process Reports Uploaded</span>
  <span class="d-block"><input type="checkbox" [checked]="inProcessTestingStarted" (change)="inProcessTestingStarted = !inProcessTestingStarted; onChange($event)" />In Process Testing Started</span>
  <span class="d-block"><input type="checkbox" [checked]="inProcessTestingComplete" (change)="inProcessTestingComplete = !inProcessTestingComplete; onChange($event)" />In Process Testing Complete</span>
  <span class="d-block"><input type="checkbox" [checked]="complete" (change)="complete = !complete; onChange($event)" />Complete</span>
  <span class="d-block"><input type="checkbox" [checked]="canceled" (change)="canceled = !canceled; onChange($event)" />Canceled</span>
  <span class="d-block"><input type="checkbox" id="deleted" [checked]="deleted" (change)="deleted = !deleted; onChange($event)" />Include Deleted</span>

</div>

<!--
  private getDefaultStatusDisplay(): string {
    return this.isRoleClient
      ? 'Not Submitted, Submitted, inProcess, complete'
      : 'Submitted, In Process';
  }

 -->