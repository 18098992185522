<div class="modal-header">
  <h4 class="modal-title">Edit Competencies</h4>
  <span class="close-icon" aria-label="Close" (click)="onClose()" style="cursor: pointer;">
    &times;
  </span>
</div>
<div class="modal-body">
  <assign-competencies
    [availableCompetencies]="clientCompetencies"
    [assignedCompetencies]="competencies"
    (selectedCompetencyChanged)="onSelectedCompetenciesChanged($event)">
  </assign-competencies>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="onClose()">Close</button>
</div>
