import {Component, ComponentRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, ViewEncapsulation} from '@angular/core';
import {Grid, ITableSettings, Row} from './models';
import {DataSource} from './data/data-source';
import {LocalDataSource} from './data/local-datasource';
import {PsTableFilterService} from './services/table-filter.service';
import {FilterConfig} from './models/filter-config';
import {isNullOrUndefined} from 'util';

@Component({
  selector: 'ps-table',
  templateUrl: './piquant-table.component.html',
  styleUrls: ['./piquant-table.scss'],
  providers: [PsTableFilterService],
  encapsulation: ViewEncapsulation.None
})
export class PiquantTableComponent implements OnChanges, OnInit {
  @Input() dataSource: any;
  @Input() settings: any;

  @Output() rowSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() public userRowSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() public delete: EventEmitter<any> = new EventEmitter<any>();
  @Output() public deleteConfirm: EventEmitter<any> = new EventEmitter<any>();

  cmpRef: ComponentRef<any>;

  grid: Grid;
  showAdvancedFilter = false;
  defaultSettings = <ITableSettings>{
    tableId: '',
    tableClass: '',
    title: '',
    showTitle: false,
    selectMode: 'single', // single|multi
    actions: [],
    filter: {
      quickFilter: true,
      advancedFilter: false,
      filters: []
    },
    delete: {
      deleteButtonContent: 'Delete',
      confirmDelete: false
    },
    noDataMessage: 'No data found',
    columns: {},
    pager: {
      display: true,
      perPage: 10
    },
    viewAction: null
  };

  constructor(private _filterService: PsTableFilterService) {

  }

  ngOnInit() {
    this.initSettings();
    if (this.cmpRef) {
      this.cmpRef.destroy();
    }
  }

  ngOnChanges(changes: { [propertyName: string]: SimpleChange }): void {
    if (this.grid) {
      if (changes['settings']) {
        this.initSettings();
        this.grid.setSettings(this.settings);
      }
      if (changes['source']) {
        this.grid.setSource(this.dataSource);
      }
    } else {
      this.initGrid();
    }
  }

  onSelectRow(row: Row): void {
    this.grid.selectRow(row);
    this._onSelectRow(row.getData());
  }

  onUserSelectRow(row: Row): void {
    if (this.grid.getSetting('selectMode') !== 'multi') {
      this.grid.selectRow(row);
      this._onUserSelectRow(row.getData());
      this.onSelectRow(row);
    }
  }

  toggleAdvancedFilter() {
    this.showAdvancedFilter = !this.showAdvancedFilter;
  }

  changePage($event) {

  }

  sort($event) {

  }

  filter($event) {

  }

  private _onSelectRow(data: any) {
    this.rowSelect.emit({
      data: data || null,
      source: this.dataSource,
    });
  }

  private _onUserSelectRow(data: any, selected: Array<any> = []) {
    this.userRowSelect.emit({
      data: data || null,
      source: this.dataSource,
      selected: selected.length ? selected : this.grid.getSelectedRows(),
    });
  }

  private initSettings() {
    if (this.settings === null || this.settings === undefined) {
      this.settings = this.defaultSettings;
    }
  }

  private initGrid() {
    this.initSettings();
    this.dataSource = this.prepareSource();
    this.grid = new Grid(this.dataSource, this.settings);
    this._filterService.dataSource = this.dataSource;
    this._filterService.columns = this.grid.getColumns();

    if (this.settings.filter && this.settings.filter.filters) {
      for (const filter of this.settings.filter.filters) {
        const config = new FilterConfig();
        config.title = filter.title;
        config.field = filter.fieldName;
        config.search = filter.filterValue;
        config.searchDisplay = isNullOrUndefined(filter.filterDisplay) ? filter.filterValue.join(',') : filter.filterDisplay;
        config.filter = this.grid.getColumn(filter.fieldName).getFilterFunction();
        this._filterService.addPendingFilter(config.field, config);
      }
      this._filterService.applyPendingFilters();
    }
  }

  private prepareSource(): DataSource {
    if (this.dataSource instanceof DataSource) {
      return this.dataSource;
    } else if (this.dataSource instanceof Array) {
      return new LocalDataSource(this.dataSource);
    }

    return new LocalDataSource();
  }
}
