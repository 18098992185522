<action-bar>
  <action-button [className]="'action-default'" [iconClass]="'fa fa-chevron-left'" (actionClick)="onClose()">Back to List</action-button>
  <action-button [className]="'action-secondary'" [iconClass]="'fa fa-pencil'" (actionClick)="gotoEdit()">Edit</action-button>
  <div *ngIf="position.isConfidential" style="margin-right: 10px; cursor: initial;" class="action-object green-background"><span style="padding-right:8px;" class="fa fa-lock"></span>{{position.isConfidential?'Confidential':'Non-Confidential'}}</div>
</action-bar>
<ps-sections-container [showNav]="false">
  <ps-section [sectionTitle]="'Position'">
    <div class="form-group row">
      <div class="col">
        <label class="form-control-label">Job Description</label>
        <div class="form-control-static">{{position.description}}</div>
      </div>
      <div class="col">
        <label class="form-control-label">Job Description File Name</label>
        <div class="form-control-static">{{position.jobDescriptionFileName}}</div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col">
        <label class="form-control-label">Function</label>
        <div class="form-control-static">{{position.name}}</div>
      </div>
      <div class="col">
        <label class="form-control-label">Level</label>
        <div class="form-control-static">{{position.positionLevelName}}</div>
      </div>
    </div>
  </ps-section>
  <ps-section [sectionTitle]="'Competencies'">
    <div class="form-group row">
      <div class="col">
        <competency-list *ngIf="position.competencies" [columns]="2" [competencies]="position.competencies"></competency-list>
      </div>
    </div>
    <div class="form-group row">
      <div class="col">
        <label class="form-control-label">Custom Competency</label>
        <div *ngIf="position.customCompetency" class="form-control-static">{{position.customCompetency}}</div>
        <div *ngIf="!position.customCompetency" class="form-control-static">None Provided</div>
      </div>
    </div>
  </ps-section>
</ps-sections-container>
