import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {IPositionLevelModel} from '../../core/interfaces/position-level.interface';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {IEditPositionModel} from '../../core/interfaces/edit-position.interface';
import {IPositionFunctionModel} from '../../core/interfaces/position-function.interface';
import {IPositionDescriptionOverrideModel} from '../../core/interfaces/position-description-override.interface';
import {FormBuilder, FormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {PositionService} from '../../core/position.service';
import {AttachmentService} from '../../core/attachment.service';
import {ICompetencyModel} from '../../core/interfaces/competency.interface';
import {Observable} from 'rxjs';
import {EditPositionModel} from '../../core/models/edit-position.model';

import {PsValidators} from '../../core/validators/email.validator';
import {EditPositionBaseComponent} from '../edit-position-base/edit-position-base.component';
import {ISelectItemModel} from '../../core/interfaces/select-item.interface';

@Component({
  selector: 'app-edit-position-modal',
  templateUrl: './edit-position-modal.component.html',
  styleUrls: ['./edit-position-modal.component.css']
})
export class EditPositionModalComponent implements OnInit {
  @ViewChild(EditPositionBaseComponent) public child;
  @Input() public title: string;
  @Input() public position: IEditPositionModel;
  @Input() public positionLevels: IPositionLevelModel[];
  @Input() public positionFunctions: IPositionFunctionModel[];
  @Input() public positionDescriptionOverrides: IPositionDescriptionOverrideModel[];
  @Input() public userOrgUnits: ISelectItemModel[];
  @Input() public readOnlyPosition: boolean;
  @Input() public confidential: boolean;
  @Input() public copy: boolean;
  @Output() public onPositionSaved = new EventEmitter<EditPositionModel>();
  @Output() public onCanceled = new EventEmitter<boolean>();

  public form: UntypedFormGroup;
  public autoUpdateDescription = false;

  private static sort(a: string, b: string): number {
    const upperA = a.toUpperCase();
    const upperB = b.toUpperCase();

    if (upperA < upperB) {
      return -1;
    }

    if (upperA > upperB) {
      return 1;
    }

    return 0;
  }

  constructor(public activeModal: NgbActiveModal) {  }

  public ngOnInit() {
    this.autoUpdateDescription = !this.position.description;
    this.positionDescriptionOverrides = this.preparePositionDescriptionOverrides(this.positionDescriptionOverrides);
  }

  public get canSave(): boolean {
    return this.child ? this.child.canSave : false;
  }

  public onSave() {
    if (this.child.canSave) {
      this.child.save().subscribe((result) => {
        if (result) {
          this.onPositionSaved.emit(result);
          this.activeModal.close();
        } else {
          alert('Save failed');
        }
      });
    }
  }

  public onCancel() {
    this.onCanceled.emit(true);
    this.activeModal.dismiss();
  }

  private preparePositionDescriptionOverrides(positionDescriptionOverrides: IPositionDescriptionOverrideModel[]): IPositionDescriptionOverrideModel[] {
    if (!positionDescriptionOverrides) {
      return positionDescriptionOverrides;
    }

    return positionDescriptionOverrides.map((pdo: IPositionDescriptionOverrideModel) => {
      const newPdo = {
        level: pdo.level ? pdo.level.trim().toUpperCase() : '',
        'function': pdo.function ? pdo.function.trim().toUpperCase() : '',
        description: pdo.description ? pdo.description.trim() : ''
      };

      return Object.assign({}, pdo, newPdo) as IPositionDescriptionOverrideModel;
    });
  }
}
