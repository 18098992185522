<div class="container" style="height:auto;width:auto;">
  Status Filter: 
  <span class="d-block"><input type="checkbox" id="showAll" [checked]="showAll" (change)="showAll = !showAll; onChange($event)" />Show All</span>
  <span class="d-block"><input type="checkbox" [checked]="unknown" (change)="unknown = !unknown; onChange($event)" />Unknown</span>
  <span class="d-block"><input type="checkbox" [checked]="queued" (change)="queued = !queued; onChange($event)" />Queued</span>
  <span class="d-block"><input type="checkbox" [checked]="sent" (change)="sent = !sent; onChange($event)" />Sent</span>
  <span class="d-block"><input type="checkbox" [checked]="resent" (change)="resent = !resent; onChange($event)" />Resent</span>
  <span class="d-block"><input type="checkbox" id="failed" [checked]="failed" (change)="failed = !failed; onChange($event)" />Failed</span>
</div>

<!--
  private getDefaultStatusDisplay(): string {
    return this.isRoleClient
      ? 'Not Submitted, Submitted, inProcess, complete'
      : 'Submitted, In Process';
  }

 -->