import {Component} from '@angular/core';
import {FilterBaseComponent} from './filter-base.component';
import {PsTableFilterService} from '../../services/table-filter.service';

@Component({
  selector: 'ps-text-filter',
  templateUrl: './text-filter.component.html'

})
export class PsTextFilterComponent extends FilterBaseComponent {
  public filterText: string;

  constructor(filterConfig: PsTableFilterService) {
    super(filterConfig);
  }

  protected clearPending(): void {
    this.filterText = null;
  }

  protected setFilterQuery(): void {
    if (this.hasText) {
      this.filterConfig.search = this.filterText;
      this.filterConfig.searchDisplay = this.filterText;
    } else {
      this._filterService.removePendingFilter(this.columnName);
    }
    this.registerFilter();
  }

  private get hasText() {
    return this.filterText && this.filterText.length > 0;
  }
}
