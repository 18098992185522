import {AbstractControl, AsyncValidatorFn, ValidatorFn} from '@angular/forms';
import {isNullOrUndefined} from 'util';
import {UserService} from '../user.service';

import {Observable} from 'rxjs';
import {map, debounceTime} from 'rxjs/operators';
import {PositionService} from '../position.service';

export module PsValidators {

  export function email(patternOverride: string = null): ValidatorFn {
    let regEx = /^[a-zA-Z0-9!#$%&‘'*+\/=?^_`{|}~.-]+@[a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?(\.[a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?)+$/i;

    return (control: AbstractControl): { [key: string]: any } => {
      const val = control.value;
      if (!isNullOrUndefined(patternOverride)) {
        regEx = new RegExp(patternOverride);
      }
      const match = regEx.test(val);
      return !match ? {'email': {val}} : null;
    };
  }

  export function usernameExists(userService: UserService): AsyncValidatorFn {
    return (c: AbstractControl): Observable<{ [key: string]: any }> => {
      return userService.checkUserName(c.value).pipe(
        debounceTime(400),
        map(res => {
        if (res['exists'] === true) {
          return {'userNameExists': true}
        } else {
          return null;
        }
      })
    );
    }
  }

  export function positionNameExists(positionService: PositionService, clientId: number): AsyncValidatorFn {
    return (c: AbstractControl): Observable<{[key: string]: any}> => {
      return positionService.checkPositionNameExists(c.value, clientId).pipe(
        map(res => {
        if (res === true) {
          return {'nameExists': true}
        } else {
          return null;
        }
      })
    );
    }
  }
}
