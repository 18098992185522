import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {UserService} from '../../core/user.service';
import {ISelectItemModel} from '../../core/interfaces/select-item.interface';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AssessmentRequestService} from '../../core/assessment-request.service';
import {PsListItem} from '../enhanced-controls/enhanced-controls.module';
import {IAssessmentRequestInfo} from './assessment-request-info.interface';

@Component({
  selector: 'assign-consultant',
  template: `
    <div class="modal-header">
      <h5 class="modal-title">Assign Consultant To Request For: {{assessmentRequestInfo.name}}</h5>
        <span class="close-icon" aria-label="Close" (click)="cancel()" style="cursor: pointer;">
          &times;
        </span>
    </div>
    <div class="modal-body">
      <div *ngIf="errorMessage" class="alert alert-danger">
        {{errorMessage}}
        <p>
          <small>Code: {{errorCode}}</small>
        </p>
      </div>
      <form (submit)="save()" [formGroup]="assignGroup" novalidate>
        <div class="form-group">
          <ps-autocomplete-select formControlName="consultant" [selectList]="consultants"
                                  [placeHolderText]="'Consultant'">
          </ps-autocomplete-select>
        </div>
        <br>
        <div class="form-group text-right">
          <button type="button" (click)="cancel()" class="btn btn-cancel">Cancel</button>
          <button type="submit" class="btn btn-primary" [disabled]="!assignGroup.valid">Save</button>
          <button type="button" (click)="remove()" class="btn btn-secondary">Remove Consultant</button>
        </div>
      </form>
    </div>`,
  providers: [UserService, AssessmentRequestService]

})
export class AssignConsultantComponent implements OnInit {
  @Input() assessmentRequestInfo: IAssessmentRequestInfo;
  @Output() assigned: EventEmitter<ISelectItemModel> = new EventEmitter<ISelectItemModel>();
  @Output() cancelled: EventEmitter<void> = new EventEmitter<void>();

  assignGroup: UntypedFormGroup;
  errorMessage: string = null;
  errorCode: number;
  consultants: PsListItem[];
  assignedConsultantName: string;

  constructor(private service: UserService,
              private activeModal: NgbActiveModal,
              private assessmentService: AssessmentRequestService,
              private builder: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.service.getConsultantSelectItems().subscribe((data: PsListItem[]) => {
      this.consultants = data;
      this.consultants.forEach(obj => {
        if (obj.name === this.assignedConsultantName) {
          this.assignGroup.controls['consultant'].setValue(obj);
        }
      })
    });

    this.assignGroup = this.builder.group({
      'consultant': [null, Validators.required]
    });
    this.assignedConsultantName = this.assessmentRequestInfo.assignedConsultantName;
  }

  save(): void {
    this.errorMessage = null;
    this.errorCode = null;
    const consultant = this.assignGroup.controls['consultant'].value;
    this.assessmentService
      .assignConsultant(this.assessmentRequestInfo.id, consultant.id)
      .subscribe((res) => {
        if (res.status === 200) {
          this.assigned.emit(consultant);
          this.activeModal.close(consultant);
        } else {
          this.errorMessage = 'An error occurred trying to assign consultant.';
          this.errorCode = res.status;
        }
      });
  }

  cancel(): void {
    this.cancelled.emit(null);
    this.activeModal.dismiss('Cancelled');
  }

  remove(): void {
    this.errorMessage = null;
    this.errorCode = null;
    const consultant = this.assignGroup.controls['consultant'].value;
    this.assessmentService
      .removeAssignedConsultant(this.assessmentRequestInfo.id, consultant.id)
      .subscribe((res) => {
        if (res.status === 200) {
          this.assigned.emit(consultant);
          this.activeModal.close(consultant);
        } else {
          this.errorMessage = 'An error occurred trying to remove a consultant.';
          this.errorCode = res.status;
        }
      });
  }
}
