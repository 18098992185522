import {Injectable} from '@angular/core';
import {ServiceBase} from './service-base';
import {AuthHttpService} from '../auth/auth-http.service';
import {AuthConfig} from '../auth/auth-config';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ChangePasswordRequest, ChangePasswordResponse} from './models/change-password.models';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class AccountService extends ServiceBase {
  private static ApiUrl = 'api/account';

  constructor(private http: HttpClient) {
    super();
  }

  public changePassword(request: ChangePasswordRequest): Observable<ChangePasswordResponse> {
    const url = `${AccountService.ApiUrl}/change-password`;
    return this.http.post<ChangePasswordResponse>(url, JSON.stringify(request), { headers: this.jsonHeaders }).pipe(
      map((res) => {
        return res as ChangePasswordResponse;
      })
    );
  }
}
