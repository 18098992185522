import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AssessmentRequestService} from '../../core/assessment-request.service';

@Component({
  selector: 'app-confirm-no-resume-dialog',
  templateUrl: './confirm-no-resume-dialog.component.html',
  styleUrls: ['./confirm-no-resume-dialog.component.scss']
})
export class ConfirmNoResumeDialogComponent implements OnInit {
  @Input() public assessmentRequestId: number;
  @Input() public candidateName: string;
  @Output() public Yes = new EventEmitter<number>();
  @Output() public NotNow = new EventEmitter<number>();
  @Output() public No = new EventEmitter<number>();

  constructor(public activeModal: NgbActiveModal,
              public assessmentRequestService: AssessmentRequestService) {
  }

  public ngOnInit() {
    if (!this.assessmentRequestId) {
      throw new Error('assessmentRequestId must be specified');
    }

    if (!this.candidateName) {
      throw new Error('candidateName must be specified');
    }
  }

  public onYes() {
    if (this.Yes) {
      this.Yes.emit(this.assessmentRequestId);
    }

    this.activeModal.close();
  }

  public onNotNow() {
    if (this.NotNow) {
      this.NotNow.emit(this.assessmentRequestId);
    }

    this.activeModal.close();
  }

  public onNo() {
    if (this.No) {
      this.No.emit(this.assessmentRequestId);
    }

    this.activeModal.close();
  }
}
