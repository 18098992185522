<div class="ps-table-filter" #dd="ngbDropdown" ngbDropdown>
  <a ngbDropdownToggle class="ps-table-filter-title ps-table-filter-opener">
    {{filterTitle}}&nbsp;<i class="fa fa-caret-down"></i>
  </a>
  <div class="dropdown-menu ps-table-filter-dd">
    <label class="form-control-label">Filter:
      <div class="pull-right" (click)="dd.close()"><i class="fa fa-close"></i></div>
    </label>
    <ul (click)="$event.stopPropagation()" class="list-unstyled">
      <li>
        <label class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" name="checkAll"
                 [(ngModel)]="checkAll" (change)="allChanged();" [disabled]="checkAll">
          <span class="custom-control-indicator"></span>
          <span class="custom-control-description">All</span>
        </label>
      </li>
      <li *ngFor="let item of listItems">
        <label class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" [id]="item.id" [name]="item.name"
                 [(ngModel)]="item.selected" (change)="selectItem()">
          <span class="custom-control-indicator"></span>
          <span class="custom-control-description">{{item.name}}</span>
        </label>
      </li>
    </ul>
    <div class="text-right" style="font-size: 12px"><a href="javascript:void(0)" class="text-primary" (click)="applyFilter();dd.close();">Apply</a></div>
  </div>
</div>
