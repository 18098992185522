<action-bar>
  <action-bar-control *ngIf="isAdmin">
    <!-- <ps-autocomplete-select [selectList]="clients" id="client" [(ngModel)]="selectedClient"
                            [placeHolderText]="'Select Client'" (selectionChanged)="onSelectClient($event)">
    </ps-autocomplete-select> -->
    <select [ngModel]="selectedClient" (ngModelChange)="onSelectClient($event)" style="height: 30px;">
      <option *ngFor="let option of clients" [ngValue]="option">{{option.name}}</option>
    </select>
  </action-bar-control>
  <action-button [iconClass]="'fa fa-plus'" [className]="'action-primary'" (actionClick)="onNewClick()" [disabled]="!selectedClient">
    Add Position To Client
  </action-button>
  <action-button (actionClick)="exportDataAsCsv()" [iconClass]="'fa fa-save'" [className]="'action-secondary'">
    Export
  </action-button>
</action-bar>

<div class="row">
  <div class="col table-title">
    <h1>Positions</h1>
  </div>
  <div class="col">
    <div class="pull-right">
      Quick Search:
      <input type="text" [(ngModel)]="inputQuickSearch" id="filter-text-box" placeholder="Type here..." (keyup)="onFilterTextBoxChanged()"/>
      <button (click)="resetFilters()">Reset Filters</button>
    </div>
  </div>
</div>

<div class="row" id="GridWithH1">
  <div class="col" style="Width: 100%;Height:100%;" >
    <ag-grid-angular style="Width: 100%;Height:100%;" class="ag-theme-material" [gridOptions]="gridOptions"></ag-grid-angular>
  </div>
</div>
<div class="gridFooter" >
  <span>Rows per page:</span>
  <select style="display:inline;" [ngModel]="selectedPaginationOption" (change)="onChangePaginationOption($event.target.value)"> <!--[(ngModel)]="selectedPaginationOption"-->
    <option *ngFor="let option of paginationOptions" [value]="option.id">{{option.name}}</option>
  </select>
</div>
<!--
<action-button style="position:absolute;top:150px;left:150px;" [iconClass]="'fa fa-plus'" [className]="'action-primary'"
(actionClick)="onTestClick()">TEst
</action-button>

<div class="row" style="margin-top:1rem;">
  <div class="col">
    <table *ngIf="selectedClientPositions && selectedClientPositions.length > 0" class="ps-table table columns">
      <thead>
      <tr class="ps-table-headers">
        <th class="ps-table-th">Id</th>
        <th class="ps-table-th">Function</th>
        <th class="ps-table-th">Level</th>
        <th class="ps-table-th">Department</th>
        <th class="ps-table-th">Description</th>
        <th class="ps-table-th">Job Description</th>
        <th class="ps-table-th">Action</th>
      </tr>
      </thead>
      <tbody>
      <tr class="ps-table-row" *ngFor="let position of selectedClientPositions">
        <td>{{position.positionId}}</td>
        <td><a [routerLink]="['view', position.positionId]"><strong>{{position.name}}</strong></a></td>
        <td>{{position.positionLevelName}}</td>
        <td>{{position.orgUnitName}}</td>
        <td>{{position.description}}</td>
        <td *ngIf="position.jobDescriptionAttachmentId">
            <a href="api/attachment/{{position.jobDescriptionAttachmentId}}/download" class="btn btn-sm btn-primary"><span class="fa fa-cloud-download"></span> Download Job Description</a>        </td>
        <td *ngIf="!position.jobDescriptionAttachmentId"></td>
        <td>
          <button class="btn btn-sm btn-primary" (click)="onClickEdit(position.positionId)"><span class="fa fa-edit"></span> Edit</button>
        </td>
      </tr>
      </tbody>
    </table>
    <p *ngIf="!selectedClient && (!selectedClientPositions || selectedClientPositions.length === 0)">Select Client To See List</p>
    <p *ngIf="selectedClient && selectedClientPositions.length === 0">There are no positions to show</p>
  </div>
</div>
-->


