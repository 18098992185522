<action-bar>
  <action-button [className]="'action-danger'" [iconClass]="'fa fa-ban'" (actionClick)="onClickCancel()">Cancel</action-button>
  <action-button [className]="'action-primary'" [iconClass]="'fa fa-floppy-o'" (actionClick)="onClickSave()" [disabled]="disableSave">Save</action-button>
</action-bar>
<form [formGroup]="form">
<div class="form-group row">
  <div class="col-sm-4">
    <div style="max-width:300px;">
      <label class="form-control-label font-weight-bold">Start Date/Time</label>
      <ngb-datepicker formControlName="startDate" [firstDayOfWeek]="0"></ngb-datepicker>
      <ngb-timepicker [minuteStep]="15" [meridian]="true" formControlName="startTime"></ngb-timepicker>
    </div>
  </div>
  <div class="col-sm-4">
    <div style="max-width:300px;">
      <label class="form-control-label font-weight-bold">End Date/Time</label>
      <ngb-datepicker formControlName="endDate" [firstDayOfWeek]="0"></ngb-datepicker>
      <ngb-timepicker [minuteStep]="15" [meridian]="true" formControlName="endTime"></ngb-timepicker>
    </div>
  </div>
</div>
<div class="form-group row">
  <div class="col">
    <label class="form-control-label" for="testing-notes">Title</label>
    <textarea class="form-control" id="testing-notes" rows="5" formControlName="title"></textarea>
  </div>
</div>
<div class="form-group row">
  <div class="col">
    <label class="form-control-label" for="testing-notes">Message</label>
    <textarea class="form-control" id="testing-notes" rows="5" formControlName="message"></textarea>
  </div>
</div>
</form>