import { Component, OnInit, Input, Inject } from '@angular/core';
import { Router } from '@angular/router';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {AssessmentRequestListItemModel, ARQuickAdditionalServiceModel} from '../assessment-request-list-item.model';
import {GridOptions, RowNode, Column, SelectionChangedEvent} from "ag-grid-community";
import {NgbTooltip} from '@ng-bootstrap/ng-bootstrap';
import {CheckboxViewRendererComponent} from './checkbox-view-renderer.component';
import {FilterHiringOutcomePollHiddenComponent} from './filter-hiring-outcome-poll-hidden.component';
import {FilterHiringOutcomeComponent} from '../filter-hiring-outcome/filter-hiring-outcome.component';
import {AssessmentRequestService} from '../../core/assessment-request.service';
import {IHiringOutcomeUpdate} from '../../core/interfaces/hiring-outcome-update.interface';
import {AuthService} from '../../auth/auth.service';
import {HiringOutcome} from '../../core/enums/hiring-outcome.enum';
import { Utility } from 'app/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray } from '@angular/forms'
import { PsValidators } from 'app/core/validators/email.validator';

@Component({
  selector: 'app-hired-offer-modal',
  templateUrl: './hired-offer-modal.component.html',
  styleUrls: ['./hiring-offer-modal.component.scss']
})

export class HiredOfferModalComponent  implements OnInit {
  public cancelButtonText: string = "No, Thanks."
  public upgradeRequests: ARQuickAdditionalServiceModel[] = [];
  public form: UntypedFormGroup;
  public candidatesArray: UntypedFormArray;
  //Temp
  public regEx = /^[a-zA-Z0-9!#$%&‘'*+\/=?^_`{|}~.-]+@[a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?(\.[a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?)*$/i;
  public wait = false;

  constructor(public dialogRef: MatDialogRef<HiredOfferModalComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private assessmentRequestService: AssessmentRequestService,
    private router: Router,
    private authService: AuthService,
    private fb: UntypedFormBuilder) {
      this.form = this.buildForm();
      this.candidatesArray = this.form.controls.candidates as UntypedFormArray;
      
  }

  private buildForm(): UntypedFormGroup{
    return this.fb.group({
      candidates: this.fb.array(this.getCandidates().map(x => this.fb.group(x)))
    });
  }

  getCandidates() {
    const controls = [];
    for (let i = 0; i < this.data.toBeOfferedRequests.length; i++) {
      controls.push({ 
        candidateName: [this.data.toBeOfferedRequests[i].candidateName],
        orgUnitName: [this.data.toBeOfferedRequests[i].orgUnitName],
        position: [this.data.toBeOfferedRequests[i].position],
        writtenFeedback: [false], 
        verbalFeedback: [false],
        updatedPhone: [''],
        updatedEmail: [''] 
      });
    }
    return controls;
  }

  ngOnInit() {
    this.upgradeRequests = this.data.toBeOfferedRequests;
  }

  //Dialog close calls this, we handle remindme logic here to catch people who click outside modal to close it.
  public onDialogClosed()
  {

  }

  public getImpersonatedUserId(): string
  {
    return (this.authService.impersonatedUser != null) ? this.authService.impersonatedUser.userId.toString() : this.authService.userId.toString();
  }

  public feedBackChanged(i){
    const array = this.form.controls.candidates as UntypedFormArray;
    const targetFormGroup = array.controls[i] as UntypedFormGroup;
    const validatorsRequired = targetFormGroup.controls.writtenFeedback.value || targetFormGroup.controls.verbalFeedback.value;
    //If one of the checkboxes is true, we want to validate E-Mail
    targetFormGroup.controls.updatedEmail.setValidators(validatorsRequired ?
      Validators.compose([PsValidators.email(), Validators.required, Validators.maxLength(254)]) :
      null
    );
    targetFormGroup.controls.updatedEmail.updateValueAndValidity();
  }

  public submit()
  {
    this.wait = true;
    if(this.form.invalid){
      this.form.controls.candidates.markAllAsTouched();
      return;
    }

    if(this.upgradeRequests.length > 0){
      this.assessmentRequestService.quickAdditionalService(this.getData()).subscribe(() => {
        this.router.navigate(['/assessment-requests/exit-offer-modal']);
        this.dialogRef.close();
        this.wait = false;
      },
        (error) => { console.log('Quick add failed.');
         this.dialogRef.close();
         this.wait = false;
      });
    } else {
      //shouldn't be here.
      this.dialogRef.close();
      this.wait = false;
    }
  }

  private getData() : ARQuickAdditionalServiceModel[] {
    const output: ARQuickAdditionalServiceModel[] = [];
    const array = this.form.controls.candidates as UntypedFormArray;
    for(var i = 0; i < this.upgradeRequests.length; i++){
      //Doing some casting; ArrayGroups are weird.
      const fg = array.controls[i] as UntypedFormGroup;
      //We only want to include entries that were selected.
      if(fg.controls.verbalFeedback.value || fg.controls.writtenFeedback.value){
        this.upgradeRequests[i].position = fg.controls.position.value;
        this.upgradeRequests[i].updatedPhone = fg.controls.updatedPhone.value;
        this.upgradeRequests[i].updatedEMail = fg.controls.updatedEmail.value;
        this.upgradeRequests[i].verbalFeedback = fg.controls.verbalFeedback.value;
        this.upgradeRequests[i].writtenFeedback = fg.controls.writtenFeedback.value;
        output.push(this.upgradeRequests[i]);
      }
    }
    return output;
  }

  public ngAfterViewInit()
  {   
    //Register the DialogClose handler.
    this.dialogRef.afterClosed().subscribe(result => {
      this.onDialogClosed();
    });
  }
}
