import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IClientCompetenciesModel} from '../../core/interfaces/client-competencies.interface';
import {ICompetencyModel} from '../../core/interfaces/competency.interface';
import {ICompetencyNode} from '../competency-node.interface';
import {CompetencyNode} from '../competency-node.model';

@Component({
  selector: 'competency-hierarchy',
  templateUrl: './compentency-hierarchy.component.html',
  styleUrls: ['./compentency-hierarchy.component.scss']
})
export class CompetencyHierarchyComponent implements OnInit {
  @Input() public clientCompetencies: ICompetencyModel[] = null;
  @Input() public competencyIdsToExclude: number[];
  @Output() public onCompetencyClicked = new EventEmitter<ICompetencyModel>();

  private selectedNodes: ICompetencyModel[] = [];
  private competencyNodes: ICompetencyModel[] = this.clientCompetencies;

  public ngOnInit(): void {
    this.competencyNodes = this.clientCompetencies;
  }

  public get breadCrumb(): any[] {
    return this.selectedNodes;
  }

  public get visibleNodes(): ICompetencyModel[] {
    if (this.selectedNodes.length <= 0) {
      return this.getVisibleCompetencyNodes(this.competencyNodes);
    }

    return this.getVisibleCompetencyNodes(this.selectedNodes[this.selectedNodes.length - 1].children);
  }

  public nodeClicked(node: ICompetencyModel): void {
    if (node.competencyType.toUpperCase() === 'G') {
      this.selectedNodes.push(node);
    } else {
      this.onCompetencyClicked.emit(node);
    }
  }

  public breadCrumbClick(node: ICompetencyModel = null): void {
    if (node) {
      const index = this.selectedNodes.indexOf(node);
      const lastIndex = this.selectedNodes.length - 1;
      if (index === lastIndex) {
        return;
      }
      this.selectedNodes = this.selectedNodes.slice(index, lastIndex);
    } else {
      this.selectedNodes = [];
    }
  }

  private getVisibleCompetencyNodes(nodes: ICompetencyModel[]): ICompetencyModel[] {
    const filteredNodes = [];

    for (const node of nodes) {
      if (node.competencyType.toUpperCase() === 'G' || this.competencyIdsToExclude.indexOf(node.competencyId) === -1) {
        filteredNodes.push(node);

        if (node.children && node.children.length) {
          node.children = this.getVisibleCompetencyNodes(node.children);
        }
      }
    }

    return filteredNodes;
  }
}
