import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'form-Invalid-modal',
  template: `
    <div class="modal-header">
      <h5 class="modal-title text-danger">{{title}}</h5>
        <span class="close-icon" aria-label="Close" (click)="onClose()" style="cursor: pointer;">
          &times;
        </span>
    </div>
    <div class="modal-body">
      <p class="text-danger">Please complete all the required fields and check the data for proper format.</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-default" (click)="onClose()">Close</button>
    </div>
  `
})
export class FormInvalidComponent {
  public title = 'Missing or Invalid Data'

  constructor(private activeModal: NgbActiveModal) { }

  public onClose() {
    this.activeModal.dismiss();
  }
}
