import {ValidatorFn, UntypedFormGroup, ValidationErrors} from '@angular/forms';

export const smsRequiresPhoneMobile: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
    const address = control.get('address');
    const mfa = control.get('mfa');
    const phoneMobile = address.get('phonemobile');
    const phonemobilecountrycode = address.get('phonemobilecountrycode');
    const mfaType = mfa.get('mfaType');
    if(mfaType.value == 2){
        const output = phoneMobile.invalid || phonemobilecountrycode.invalid || phonemobilecountrycode.value == '' || phoneMobile.value == '' || !phonemobilecountrycode.value || !phoneMobile.value;
        if(output){
            mfaType.setErrors({ 'smsRequiresPhoneMobile': true })
            mfaType.markAsDirty();
            return { 'smsRequiresPhoneMobile': true }
        } else {
            mfaType.setErrors(null);

        }
    }
    return null
  };