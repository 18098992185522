import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SharedModule} from '../shared/shared.module';
import {ListEmailComponent} from './list-email/list-email.component';
import {ViewEmailComponent} from './view-email/view-email.component';
import {AuthGuard} from '../auth/auth.guard';
import {SecureLayoutComponent} from '../shared/secure-layout/secure-layout.component';
import {EmailListResolver, EmailResolver} from './resolvers.service';
import {AdminGuard} from '../core/admin.guard';
import { EscapeHtmlPipe } from '../core/pipes/keep-html.pipe';
import {AgGridModule} from 'ag-grid-angular';
import {FilterEmailStatusComponent} from './filter-grid-status/filter-grid-status.component';
import {ViewButtonRendererComponent} from './view-button-renderer/view-button-renderer.component';
const routes: Routes = [
  {
    path: 'emails', component: SecureLayoutComponent, canActivateChild: [AuthGuard, AdminGuard], children: [
    {
      path: 'view/:id',
      component: ViewEmailComponent,
      resolve: {
        email: EmailResolver
      }
    },
    {
      path: '',
      component: ListEmailComponent,
      resolve: {
        emails: EmailListResolver
      }
    }
  ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    AgGridModule.withComponents([FilterEmailStatusComponent, ViewButtonRendererComponent])
  ],
  exports: [],
  declarations: [
    ListEmailComponent,
    ViewEmailComponent,
    EscapeHtmlPipe,
    FilterEmailStatusComponent,
    ViewButtonRendererComponent
  ],
  providers: [
    EmailListResolver,
    EmailResolver
  ]
})
export class EmailsModule {
}
