import {Component} from "@angular/core";
import {AgRendererComponent} from 'ag-grid-angular';
import {HiringOutcome} from '../../core/enums/hiring-outcome.enum';

@Component({
    selector: 'app-checkbox-view-renderer',
    template: `
    Hired: <input name="outcomeHired" type="checkbox" [checked]="outcomeHired" (change)="onChange($event)" />
      |   Not Hired: <input name="outcomeNotHired" type="checkbox" [checked]="outcomeNotHired" (change)="onChange($event)" />
      |   Pending: <input name="pending" type="checkbox" [checked]="pending" (change)="onChange($event)" />
    `,
    styles: [
            `
            ::ng-deep
            .mat-checkbox-layout {
                /* horizontally align the checkbox */
                width: 100%;
                display: inline-block !important;
                text-align: center;
                margin-top: -4px; /* to offset the cells internal padding - could be done in cells CSS instead*/

                /* vertically align the checkbox when not using the ag-material theme - should be the same as the 
                rowHeight - cell padding
                   (you can of course alter the cell padding via CSS instead if you'd prefer)
                line-height: 42px;
                 */
            }
        `
    ]
})
export class CheckboxViewRendererComponent implements AgRendererComponent {
    private params: any;

    public outcomeHired: boolean = false;
    public outcomeNotHired: boolean = false;
    public pending: boolean = false;

    agInit(params: any): void {
        this.params = params;
        switch(this.params.data.hiringOutcome){
            case HiringOutcome.Hired:
                this.outcomeHired = true;
                break;
            case HiringOutcome.NotHired:
                this.outcomeNotHired = true;
                break;
            case HiringOutcome.Pending:
                this.pending = true;
                break;
            default:
                break;
        }
    }

    onChange(event) {
        var hiringOutcome: number = 0;
        switch(event.target.name){ 
            case "outcomeHired": { 
                if(event.target.checked){
                    this.outcomeHired = true;
                    this.outcomeNotHired = false;
                    this.pending = false;
                    hiringOutcome = HiringOutcome.Hired;
                }
                else{
                this.outcomeHired = false;
                hiringOutcome = HiringOutcome.NotYetAnswered;
                }
                break; 
            } 
            case "outcomeNotHired": { 
                if(event.target.checked){
                    this.outcomeHired = false;
                    this.outcomeNotHired = true;
                    this.pending = false;
                    hiringOutcome = HiringOutcome.NotHired
                }
                else {
                    this.outcomeNotHired = false;
                    hiringOutcome = HiringOutcome.NotYetAnswered;
                }
                break; 
            } 
            case "pending": {
                if(event.target.checked){
                    this.outcomeHired = false;
                    this.outcomeNotHired = false;
                    this.pending = true;
                    hiringOutcome = HiringOutcome.Pending;
                }
                else{
                    this.pending = false;
                    hiringOutcome = HiringOutcome.NotYetAnswered;
                }
                break; 
            }
            default: { 
                //statements; 
                break; 
            } 
        } 
        this.params.context.componentParent.QueueOutcomeDecisionUpdate(this.params.data.assessmentRequestId, hiringOutcome, this.params.data.hasAnyAdditionalService)
        
    }

    refresh(params: any): boolean {
        return false;
    }
}
