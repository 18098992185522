import {ICompetencySetListItem} from '../interfaces/competency-set.interface';

import {isNullOrUndefined} from 'util';
import {ICompetencySetDetail} from '../interfaces/competency-set-detail.interface';
import {ICompetencyModel} from '../interfaces/competency.interface';

export class CompetencySetListItem implements ICompetencySetListItem {
  public competencySetId?: number;
  public name: string;
  public description: string;
  public isActive: boolean;
  public assignedToClientsCount: number;
  public assignedCompetenciesCount: number;

  constructor(name?: string, description?: string, isActive?: boolean) {
    this.name = name;
    this.description = description;
    this.isActive = isNullOrUndefined(isActive) ? false : isActive;
  }
}

export class CompetencySet {
  public competencySetId?: number;
  public name: string;
  public description: string;
  public isActive: boolean;
  public competencies: ICompetencyModel[];

  constructor() {
    this.isActive = true;
    this.name = '';
    this.description = '';
    this.competencies = [];
  }
}
