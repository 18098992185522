
import {of as observableOf,  Observable } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import { IEditPositionModel } from '../../core/interfaces/edit-position.interface';
import {Form, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import { IPositionLevelModel } from '../../core/interfaces/position-level.interface';
import { ICompetencyModel } from '../../core/interfaces/competency.interface';
import { EditPositionModel } from '../../core/models/edit-position.model';
import { PositionService } from '../../core/position.service';
import { IPositionFunctionModel } from '../../core/interfaces/position-function.interface';
import { IPositionDescriptionOverrideModel } from '../../core/interfaces/position-description-override.interface';
import {NgbTooltip} from '@ng-bootstrap/ng-bootstrap';
import { AttachmentService } from '../../core/attachment.service';
import { PsValidators } from '../../core/validators/email.validator';
import { OrgUnitItem } from '../../core/interfaces/org-unit-item.interface';
import {ISelectItemModel} from '../../core/interfaces/select-item.interface';
import {UploadErrorDialogComponent} from '../../shared/upload-error-dialog/upload-error-dialog.component';
import {ConfirmationDialogComponent} from '../../shared/confirmation-dialog/confirmation-dialog.component';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from '../../auth/auth.service';

@Component({
  selector: 'edit-position-base',
  templateUrl: 'edit-position-base.component.html',
  styleUrls: ['edit-position-base.component.scss', '../../../styles/form.scss']
})
export class EditPositionBaseComponent implements OnInit {
  @Input() public position: IEditPositionModel;
  @Input() public positionLevels: IPositionLevelModel[];
  @Input() public positionFunctions: IPositionFunctionModel[];
  @Input() public maxSelectedAllowed = 10;
  @Input() public positionDescriptionOverrides: IPositionDescriptionOverrideModel[];
  @Input() public userOrgUnits: ISelectItemModel[];
  @Input() public readOnlyPosition: boolean;
  @Input() public confidential: boolean;
  @Input() public copy: boolean;
  public title = 'Edit Position';
  public orgUnitRequired = false;
  public form: UntypedFormGroup;
  public autoUpdateDescription = false;
  public selectedCompetencies: ICompetencyModel[];
  private _modal: NgbModalRef;
  public fileName = '';

  constructor(private fb: UntypedFormBuilder,
              private authService: AuthService,
              private modalService: NgbModal,
              private positionService: PositionService,
              private attachmentService: AttachmentService) {
              this.maxSelectedAllowed = 10;
  }

  public onConfidential(){
    this._modal = this.modalService.open(ConfirmationDialogComponent);
    const instance = this._modal.componentInstance as ConfirmationDialogComponent;
    instance.title = this.position.isConfidential ? 'Disable Confidentiality' : 'Set Position Confidential';
    instance.body = this.position.isConfidential ? `Are you sure you want to disable Confidentiality for this position?` : 'Are you sure you want to make this position confidential?';
    instance.onConfirm.subscribe((isConfirmed) => {
      if (isConfirmed) {
       this.position.isConfidential = !this.position.isConfidential;
      }         
    });

  }

  public ngOnInit() {
    this.autoUpdateDescription = !this.position.description;
    this.orgUnitRequired = this.userOrgUnits.length > 0;
    this.fileName = this.position.jobDescriptionFileName;
    this.form = this.buildForm(this.position);
    this.selectedCompetencies = this.position.competencies;
    this.positionDescriptionOverrides = this.preparePositionDescriptionOverrides(this.positionDescriptionOverrides);
    this.title = this.position.positionId ? this.title : 'Add Position';
    if(this.copy)
      this.form.markAsDirty();
  }

  public getUserId() : string{
    return (this.authService.impersonatedUser != null) ? this.authService.impersonatedUser.displayName : this.authService.loggedInUser.displayName;
  }

  public get userLevel(): number {
    return this.authService.userLevel;
  }

  public get positionLevelNames(): string[] {
    if (!this.positionLevels) {
      return [];
    }

    return this.positionLevels
      .map((pl) => pl.name);
  }

  public get positionFunctionNames(): string[] {
    if (!this.positionFunctions) {
      return [];
    }

    return this.positionFunctions
      .map((pf) => pf.name)
      .sort(EditPositionBaseComponent.sort);
  }
  /*
    public get orgUnitNames(): string[] {
      if (!this.userOrgUnits) {
        return [];
      }

      return this.userOrgUnits
        .map((ou) => ou.name);
    }
  */

  public onSelectedCompetenciesChanged(selectedCompetencies: ICompetencyModel[]) {
    this.form.markAsDirty();
    this.selectedCompetencies = selectedCompetencies;
  }

  public get canSave() {
    return this.form && this.form.valid && this.form.dirty && this.selectedCompetencies.length;
  }

  public get positionLevelControl(): UntypedFormControl {
    const group = this.form.controls['position'] as UntypedFormGroup;
    return group.controls['positionLevel'] as UntypedFormControl;
  }

  public get positionFunctionControl(): UntypedFormControl {
    const group = this.form.controls['position'] as UntypedFormGroup;
    return group.controls['positionFunction'] as UntypedFormControl;
  }

  public get positionOrgUnitControl(): UntypedFormControl {
    const group = this.form.controls['position'] as UntypedFormGroup;
    return group.controls['positionOrgUnit'] as UntypedFormControl;
  }

  public get descriptionControl(): UntypedFormControl {
    const group = this.form.controls['position'] as UntypedFormGroup;
    return group.controls['description'] as UntypedFormControl;
  }

  public get customCompetencyControl(): UntypedFormControl {
    const group = this.form.controls['position'] as UntypedFormGroup;
    return group.controls['customCompetency'] as UntypedFormControl;
  }

  public get noJobDescriptionControl(): UntypedFormControl {
    const group = this.form.controls['position'] as UntypedFormGroup;
    return group.controls['noJobDescription'] as UntypedFormControl;
  }

  public get jobDescriptionAttachmentIdControl() {
    const group = this.form.controls['position'] as UntypedFormGroup;
    return group.controls['jobDescriptionAttachmentId'] as UntypedFormControl;
  }

  public save(): Observable<IEditPositionModel> {
    if (!this.canSave) {
      return observableOf(null);
    }
    const group = this.form.controls['position'] as UntypedFormGroup;
    const newPosition = this.getFormValues(group);
    newPosition.createdBy = this.getUserId();
    return this.positionService.save(newPosition);
  }

  public updatePositionStatus(): Observable<IEditPositionModel> {
    // if (!this.canSave) {
    //   return Observable.of(null);
    // }
    const group = this.form.controls['position'] as UntypedFormGroup;
    const activePosition = this.getFormValues(group);
    return this.positionService.updatePositionStatus(activePosition);
  }

  private onLevelOrFunctionChange = () => {
    if (!this.autoUpdateDescription) {
      return;
    }

    if (this.positionLevelControl.value && this.positionLevelControl.valid &&
      this.positionFunctionControl.value && this.positionFunctionControl.valid) {
      let newDescription: string;

      if (this.positionDescriptionOverrides) {
        const upperLevel = this.positionLevelControl.value.trim().toUpperCase();
        const upperFunction = this.positionFunctionControl.value.trim().toUpperCase();

        const override = this.positionDescriptionOverrides.find((pdo) => {
          return pdo.level === upperLevel && pdo.function === upperFunction;
        });

        if (override) {
          newDescription = override.description;
        }
      }

      if (!newDescription) {
        newDescription = `${this.positionLevelControl.value.trim()} ${this.positionFunctionControl.value.trim()}`;
      }

      this.descriptionControl.setValue(newDescription);
    }
  }

  public onUploadJobDescriptionFileChange(event) {
    const fileList: FileList = event.target.files;

    if (fileList.length) {
      const file: File = fileList[0];
      this.attachmentService.upload(file)
        .subscribe((attachmentId) => {
            this.jobDescriptionAttachmentIdControl.setValue(attachmentId);
            this.jobDescriptionAttachmentIdControl.markAsDirty();
            this.fileName = file.name;
            event.target.value = '';
          },
          error => {
            this._modal = this.modalService.open(UploadErrorDialogComponent);
            const instance = this._modal.componentInstance as UploadErrorDialogComponent;
            instance.title = 'Error Uploading File';
            instance.body = `The file you are uploading exceeds the maximum file size.<br/><br/>Please contact us at <a href='tel:314-993-8008'>314-993-8008</a> or <a href='mailto:testing@leadershipall.com'>testing@leadershipall.com</a>.`;

            instance.onConfirm.subscribe((isConfirmed) => {
              if (isConfirmed) {
                event.target.value = '';
              }
            });
          });
    }
  }

  private buildForm(position: IEditPositionModel): UntypedFormGroup {
    const positionLevelControl = this.fb.control(position.positionLevelName, Validators.required);
    const positionFunctionControl = this.fb.control(position.name, Validators.required);
    const positionOrgUnitControl = this.fb.control(position.orgUnitId, Validators.required); //this.orgUnitRequired ? this.fb.control(position.orgUnitId, Validators.required) : this.fb.control(position.orgUnitId);
      const descriptionControl = this.fb.control(position.description, Validators.required);
      const noJobDescriptionControl = this.fb.control(position.noJobDescription);

    const formGroup = this.fb.group({
      position: new UntypedFormGroup({
        positionLevel: positionLevelControl,
        positionFunction: positionFunctionControl,
        positionOrgUnit: positionOrgUnitControl,
        description: descriptionControl,
        noJobDescription: noJobDescriptionControl,
        jobDescriptionAttachmentId: this.fb.control(position.jobDescriptionAttachmentId),
        customCompetency: this.fb.control(position.customCompetency, Validators.maxLength(4000))
      })
    });

    // TODO: Uncomment if we would like the Position field to be auto populated by Concat of Level and Function
    // positionLevelControl.valueChanges.subscribe(this.onLevelOrFunctionChange);
    // positionFunctionControl.valueChanges.subscribe(this.onLevelOrFunctionChange);

    // descriptionControl.valueChanges.subscribe((val) => {
    //   if (!val) {
    //     this.autoUpdateDescription = true;
    //   }
    // }
    // );

    return formGroup;
  }

  private getFormValues(form: UntypedFormGroup): EditPositionModel {
    const newPosition = new EditPositionModel(this.position);
    newPosition.positionLevelName = form.controls['positionLevel'].value;
    newPosition.name = form.controls['positionFunction'].value;
    newPosition.orgUnitId = form.controls['positionOrgUnit'].value;
    newPosition.description = form.controls['description'].value;
    newPosition.customCompetency = form.controls['customCompetency'].value;
    newPosition.noJobDescription = form.controls['noJobDescription'].value;
    newPosition.jobDescriptionAttachmentId = form.controls['jobDescriptionAttachmentId'].value;
    newPosition.competencies = this.selectedCompetencies;
    if(!newPosition.orgUnitId)
      newPosition.orgUnitName = null;
    return newPosition;
  }

  private preparePositionDescriptionOverrides(positionDescriptionOverrides: IPositionDescriptionOverrideModel[]): IPositionDescriptionOverrideModel[] {
    if (!positionDescriptionOverrides) {
      return positionDescriptionOverrides;
    }

    return positionDescriptionOverrides.map((pdo: IPositionDescriptionOverrideModel) => {
      const newPdo = {
        level: pdo.level ? pdo.level.trim().toUpperCase() : '',
        'function': pdo.function ? pdo.function.trim().toUpperCase() : '',
        description: pdo.description ? pdo.description.trim() : ''
      };

      return Object.assign({}, pdo, newPdo) as IPositionDescriptionOverrideModel;
    });
  }

  private static sort(a: string, b: string): number {
    const upperA = a.toUpperCase();
    const upperB = b.toUpperCase();

    if (upperA < upperB) {
      return -1;
    }

    if (upperA > upperB) {
      return 1;
    }

    return 0;
  }
}
