<action-bar>
  <action-button [iconClass]="'fa fa-ban'" [className]="'action-danger'" (actionClick)="cancel()">Cancel</action-button>
  <action-button [iconClass]="'fa fa-floppy-o'" [className]="'action-primary'" (actionClick)="submit()"
                 [disabled]="!assignedUsers || assignedUsers.length === 0">Save</action-button>
</action-bar>
<ngb-alert *ngIf="alertMessage" class="alert-overlay"
           type="success" (close)="alertMessage = null">{{ alertMessage }}</ngb-alert>
<h2>Set Default Recipient of Assessment Results <span *ngIf="ownerUser && isAdmin">for ({{ownerUser.name}})</span></h2>

<div class="row" *ngIf="isAdmin">
  <div class="col-6">
    <label class="form-control-label">Client</label>
    <ps-autocomplete-select [placeHolderText]="'Select Client'"
                            [selectedItem]="client"
                            [selectList]="clients" (selectionChanged)="onClientChanged($event)">
    </ps-autocomplete-select>
  </div>
  <div class="col-6">
    <label class="form-control-label">Owner User</label>
    <ps-autocomplete-select [placeHolderText]="'Select Department'"
                            [(selectedItem)]="ownerUser"
                            [selectList]="users" (selectionChanged)="onOwnerUserChanged($event)">
    </ps-autocomplete-select>
  </div>
</div>
<div class="row" *ngIf="client; else noAvailableUsers;">
  <div class="col">
    <br/>
    <h5>Available Recipients</h5>
    <div class="select-list scroll scroll-lg select-list-striped">
      <div *ngFor="let item of availableUsers" class="select-list-item d-flex flex-row align-items-stretch justify-content-between flex-nowrap">
        <div *ngIf="item.name">
          <i class="fa fa-address-card-o"></i>
          <strong>{{item.name}}</strong>&nbsp;<span class="text-muted">({{item.isSystem ? 'System' : 'Non-system'}})</span><br/>
          E: {{item.email}}<br/>
          <span *ngIf="item.phoneMobile">M: {{item.phoneMobile}}<br/></span>
          W: {{item.phoneWork}}
        </div>
        <div *ngIf="!item.name">
          <i class="fa fa-address-card-o"></i>
          <strong>{{item.email}}</strong>&nbsp;<span class="text-muted">({{item.isSystem ? 'System' : 'Non-system'}})</span><br/>
          <span *ngIf="item.phoneMobile">M: {{item.phoneMobile}}<br/></span>
          W: {{item.phoneWork}}
        </div>
        <div class="ml-2" (click)="itemClicked(item)" style="cursor: pointer;">
          <i class="fa fa-plus-square-o fa-lg text-success"></i>&nbsp;
        </div>
      </div>
    </div>
  </div>
  <div class="col">
    <br/>
    <h5>Default Recipients</h5>
    <div class="select-list scroll scroll-lg select-list-striped">
      <div *ngFor="let item of assignedUsers" class="select-list-item d-flex flex-row align-items-stretch justify-content-between flex-nowrap">
        <div class="mr-2" (click)="itemClicked(item)" style="cursor: pointer;">
          <i class="fa fa-trash-o text-danger fa-lg"></i>
        </div>
        <div *ngIf="item.name">
          <i class="fa fa-address-card-o"></i>
          <strong>{{item.name}}</strong>&nbsp;<span class="text-muted">({{item.isSystem ? 'System' : 'Non-system'}})</span><br/>
          E: {{item.email}}<br/>
          <span *ngIf="item.phoneMobile">M: {{item.phoneMobile}}<br/></span>
          W: {{item.phoneWork}}
        </div>
        <div *ngIf="!item.name">
          <i class="fa fa-address-card-o"></i>
          <strong>{{item.email}}</strong>&nbsp;<span class="text-muted">({{item.isSystem ? 'System' : 'Non-system'}})</span><br/>
          <span *ngIf="item.phoneMobile">M: {{item.phoneMobile}}<br/></span>
          W: {{item.phoneWork}}
        </div>
        <div class="d-flex ml-auto">
          <div class="form-check-inline">
            <label class="form-check-label">
              <input class="form-check-input" type="checkbox" [ngModel]="item.verbal" (change)="verbalChanged($event, item)">
              Verbal
            </label>
          </div>
          <div class="form-check-inline">
            <label class="form-check-label">
              <input class="form-check-input" type="checkbox" [ngModel]="item.written" (change)="writtenChanged($event, item)">
              Written
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #noAvailableUsers>
  <p class="text-muted">Select client to see available users.</p>
</ng-template>
