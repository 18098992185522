<div class="modal-header">
  <h4 class="modal-title">Add Position</h4>
</div>
<div class="modal-body">
  <edit-position-base [position]="position" [positionLevels]="positionLevels" [positionFunctions]="positionFunctions"
                      [positionDescriptionOverrides]="positionDescriptionOverrides" [userOrgUnits]="userOrgUnits" [readOnlyPosition]="readOnlyPosition" [copy]="copy" [confidential]="confidential">
  </edit-position-base>
</div>
<div class="modal-footer" style="justify-content: flex-start;">
  <button type="button" class="btn btn-cancel" (click)="onCancel()">Cancel</button>
  <button type="button" class="btn btn-primary" (click)="onSave()" [disabled]="!canSave">Save</button>
</div>