import {Injectable} from '@angular/core';
import {AuthHttpService} from '../auth/auth-http.service';
import {ServiceBase} from './service-base';
import {Observable, observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AttachmentModel} from './models/attachment.model';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { IAttachmentModel } from './interfaces/attachment.interface';

@Injectable()
export class AttachmentService extends ServiceBase {
  private static ApiUrl = 'api/attachment';

  constructor(private http: HttpClient) {
    super();
  }

  public get(id: number): Observable<AttachmentModel> {
    const url = `${AttachmentService.ApiUrl}/${id}`;

    return this.http.get<AttachmentModel>(url);
  }

  public upload(file: File): Observable<number> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    var headers = new HttpHeaders({'Accept': 'application/json'})

    return this.http.post<number>(`${AttachmentService.ApiUrl}/upload`, formData, {headers: headers});
  }

  public email(attachmentId: number, assessmentRequestId: number, emails): Observable<Object> {
    const url = `${AttachmentService.ApiUrl}/${attachmentId}/${assessmentRequestId}/email`;
    return this.http.post<Object>(url, JSON.stringify(emails), {headers: this.jsonHeaders, observe: "response" });
  }

  public download(id: number) {
    const url = `${AttachmentService.ApiUrl}/${id}/download`;
    this.getFile(url).subscribe();
  }

  public downloadWithAssociatedRequests(fileId:number, assessmentRequestId:number){
    const url = `${AttachmentService.ApiUrl}/${fileId}/${assessmentRequestId}/download-with-associated-requests`;
    return this.getFile(url);
  }

  private getFile(url: string){
    const observable = new Observable((observer) => {
      this.http.get(url, {headers: this.jsonHeaders, responseType: 'blob', observe: 'response' }).subscribe(data => { 
        //Headers only support ASCII.
        const filename = this.fromBinary(data.headers.get('File-Name'));
        const blob = data.body;
  
        //Support MS Browsers.
        // if('msSaveOrOpenBlob' in navigator){
        //   window.navigator.msSaveOrOpenBlob(new Blob([blob]), filename);
        // }else{
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement("a");
              link.href = url;
              link.download = filename;
              link.click();
              window.URL.revokeObjectURL(url);
              observer.next(true);
        //}
       },
      error => {
        observer.error(true);
      });
    })
    return observable;
  }

  //Decode Base64 > Unicode 
  public fromBinary(encoded) {
    //First decode base64
    var binary = atob(encoded)
    //Now unicode
    const bytes = new Uint8Array(binary.length);
    for (let i = 0; i < bytes.length; i++) {
      bytes[i] = binary.charCodeAt(i);
    }
    return String.fromCharCode(...new Uint16Array(bytes.buffer));
  }
}
