import { Component, ViewChild, ViewContainerRef} from '@angular/core';
import {IFilterAngularComp} from "ag-grid-angular";
import {AssessmentRequestStatus} from '../../core/enums/assessment-request-status.enum';
import {IAfterGuiAttachedParams, IDoesFilterPassParams, IFilterParams, RowNode} from "ag-grid-community";

@Component({
  selector: 'app-filter-grid-status',
  templateUrl: './filter-grid-status.component.html',
  styleUrls: ['./filter-grid-status.component.scss']
})

export class FilterGridStatusComponent implements IFilterAngularComp  {

  constructor() { }

  private params: IFilterParams;
  private valueGetter: (rowNode: RowNode) => any;
  public text: string = '';
  public notSubmitted: boolean;// = false;
  public inProcess: boolean;// = false;
  public inProcessReportsUploaded: boolean;
  public inProcessTestingStarted: boolean;
  public inProcessTestingComplete: boolean;
  public submitted: boolean;// = false;
  public complete: boolean;// = false;
  public deleted: boolean;// = false;
  public showAll: boolean;// = true;
  public canceled: boolean;
  //@ViewChild('input', {read: ViewContainerRef}) public input;

  agInit(params: IFilterParams): void {
      this.params = params;
      //this.valueGetter = params.valueGetter;
  }

  isFilterActive(): boolean {
    //We may as well just always leave the filter active... because we have to hide deleted by default,
    //the only way the filter doesn't need to be active (FALSE, i.e. no filter) is if showall and deleted are both selected.
    return !(this.showAll && this.deleted)
  }

  //returns bool to indicate whether the row will appear in result list.
  doesFilterPass(params: IDoesFilterPassParams): boolean {
      switch (params.node.data.statusId) {
        case AssessmentRequestStatus.Deleted:
          return this.deleted;
        case AssessmentRequestStatus.Complete:
          return this.complete || this.showAll;
        case AssessmentRequestStatus.InProcess: 
          return this.inProcess || this.showAll;
          case AssessmentRequestStatus.InProcessTestingStarted: 
          return this.inProcessTestingStarted || this.showAll;
        case AssessmentRequestStatus.InProcessTestingComplete: 
          return this.inProcessTestingComplete || this.showAll;
        case AssessmentRequestStatus.InProcessReportsUploaded: 
          return this.inProcessReportsUploaded || this.showAll;
        case AssessmentRequestStatus.NotSubmitted:
          return this.notSubmitted || this.showAll;
        case AssessmentRequestStatus.Submitted:
          return this.submitted || this.showAll;
        case AssessmentRequestStatus.Canceled:
          return this.canceled || this.showAll;
        default:
          return true;
      }
  }

  getModel(): any {
      return {notSubmitted: this.notSubmitted, submitted: this.submitted, inProcess: this.inProcess, 
        deleted: this.deleted, complete: this.complete, canceled: this.canceled, showAll: this.showAll,
        inProcessReportsUploaded: this.inProcessReportsUploaded, inProcessTestingStarted: this.inProcessTestingStarted, inProcessTestingComplete: this.inProcessTestingComplete};
  }

  setModel(model: any): void {
    //this.text = model ? model.value : '';
    
    //Not much effort to ensure this is a 'valid' state coming in but... it can only go so far wrong.
    if(model != null) {
      this.notSubmitted = model.notSubmitted || false;
      this.submitted = model.submitted || false;
      this.inProcess = model.inProcess || false;
      this.deleted = model.deleted || false;
      this.complete = model.complete || false;
      this.showAll = model.showAll || false;
      this.canceled = model.canceled || false;
      this.inProcessReportsUploaded = model.inProcessReportsUploaded || false;
      this.inProcessTestingStarted = model.inProcessTestingStarted || false;
      this.inProcessTestingComplete = model.inProcessTestingComplete || false;
    }
    else
      this.showAll = true;  
  }

  public getModelAsString(model: any): any {
    return "test";
  }

  onChange(event): void {
    
    //If the option was enabled
    if(event.target.checked) {
      //and it was either deleted or showAll
      if(event.target.id != "deleted" && event.target.id != "showAll")
        //turn showAll off
        this.showAll = false;
      //If we turned showAll on, we need to uncheck the others (except deleted?)  
      if(event.target.id == "showAll") {
        this.notSubmitted = false;
        this.inProcess = false;
        this.submitted = false;
        this.complete = false;
        this.canceled = false;
        this.inProcessReportsUploaded = false;
        this.inProcessTestingStarted = false;
        this.inProcessTestingComplete = false;
        //this.deleted = false;
      }
    }
    else      
      if(!this.notSubmitted && !this.inProcess && !this.submitted && !this.complete && !this.deleted && !this.canceled && !this.inProcessReportsUploaded && !this.inProcessTestingStarted && !this.inProcessTestingComplete)
      {
        this.showAll = true; //If all other filters are false, we should probably turn showall back on (unlikely the user actually wants to see nothing.)
        if(event.target.id == "showAll") //Also, If this is coming in on the showall checkbox, we need to set the target too!
        event.target.checked = true; 
      }
    this.params.filterChangedCallback();
  }
}
