export * from './components/ps-autocomplete-select/ps-autocomplete-select';
export * from './components/ps-autocomplete/ps-autocomplete';
export * from './components/ps-autocomplete-settings';
export * from './core/listitem/ps-list-item';
export * from './components/ps-options/ps-options';
export * from './components/ps-option/ps-option';
export * from './components/ps-input-validation/ps-input-validation';
export * from './components/ps-sections/ps-sections-container';
export * from './components/ps-sections/ps-section';
export * from './components/ps-hierarchy-select/ps-hierarchy-select';
export * from './components/ps-hierarchy-select/ps-hierarchy-select-node';
export * from './core/hierarchynode/ps-hierarchy-node';
export * from './components/ps-static-text/ps-static-text.component';

import {NgModule} from '@angular/core';
import {PsAutoCompleteSelectComponent} from './components/ps-autocomplete-select/ps-autocomplete-select';
import {PsAutoCompleteComponent} from './components/ps-autocomplete/ps-autocomplete';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {PsOptionsComponent} from './components/ps-options/ps-options';
import {PsOptionComponent} from './components/ps-option/ps-option';
import {PsHintDirective, PsInputDirective, PsInputValidation} from './components/ps-input-validation/ps-input-validation';
import {PsSectionComponent} from './components/ps-sections/ps-section';
import {PsSectionsContainerComponent} from './components/ps-sections/ps-sections-container';
import {PsHierarchyNodeComponent} from './components/ps-hierarchy-select/ps-hierarchy-select-node';
import {PsHierarchyComponent} from './components/ps-hierarchy-select/ps-hierarchy-select';
import {PsStaticTextComponent} from './components/ps-static-text/ps-static-text.component';
@NgModule({
    declarations: [
        PsAutoCompleteSelectComponent,
        PsAutoCompleteComponent,
        PsOptionComponent,
        PsOptionsComponent,
        PsInputValidation,
        PsInputDirective,
        PsHintDirective,
        PsSectionComponent,
        PsSectionsContainerComponent,
        PsHierarchyComponent,
        PsHierarchyNodeComponent,
        PsStaticTextComponent
    ],
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule
    ],
    exports: [
        PsAutoCompleteSelectComponent,
        PsAutoCompleteComponent,
        PsOptionsComponent,
        PsOptionComponent,
        PsInputValidation,
        PsInputDirective,
        PsHintDirective,
        PsSectionComponent,
        PsSectionsContainerComponent,
        PsHierarchyComponent,
        PsHierarchyNodeComponent,
        PsStaticTextComponent
    ],
    providers: []
})
export class EnhancedControlsModule {
}


