import { Component, OnInit, ViewChild } from '@angular/core';
import {AgRendererComponent} from 'ag-grid-angular';
import { CandidateModel } from '../../core/models/candidate.model';
import {NgbTooltip, NgbPopover} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import {ITableAction, ITableSettings, Row} from '../../piquant-table/models';

@Component({
  selector: 'app-view-button-renderer',
  templateUrl: './view-button-renderer.component.html',
})
export class ViewButtonRendererComponent implements AgRendererComponent {

  @ViewChild('p') public popover: NgbPopover;

  public name: string;

  private actionTable: ITableAction;

  constructor(
    private router: Router,
  ) { }

  public params:any;
  
  agInit(params:any):void {
      this.params = params;
      this.name = params.data.name;
  }

  public ngAfterViewInit()
  {
    //this.params.context.componentParent.registerRenderEvent(this.params.rowIndex, this.showActions);
  }
 
  public showActions(show: any)
  {
    if(show)
      this.popover.open();
    else
      this.popover.close();
  }

  public invokeBuildActions(): ITableAction
  {
    //return this.params.context.componentParent.buildTableActions();

    //Delete was disabled already and edit is covered by the "name" link... was struggling to get the edit button to work, disabling for now...
    return null;
  }

  public refresh(params:any):boolean
  {
    return true;
  }
}
