<div class="row">
  <div class="col">
    <h5>Available Competencies</h5>
    <p class="text-muted">Click competency below to assign</p>
    <div class="view-select" style="margin-bottom: 15px;">
      View:&nbsp;
      <div class="form-check-inline">
        <label class="form-check-label form-control-label">
          <input class="form-check-input" type="radio" name="showAvailableAsList" [value]="false"
                 [(ngModel)]="showAvailableAsList" (ngModelChange)="onListTypeChange()">
          Hierarchy
        </label>
      </div>
      <div class="form-check-inline">
        <label class="form-check-label form-control-label">
          <input class="form-check-input" type="radio" name="showAvailableAsList" [value]="true"
                 [(ngModel)]="showAvailableAsList" (ngModelChange)="onListTypeChange()">
          List
        </label>
      </div>
    </div>
    <quick-filter-input (onChanged)="competencyFilterChange($event)"></quick-filter-input>
  </div>
  <div class="col">
    <h5>Assigned Competencies</h5>
    <p class="text-muted">Click competency below to remove</p>
    <p>{{assignedCompetencies.length}} of {{maxAssignedCompetenciesAllowed}} Competencies Selected</p>
    <quick-filter-input (onChanged)="assignFilterChange($event)"></quick-filter-input>
  </div>
</div>
<div class="row">
  <div class="col">
    <competency-hierarchy *ngIf="!showAvailableAsList" (onCompetencyClicked)="competencyClicked($event, true)"
                          [clientCompetencies]="availableCompetencies" [competencyIdsToExclude]="assignedIds">
    </competency-hierarchy>
    <competency-list *ngIf="showAvailableAsList" [iconSide]="'right'" [scrollSize]="'md'" [competencies]="filteredAvailableCompetencies"
                     [iconClasses]="['fa', 'fa-chevron-right']" (onCompetencyClicked)="competencyClicked($event, true)">
    </competency-list>
  </div>
  <div class="col">
    <competency-list *ngIf="assignedCompetencies && assignedCompetencies.length" [(competencies)]="filteredSelectedCompetencies"
                     [iconClasses]="['fa', 'fa-chevron-left']"
                     (onCompetencyClicked)="competencyClicked($event, false)">
    </competency-list>
    <div *ngIf="!assignedCompetencies || !assignedCompetencies.length">No competencies assigned.</div>
  </div>
</div>
