import {Component, OnInit} from '@angular/core';
import {IApiAdminModel} from '../../core/interfaces/api-admin.interface';
import {ApiAdminModel} from '../../core/models/api-admin.model';
import {ActivatedRoute, Router} from '@angular/router';
import {LocalDataSource} from '../../piquant-table/data/local-datasource';
import {ITableSettings} from '../../piquant-table/models';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {EmailStatus} from '../../core/enums/email-status.enum';
import {format} from 'date-fns';
import {GridOptions} from "ag-grid-community";
import { ViewButtonRendererComponent } from '../view-button-renderer/view-button-renderer.component';
import {FilterApiStatusComponent} from '../filter-grid-status/filter-grid-status.component';
import {AuthService} from '../../auth/auth.service';
import {ApiAdminService} from '../../core/api-admin.service'
import {DescriptionRendererComponent} from '../description-renderer/description-renderer.component';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'list-api-history',
  templateUrl: './list-api-history.component.html',
  styleUrls: ['list-api-history.component.css'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class ListApiHistoryComponent implements OnInit {
  public psTableSettings: ITableSettings;
  public apicalls: LocalDataSource = new LocalDataSource();
  public gridOptions: GridOptions;
  public inputQuickSearch: string;
  public selectedPaginationOption = 2;
  public paginationOptions = [
    //{id: 0, name: "Auto"}
    {id: 0, name: "10"},
    {id: 1, name: "50"},
    {id: 2, name: "100"},
    {id: 3, name: "500"}
  ];
  private apiAdminService: ApiAdminService;

  constructor(private _apiAdminService: ApiAdminService, 
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,) {
      this.apiAdminService = _apiAdminService;
      this.gridOptions = <GridOptions> {
      columnDefs: this.createColumnDefs(),
      rowHeight: 48, // recommended row height for material design data grids,
      headerHeight: 38,
      rowBuffer: 5, // This is set very low right now because of poor performance for the ngb Popover workaround in place. Default is 20
      animateRows: true,
      pagination: true,
      rowSelection: "single",
      suppressCellFocus: true,
      suppressMenuHide: true,
      getRowClass: (event) => this.rowBackgroundColor(event),
      onSortChanged: () => this.onColumnChanged(),
      onColumnResized: () => this.onColumnChanged(),
      onFilterChanged: (event) => this.onFilterChanged(event),
      context: { componentParent: this },   // Make this component (and its members) available to child components.
      defaultColDef: {
        resizable: true,
        sortable: true
      }
    }
  }

  onResize(event) {
    localStorage.setItem('apiAdminWindowWidth', JSON.stringify(event.target.innerWidth));
    this.gridOptions.api.sizeColumnsToFit();
  }

  public onClickRetry(positionId: number): Observable<any> {
    return this.apiAdminService.retry(positionId).pipe(
      tap(() => {
        this.apiAdminService.getApiHistory().subscribe((data) => {
          this.gridOptions.api.setRowData(data);
        });
      })
    );
  }

  private createColumnDefs() {
    return [
      {
        headerName: "Id",
        field: "id",
        filter: "agTextColumnFilter",
        menuTabs: ['filterMenuTab'],
        icons: {
          sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
          sortDescending: '<i class="fa fa-sort-alpha-desc"/>',
          filter: '<i class="fa fa-search"/>',
          menu: '<i class="fa fa-filter"/>'
        }
      },
      {
        headerName: "Description",
        field: "description",
        filter: "agTextColumnFilter",
        menuTabs: ['filterMenuTab'],
        icons: {
          sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
          sortDescending: '<i class="fa fa-sort-alpha-desc"/>',
          filter: '<i class="fa fa-search"/>',
          menu: '<i class="fa fa-filter"/>'
        }
      },
      {
        headerName: "Response",
        field: "responseString",
        cellRendererFramework: DescriptionRendererComponent,
        filter: "agTextColumnFilter",
        menuTabs: ['filterMenuTab'],
        editable: true,
        icons: {
          sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
          sortDescending: '<i class="fa fa-sort-alpha-desc"/>',
          filter: '<i class="fa fa-search"/>',
          menu: '<i class="fa fa-filter"/>'
        }
      },
      {
        headerName: "Date",
        field: "date",
        filter: "agDateColumnFilter",
        menuTabs: ['filterMenuTab'],
        sort: 'desc',
        suppressSizeToFit: true,
        width: 120,
        valueFormatter: this.dateCellFormatter,
        icons: {
          sortAscending: '<i class="fa fa-arrow-up"/>',
          sortDescending: '<i class="fa fa-arrow-down"/>',
          filter: '<i class="fa fa-search"/>',
          menu: '<i class="fa fa-filter"/>'
        }
      },
      {
        headerName: "API",
        field: "apiCallTypeName",
        filter: "agTextColumnFilter",
        menuTabs: ['filterMenuTab'],
        suppressSizeToFit: true,
        width: 120,
        icons: {
          sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
          sortDescending: '<i class="fa fa-sort-alpha-desc"/>',
          filter: '<i class="fa fa-search"/>',
          menu: '<i class="fa fa-filter"/>'
        }
      },
      {
        headerName: "Result",
        field: "responseId",
        cellRendererFramework: ViewButtonRendererComponent,
        filter: "agTextColumnFilter",
        menuTabs: ['filterMenuTab'],
        suppressSizeToFit: true,
        width: 120,
        icons: {
          sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
          sortDescending: '<i class="fa fa-sort-alpha-desc"/>',
          filter: '<i class="fa fa-search"/>',
          menu: '<i class="fa fa-filter"/>'
        }
      },
    ];
  }

  public ngOnInit() {
    
  }

  //Move this to shared?
  private dateCellFormatter(params) {
    const thisDate: Date = new Date(params.value);
    return `${(thisDate.getMonth() + 1)}/${thisDate.getDate()}/${thisDate.getFullYear()} ${thisDate.getHours()}:${String(thisDate.getMinutes()).padStart(2, '0')}`;
  }

  private emailStatusFormatter(params){
    return EmailStatus[params.value];
  }

  public rowBackgroundColor(event) : string{
    if (event.node.rowIndex % 2 === 0) {
      return 'shaded-row';
    }
    else
      return null;
  }

  public setPageSize(paginationOption)
  {
    //Current version of AG Grid appears to have problems with autopagination (at least if it's turned on at runtime)... disabling.
    // if(paginationOption != 0)
    // {
      this.gridOptions.paginationAutoPageSize = false;
      this.gridOptions.api.paginationSetPageSize(Number( (this.paginationOptions[paginationOption]) ? this.paginationOptions[paginationOption].name : 100));
    // }
    // else
    // {
    //   this.gridOptions.paginationAutoPageSize = true;
    //   //this.gridOptions.api.doLayout();
    //   this.gridOptions.api.redrawRows();
    // }
  }

  public onFilterTextBoxChanged() {
      this.gridOptions.api.setQuickFilter(this.inputQuickSearch);
  }

  public ngAfterViewInit() {
    this.route.data.subscribe((data: any) => {
      const apicalls = data.apiHistory as IApiAdminModel[];
      this.gridOptions.api.setRowData(apicalls);
      this.apicalls.load(apicalls);
    });
    this.loadGridPreferences();
  }

  public loadGridPreferences() {
    //Handle loading saved Sort, Filter, and Column settings.
    var columnState = localStorage.getItem('gridApiAdminColumnState' + this.getImpersonatedUserId());  //gridColumnState'+this.getImpersonatedUserId(), JSON.stringify(this.gridOptions.columnApi.getColumnState()
    if (columnState != null) {
      this.gridOptions.columnApi.applyColumnState(JSON.parse(columnState));
      //Prevent poor column saved states when a user's window size changes between sessions.
      if (+localStorage.getItem('apiAdminWindowWidth') != window.innerWidth) {
        this.gridOptions.api.sizeColumnsToFit();
        localStorage.setItem('apiAdminWindowWidth', JSON.stringify(window.innerWidth));
      }
    }
    else {
      this.gridOptions.api.sizeColumnsToFit();
      this.resetFilters();
    }
    var filterModel = localStorage.getItem('gridApiAdminFilterModel' + this.getImpersonatedUserId());
    if (filterModel != null) {
      const thisModel = JSON.parse(filterModel);
      this.gridOptions.api.setFilterModel(thisModel);      
    }
    else
      this.resetFilters();
    //Also attempt to load pagination selection
    var paginationOption = localStorage.getItem('gridApiAdminPagination' + this.getImpersonatedUserId());
    if (paginationOption != null)
      this.onChangePaginationOption(Number(paginationOption));
  }

  public resetFilters() {
    //Reset Pagination Option
    this.onChangePaginationOption(2);
    // each column has to have filters removed.
    for (let column of this.gridOptions.columnApi.getAllColumns()) {
      this.gridOptions.api.destroyFilter(column);
    }
    // Now cleanup the quick search -> reset the input element
    this.inputQuickSearch = '';
    // and reset the grid quickfilter
    this.gridOptions.api.setQuickFilter('');
    this.gridOptions.api.setFilterModel({responseId:{type:"notContains",filter:"200",filterType:"text"}});
    // reset the default sort.
    this.gridOptions.columnApi.resetColumnState();
    // finally raise the events...
    this.gridOptions.api.onSortChanged();
    this.gridOptions.api.onFilterChanged();
    this.gridOptions.api.sizeColumnsToFit();
  }

  public get isRoleClient() {
    return this.authService.isRoleClient;
  }

  public onColumnChanged() {
    localStorage.setItem('gridApiAdminColumnState' + this.getImpersonatedUserId(), JSON.stringify(this.gridOptions.columnApi.getColumnState())); //get/applyColumnState()
  }

  public onFilterChanged(event) {
    localStorage.setItem('gridApiAdminFilterModel'+this.getImpersonatedUserId(), JSON.stringify(this.gridOptions.api.getFilterModel()));
  }

  public getImpersonatedUserId(): string
  {
    return (this.authService.impersonatedUser != null) ? this.authService.impersonatedUser.userId.toString() : this.authService.userId.toString();
  }

  public onChangePaginationOption(option)
  {
    this.selectedPaginationOption = option; // event.target.value;
    this.setPageSize(this.selectedPaginationOption);
    localStorage.setItem('gridApiAdminPagination'+this.getImpersonatedUserId(), this.selectedPaginationOption.toString());
  }
}
