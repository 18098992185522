import {NgModule, Provider} from '@angular/core';
import {Router, RouterModule, Routes} from '@angular/router';
import {LoginLayoutComponent} from '../shared/public-layout/login-layout.component';
import {LoginComponent} from './login/login.component';
import {AuthGuard} from './auth.guard';
import {AuthService} from './auth.service';
import {AuthHttpService} from './auth-http.service';
import {HttpClient} from '@angular/common/http';
import {LoadingSpinnerService} from '../shared/loading-spinner/loading-spinner.service';
import {ImpersonateUserComponent} from './impersonate-user/impersonate-user.component';
import {ImpersonateUserGuard} from './impersonate-user/impersonate-user.guard';
import {SecureLayoutComponent} from '../shared/secure-layout/secure-layout.component';
import {ClientSelectItemsNoImpersonationResolver, UserSelectItemsNoImpersonationResolver} from '../core/resolvers.service';
import {SharedModule} from '../shared/shared.module';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {ForgotPasswordSentComponent} from './forgot-password/forgot-password-sent.component';
import {ForgotPasswordResetComponent} from './forgot-password/forgot-password-reset.component';
import {AppService} from '../core/app.service';

const routes: Routes = [
  {
    path: 'login',
    component: LoginLayoutComponent,
    children: [
      {path: '', component: LoginComponent}
    ]
  },
  {
    path: 'forgot-password',
    component: LoginLayoutComponent,
    children: [
      {path: '', component: ForgotPasswordComponent},
      {path: 'sent', component: ForgotPasswordSentComponent},
      {path: 'reset', component: ForgotPasswordResetComponent}
    ]
  },
  {
    path: 'impersonate', component: SecureLayoutComponent, canActivateChild: [AuthGuard], children: [
    {
      path: '',
      component: ImpersonateUserComponent,
      canActivate: [ImpersonateUserGuard],
      resolve: {
        clients: ClientSelectItemsNoImpersonationResolver,
        users: UserSelectItemsNoImpersonationResolver
      }
    }
  ]
  }
];

// export function authHttpServiceFactory(backend: XHRBackend, options: RequestOptions, router: Router, loadingBarService: LoadingSpinnerService, authService: AuthService) {
//   return new AuthHttpService(backend, options, router, loadingBarService, authService);
// }

// const authHttpServiceProvider: Provider = {
//   provide: AuthHttpService,
//   useFactory: authHttpServiceFactory,
//   deps: [XHRBackend, RequestOptions, Router, LoadingSpinnerService, AuthService]
// };

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule
  ],
  exports: [],
  declarations: [
    LoginComponent,
    ForgotPasswordComponent,
    ForgotPasswordSentComponent,
    ForgotPasswordResetComponent,
    ImpersonateUserComponent
  ],
  providers: [
    AuthGuard,
    AuthService,
    AppService,
    //authHttpServiceProvider,
    HttpClient,
    ImpersonateUserGuard
  ]
})
export class AuthModule {
}
