import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {FeedbackUserService} from '../../core/feedback-user.service';
import {Utility} from '../../core/utility';
import {AssessmentRequestProxyService} from '../../core/assessment-request-proxy.service';
import {ProxySubmitItem} from '../../core/interfaces/assessment-request-proxy.interface';
import { AssessmentRequestListItemModel } from '../assessment-request-list-item.model';
import { ConfirmationDialogComponent } from 'app/shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'proxy-report-dialog',
  templateUrl: './proxy-report-dialog.component.html',
  styleUrls: ['./proxy-report-dialog.component.scss']
})
export class ProxyReportDialogComponent implements OnInit {
  @Input() public proxyUsers: any;
  @Input() public clientUsers: any;
  @Input() public assessmentRequestId: number;
  @Input() public assessmentRequest: AssessmentRequestListItemModel;
  public filteredUsers: any;
  public _search: string;
  private _modal: NgbModalRef;

  constructor(public activeModal: NgbActiveModal,
              private modalService: NgbModal,
              private feedbackUserService: FeedbackUserService,
              private proxyService: AssessmentRequestProxyService) {
  }

  public ngOnInit(): void {
    for (let proxyUser of this.proxyUsers) {
      for (let clientUser of this.clientUsers) {
        if (proxyUser.user_id === clientUser.id) {
          clientUser.proxy = true;
          break;
        }
      }
    }
    this.filteredUsers = this.clientUsers.sort(Utility.sortByName);
    if(this.assessmentRequest.isConfidential){
      this._modal = this.modalService.open(ConfirmationDialogComponent); 
        const instance = this._modal.componentInstance as ConfirmationDialogComponent;
        instance.title = 'Warning: Confidential Record';
        instance.body = 'This record is marked CONFIDENTIAL! Are you sure that you want to share this report with another User? Do not proceed unless you have permission to share this record.';
        instance.affirmativeButtonText = 'Continue';
        instance.negativeButtonText = 'Go Back';
        instance.onConfirm.subscribe((isConfirmed) => {
          if (!isConfirmed) {
            this.activeModal.dismiss();
          }
        });
    }

  }

  public onDismiss() {
    this.activeModal.dismiss();
  }

  public onClose() {
    let proxyList: ProxySubmitItem = {
      assessmentRequestId: this.assessmentRequestId,
      proxyUsers: []
    };
    const filteredUsers = this.getProxiedUsers() || [];

    if (filteredUsers.length) {
      filteredUsers.forEach((user) => {
        proxyList.proxyUsers.push({userId: user.id, assessmentRequestId: this.assessmentRequestId, id: null})
      });
    }
    this.proxyService.saveProxyList(proxyList)
      .subscribe(() => {
        this.activeModal.close();
    });
  }

  private getProxiedUsers(): any {
    return this.filteredUsers.filter((u) => u.proxy);
  }
}
