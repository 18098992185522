import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ActionBarModule} from './action-bar/action-bar.module';
import {GlobalNavComponent} from './global-nav/global-nav.component';
import {MainNavComponent} from './main-nav/main-nav.component';
import {RouterModule} from '@angular/router';
import {PublicLayoutComponent} from './public-layout/public-layout.component';
import {LoginLayoutComponent} from './public-layout/login-layout.component';
import {SecureLayoutComponent} from './secure-layout/secure-layout.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {GenderPipe} from './pipes/gender.pipe';
import {GroupByPipe} from './pipes/group-by.pipe';
import {CandidateTypePipe} from './pipes/candidate-type.pipe';
import {BoolPipe} from './pipes/bool.pipe';
import {QuickSearchPipe} from './pipes/search-filter.pipe';
import {AssignConsultantComponent} from './assign-consultant/assign-consultant.component';
import {EnhancedControlsModule} from './enhanced-controls/enhanced-controls.module';
import {LoadingSpinnerComponent} from './loading-spinner/loading-spinner.component';
import {LoadingSpinnerService} from './loading-spinner/loading-spinner.service';
import {CustomNgbModule} from './custom-ngb.module';
import {QuickFilterInputComponent} from './quick-filter-input/quick-filter-input';
import {CanDeactivateComponentGuard} from './can-deactivate-component.guard';
import {ConfirmationDialogComponent} from './confirmation-dialog/confirmation-dialog.component';
import {UploadErrorDialogComponent} from './upload-error-dialog/upload-error-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        CustomNgbModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        ActionBarModule,
        EnhancedControlsModule
    ],
    exports: [
        CommonModule,
        ActionBarModule,
        FormsModule,
        ReactiveFormsModule,
        CustomNgbModule,
        GlobalNavComponent,
        MainNavComponent,
        PublicLayoutComponent,
        LoginLayoutComponent,
        SecureLayoutComponent,
        NotFoundComponent,
        GenderPipe,
        GroupByPipe,
        CandidateTypePipe,
        BoolPipe,
        QuickSearchPipe,
        EnhancedControlsModule,
        LoadingSpinnerComponent,
        QuickFilterInputComponent,
        ConfirmationDialogComponent,
        UploadErrorDialogComponent
    ],
    declarations: [
        GlobalNavComponent,
        MainNavComponent,
        PublicLayoutComponent,
        LoginLayoutComponent,
        SecureLayoutComponent,
        NotFoundComponent,
        AssignConsultantComponent,
        GenderPipe,
        GroupByPipe,
        CandidateTypePipe,
        BoolPipe,
        QuickSearchPipe,
        LoadingSpinnerComponent,
        QuickFilterInputComponent,
        ConfirmationDialogComponent,
        UploadErrorDialogComponent
    ],
    providers: [
        CanDeactivateComponentGuard,
        LoadingSpinnerService
    ]
})
export class SharedModule {
}
