import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import { LicenseManager } from 'ag-grid-enterprise';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private router: Router) {
  }

  public ngOnInit() {
    LicenseManager.setLicenseKey("CompanyName=Chapman & Co Leadership,LicensedApplication=C & Co Portal,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-027366,ExpiryDate=14_April_2023_[v2]_MTY4MTQyNjgwMDAwMA==72f1fbada266935b77a43252acb3cb30")
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // scroll to top of page when route changes
        window.scrollTo(0, 0);
      }
    });
  }
}
