/**
 * Created by saintg7900 on 6/27/17.
 */
import {Subject} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable()
export class AppService {
  private loggingOutSource = new Subject();
  private loggedOutSource = new Subject();

  public onLoggingOut = this.loggingOutSource.asObservable();
  public onLoggedOut = this.loggingOutSource.asObservable();

  public loggingOut() {
    this.loggingOutSource.next();
  }

  public loggedOut() {
    this.loggedOutSource.next();
  }
}
