<ps-sections-container [formGroup]="changePasswordForm" *ngIf="changePasswordForm && !showSuccess" [showNav]="false">
  <action-bar>
    <action-button *ngIf="!showSuccess" [disabled]="!changePasswordForm.valid" [iconClass]="'fa fa-floppy-o'"
                   [className]="'action-primary'" (actionClick)="submit()">Save Changes
    </action-button>
  </action-bar>
  <ps-section [sectionTitle]="'Change Password'" [sectionId]="'change-password'">
    <div class="row col">
      <p class="lead">Use this form to change your password, {{username}}.</p>
    </div>
    <div class="row col" *ngIf="errorMessage">
      <div class="text-danger">
        <strong>Unable to reset password:</strong><br/>
        {{errorMessage}}<br/>
      </div>
    </div>
    <div class="form-group row">
      <ps-input-validation [columnSize]="6">
        <label class="form-control-label">Current Password</label>
        <input psInput #currentPassword class="form-control" id="currentPassword" type="password" formControlName="currentPassword"/>
      </ps-input-validation>
    </div>
    <div class="form-group row">
      <ps-input-validation [columnSize]="6">
        <ng-template #tipContent>
          <div>Must be at least 6 characters.</div>
          <div>Must have 1 letter.</div>
          <div>Must have 1 number</div>
          <div>Must have 1 of these special characters !@#$%^&*_-=</div>
        </ng-template>
        <label class="form-control-label">New Password
          <i class="fa fa-question-circle" placement="right" [ngbTooltip]="tipContent"></i></label>
        <input psInput #newPassword class="form-control" id="newPassword" type="password" (keyup)="newPasswordChange()" formControlName="newPassword"/>
      </ps-input-validation>
    </div>
    <div class="form-group row">
      <ps-input-validation [columnSize]="6">
        <label class="form-control-label">Confirm New Password </label>
        <input psInput #confirmPassword class="form-control" id="confirmPassword" type="password" formControlName="confirmPassword"/>
      </ps-input-validation>
    </div>
  </ps-section>
</ps-sections-container>
<ps-sections-container [showNav]="false" *ngIf="showSuccess">
  <ps-section [sectionTitle]="'Change Password'">
    <h4 class="text-success"><i class="fa fa-check-circle"></i> Success</h4>
    <p>Your password has been changed successfully.</p>
    <p><a [routerLink]="['/assessment-requests']" class="btn btn-default">Home</a></p>
  </ps-section>
</ps-sections-container>
