import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {PsListItem} from '../../../shared/enhanced-controls/core/listitem/ps-list-item';
import {FilterBaseComponent} from './filter-base.component';
import {PsTableFilterService} from '../../services/table-filter.service';

@Component({
  selector: 'ps-multi-select-filter',
  templateUrl: './multi-select-filter.component.html'
})
export class PsMultiSelectFilterComponent extends FilterBaseComponent implements OnInit, OnDestroy {
  @Input() public listItems: PsListItem[] = [];
  public selectedItems: any;
  public checkAll: boolean;

  constructor(filterService: PsTableFilterService) {
    super(filterService);
  }

  public ngOnInit(): void {
    const filter = this._filterService.getAppliedFilter(this.columnName);
    if (filter) {
      this.filterConfig = filter;
      const search = this.filterConfig.search;
      this.listItems.forEach((e: PsListItem) => {
        e.selected = search.indexOf(e.id) > -1;
      });
      const selected = this.getSelectedItems;
      this.checkAll = !selected || selected.length === 0;

    } else {
      this.checkAll = true;
    }
    this.selectedItems = this.getSelectedItems;
  }

  public allChanged() {
    if (this.checkAll) {
      this.deselectAll();
      this.selectedItems = this.getSelectedItems;
    }
  }

  public selectItem() {
    this.checkAll = false;
    const notSelected = this.listItems.every((e) => {
      return e.selected === false;
    });
    if (notSelected) {
      this.checkAll = true;
      this.deselectAll();
    }
    this.selectedItems = this.getSelectedItems;
  }

  private get getSelectedItems(): any {
    if (this.checkAll) {
      return this.listItems.map((s) => {
        return s.id;
      });
    }
    return this.listItems.filter(e => {
      return e.selected;
    }).map((s) => s.id );
  }

  private deselectAll() {
    this.listItems.forEach((s) => {
      s.selected = false;
    });
  }

  protected clearPending(): void {
    this.deselectAll();
    this.checkAll = true;
  }

  protected setFilterQuery(): void {
    if (this.checkAll) {
      this._filterService.removePendingFilter(this.columnName);
    } else {
      this.initializeFilter();
      this.filterConfig.search = this.listItems.filter(e => e.selected).map(e => {
        return e.id;
      });
      this.filterConfig.searchDisplay = this.listItems.filter(e => e.selected).map(e => {
        return e.name;
      }).join(',');
      this.registerFilter();
    }
  }
}
