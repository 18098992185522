import {UntypedFormGroup} from '@angular/forms';
import {EditAssessmentRequestModel} from '../../edit-assessment-request.model';

export abstract class SectionBase {
  private _assessmentRequest: EditAssessmentRequestModel;
  public formGroup: UntypedFormGroup;

  public get assessmentRequest(): EditAssessmentRequestModel {
    return this._assessmentRequest;
  }

  public abstract get formValue(): any;

  protected abstract buildFormGroup(): UntypedFormGroup;

  protected setAssessmentRequest(assessmentRequest: EditAssessmentRequestModel) {
    this._assessmentRequest = assessmentRequest;
  }

  public touchAllControls() {
    for (const ctrlKey in this.formGroup.controls) {
      const ctrl = this.formGroup.get(ctrlKey);
      ctrl.markAsTouched();
    }
  }
}
