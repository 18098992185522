import {Component, Input, OnDestroy, OnInit, Output, EventEmitter} from '@angular/core';
import {HierarchyNode} from '../../core/hierarchynode/ps-hierarchy-node';
import {HierarchyManagerService} from '../../services/hierarchy-manager.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'ps-hierarchy-node',
  templateUrl: './ps-hierarchy-select-node.html'
})
export class PsHierarchyNodeComponent implements OnInit, OnDestroy {
  @Input() public node: HierarchyNode;
  @Input() public level: number;
  @Input() public readOnly = false;
  @Input() public text = '';
  @Output() nodeClickedOutput: EventEmitter<HierarchyNode> = new EventEmitter<HierarchyNode>();
  private selectionChangedSub: Subscription;
  public levelClass: string;

  constructor(private hierarchyService: HierarchyManagerService) {
    this.selectionChangedSub = this.hierarchyService.onSelectionChanged$.subscribe((changedNode) => {
      if (this.hierarchyService.multiSelect) {
        return;
      }
      if (this.node.id === changedNode.id) {
        return;
      }
      this.selectNode(changedNode.id);
    });
  }

  public ngOnInit(): void {
    this.levelClass = `level-${this.level}`;
  }

  public ngOnDestroy(): void {
    this.selectionChangedSub.unsubscribe();
  }

  private selectNode(nodeId: number) {
    this.node.selected = this.node.id === nodeId;
  }

  public itemClicked() {
    if (this.readOnly) {
      return;
    }
    if (this.canBeSelected) {
      this.node.selected = !this.node.selected;
      this.hierarchyService.itemSelectChange(this.node);
    }
  }

  //This node was clicked
  public onNodeClicked(){
    this.nodeClickedOutput.emit(this.node);
  }

  //Child node was clicked, pass it on.
  public nodeClickedEvent(event){
    this.nodeClickedOutput.emit(event);
  }

  public get isSelected(): boolean {
    return this.node.selected;
  }

  public get canBeSelected(): boolean {
    return this.node.canBeSelected;
  }
}
