import {Component, ContentChildren, EventEmitter, OnDestroy, Output, QueryList} from '@angular/core';
import {PsOptionComponent} from '../ps-option/ps-option';
import {PsListItem} from '../../core/listitem/ps-list-item';
import {OptionsManagerService} from '../../services/options-manager.service';
import {Subscription} from 'rxjs';

let _uniqueSelectListId = 0;

@Component({
  selector: 'ps-options',
  templateUrl: './ps-options.html',
  providers: [OptionsManagerService]
})
export class PsOptionsComponent implements OnDestroy {
  @ContentChildren(PsOptionComponent) optionList: QueryList<PsOptionComponent>;
  @Output() selectionChanged: EventEmitter<PsListItem> = new EventEmitter<PsListItem>();
  id = `select-list-${_uniqueSelectListId++}`;
  activeItemIndex = -1;
  private subscription: Subscription;

  constructor(private optionsManager: OptionsManagerService) {
    this.subscription = optionsManager.itemSelected$.subscribe(idx => {
      this.selectItemByIndex(idx);
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public selectItemByIndex(index: number) {
    const selected = this.optionList.toArray()[index];
    if (!selected) {
      return;
    }
    this.clearAllSelected();
    selected.selected(true);
    this.selectionChanged.emit(selected.value);
  }

  public reset() {
    this.clearAllSelected();
    this.clearAllActive();
    this.selectionChanged.emit(null);
  }

  public setPrevItemActive() {
    if (this.activeItemIndex <= 0) {
      this.activeItemIndex = 0;
      this.setActiveItem(this.activeItemIndex);
      return;
    }
    this.activeItemIndex--;
    this.setActiveItem(this.activeItemIndex);
  }

  public setNextItemActive() {
    const max = (this.optionList.length - 1);
    if (this.activeItemIndex >= max) {
      this.activeItemIndex = max;
      this.setActiveItem(this.activeItemIndex);
      return;
    }
    this.activeItemIndex++;
    this.setActiveItem(this.activeItemIndex);
  }

  public setFirstItemActive() {
    this.clearAllActive();
    this.activeItemIndex = 0;
    this.optionList.first.activated(true);
  }

  public setLastItemActive() {
    this.clearAllActive();
    this.activeItemIndex = this.optionList.length - 1;
    this.optionList.last.activated(true);
  }

  public setActiveItem(index: number) {
    for (let i = 0; i < this.optionList.length; i++) {
      const opt = this.optionList.toArray()[i] as PsOptionComponent;
      if (i === index) {
        this.activeItemIndex = index;
        opt.activated(true);
      } else {
        opt.activated(false);
      }
    }
  }

  public selectActiveItem() {
    if (this.activeItemIndex < 0) {
      return;
    }
    this.selectItemByIndex(this.activeItemIndex);
  }

  private clearAllActive() {
    this.activeItemIndex = -1;
    for (const op of this.optionList.toArray()) {
      op.activated(false);
    }
  }

  private clearAllSelected() {
    for (const op of this.optionList.toArray()) {
      op.selected(false);
    }
  }


}
