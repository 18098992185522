
import {defer as observableDefer, Observable} from 'rxjs';
import {map, publishReplay, refCount, take} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {AuthHttpService} from '../auth/auth-http.service';
import {ServiceBase} from './service-base';
import {ICompetencyModel} from './interfaces/competency.interface';
import {CompetencyModel} from './models/competency.model';
import {HttpClient, HttpResponse} from '@angular/common/http';

@Injectable()
export class CompetencyService extends ServiceBase {
  private static ApiUrl = 'api/competency';
  private data$: Observable<ICompetencyModel[]>;
  private cacheDuration: number = 1000 * 60 * 10;

  constructor(private http: HttpClient) {
    super();
  }

  public get(): Observable<ICompetencyModel[]> {
    if (!this.data$) {
      this.data$ = observableDefer(() => this.http.get<ICompetencyModel[]>(CompetencyService.ApiUrl)).pipe(
        map(competencies => competencies.map(competency => {return new CompetencyModel(competency) as ICompetencyModel;})),
        publishReplay(1, this.cacheDuration),
        refCount(),
        take(1)
      );
    }

    return this.data$;
  }

  public delete(id: number): Observable<HttpResponse<Object>> {
    const url = `${CompetencyService.ApiUrl}/${id}`;
    return this.http.delete<HttpResponse<Object>>(url, {observe: "response"} );
  }

  public getByCompetencySet(competencySetId: number): Observable<ICompetencyModel[]> {
    const url = `${CompetencyService.ApiUrl}/set/${competencySetId}`;

    return this.http.get<ICompetencyModel[]>(url).pipe(map(competencies => competencies.map(competency => {return new CompetencyModel(competency) as ICompetencyModel;})));
  }

  public clearCache(): void {
    this.data$ = null;
  }
}
