import { Component, OnInit } from '@angular/core';
import {AgRendererComponent} from 'ag-grid-angular';

@Component({
  selector: 'app-edit-button-renderer',
  template: '<button class="btn btn-sm btn-secondary" (click)="onClickEdit(params?.data?.positionId)"><span class="fa fa-edit"></span> Edit</button>'
})
export class EditButtonRendererComponent implements AgRendererComponent {

  constructor() { }

  public params:any;
  
  agInit(params:any):void {
      this.params = params;
  }

  public onClickEdit(positionId: number)
  {
    this.params.context.componentParent.onClickEdit(positionId);
  }

  public refresh(params:any):boolean
  {
    return true;
  }
}
