import {Component} from '@angular/core';

@Component({
    selector: 'action-bar-control',
    template: `
      <div class="action-bar-control w-50" [ngClass]=""><ng-content></ng-content></div>
    `
})
export class ActionBarControlComponent {

}
