import {AfterViewInit, Directive, Input, OnDestroy} from '@angular/core';
import {Column, FilterConfig} from '../../models';
import {PsTableFilterService} from '../../services/table-filter.service';
import {Subscription} from 'rxjs';
import {isNullOrUndefined} from 'util';

@Directive({
  selector: 'ps-table-filter'
})
export class PsTableFilterDirective {
}

@Directive()
export abstract class FilterBaseComponent implements AfterViewInit, OnDestroy {
  @Input() public columnName: string;
  @Input() public filterTitle: string;
  public column: Column;
  public filterConfig: FilterConfig;

  private resetSubscription: Subscription;
  private removeSubscription: Subscription;

  constructor(protected _filterService: PsTableFilterService) {
    this.resetSubscription = _filterService.resetFilters$.subscribe(() => {
      this.clearPending();
    });
    this.removeSubscription = _filterService.removeFilter$.subscribe(columnName => {
      if (this.columnName.toLowerCase() === columnName.toLowerCase()) {
        this.clearPending();
      }
    });
  }

  ngOnDestroy(): void {
    this.resetSubscription.unsubscribe();

  }

  ngAfterViewInit(): void {
    this.column = this._filterService.getColumn(this.columnName);
    if (!this.column) {
      return;
    }
    this._filterService.dataSource.onChanged().subscribe(() => {
      const filterConfigs = this._filterService.dataSource.getFilterConfigs();
      if (filterConfigs && filterConfigs.length === 0) {
        this.initializeFilter();
      } else {
        const filter = filterConfigs[this.columnName];
        if (isNullOrUndefined(filter)) {
          this.initializeFilter();
        }
        this.filterConfig = filter;
      }
    });
  }

  protected initializeFilter() {
    this.filterConfig = new FilterConfig();
    this.filterConfig.field = this.column.id;
    this.filterConfig.title = this.filterTitle;
    this.filterConfig.filter = this.column.getFilterFunction();
  }

  public applyFilter(): void {
    this.setFilterQuery();
    this._filterService.applyPendingFilters();
  }

  protected abstract setFilterQuery(): void;

  protected abstract clearPending(): void;

  protected registerFilter(): void {
    this._filterService.addPendingFilter(this.columnName, this.filterConfig);
  }

}
