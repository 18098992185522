export interface IFilterConfig {
  field: string;
  title: string;
  searchDisplay: string;
  search: any;
  filter: () => any;
}

export class FilterConfig implements IFilterConfig {
  field: string;
  title: string;
  searchDisplay: string;
  search: any;
  filter: () => any;

  constructor(field?: string, title?: string, searchDisplay?: string, search?: any, filter?: () => any) {
    this.field = field;
    this.title = title;
    this.searchDisplay = searchDisplay;
    this.search = search;
    this.filter = filter;
  }
}
