import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {FeedbackUserService} from '../../core/feedback-user.service';
import {Utility} from '../../core/utility';
import {IFeedbackUserSelectItem,IUserSelectItemModel} from '../../core/interfaces/select-item.interface';
import {AddNewFeedbackUserDialogComponent} from '../add-new-feedback-user-dialog/add-new-feedback-user-dialog.component';
import {IFeedbackUserModel} from '../../core/interfaces/feedback-user.interface';
import {ISelectItemModel} from '../../core/interfaces/select-item.interface';
//import {FeedbackSection} from '../../assessment-requests/edit-assessment-request/sections/feedback.section';

@Component({
  selector: 'add-existing-feedback-user-dialog',
  templateUrl: './add-existing-feedback-user-dialog.component.html',
  styleUrls: ['./add-existing-feedback-user-dialog.component.scss']
})
export class AddExistingFeedbackUserDialogComponent implements OnInit {
  @Input() public availableFeedbackUsers: IFeedbackUserSelectItem[];
  @Input() public currentFeedbackUsers: IFeedbackUserSelectItem[];
  @Input() public assessmentId: number;
 // @Input() public feedbackUserSection: FeedbackSection;
  @Output() public onUsersSelected = new EventEmitter<IFeedbackUserSelectItem[]>();
  @Output() public onAddNewFeedbackUser = new EventEmitter<IFeedbackUserModel>();
  // Input for AddNewFeedbackUser
  @Input() public requestedByUser: IUserSelectItemModel;
  @Input() public states: ISelectItemModel[] = [];
  @Input() public countries: ISelectItemModel[] = [];



  private _modal: NgbModalRef;
  public _search: string;

  public get availableUsers(): IFeedbackUserSelectItem[] {
    return this.filteredUsers.sort(Utility.sortByName);
  }

  public get hasSelectedUsers(): boolean {
    const selectedUsers = this.getSelectedUsers();
    return selectedUsers.length > 0;
  }

  private get filteredUsers(): IFeedbackUserSelectItem[] {
    return (this.availableFeedbackUsers && this.currentFeedbackUsers)
      ? this.availableFeedbackUsers.filter((afu) =>
        !this.currentFeedbackUsers.some((cfu) => cfu.userId === afu.userId && cfu.isSystem === afu.isSystem))
      : [];
  }

  constructor(public activeModal: NgbActiveModal,
              private modalService: NgbModal,
              private feedbackUserService: FeedbackUserService) {
  }

  public ngOnInit(): void {
    if (this.availableFeedbackUsers) {
      this.availableFeedbackUsers.forEach((u) => {
        u.selected = false;
        u.assessmentId = this.assessmentId;
      });
    }
  }

  public onDismiss() {
    this.activeModal.dismiss();
  }

  public onClose() {
    const selectedUsers = this.getSelectedUsers() || [];

    if (selectedUsers.length) {
      this.feedbackUserService.saveList(selectedUsers)
        .subscribe(() => {
          this.onUsersSelected.emit(this.getSelectedUsers() );
          this.activeModal.close();
        });
    }
  }

  public onClickAddNewFeedbackRecipient() {
    this._modal = this.modalService.open(AddNewFeedbackUserDialogComponent, {size: 'lg'});
    const instance = this._modal.componentInstance as AddNewFeedbackUserDialogComponent;
    instance.requestedByUser = this.requestedByUser;
    instance.states = this.states;
    instance.countries = this.countries;
    //instance.feedbackUserSection = this.feedbackUserSection;
    instance.onAddNewFeedbackUser.subscribe((newUser: IFeedbackUserModel) => {
      this.onAddNewFeedbackUser.emit(newUser);
      // const val = this.feedbackUserSection.feedbackUsersControl.value.concat([newUser]);
      // this.feedbackUserSection.feedbackUsersControl.setValue(val);
      // this.feedbackUserSection.feedbackUsersControl.markAsDirty();

    });
    this.onDismiss();
  }

  private getSelectedUsers(): IFeedbackUserSelectItem[] {
    return this.availableFeedbackUsers.filter((u) => u.verbal || u.written);
  }
}
