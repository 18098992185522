import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {UserService} from '../../core';
import {IDuplicateCandidateInfo} from '../../core/interfaces/duplicate-candidate.interface';

@Component({
  selector: 'app-duplicate-candidate-dialog',
  templateUrl: './duplicate-candidate-dialog.component.html',
  styleUrls: ['./duplicate-candidate-dialog.component.scss']
})
export class DuplicateCandidateDialogComponent implements OnInit {
  @Input() public duplicateCandidates: IDuplicateCandidateInfo[];

  constructor(private router: Router,
              public activeModal: NgbActiveModal,
              public user: UserService) {
  }

  public ngOnInit() {
    if (this.duplicateCandidates.length < 0) {
      throw new Error('clientId must be specified')
    }
  }

  public onCancel() {
    this.activeModal.dismiss();
  }

  public onYes() {
    this.activeModal.dismiss();
  }

  public onNo() {
    this.activeModal.dismiss();
    this.router.navigate(['assessment-requests']);
  }
}
