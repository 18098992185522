<div [class]="'hierarchy-node-text ' + levelClass" [id]="node.id" [ngClass]="{'clickable': canBeSelected}" *ngIf="!readOnly">
  <label class="custom-control custom-checkbox">
    <input [id]="'ck'+node.id" type="checkbox" class="custom-control-input" [disabled]="!canBeSelected" [checked]="isSelected" (change)="itemClicked()">
    <label class="custom-control-label" [for]="'ck'+node.id"><i class="fa fa-folder-o"></i>{{text}} <i *ngIf="!node.active" class="fa fa-ban text-muted" aria-hidden="true" title="Disabled"></i></label> 
    
  </label>
</div>
<div (click)="onNodeClicked()" [class]="'hierarchy-node-text ' + levelClass" [id]="node.id" *ngIf="readOnly">
  <i class="fa text-primary" style="padding-right:5px;" [ngClass]="{'fa-check-square': isSelected, 'fa-square-o': !isSelected}"></i>
  <i class="fa fa-folder-o"></i>
  {{text}} 
  <i *ngIf="!node.active" class="fa fa-ban text-muted" aria-hidden="true" title="Disabled"></i>
</div>
<div class="hierarchy-container" *ngIf="node.children && node.children.length" [id]="node.id + '-children'">
  <ps-hierarchy-node (nodeClickedOutput)="nodeClickedEvent($event)" *ngFor="let child of node.children" [node]="child" [id]="child.id" [level]="level + 1" [readOnly]="readOnly" [text]="child.name"></ps-hierarchy-node>
</div>
