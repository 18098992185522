import {FormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ICompetencyModel} from '../../../core/interfaces/competency.interface';
import {Injectable} from '@angular/core';
import {EditCompetenciesComponent} from '../../../competencies/edit-competencies/edit-competencies.component';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ClientService} from '../../../core/client.service';
import {IPositionListItemModel} from '../../../core/interfaces/position-list-item.interface';

import {SectionBase} from './base.section';
import {EditAssessmentRequestModel} from '../../edit-assessment-request.model';
import {AppService} from '../../../core/app.service';
import {PsValidators} from '../../../core/validators/count.validator';

@Injectable()
export class PositionCompetenciesSection extends SectionBase {
  private _modal: NgbModalRef;
  private servicesRequestedSection: any;
  public get competenciesControl() {
    return this.formGroup.controls['competencies'] as UntypedFormControl;
  }

  public get customCompetenciesControl() {
    return this.formGroup.controls['customCompetencies'] as UntypedFormControl;
  }

  public get notesControl() {
    return this.formGroup.controls['notes'] as UntypedFormControl;
  }

  public get isInvalid(): boolean {
    if(this.servicesRequestedSection)
      if(this.servicesRequestedSection.formValue.assessmentLevelReferenceId == 5)
        return false;
        
    if (!this.competenciesControl) {
      return true;
    }
    return (!this.competenciesControl.value || this.competenciesControl.value.length === 0) && (this.competenciesControl.dirty || this.competenciesControl.touched);
  }

  constructor(private fb: UntypedFormBuilder,
              private modalService: NgbModal,
              private clientService: ClientService,
              private appService: AppService) {
    super();
  }

  public init({assessmentRequest, servicesRequestedSection}: { assessmentRequest: EditAssessmentRequestModel, servicesRequestedSection: any }) {
    super.setAssessmentRequest(assessmentRequest);
    this.formGroup = this.buildFormGroup();
    this.servicesRequestedSection = servicesRequestedSection;
    this.competenciesControl.setValue(this.assessmentRequest.competencies);
    this.customCompetenciesControl.setValue(this.assessmentRequest.customCompetencies);
    this.notesControl.setValue(this.assessmentRequest.purposeNotes);
    this.appService.onLoggingOut.subscribe(() => {
      if (!this._modal) {
        return;
      }
      this._modal.dismiss('logging out');
    });
  }

  public get formValue(): any {
    const values = this.formGroup.value;

    const competencies = values.competencies.map((c) => {
      return {competencyId: c.competencyId, name: c.name} as ICompetencyModel;
    });

    return {
      competencies: competencies,
      customCompetencies: values.customCompetencies,
      purposeNotes: values.notes
    };
  }

  public onClickEditCompetencies(clientId: number) {
    this.clientService.getCompetencies(clientId)
      .subscribe((clientCompetencies) => {
        this.competenciesControl.markAsTouched();

        this._modal = this.modalService.open(EditCompetenciesComponent, {size: 'lg'});
        const instance = this._modal.componentInstance as EditCompetenciesComponent;
        instance.clientCompetencies = clientCompetencies;
        instance.competencies = this.competenciesControl.value;

        instance.onUpdateCompetencies.subscribe((newCompetencies) => {
          this.competenciesControl.setValue(newCompetencies);
          this.competenciesControl.markAsDirty();
        });
      });
  }

  public onPositionChange(newPosition: IPositionListItemModel) {
    if (!newPosition) {
      return;
    }
    if (newPosition.customCompetency) {
      this.customCompetenciesControl.setValue(newPosition.customCompetency);
      this.customCompetenciesControl.markAsDirty();
    }

   
  }

  protected buildFormGroup(): UntypedFormGroup {
    return this.fb.group({
      competencies: [],
      customCompetencies: [null, Validators.maxLength(4000)],
      notes: [null, Validators.maxLength(4000)]
    });
  }
}
