
import {throwError as observableThrowError, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import {ISelectItemModel} from './interfaces/select-item.interface';

@Injectable()
export abstract class ServiceBase {
  private _jsonHeaders = new HttpHeaders({'Content-Type': 'application/json'});

  protected get jsonHeaders(): HttpHeaders {
    return this._jsonHeaders;
  }

  protected extractData(res: Response) {
    return res ? res.json() : null;
  }

  protected handleError(error: Response | any) {
    if (error) {
      const errMsg = error.message ? error.message : error.toString();
      console.error(errMsg);
    }

    return observableThrowError(error);
  }

  // sort alphabetically, but make sure the item with firstItemId is always moved to the beginning of the array
  protected sortSelectItems(items: ISelectItemModel[], firstItemId: number = null): ISelectItemModel[] {
    let firstItem: ISelectItemModel = null;

    if (firstItemId) {
      const index = items.findIndex((i) => i.id === firstItemId);

      // remove item from the list
      if (index > 0) {
        firstItem = items[index];
        items.splice(index, 1);
      }
    }

    // sort the rest of the list alphabetically
    items.sort((a: ISelectItemModel, b: ISelectItemModel) => {
      if (a.name < b.name) {
        return -1
      }

      if (a.name > b.name) {
        return 1
      }

      return 0;
    });

    // add first item to the beginning of the now alphabetical list
    if (firstItem) {
      items.unshift(firstItem);
    }

    return items;
  }
}
