import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'quickSearch',
  pure: false
})
export class QuickSearchPipe implements PipeTransform {
  transform(feedbackUsers: any[], filter: string) {
    if (!feedbackUsers || !filter) {
      return feedbackUsers;
    }

    return feedbackUsers.filter(user => user.name.toLowerCase().includes(filter.toLowerCase()) || user.email.toLowerCase().includes(filter.toLowerCase()));
  }
}
