<div [ngClass]="{'has-danger': showError}" style="width: 100%;">
  <ng-content></ng-content>
</div>
<div class="validation-container">
  <i class="oh-my fa fa-exclamation-circle text-danger fa-lg" *ngIf="showError"></i>
</div>
<div class="validation-errors-container" [ngClass]="{show: showList}">
  <ul class="validation-errors">
    <li class="validation-error" *ngIf="hasError('required')">
      This field is required
    </li>
    <li class="validation-error" *ngIf="hasError('maxlength')">
      Value it too long. Max Length: {{getError('maxlength')?.requiredLength}}
    </li>
    <li class="validation-error" *ngIf="hasError('minlength')">
      Value it too short. Min Length: {{getError('minlength')?.requiredLength}}
    </li>
    <li class="validation-error" *ngIf="hasError('passwordcompare')">
      Passwords do not match.
    </li>
    <li class="validation-error" *ngIf="hasError('email')">
      Please provide a valid email
    </li>
    <li class="validation-error" *ngIf="hasError('pattern')">
      Please provide a valid email
    </li>
    <li class="validation-error" *ngIf="hasError('userNameExists')">
      Username already exists
    </li>
    <li class="validation-error" *ngIf="hasError('clientNameExists')">
      Client name already exists
    </li>
    <li class="validation-error" *ngIf="hasError('nameExists')">
      Name already exists
    </li>
    <li class="validation-error" *ngIf="hasError('password')">
      Password does not meet complexity.
      <ul>
        <li>Must be between 6 and 18 characters.</li>
        <li>Must have 1 letter.</li>
        <li>Must have 1 number</li>
        <li>Must have 1 special char !@#$%^&*()_-=</li>
      </ul>
    </li>
  </ul>
</div>
<ng-content select="ps-hint"></ng-content>

