import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ISelectItemModel} from '../../../core/interfaces/select-item.interface';
import {ICandidateModel} from '../../../core/interfaces/candidate.interface';
import {Injectable} from '@angular/core';
import {AttachmentService} from '../../../core/attachment.service';
import {SectionBase} from './base.section';
import {EditAssessmentRequestModel} from '../../edit-assessment-request.model';
import {UploadErrorDialogComponent} from '../../../shared/upload-error-dialog/upload-error-dialog.component';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { PsValidators } from 'app/core/validators/email.validator';

@Injectable()
export class CandidateSection extends SectionBase {
  private _languages: ISelectItemModel[];
  private _modal: NgbModalRef;

  constructor(private fb: UntypedFormBuilder,
              private attachmentService: AttachmentService,
              private modalService: NgbModal,) {
    super();
  }

  public get typeControl() {
    return this.formGroup.controls['type'] as UntypedFormControl;
  }

  public get positionControl() {
    return this.formGroup.controls['position'] as UntypedFormControl;
  }

  public get confidentialControl() {
    return this.formGroup.controls['confidential'] as UntypedFormControl;
  }

  public get firstNameControl() {
    return this.formGroup.controls['firstName'] as UntypedFormControl;
  }

  public get middleNameControl() {
    return this.formGroup.controls['middleName'] as UntypedFormControl;
  }

  public get lastNameControl() {
    return this.formGroup.controls['lastName'] as UntypedFormControl;
  }

  public get workPhoneControl() {
    return this.formGroup.controls['workPhone'] as UntypedFormControl;
  }

  public get mobilePhoneControl() {
    return this.formGroup.controls['mobilePhone'] as UntypedFormControl;
  }

  public get emailControl() {
    return this.formGroup.controls['email'] as UntypedFormControl;
  }

  public get genderControl() {
    return this.formGroup.controls['gender'] as UntypedFormControl;
  }

  public get languageControl() {
    return this.formGroup.controls['language'] as UntypedFormControl;
  }

  public get noResumeControl() {
    return this.formGroup.controls['noResume'] as UntypedFormControl;
  }

  public get resumeAttachmentIdControl() {
    return this.formGroup.controls['resumeAttachmentId'] as UntypedFormControl;
  }

  public get originalFilenameControl() {
    return this.formGroup.controls['originalFilename'] as UntypedFormControl;
  }

  public init({assessmentRequest, languages}: { assessmentRequest: EditAssessmentRequestModel, languages: ISelectItemModel[] }) {
    super.setAssessmentRequest(assessmentRequest);
    this.formGroup = this.buildFormGroup();

    if (!languages) {
      throw new Error('languages must be specified');
    }

    this._languages = languages;

    const candidate = this.assessmentRequest.candidate;

    this.typeControl.setValue(candidate.candidateType);
    this.positionControl.setValue(candidate.position);
    this.confidentialControl.setValue(this.assessmentRequest.isConfidential);
    this.firstNameControl.setValue(candidate.firstName);
    this.middleNameControl.setValue(candidate.middleName);
    this.lastNameControl.setValue(candidate.lastName);
    this.workPhoneControl.setValue(candidate.phoneWork);
    this.mobilePhoneControl.setValue(candidate.phoneMobile);
    this.emailControl.setValue(candidate.email);
    this.genderControl.setValue(candidate.gender);

    const language = candidate && candidate.languageId
      ? this._languages.find((l) => l.id === candidate.languageId)
      : null;

    this.languageControl.setValue(language);

    this.noResumeControl.setValue(this.assessmentRequest.candidateNoResume);
    this.resumeAttachmentIdControl.setValue(this.assessmentRequest.resumeAttachmentId);
    this.originalFilenameControl.setValue(this.assessmentRequest.originalFilename);

    // if (this.assessmentRequest.hasParent) {
    //   this.formGroup.disable();
    // }
  }

  public clear() {
    this.typeControl.setValue(null);
    this.positionControl.setValue(null);
    this.confidentialControl.setValue(false);
    this.firstNameControl.setValue(null);
    this.middleNameControl.setValue(null);
    this.lastNameControl.setValue(null);
    this.workPhoneControl.setValue(null);
    this.mobilePhoneControl.setValue(null);
    this.emailControl.setValue(null);
    this.genderControl.setValue(null);
    this.languageControl.setValue(null);
    this.noResumeControl.setValue(false);
    this.resumeAttachmentIdControl.setValue(null);
    this.originalFilenameControl.setValue(null);
  }

  public get formValue(): any {
    const values = this.formGroup.value;

    const candidateModel = {
      candidateType: values.type,
      position: values.position,
      firstName: values.firstName,
      middleName: values.middleName,
      lastName: values.lastName,
      phoneWork: values.workPhone,
      phoneMobile: values.mobilePhone,
      email: values.email,
      gender: values.gender,
      languageId: values.language ? values.language.id : null
    } as ICandidateModel;

    return {
      candidate: candidateModel,
      candidateNoResume: values.noResume,
      resumeAttachmentId: values.resumeAttachmentId,
      isConfidential: values.confidential
    };
  }

  public onUploadResumeFileChange(event) {
    const fileList: FileList = event.target.files;

    if (fileList.length) {
      const file: File = fileList[0];
      this.attachmentService.upload(file).subscribe(
        (attachmentId) => {
          this.resumeAttachmentIdControl.setValue(attachmentId);
          this.resumeAttachmentIdControl.markAsDirty();
          this.originalFilenameControl.setValue(file.name);
      },
       error => {
          this._modal = this.modalService.open(UploadErrorDialogComponent);
          const errorDialogInstance = this._modal.componentInstance as UploadErrorDialogComponent;
          errorDialogInstance.title = 'Error Uploading File';
          errorDialogInstance.body = `An error occurred while trying to attach the resume.
          <br/>
          <br/>Please try again or contact us at:
          <br/><a href='tel:314-993-8008'>314-993-8008</a> or <a href='mailto:testing@leadershipall.com'>testing@leadershipall.com</a>.
          <br/>`;
          errorDialogInstance.onConfirm.subscribe(
            (isConfirmed) => {
              if (isConfirmed) {
                event.target.value = '';
              }
          })
       });
    }
  }

  protected buildFormGroup(): UntypedFormGroup {
    return this.fb.group({
      type: [null, Validators.required],
      position: [null, Validators.maxLength(200)],
      confidential: null,
      firstName: [null, [Validators.required, Validators.maxLength(200)]],
      middleName: [null, Validators.maxLength(200)],
      lastName: [null, [Validators.required, Validators.maxLength(200)]],
      workPhone: [null, [Validators.maxLength(50)]],
      mobilePhone: [null, Validators.maxLength(50)],
      email: [null, Validators.compose([PsValidators.email(), Validators.required, Validators.maxLength(254)])],
      gender: [null, Validators.required],
      language: [null, Validators.required],
      noResume: null,
      resumeAttachmentId: null,
      originalFilename: null
    });
  }

}
