import {Component, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {FeedbackUserService} from '../../core/feedback-user.service';
import {AuthService} from '../../auth/auth.service';
import {ISelectItemModel} from '../../core/interfaces/select-item.interface';
import {ClientService} from '../../core/client.service';
import {ViewButtonRendererComponent} from '../view-button-renderer/view-button-renderer.component';
import {isNullOrUndefined} from 'util';
import {UserService} from '../../core/user.service';
import {LocalDataSource} from '../../piquant-table/data/local-datasource';
import {ITableAction,ITableSettings} from '../../piquant-table/models/';
import {IAdditionalUserModel} from '../../core/interfaces/additional-user.interface';
import {AddNewFeedbackUserDialogComponent} from '../add-new-feedback-user-dialog/add-new-feedback-user-dialog.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {StateService} from '../../core/state.service';
import {CountryService} from '../../core/country.service';
import {IFeedbackUserSelectItem,IUserSelectItemModel} from '../../core/interfaces/select-item.interface';
import {GridOptions, RowNode, Column, SelectionChangedEvent} from "ag-grid-community";
import { DetailCellRendererComponent } from '../detail-cell-renderer/detail-cell-renderer.component';
import { Router } from '@angular/router';
import {FilterUsersStatusComponent} from '../filter-grid-status/filter-grid-status.component';

@Component({
    selector: 'manage-external-recipients',
   templateUrl: './manage-external-recipients.component.html',
   host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class ManageExternalRecipientsComponent implements OnInit {
  public alertMessage: string;
  public externalUsers: IAdditionalUserModel[] = [];
  public externalUsersDataSource: LocalDataSource = new LocalDataSource();
  public clients: ISelectItemModel[] = [];
  public client: ISelectItemModel = null;
  public users: ISelectItemModel[] = [];
  public ownerUser: ISelectItemModel = null;
  public ownerUserWithClient: IUserSelectItemModel = null;
  public psTableSettings: ITableSettings;
  private _saveSuccess = new Subject<string>();
  private states: ISelectItemModel[] = null;
  private countries: ISelectItemModel[] = null;
  public gridOptions: GridOptions;
  public selectedPaginationOption = 2;
  public inputQuickSearch: string;
  private previousNodeSelection: RowNode = null;
  public paginationOptions = [
    {id: 0, name: "10"},
    {id: 1, name: "50"},
    {id: 2, name: "100"},
    {id: 3, name: "500"}
  ];

  constructor(private feedbackService: FeedbackUserService,
              private authService: AuthService,
              private clientService: ClientService,
              private userService: UserService,
              private modalService: NgbModal,
              private stateService: StateService,
              private countryService: CountryService) {
    this.gridOptions = <GridOptions>{
      masterDetail: true,
      detailCellRenderer: DetailCellRendererComponent,
      detailRowHeight: (115+(4/((window.innerWidth-263)/75))*47),
      columnDefs: this.createColumnDefs(),
      rowHeight: 48, // recommended row height for material design data grids,
      headerHeight: 38,
      suppressCellFocus: true,
      suppressMenuHide: true,
      animateRows: true,
      pagination: true,
      onSortChanged: () => this.onColumnChanged(),
      onColumnResized: () => this.onColumnChanged(),
      onFilterChanged: (event) => this.onFilterChanged(event),
      onRowClicked: (event) => this.onSelectionChanged(event),
      context: { componentParent: this },   // Make this component (and its members) available to child components.
      
      defaultColDef: {
        resizable: true,
        sortable: true
      }
    }
  }

  onResize(event) {
    localStorage.setItem('usersWindowWidth', JSON.stringify(event.target.innerWidth));
    this.gridOptions.api.sizeColumnsToFit();
  }

  public onSelectionChanged(event)
  {
    var selectedNode: RowNode = event.node;
    if(this.previousNodeSelection != null){
      //Colapse the open detail if one was open
      this.previousNodeSelection.setExpanded(false);
      if(this.previousNodeSelection == selectedNode){
        //Deselect if the user clicked on the same node
        selectedNode.setSelected(false);
        selectedNode = null;
      }
    }
    
    //If there's still a selected node, expand it
    if(selectedNode != null){
      selectedNode.setExpanded(true);
    }
    //and cache it as the previous selection.
    this.previousNodeSelection = selectedNode;
  }

  public onColumnChanged() {
    localStorage.setItem('gridRecipientsColumnState' + this.getImpersonatedUserId(), JSON.stringify(this.gridOptions.columnApi.getColumnState())); //get/applyColumnState()
  }

  public onFilterChanged(event) {
    localStorage.setItem('gridRecipientsFilterModel' + this.getImpersonatedUserId(), JSON.stringify(this.gridOptions.api.getFilterModel()));
  }

  public onFilterTextBoxChanged() {
    this.gridOptions.api.setQuickFilter(this.inputQuickSearch);
  }

  public getImpersonatedUserId(): string {
    return (this.authService.impersonatedUser != null) ? this.authService.impersonatedUser.userId.toString() : this.authService.userId.toString();
  }

  private createColumnDefs() {
    return [
      {
        headerName: 'Name',
        field: 'name',
        valueFormatter: 'value',
        filter: 'agTextColumnFilter',
        menuTabs: ['filterMenuTab'],
        sort: 'desc',
        cellRendererFramework: ViewButtonRendererComponent,
        icons: {
          sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
          sortDescending: '<i class="fa fa-sort-alpha-desc"/>',
          filter: '<i class="fa fa-search"/>',
          menu: '<i class="fa fa-filter"/>'
        }
      },
      {
        headerName: 'Title',
        field: 'titlePos',
        filter: 'agTextColumnFilter',
        menuTabs: ['filterMenuTab'],
        icons: {
          sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
          sortDescending: '<i class="fa fa-sort-alpha-desc"/>',
          filter: '<i class="fa fa-search"/>',
          menu: '<i class="fa fa-filter"/>'
        }
      },
      {
        headerName: 'Email',
        field: 'email',
        filter: 'agTextColumnFilter',
        menuTabs: ['filterMenuTab'],
        icons: {
          sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
          sortDescending: '<i class="fa fa-sort-alpha-desc"/>',
          filter: '<i class="fa fa-search"/>',
          menu: '<i class="fa fa-filter"/>'
        }
      },
      {
        headerName: 'Phone',
        field: 'phone',
        filter: 'agNumberColumnFilter',
        menuTabs: ['filterMenuTab'],
        icons: {
          sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
          sortDescending: '<i class="fa fa-sort-alpha-desc"/>',
          filter: '<i class="fa fa-search"/>',
          menu: '<i class="fa fa-filter"/>'
        }
      },
      {
        headerName: 'State',
        field: 'stateName',
        filter: 'agTextColumnFilter',
        menuTabs: ['filterMenuTab'],
        icons: {
          sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
          sortDescending: '<i class="fa fa-sort-alpha-desc"/>',
          filter: '<i class="fa fa-search"/>',
          menu: '<i class="fa fa-filter"/>'
        }
      },
      {
        headerName: 'Country',
        field: 'countryName',
        filter: 'agTextColumnFilter',
        menuTabs: ['filterMenuTab'],
        icons: {
          sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
          sortDescending: '<i class="fa fa-sort-alpha-desc"/>',
          filter: '<i class="fa fa-search"/>',
          menu: '<i class="fa fa-filter"/>'
        }
      },
      {
        headerName: 'Active',
        field: 'isActive',
        valueFormatter: this.statusCellFormatter,
        filterFramework: FilterUsersStatusComponent,
        menuTabs: ['filterMenuTab'],
        icons: {
          sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
          sortDescending: '<i class="fa fa-sort-alpha-desc"/>',
          filter: '<i class="fa fa-search"/>',
          menu: '<i class="fa fa-filter"/>'
        }
      },
    ];
  }

  private statusCellFormatter(params) {
    if (params.value) {
      return 'Active';
    }
    return 'Inactive';
  }

  private buildTableActions(): ITableAction[] {
    return [
      {
        name: 'Edit',
        action: (row: any) => {
          this.openEdit(row);
        },
        iconClass: 'fa fa-edit',
        actionClass: 'btn btn-link'
      }, {
        name: 'Toggle Activation',
        action: (row: any) => {
          const user = row as IAdditionalUserModel;
          this.feedbackService.toggleAdditionalUserActivation(user.additionalUserId)
            .subscribe((res) => {
              if (res.ok) {
                user.isActive = !user.isActive;
                this.loadFeedBackUsers(this.ownerUserId);
              }
            });
        },
        iconClass: 'fa fa-exchange',
        actionClass: 'btn btn-link'
      }
    ];
  }

  public ngOnInit() {
    if (this.authService.isRoleAdmin) {
      this.clientService.getSelectItems().subscribe((data: ISelectItemModel[]) => {
        this.clients = data;
      });
    } else {
      this.loadFeedBackUsers(this.authService.userId);
      this.ownerUserWithClient = { id: this.authService.userId, name: this.authService.userName, clientId: this.authService.clientId, clientName: this.authService.clientName, selected: true }
    }
    this.stateService.getSelectItems().subscribe((states) => {
      this.states = states;
    });
    this.countryService.getSelectItems().subscribe((countries) => {
      this.countries = countries;
    });
    this._saveSuccess.subscribe((message) => this.alertMessage = message);
    debounceTime.call(this._saveSuccess, 2000).subscribe(() => this.alertMessage = null);
  }

  public onClientChanged(selectedClient: ISelectItemModel) {
    if (isNullOrUndefined(selectedClient)) {
      this.resetOwnerUsers();
      this.externalUsers = [];
      this.client = null;
    } else {
      this.client = selectedClient;
      this.loadOwnerUsers(selectedClient.id);
    }
  }

  public onOwnerUserChanged(selectedUser: ISelectItemModel) {
    if (isNullOrUndefined(selectedUser)) {
      this.ownerUser = null;
      this.externalUsers = [];
    } else {
      this.ownerUser = selectedUser;
      this.ownerUserWithClient = { id: this.ownerUser.id, name: this.ownerUser.name, clientId: this.client.id, clientName: this.client.name, selected: this.ownerUser.selected }
      this.loadFeedBackUsers(this.ownerUser.id);
    }
  }

  public submit() {

  }

  public cancel() {

  }

  public ngAfterViewInit() {
    this.gridOptions.api.setRowData(null);
    this.loadGridPreferences();
  }

  public setPageSize(paginationOption) {
    this.gridOptions.paginationAutoPageSize = false;
    this.gridOptions.api.paginationSetPageSize(Number( (this.paginationOptions[paginationOption]) ? this.paginationOptions[paginationOption].name : 100));
  }

  public get isAdmin(): boolean {
    return this.authService.isRoleAdmin;
  }

  public get ownerUserId(): number {


    if (this.authService.isRoleAdmin) {
      return isNullOrUndefined(this.ownerUser) ? null : this.ownerUser.id;
    } else {
      return this.authService.userId;
    }
  }

  public loadGridPreferences() {
    //Handle loading saved Sort, Filter, and Column settings.
    var columnState = localStorage.getItem('gridRecipientsColumnState' + this.getImpersonatedUserId());  //gridColumnState'+this.getImpersonatedUserId(), JSON.stringify(this.gridOptions.columnApi.getColumnState()
    if (columnState != null) {
      this.gridOptions.columnApi.applyColumnState(JSON.parse(columnState));
      //Prevent poor column saved states when a user's window size changes between sessions.
      if (+localStorage.getItem('RecipientsWindowWidth') != window.innerWidth) {
        this.gridOptions.api.sizeColumnsToFit();
        localStorage.setItem('RecipientsWindowWidth', JSON.stringify(window.innerWidth));
      }
    }
    else {
      this.gridOptions.api.sizeColumnsToFit();
      this.resetFilters();
    }
    var filterModel = localStorage.getItem('gridRecipientsFilterModel' + this.getImpersonatedUserId());
    if (filterModel != null) {
      const thisModel = JSON.parse(filterModel);
      this.gridOptions.api.setFilterModel(thisModel);
    }
    else
      this.resetFilters();
    //Also attempt to load pagination selection
    var paginationOption = localStorage.getItem('gridRecipientsPagination' + this.getImpersonatedUserId());
    if (paginationOption != null)
      this.onChangePaginationOption(Number(paginationOption));
  }

  public resetFilters() {
    //Reset Pagination Option
    this.onChangePaginationOption(2);
    // each column has to have filters removed.
    for (let column of this.gridOptions.columnApi.getAllColumns()) {
      this.gridOptions.api.destroyFilter(column);
    }
    // Now cleanup the quick search -> reset the input element
    this.inputQuickSearch = '';
    // and reset the grid quickfilter
    this.gridOptions.api.setQuickFilter('');
    // reset the default sort.
    this.gridOptions.columnApi.resetColumnState();
    // finally raise the events...
    this.gridOptions.api.onSortChanged();
    this.gridOptions.api.onFilterChanged();
    this.gridOptions.api.sizeColumnsToFit();
  }

  public onChangePaginationOption(option) {
    this.selectedPaginationOption = option; // event.target.value;
    this.setPageSize(this.selectedPaginationOption);
    localStorage.setItem('gridRecipientsPagination'+this.getImpersonatedUserId(), this.selectedPaginationOption.toString());
  }

  public openAdd() {
    this.openModal();
  }

  public openEdit(editUser: IAdditionalUserModel) {
    this.openModal(editUser);
  }

  private openModal(editUser: IAdditionalUserModel = null) {
    const addModal = this.modalService.open(AddNewFeedbackUserDialogComponent, {size: 'lg'});
    const instance = addModal.componentInstance as AddNewFeedbackUserDialogComponent;
    if (editUser) {
      instance.editAdditionalUser = editUser;
    }
    // instance.ownerUserId = this.ownerUserId;
    instance.requestedByUser = this.ownerUserWithClient;
    instance.states = this.states;
    instance.countries = this.countries;
    instance.onAddNewFeedbackUser.subscribe(() => {
      this.loadFeedBackUsers(this.ownerUserId);
      this.showSaveSuccess();
    });
  }

  private loadOwnerUsers(clientId: number) {
    this.userService.getUsersByClientId(clientId, true).subscribe(data => {
      this.users = data;
      
    });
  }

  private loadFeedBackUsers(ownerUserid: number) {
    this.externalUsers = [];
    this.feedbackService.getAvailableExternal(ownerUserid, true).subscribe(data => {
      this.externalUsers = data;
      this.externalUsersDataSource.load(this.externalUsers);
      this.gridOptions.api.setRowData(data);
    });
  }

  private resetOwnerUsers() {
    this.users = [];
    this.ownerUser = null;
  }

  private showSaveSuccess() {
    this._saveSuccess.next('Saved Successfully');
  }

}
