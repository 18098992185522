import {Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {HierarchyNode} from '../../core/hierarchynode/ps-hierarchy-node';
import {HierarchyManagerService} from '../../services/hierarchy-manager.service';
import {Subscription} from 'rxjs';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'ps-hierarchy',
  templateUrl: './ps-hierarchy-select.html',
  styleUrls: ['./ps-hierarchy-select.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [HierarchyManagerService, {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PsHierarchyComponent),
    multi: true
  }]
})
export class PsHierarchyComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() multiSelect = false;
  @Input() readOnly = false;
  @Input() selectedNode: HierarchyNode;
  @Input() nodes: HierarchyNode[];
  @Input() showRootNode = false;
  @Input() rootNodeName = 'Root';
  @Input() rootNodeSelectable = false;
  @Input() rootNodeClearsSelection = false;
  @Output() nodeSelectionChanged: EventEmitter<HierarchyNode[]> = new EventEmitter<HierarchyNode[]>();
  @Output() nodeClicked: EventEmitter<HierarchyNode> = new EventEmitter<HierarchyNode>();

  private onItemSelectionChangedSub: Subscription;
  private selectedNodes: HierarchyNode[] = [];
  public rootSelected = false;

  constructor(private hierarchyService: HierarchyManagerService) {  }

  ngOnInit(): void {
    this.hierarchyService.multiSelect = this.multiSelect;
    this.selectedNodes = this.getSelectedNodes(this.nodes, []);
    this.onItemSelectionChangedSub = this.hierarchyService.onSelectionChanged$.subscribe((changedNode) => {
      // Here we want to handle a change selection and let listening components know.

      if (this.hierarchyService.multiSelect) {
        this.selectedNodes = this.getSelectedNodes(this.nodes, []);
      } else {
        this.selectedNodes = [changedNode];
      }

      if (changedNode.selected && this.rootNodeSelectable) {
        this.rootSelected = false;
      }

      this.checkSelectRoot();
      // Let the world know.
      this.nodeSelectionChanged.emit(this.selectedNodes);
      this.writeValue(changedNode);
    });
    this.checkSelectRoot();
  }

  public onNodeClicked(event){
    this.nodeClicked.emit(event);
  }

  public rootClicked() {
    if (!this.rootNodeSelectable) {
      return;
    }

    this.rootSelected = !this.rootSelected;
    if (this.rootSelected && this.rootNodeClearsSelection) {
      this.clearSelection(this.nodes);
      this.selectedNodes = [];
      this.nodeSelectionChanged.emit(this.selectedNodes);
    }
  }

  public writeValue(obj: HierarchyNode): void {
    if (obj) {
      const ids = this.selectedNodes.map(e => {return e.id});
      this.propagateChange(ids);
    }
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  private propagateChange = (v: any) => {};
  private propagateTouch = (v: any) => {};

  private checkSelectRoot() {
    if (!this.selectedNodes || this.selectedNodes.length === 0){
      this.rootSelected = true;
    }
  }

  private getSelectedNodes(nodes: HierarchyNode[], selectedNodes: HierarchyNode[] = []): HierarchyNode[] {
    for (const node of nodes) {
      if (node.selected) {
        selectedNodes.push(node);
      }
      if (node.children.length > 0) {
        this.getSelectedNodes(node.children, selectedNodes);
      }
    }
    return selectedNodes;
  }

  private clearSelection(nodes: HierarchyNode[]) {
    for (const node of nodes) {
      node.selected = false;
      this.hierarchyService.itemSelectChange(node);
      if (node.children && node.children.length > 0) {
        this.clearSelection(node.children);
      }
    }
  }

  ngOnDestroy(): void {
    this.onItemSelectionChangedSub.unsubscribe();
  }
}

