import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'candidateType'})
export class CandidateTypePipe implements PipeTransform {

  transform(value: string): string {
    switch (value.toLowerCase()) {
      case 'i': {
        return 'Internal';
      }
      case 'e': {
        return 'External';
      }
      default : {
        return 'Unknown';
      }
    }
  }
}
