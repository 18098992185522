import { Component } from '@angular/core';
import {IFilterAngularComp} from "ag-grid-angular";
import {AssessmentRequestStatus} from '../../core/enums/assessment-request-status.enum';
import {AuthService} from '../../auth/auth.service';
import {IAfterGuiAttachedParams, IDoesFilterPassParams, IFilterParams, RowNode} from "ag-grid-community";
@Component({
  selector: 'app-filter-hiring-outcome-poll-hidden',
  template: '',
  styles: ['']
})

export class FilterHiringOutcomePollHiddenComponent implements IFilterAngularComp {
  private params: IFilterParams;

  agInit(params: IFilterParams): void {
    this.params = params;
  }

  constructor(private authService: AuthService) { }

  isFilterActive(): boolean {
    return true;
  }

  //returns bool to indicate whether the row will appear in result list.
  doesFilterPass(params: IDoesFilterPassParams): boolean {
    //Here be all the rest of the filters we need that don't really need their own column
    var theRestOfIt: boolean = false;
    if(params.node.data.statusId == AssessmentRequestStatus.Complete &&
      (params.node.data.candidateType == "E" || (params.node.data.candidateType == "I" && params.node.data.assessmentPurposeId == 4) )&&
      params.node.data.parentAssessmentRequestId == null
       ){
        theRestOfIt = true;
    }
    //Return the result
    return theRestOfIt;
  }

  public getImpersonatedUserId(): string
  {
    return (this.authService.impersonatedUser != null) ? this.authService.impersonatedUser.userId.toString() : this.authService.userId.toString();
  }

  //Returns the current filter options.
  getModel(): any {
      return {};
  }

  //Sets the current filter options.
  setModel(model: any): void {
    
  }

  onChange(event) {
    this.params.filterChangedCallback();

  }

}

