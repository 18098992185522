export class ChangePasswordTokenRequest {
  token: string;
  username: string;
  newPassword: string;
  confirmPassword: string;
}

export class ChangePasswordTokenResponse {
  success: boolean;
  message: string;
}
