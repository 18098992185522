
import {throwError as observableThrowError, defer as observableDefer, Observable} from 'rxjs';
import {map, publishReplay, refCount, take, catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ISelectItemModel, IUserSelectItemModel} from './interfaces/select-item.interface';
import {ServiceBase} from './service-base';
import {AuthHttpService} from '../auth/auth-http.service';
import {IUserAuthModel, IUserModel} from './interfaces/user.interface';
import {IEditUserModel} from './interfaces/edit-user.interface';
import {UserRole} from './models/user.model';
import {IUserBillingContactModel} from './interfaces/billing-contact.interface';
import {IDuplicateCandidateInfo} from './interfaces/duplicate-candidate.interface';
import {PositionService} from './position.service';
import {ICompetencyModel} from './interfaces/competency.interface';
import {HttpClient, HttpResponse} from '@angular/common/http';

@Injectable()
export class UserService extends ServiceBase {
  private static ApiUrl = 'api/user';
  private static ProxyApiUrl = 'api/proxyusers';
  private data$: Observable<IUserSelectItemModel[]>;
  private cacheDuration: number = 1000 * 60 * 5;

  constructor(private http: HttpClient) {
    super();
  }

  public getSelectItems(): Observable<IUserSelectItemModel[]> {
    if (!this.data$) {
      const url = UserService.ApiUrl + '/list';

      this.data$ = observableDefer(() => this.http.get(url)).pipe(
        map((res: Response) => (res || []) as IUserSelectItemModel[]),
        publishReplay(1, this.cacheDuration),
        refCount(),
        take(1)
      );
    }

    return this.data$;
  }

  public getConsultantSelectItems(): Observable<ISelectItemModel[]> {
    const url = UserService.ApiUrl + '/list?role=' + UserRole.Consultant;
    return this.http.get<ISelectItemModel[]>(url);
  }

  public getAvailableProxyUsers(userId: number): Observable<ISelectItemModel[]> {
    const url = UserService.ProxyApiUrl + '?userId=' + userId;
    return this.http.get<ISelectItemModel[]>(url)
  }

  public getSelectItemsWithoutImpersonation(): Observable<IUserSelectItemModel[]> {
    const url = UserService.ApiUrl + '/list?impersonate=false';
    return this.http.get<IUserSelectItemModel[]>(url);
  }

  public getUsers(): Observable<IUserModel[]> {
    return this.http.get<IUserModel[]>(UserService.ApiUrl);
  }

  public getUsersByClientId(clientId: number, isActive: boolean = null): Observable<ISelectItemModel[]> {
    let url = `${UserService.ApiUrl}/client/${clientId}`;
    if (isActive != null) {
      url += `?isActive=${isActive}`;
    }
    return this.http.get(url).pipe(
      map((res: Response) => (res || []) as ISelectItemModel[])
    );
  }

  public getUsersWithOrgsByClientId(clientId: number, isActive: boolean = null): Observable<IUserAuthModel[]> {
    let url = `${UserService.ApiUrl}/clientorgs/${clientId}`;
    if (isActive != null) {
      url += `?isActive=${isActive}`;
    }
    return this.http.get(url).pipe(
      map((res: Response) => (res || []) as IUserAuthModel[])
    );
  }

  public getUser(id: number): Observable<IUserModel> {
    const url = UserService.ApiUrl + '/' + id;
    return this.http.get<IUserModel>(url);
  }

  public getUserForEdit(id: number): Observable<IEditUserModel> {
    const url = UserService.ApiUrl + '/edit/' + id;
    return this.http.get<IEditUserModel>(url);
  }

  public checkUserName(username: string): Observable<boolean> {
    const url = UserService.ApiUrl + '/checkname/' + username;
    return this.http.get<boolean>(url);
  }


  public getProfileForEdit(): Observable<IEditUserModel> {
    const url = UserService.ApiUrl + '/edit-profile';
    return this.http.get<IEditUserModel>(url);
  }

  public getBillingContacts(id: number): Observable<IUserBillingContactModel[]> {
    const url = `${UserService.ApiUrl}/${id}/billing-contact`;
    return this.http.get<IUserBillingContactModel[]>(url);
  }

  public getUserAuth(id?: number): Observable<IUserAuthModel> {
    let url = `${UserService.ApiUrl}/auth`;

    if (id) {
      url += `/${id}`;
    }

    return this.http.get<IUserAuthModel>(url);
  }

  public getDuplicateCandidateInfo(clientId: number, email: string): Observable<IDuplicateCandidateInfo[]> {
    const url = `${UserService.ApiUrl}/duplicate-candidate?clientId=${clientId}&email=${email}`;
    return this.http.get<IDuplicateCandidateInfo[]>(url);
  }

  public save(user: IEditUserModel): Observable<HttpResponse<any>> {
    return (user.userId)
      ? this.update(user)
      : this.add(user);
  }

  public saveProfile(user: IEditUserModel): Observable<HttpResponse<any>> {
    return this.updateProfile(user);
  }

  public clearCache(): void {
    this.data$ = null;
  }

  public transferUser(fromUserId: number, toUserId: number) {
    const url = `${UserService.ApiUrl}/transfer-user`;
    const json = JSON.stringify({'fromUserId': fromUserId, 'toUserId': toUserId});
    return this.http.post(url, json, {headers: this.jsonHeaders});
  }

  public resendWelcome(userId: number) {
    const url = `${UserService.ApiUrl}/resend-welcome/${userId}`;
    return this.http.get(url);
  }

  private update(user: IEditUserModel): Observable<HttpResponse<any>> {
    const url = `${UserService.ApiUrl}/${user.userId}`;
    const json = JSON.stringify(user);

    return this.http.put<HttpResponse<any>>(url, json, {headers: this.jsonHeaders});
  }

  private updateProfile(user: IEditUserModel): Observable<HttpResponse<any>> {
    const url = `${UserService.ApiUrl}/edit-profile`;
    const json = JSON.stringify(user);

    return this.http.put<HttpResponse<any>>(url, json, {headers: this.jsonHeaders});
  }

  private add(user: IEditUserModel): Observable<HttpResponse<any>> {
    const json = JSON.stringify(user);

    return this.http.post<HttpResponse<any>>(UserService.ApiUrl, json, {headers: this.jsonHeaders});
  }

  public saveProxyUsers(userId: number, ids: number[]): Observable<HttpResponse<any>> {
    const url = `${UserService.ProxyApiUrl}/${userId}`;
    const json = JSON.stringify(ids);

    return this.http.put<HttpResponse<any>>(url, json, {headers: this.jsonHeaders, observe: "response"}).pipe(
      catchError((res: Response) => {
        this.handleError(null);
        return observableThrowError(res);
      })
    );
  }
}
