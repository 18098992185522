import {Injectable} from '@angular/core';
import {ActionCanLoad} from './models/actionCanLoad';

@Injectable()
export class ActionSecurityService {

  checkCanLoad(canLoad: any[]): boolean {
    let safe = true;
    if (!canLoad) {
      return safe;
    }

    for (let guard of canLoad) {
      if (!this.isCanLoadType(guard)) {
        continue;
      }

      guard = guard as ActionCanLoad;
      const guardCanLoad = guard.canLoad();

      if (guardCanLoad) {
        continue;
      }

      safe = false;
      break;
    }

    return safe;
  }

  private isCanLoadType(value: any): value is ActionCanLoad {
    return (typeof value.canLoad === 'function');
  }
}
