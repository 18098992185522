import {Component, ViewChild, ElementRef} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {IAdditionalServiceAttachmentModel, IAssessmentLevelAttachmentModel, IAttachmentBaseModel} from '../../core/interfaces/attachment-base.interface';
import {IAssessmentTestingScheduleModel} from '../../core/interfaces/assessment-testing-schedule.interface';
import {IAdditionalServiceReferenceModel} from '../../core/interfaces/additional-service-reference.interface';
import {format} from 'date-fns';
import { AdditionalServiceReferenceTypes } from 'app/core/enums/additional-service-types.enum';
import { IAssessmentLevelReferenceModel } from 'app/core/interfaces/assessment-level-reference.interface';
import { AuthService } from 'app/auth/auth.service';
import {NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {EmailReportDialogComponent} from '../email-report-dialog/email-report-dialog.component';
import {AttachmentService} from '../../core/attachment.service';
import { UploadErrorDialogComponent } from 'app/shared/upload-error-dialog/upload-error-dialog.component';
import {NgbTooltip} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-download-reports',
  templateUrl: './download-reports.component.html',
  styleUrls: ['./download-reports.component.scss']
})
export class DownloadReportsComponent {
  private _modal: NgbModalRef;
  private _assessmentLevelAttachments: IAssessmentLevelAttachmentModel[];
  private _additionalServiceAttachments: IAdditionalServiceAttachmentModel[];
  public assessmentRequestId: number;
  public testingSchedule: IAssessmentTestingScheduleModel;
  public additionalServiceReferences: IAdditionalServiceReferenceModel[];
  private _additionalServiceAttachmentsRaw: IAdditionalServiceAttachmentModel[];
  public disableDownload: boolean;
  constructor(private activatedRoute: ActivatedRoute,
                  private authService: AuthService,
                  private router: Router,
                  private modalService: NgbModal,
                  private AttachmentService: AttachmentService) {
    this.activatedRoute.params.subscribe((params: Params) => {
      const id = params['id'];
      this.assessmentRequestId = +id;

    });

    this.activatedRoute.data.subscribe((data: any) => {
      this._assessmentLevelAttachments = data.assessmentLevelAttachments || [];
      this._additionalServiceAttachments = data.additionalServiceAttachments || [];
      this.testingSchedule = data.testingSchedule;
      this.additionalServiceReferences = data.additionalServiceReferences || [];
      // only interested in actual reports (where there's an attachment ID)
      this._assessmentLevelAttachments = this._assessmentLevelAttachments.filter((ala) => ala.attachmentId);
      this._additionalServiceAttachmentsRaw = this._additionalServiceAttachments.map(x => Object.assign({}, x));
      this._additionalServiceAttachments = this._additionalServiceAttachments.filter((asa) => asa.attachmentId);
    });
  }

  public disableEmailTooltipText(attachment: IAttachmentBaseModel){
    var disableEmailToolTip = '';
    
    if(this.clientLevelDisable)
      disableEmailToolTip = 'This feature has been disabled for your organization';
    if(attachment.isConfidential)
      disableEmailToolTip = 'Confidential Records cannot be emailed from the portal.';

    return disableEmailToolTip;
  }

  public onClickCancel() {
    this.router.navigate(['assessment-requests']);
  }

  public get attachments(): IAttachmentBaseModel[] {
    return [].concat(this._assessmentLevelAttachments).concat(this._additionalServiceAttachments);
  }

  public formatDate(date: Date | string) {
    if (!date) {
      return null;
    }

    if (typeof date === 'string') {
      date = new Date(date);
    }

    return format(date, 'MM/dd/yyyy');
  }

  public verbalFeedbackComplete(additionalService: IAdditionalServiceReferenceModel): boolean {
    if (additionalService.additionalServiceReferenceId !== AdditionalServiceReferenceTypes.VerbalDevelopmentFeedback) {
      return false;
    }
    return this._additionalServiceAttachmentsRaw.some((as) => as.additionalServiceReferenceId === additionalService.additionalServiceReferenceId && as.isComplete);
  }

  public isValidAdditionalService(additionalServiceReference: IAdditionalServiceReferenceModel): boolean {
    return this._additionalServiceAttachmentsRaw && additionalServiceReference.additionalServiceReferenceId === 2 && this._additionalServiceAttachmentsRaw.some((asa) => asa.additionalServiceReferenceId === additionalServiceReference.additionalServiceReferenceId);
  }

  public get isAdmin(): boolean {
    return this.authService.isRoleAdmin;
  }

  public get clientLevelDisable(): boolean {
    //emergency workaround to disable feature for particular client.
    return this.authService.clientId == 89;
  }

  private showEmailReportDialog(attachment: IAttachmentBaseModel){
    this._modal = this.modalService.open(EmailReportDialogComponent);
    const instance = this._modal.componentInstance as EmailReportDialogComponent;
    instance.attachmentId = attachment.attachmentId;
    instance.assessmentRequestId = this.assessmentRequestId;
  }

  public downloadAttachment(attachmentId, assessmentRequestId){
    this.disableDownload = true;
    this.AttachmentService.downloadWithAssociatedRequests(attachmentId, assessmentRequestId).subscribe(
      val => {this.disableDownload = false}, 
      error => {this.disableDownload = false}
    );
  }
}
