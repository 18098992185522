<action-bar>
  <action-button (actionClick)="newAssessmentClick()" [iconClass]="'fa fa-plus'" [className]="'action-primary'">
    New Assessment
  </action-button>
  <action-button (actionClick)="exportDataAsCsv()" [iconClass]="'fa fa-save'" [className]="'action-secondary'">
    Export
  </action-button>
  <action-button (actionClick)="display360Info()" [className]="'action-secondary'">
    360-feedback Request
  </action-button>
</action-bar>

<div class="row">
  <div class="col table-title ">
    <h1>Assessment Requests</h1>
  </div>
  <div class="col">
    <div class="pull-right">
      Quick Search:
      <input type="text" [(ngModel)]="inputQuickSearch" id="filter-text-box" placeholder="Type here..." (keyup)="onFilterTextBoxChanged()"/>
      <button (click)="resetFilters()">Reset Filters</button>
    </div>
  </div>
</div>

<div class="row" id="GridWithH1">
  <div class="col" style="Width: 100%;Height:100%;" >
    <ag-grid-angular style="Width: 100%;Height:100%;" class="ag-theme-material" [gridOptions]="gridOptions"></ag-grid-angular>
  </div>
</div>

<div class="gridFooter" >
  <span>Rows per page:</span>
  <select style="display:inline;" [ngModel]="selectedPaginationOption" (change)="onChangePaginationOption($event.target.value)"> <!--[(ngModel)]="selectedPaginationOption"-->
    <option *ngFor="let option of paginationOptions" [value]="option.id">{{option.name}}</option>
  </select>
  <div class="pull-right" style="display:inline;">
      Hide Not-Hired
      <input name="hideNotHired" type="checkbox" [(checked)]="hideNotHired" (change)="onChangeHideNotHired($event)">
  </div>
</div>

<!--
<ps-table [settings]="psTableSettings" [dataSource]="assessmentRequests">
  <ps-advanced-filters>
    <ps-multi-select-filter ps-table-filter [filterTitle]="'Status'" [listItems]="statuses" [columnName]="'statusId'"></ps-multi-select-filter>
    <ps-text-filter ps-table-filter [filterTitle]="'Candidate'" [columnName]="'candidateName'"></ps-text-filter>
    <ps-text-filter ps-table-filter *ngIf="!isRoleClient" [filterTitle]="'Client'" [columnName]="'clientName'"></ps-text-filter>
    <ps-text-filter ps-table-filter [filterTitle]="'Department'" [columnName]="'orgUnitName'"></ps-text-filter>
    <ps-text-filter ps-table-filter [filterTitle]="'Requested By'" [columnName]="'requestedByName'"></ps-text-filter>
    <ps-text-filter ps-table-filter [filterTitle]="'Consultant'" [columnName]="'consultantName'"></ps-text-filter>
    <ps-text-filter ps-table-filter [filterTitle]="'Position'" [columnName]="'position'"></ps-text-filter>
    <ps-date-range-filter ps-table-filter [filterTitle]="'Created Date'" [columnName]="'createdDate'"></ps-date-range-filter>
  </ps-advanced-filters>
</ps-table>
-->
