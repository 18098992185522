<div style="height:fit-content;">
    <div style="margin:20px;">
        <span style="display:inline;">
            <ng-container *ngFor="let action of actionTable">
                <button *ngIf="action.isRowVisible(params.data)" [ngClass]="action.actionClass" class="ps-table-action-link" [disabled]="action.disabled(params.data)"
                        (click)="action.action(params.data);">

                <i *ngIf="action.iconClass" [ngClass]="action.iconClass"></i>
                {{action.name}}
                </button>
            </ng-container>
        </span>
    </div>
</div>
