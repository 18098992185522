<div class="row" [id]="sectionId">
  <div class="col">
    <div class="card">
      <h4 class="card-header" [ngClass]="{'bg-danger': showingErrors}">
        {{sectionTitle}}
      </h4>
      <div class="card-body">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
