import {CanDeactivate} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

export interface ICanDeactivateComponent {
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class CanDeactivateComponentGuard implements CanDeactivate<ICanDeactivateComponent> {
  public canDeactivate(target: ICanDeactivateComponent): Observable<boolean> | Promise<boolean> | boolean {
    return target.canDeactivate ? target.canDeactivate() : true;
  }
}
