import { Component } from '@angular/core';
import {IFilterAngularComp} from "ag-grid-angular";
import {AssessmentRequestStatus} from '../../core/enums/assessment-request-status.enum';
import {AuthService} from '../../auth/auth.service';
import {HiringOutcome} from '../../core/enums/hiring-outcome.enum';
import {IAfterGuiAttachedParams, IDoesFilterPassParams, IFilterParams, RowNode} from "ag-grid-community";
@Component({
  selector: 'app-filter-hiring-outcome',
  templateUrl: './filter-hiring-outcome.component.html',
  styleUrls: ['./filter-hiring-outcome.component.scss']
})
export class FilterHiringOutcomeComponent implements IFilterAngularComp {
  private params: IFilterParams;
  public outcomeHired: boolean = false;

  public pending: boolean = false;
  public showUnanswered: boolean = false;
  public showAll: boolean = false;
  //Currently the AR Grid has a pared down implementation of this filter that toggles between not hired and everything else. Would be better to make this go away.
  //These two are for that hidden filter.
  public ARGridMode: boolean = false;
  public outcomeNotHired: boolean = false;

  agInit(params: IFilterParams): void {
    this.params = params;
  }

  constructor(private authService: AuthService) { }

  isFilterActive(): boolean {
    return true;
  }
  
  //returns bool to indicate whether the row will appear in result list.
  doesFilterPass(params: IDoesFilterPassParams): boolean {
    var outcomePass: boolean = false;
    if(this.showAll){
      return true;
    }
    if(this.showUnanswered)
      if(params.node.data.hiringOutcome < 2)
        outcomePass = true;
    if(this.outcomeHired && params.node.data.hiringOutcome == HiringOutcome.Hired)
    outcomePass = true;
    if(this.pending && params.node.data.hiringOutcome == HiringOutcome.Pending)
    outcomePass = true;
    if(this.outcomeNotHired && params.node.data.hiringOutcome == HiringOutcome.NotHired)
    outcomePass = true;
    return outcomePass;// && theRestOfIt;
  }

  //Returns the current filter options.
  getModel(): any {
      return {outcomeHired: this.outcomeHired, outcomeNotHired: this.outcomeNotHired, pending: this.pending, showUnanswered: this.showUnanswered, showAll: this.showAll};
  }

  //Sets the current filter options.
  setModel(model: any): void {
    if(model != null) {
      this.outcomeHired = model.outcomeHired || false;
      this.outcomeNotHired = model.outcomeNotHired || false;
      this.pending = model.pending || true;
      this.showUnanswered = model.showUnanswered || true;
      this.showAll = model.showAll || false;
    }
    else{
      this.outcomeHired = false;
      this.outcomeNotHired = false;
      this.pending = true;
      this.showUnanswered = true;
      this.showAll = false;
    }
  }

  onChange(event) {
    switch(event.target.name){ 
        case "outcomeHired": { 
          this.outcomeHired = event.target.checked;
          this.showAll = false;
          break; 
        } 
        case "outcomeNotHired": { 
          this.outcomeNotHired = event.target.checked;
          this.showAll = false;
          break; 
        } 
        case "pending": {
          this.pending = event.target.checked;
          this.showAll = false;
          break; 
        }
        case "showUnanswered": {
          this.showUnanswered = event.target.checked;
          this.showAll = false;
          break; 
        }
        case "showAll": {
          if(event.target.checked){
            this.outcomeHired = true;
            this.outcomeNotHired = true;
            this.pending = true;
            this.showUnanswered = true;
            this.showAll = true;
          }
          else{
            this.outcomeHired = false;
            this.outcomeNotHired = false;
            this.pending = true;
            this.showUnanswered = false;
            this.showAll = false;
          }
          break; 
        }
        default: { 
            //statements; 
            break; 
        } 
    } 
    this.params.filterChangedCallback();

  }

}

