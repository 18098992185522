<ng-template #popContent>
    <div>
        <span style="display:inline;">
        <!-- <button style="float: right; top: -40px; position: relative" (click)="p.close()"><i class="fa fa-close text-danger"></i></button> -->
        <ng-container *ngFor="let action of actionTable">
            <button *ngIf="action.isRowVisible(params.data)" [ngClass]="action.actionClass" class="ps-table-action-link" [disabled]="action.disabled(params.data)"
                    (click)="action.action(params.data); p.close()">
            <i *ngIf="action.iconClass" [ngClass]="action.iconClass"></i>
            {{action.name}}
            </button>
        </ng-container>    
        </span>
    </div>
</ng-template>
<div style="width: 0px" container="body" [ngbPopover]="popContent" #p="ngbPopover" popoverTitle="Actions" triggers="manual" placement="bottom top"></div>
<span>
<!-- <i style="font-size:14pt; color:#9aa184; padding-right:10px; padding-left:0px;" class="fa fa-bars" container="body" [ngbPopover]="popContent" #p="ngbPopover" popoverTitle="Actions - {{candidateName}}" triggers="manual" (click)="p.open()"></i> -->
<!-- <a [routerLink]="['view', params?.data?.assessmentRequestId]" >-->
<strong class="aniborder">
    <i *ngIf="showConfidentialLock" class="fa fa-lock" ngbTooltip="Confidential Request" container="body"></i>
    {{candidateName}}
    <i *ngIf="showAdditionalServiceIcon" class="fa fa-plus-square-o" ngbTooltip="Additional request for development feedback" container="body"></i>
    <i *ngIf="showUpgradeServiceIcon" class="fa fa-arrow-circle-o-up" ngbTooltip="Additional request for an upgraded assessment" container="body"></i>
    <i *ngIf="showAdditionalServiceParentIcon" class="fa fa-square-o" ngbTooltip="This request has additional service(s)" container="body"></i>
    <i *ngIf="showAdditionalServicesCompleteParentIcon" class="fa fa-check-square-o" ngbTooltip="Additional service(s) complete" container="body"></i>
</strong>
<!-- </a> -->
</span>
