import {Component, Input, OnInit, ViewEncapsulation, Output, EventEmitter} from '@angular/core';
import {OrgUnitItem} from '../../core/interfaces';
import {OrgUnitHelperService} from './org-unit-helper.service';
import {isNullOrUndefined} from 'util';


@Component({
  selector: 'edit-org-units',
  styleUrls: ['./edit-org-units.component.scss'],
  encapsulation: ViewEncapsulation.None,
  template: `    
    <div class="row" *ngIf="!readOnly">
      <div class="col">
        <p class="text-danger" *ngIf="nameExists">Department name already exists please use a unique name.</p>
        <p class="inline-input-group">
          <input type="text" class="inline-input" placeholder="Department Name" [value]="levelName" (input)="levelName = $event.target.value" />
          <button class="btn btn-link" (click)="addOrgUnit($event)"><i class="fa fa-folder-o"></i> Add Department</button>          
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="scroll scroll-lg">
          <p *ngIf="!organizations || !organizations.length">No organization levels created add some levels.</p>
          <div *ngIf="organizations && organizations.length">
            <edit-org-unit-node [selectedOrgUnitId]="selectedOrgUnitId" (editSelected)="orgUnitClicked($event)" *ngFor="let orgUnit of organizations" [orgUnit]="orgUnit" [readOnly]="readOnly"></edit-org-unit-node>
          </div>          
        </div>
      </div>
    </div>`
})
export class EditOrgUnitsComponent implements OnInit {
  @Input() organizations: OrgUnitItem[] = [];
  @Input() readOnly: false;
  @Input() selectedOrgUnitId: number;
  @Output() selected: EventEmitter<any> = new EventEmitter();
  public levelName = '';
  public nameExists = false;
  public rootOrgUnit: OrgUnitItem;

  constructor(private _helperService: OrgUnitHelperService) {  }

  ngOnInit(): void {
    this._helperService.orgUnits = this.organizations;
    if (this.organizations.length === 0) {
        this.addCorporateOrgUnit();
    }

    this.rootOrgUnit = this.organizations.find(o => o.isRoot === true);
    if (this.rootOrgUnit === undefined) {
    }

    this._helperService.onNameExists$.subscribe((e: boolean) => this.nameExists = e);
  }
  
  public orgUnitClicked(orgUnit) {
    this.selected.emit(orgUnit)
  }

  public get orgUnits(): OrgUnitItem[] {
    return this._helperService.orgUnits;
  }

  public addOrgUnit(event) {
    if (this.readOnly) {
      return;
    }
    const orgName = this.levelName.trim() || 'untitled';
    if (this._helperService.checkNameExists(orgName)) {
      return;
    }

    const item = new OrgUnitItem();
    item.orgUnitId = this._helperService.getNewId();
    item.name = orgName;
    item.isDirty = true;
    item.isNew = true;
    item.isTouched = true;

    let selectedItem = this._helperService.selectedItem;
    if (!isNullOrUndefined(selectedItem)) {
        item.parentId = selectedItem.orgUnitId;
        if (!selectedItem.children) {
          selectedItem.children = [];
        }
    } else {
      item.parentId = this.rootOrgUnit.orgUnitId;
      selectedItem = this.rootOrgUnit;
    }
    selectedItem.showChildren = true;
    selectedItem.children.push(item);
    
    this._helperService.listChanged(item);
    this.levelName = '';
  }

  public addCorporateOrgUnit() {
    if (this.readOnly) {
      return;
    }

    const item = new OrgUnitItem();
    item.orgUnitId = this._helperService.getNewId();
    item.name = 'Corporate';
    item.isDirty = true;
    item.isNew = true;
    item.isTouched = true;
    item.isRoot = true;
    item.parentId = null;
    this._helperService.orgUnits.push(item);
    item.isSelected = true;
    this._helperService.listChanged(item);
    this._helperService.selectedItem = item;
  }

}
