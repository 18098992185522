import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ISelectItemModel, IUserSelectItemModel} from './interfaces/select-item.interface';
import {ServiceBase} from './service-base';
import {AuthHttpService} from '../auth/auth-http.service';
import {IApiAdminModel} from './interfaces/api-admin.interface';
import { HttpClient, HttpResponse} from '@angular/common/http';
@Injectable()
export class ApiAdminService extends ServiceBase {
  private static ApiUrl = 'api/api-admin';
  //private data$: Observable<ISelectItemModel[]>;
  private cacheDuration: number = 1000 * 60 * 5;

  constructor(private http: HttpClient) {
    super();
  }

  public getApiHistory(): Observable<IApiAdminModel[]> {
    return this.http.get<IApiAdminModel[]>(ApiAdminService.ApiUrl);
  }

  public retry(id: number): Observable<HttpResponse<String>> {
    const url = `${ApiAdminService.ApiUrl}/retry/${id}`;
    return this.http.get<HttpResponse<String>>(url);
  }
}
