import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

Injectable()
@Injectable()
export class OptionsManagerService {
  private selectItemSource = new Subject<number>();

  itemSelected$ = this.selectItemSource.asObservable();

  selectItem(index: number) {
    this.selectItemSource.next(index);
  }
}
