import {format} from 'date-fns';

export class LocalFilter {
  protected static filterCallBack = (value: string, search: string): boolean => {
    if (!value || !search) {
      return false;
    }

    return value.toString().toLowerCase().includes(search.toString().toLowerCase());
  };

  protected static dateFilterCallBack = (value: Date, search: string): boolean => {
    if (!value || !search) {
      return false;
    }

    const formatValue = format(value, 'MM/dd/yyyy');
    return formatValue.toString().toLowerCase().includes(search.toString().toLowerCase());
  };

  static filter(data: Array<any>, field: string, search: string, customFilter?: Function): Array<any> {
    const filter: Function = customFilter ? customFilter : this.filterCallBack;

    return data.filter((el) => {
      const value = typeof el[field] === 'undefined' || el[field] === null ? '' : el[field];
      return filter.call(null, value, search);
    });
  }

  static quickFilter(data: Array<any>, query: string): Array<any> {
    return data.filter((el) => {
      let found = false;

      for (const i in el) {
        if (el.hasOwnProperty(i)) {
          const val = el[i];

          found = (val instanceof Date)
            ? this.dateFilterCallBack(val, query)
            : this.filterCallBack(val, query);

          if (found) {
            return found;
          }
        }
      }
      return found;
    });
  }
}

export class LocalSorter {
  protected static COMPARE = (direction, a, b) => {
    if (a < b) {
      return -1 * direction;
    }
    if (a > b) {
      return direction;
    }
    return 0;
  };

  static sort(data: Array<any>, field: string, direction: string, customCompare?: Function): Array<any> {
    const dir: number = (direction === 'asc') ? 1 : -1;
    const compare: Function = customCompare ? customCompare : this.COMPARE;

    return data.sort((a, b) => {
      return compare.call(null, dir, a[field], b[field]);
    });
  }
}

export class LocalPager {
  static paginate(data: Array<any>, page: number, perPage: number): Array<any> {
    return data.slice(perPage * (page - 1), perPage * page);
  }
}
