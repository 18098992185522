import {Injectable} from '@angular/core';
import {ServiceBase} from './service-base';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AuthHttpService} from '../auth/auth-http.service';
import {IFeedbackUserModel} from './interfaces/feedback-user.interface';
import {DefaultFeedbackUsersModel, IFeedbackUserSelectItem} from './interfaces/select-item.interface';
import {isNullOrUndefined} from 'util';
import {IAdditionalUserModel} from './interfaces/additional-user.interface';
import {HttpClient, HttpResponse} from '@angular/common/http';

@Injectable()
export class FeedbackUserService extends ServiceBase {
  private static ApiUrl = 'api/feedback-user';

  constructor(private http: HttpClient) {
    super();
  }

  public getAvailable(userId: number): Observable<IFeedbackUserSelectItem[]> {
    const url = `${FeedbackUserService.ApiUrl}?userId=${userId}`;
    return this.http.get<IFeedbackUserSelectItem[]>(url);
  }

  public getAvailableExternal(userId: number, includeInactive: boolean = false): Observable<IAdditionalUserModel[]> {
    const url = `${FeedbackUserService.ApiUrl}/additional-users?userId=${userId}&includeInactive=${includeInactive}`;
    return this.http.get<IAdditionalUserModel[]>(url);
  }

  public getAvailableForClient(clientId: number, userId: number = null): Observable<IFeedbackUserSelectItem[]> {
    let url = `${FeedbackUserService.ApiUrl}/available-client/${clientId}`;
    if (isNullOrUndefined(userId)) {
      return this.http.get<IFeedbackUserSelectItem[]>(url);
    } else {
      url += '?ownerUserId=' + userId;
      return this.http.get<IFeedbackUserSelectItem[]>(url);
    }
  }

  public toggleAdditionalUserActivation(additionalUserId: number): Observable<HttpResponse<Object>> {
    const url = `${FeedbackUserService.ApiUrl}/additional-users/toggle-activation/${additionalUserId}`;
    return this.http.post<HttpResponse<Object>>(url, null, {headers: this.jsonHeaders, observe: "response" });
  }

  public getDefaults(clientId: number, userId: number = null): Observable<IFeedbackUserModel[]> {
    let url = `${FeedbackUserService.ApiUrl}/defaults/${clientId}`;
    if (isNullOrUndefined(userId)) {
      return this.http.get<IFeedbackUserModel[]>(url);
    } else {
      url += '?ownerUserId=' + userId;
      return this.http.get<IFeedbackUserModel[]>(url);
    }
  }

  public save(user: IFeedbackUserModel): Observable<IFeedbackUserModel> {
    return (user.feedbackUserAssessmentId)
      ? this.update(user)
      : this.add(user);
  }

  public saveAdditionalUser(user: IAdditionalUserModel): Observable<IAdditionalUserModel> {
    const url =  `${FeedbackUserService.ApiUrl}/additional-user`;
    const json = JSON.stringify(user);
    return this.http.post<IAdditionalUserModel>(url, json, {headers: this.jsonHeaders});
  }

  public saveList(users: IFeedbackUserModel[]): Observable<IFeedbackUserModel[]> {
    const url = `${FeedbackUserService.ApiUrl}/list`;
    const json = JSON.stringify(users);

    return this.http.post<IFeedbackUserModel[]>(url, json, {headers: this.jsonHeaders});
  }

  public saveDefaults(model: DefaultFeedbackUsersModel): Observable<void> {
    const url = `${FeedbackUserService.ApiUrl}/defaults`;
    const json = JSON.stringify(model);

    return this.http.post<void>(url, json, {headers: this.jsonHeaders});
  }

  private add(user: IFeedbackUserModel): Observable<IFeedbackUserModel> {
    const json = JSON.stringify(user);
    return this.http.post<IFeedbackUserModel>(FeedbackUserService.ApiUrl, json, {headers: this.jsonHeaders});
  }

  private update(user: IFeedbackUserModel): Observable<IFeedbackUserModel> {
    const url = `${FeedbackUserService.ApiUrl}/${user.feedbackUserAssessmentId}`;
    const json = JSON.stringify(user);
    return this.http.put<IFeedbackUserModel>(url, json, {headers: this.jsonHeaders});
  }
}
