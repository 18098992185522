import {Component} from '@angular/core';
import {AuthService} from '../../auth/auth.service';

@Component({
  selector: 'main-nav',
  templateUrl: 'main-nav.component.html'
})
export class MainNavComponent {
  public feedback = true;

  constructor(private authService: AuthService) {
  }

  public toggle(id: string) {
    switch (id) {
      case 'feedback':
        this.feedback = !this.feedback;
        break;
      default:
        this.feedback = true;
        break;
    }
  }

  public get isAdmin(): boolean {
    return this.authService.isRoleAdmin;
  }
}
