<div class="modal-header">
  <h4 class="modal-title">{{editAdditionalUser ? 'Edit' : 'Add'}} Recipient of Assessment Results</h4>
  <span class="close-icon" aria-label="Close" (click)="onDismiss()" style="cursor: pointer;">
    &times;
  </span>
</div>
<div class="modal-body">
  <div class="col">
    <ps-sections-container [formGroup]="form" *ngIf="form" [showNav]="false">
      <div class="form-group row">
        <ps-input-validation>
          <label class="form-control-label" for="add-new-feedback-user-name">Name *</label>
          <input psInput type="text" class="form-control" #name id="add-new-feedback-user-name" formControlName="name">
        </ps-input-validation>
        <ps-input-validation>
          <label class="form-control-label" for="add-new-feedback-user-email">Title/Position</label>
          <input psInput type="text" class="form-control" #title id="add-new-feedback-user-title" formControlName="title">
        </ps-input-validation>
      </div>
      <div class="form-group row">
        <ps-input-validation>
          <label class="form-control-label" for="add-new-feedback-user-email">Email *</label>
          <input psInput type="text" class="form-control" #email id="add-new-feedback-user-email" formControlName="email">
        </ps-input-validation>
        <ps-input-validation>
          <label class="form-control-label" for="add-new-feedback-user-email">Phone *</label>
          <input psInput type="text" class="form-control" #phone id="add-new-feedback-user-phone" formControlName="phone">
        </ps-input-validation>
      </div>
      <div class="form-group row">
        <ps-input-validation>
          <label class="form-control-label" for="add-new-feedback-user-state">State</label>
          <select psInput class="form-control" id="add-new-feedback-user-state" formControlName="state">
            <option *ngFor="let state of states" [ngValue]="state">{{state.name}}</option>
          </select>
        </ps-input-validation>
        <ps-input-validation>
          <label class="form-control-label" for="add-new-feedback-user-country">Country</label>
          <select psInput class="form-control" id="add-new-feedback-user-country" formControlName="country">
            <option *ngFor="let country of countries" [ngValue]="country">{{country.name}}</option>
          </select>
        </ps-input-validation>
      </div>
    </ps-sections-container>
  </div>
</div>
<div class="modal-footer">
  <div class="col">
    <div class="row">
      <div *ngFor="let alert of alerts">
        <ngb-alert [type]="alert.type" (close)="closeAlert(alert)">{{alert.message}}</ngb-alert>
      </div>
    </div>
  </div>
  <div class="modal-footer-left">
  <button type="button" class="btn btn-cancel" (click)="onDismiss()"><span class="fa fa-ban"></span> Cancel</button>
  <button type="button" class="btn btn-primary" (click)="onSave()" [disabled]="form.invalid"><span class="fa fa-save"></span> Save</button>
</div>
</div>
