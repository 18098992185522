<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
  <span class="close-icon" aria-label="Close" (click)="confirm(false)" style="cursor: pointer;">
    &times;
  </span>
</div>
<div class="modal-body">
  <div [innerHtml]='body'></div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="confirm(true)">OK</button>
</div>
