import {IFeedbackUserModel} from '../interfaces/feedback-user.interface';

export class FeedbackUserModel {
  public data: IFeedbackUserModel;

  public get feedbackUser(): IFeedbackUserModel {
    return this.data;
  }

  public get name(): string {
    return this.data.name;
  }

  public get email(): string {
    return this.data.email;
  }

  public get phone(): string {
    return this.data.phoneWork;
  }

  public get verbal(): boolean {
    return this.data.verbal;
  }

  public set verbal(val: boolean) {
    this.data.verbal = val;
  }

  public get written(): boolean {
    return this.data.written;
  }

  public set written(val: boolean) {
    this.data.written = val;
  }

  constructor(user: IFeedbackUserModel) {
    this.data = user;
  }
}
