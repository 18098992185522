import {DataSet} from '../data/data-set';

export class Column {
  public title = '';
  public type = '';
  public class = '';
  public isSortable = true;
  public isEditable = true;
  public isFilterable = true;
  public isVisible = true;
  public sortDirection = '';
  public defaultSortDirection = '';
  public isViewLink = false;
  public editor: { type: string, config: any, component: any } = {type: '', config: {}, component: null};
  compareFunction: Function;
  valuePrepareFunction: Function;
  filterFunction: () => any;
  cellRenderFunction: Function;

  constructor(public id: string, protected settings: any, protected dataSet: DataSet) {
    this.process();
  }

  public getCompareFunction(): Function {
    return this.compareFunction;
  }

  public getValuePrepareFunction(): Function {
    return this.valuePrepareFunction;
  }

  public getFilterFunction(): () => any {
    return this.filterFunction;
  }

  public getCellRenderFunction(): Function {
    return this.cellRenderFunction;
  }

  public getConfig(): any {
    return this.editor.config;
  }

  prepareType(): string {
    return this.settings['type'] || this.determineType();
  }

  prepareSortDirection(): string {
    if (this.settings['sort']) {
      return this.settings['sort'] === 'desc' ? 'desc' : 'asc';
    }
    return '';
  }

  determineType(): string {
    // TODO: determine type by data
    return 'text';
  }

  public get columnClass(): string {
    let cssClass = this.settings['class'] ? this.settings['class'] : '';
    if (this.isSortable){
      cssClass += ` sortable ${this.sortDirection}`;
    }
    return cssClass;
  }

  protected process(): void {
    this.title = this.settings['title'];
    this.class = this.settings['class'];
    this.type = this.prepareType();
    this.editor = this.settings['editor'];
    this.isFilterable = typeof this.settings['filter'] === 'undefined' ? true : !!this.settings['filter'];
    this.defaultSortDirection = ['asc', 'desc'].indexOf(this.settings['sortDirection']) !== -1 ? this.settings['sortDirection'] : '';
    this.isSortable = typeof this.settings['sort'] === 'undefined' ? true : !!this.settings['sort'];
    this.isEditable = typeof this.settings['editable'] === 'undefined' ? true : !!this.settings['editable'];
    this.sortDirection = this.prepareSortDirection();
    this.compareFunction = this.settings['compareFunction'];
    this.valuePrepareFunction = this.settings['valuePrepareFunction'];
    this.filterFunction = this.settings['filterFunction'];
    this.cellRenderFunction = this.settings['cellRenderFunction'];
    this.isViewLink = this.settings['isViewLink'];
    this.isVisible = typeof this.settings['isVisible'] === 'undefined' ? true : !!this.settings['isVisible'];
  }
}
