import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {IBillingContactModel, IUserBillingContactModel} from '../../core/interfaces/billing-contact.interface';
import {ISelectItemModel} from '../../core/interfaces/select-item.interface';
import {BillingContactModel} from '../../core/models/billing-contact.model';
import {BillingContactService} from '../../core/billing-contact.service';
import {AddressModel} from '../../core/models/address.model';
import {IEditUserModel} from '../../core/interfaces/edit-user.interface';
@Component({
  selector: 'app-edit-billing-contact-modal',
  templateUrl: './edit-billing-contact-dialog.component.html',
  styleUrls: ['./edit-billing-contact-dialog.component.scss']
})
export class EditBillingContactDialogComponent implements OnInit {
  @Input() public billingContact: IBillingContactModel;
  @Input() public states: ISelectItemModel[] = [];
  @Input() public countries: ISelectItemModel[] = [];
  @Input() public user: IEditUserModel;
  @Input() public orgUnitName: string;
  @Output() public onUpdate = new EventEmitter<IBillingContactModel>();

  public form: UntypedFormGroup;
  public verb: string = 'Add';
  public isUSA: boolean;
  public disableAddButton: boolean;
  constructor(public activeModal: NgbActiveModal,
              private fb: UntypedFormBuilder,
              private billingContactService: BillingContactService) {
  }

  public ngOnInit(): void {
    this.disableAddButton = false;
    if (!this.user) {
      throw new Error('user is required');
    }

    this.form = this.buildForm(this.billingContact || new BillingContactModel());
    if(this.billingContact && this.billingContact.address)
      this.isUSA = this.isUSofA(this.billingContact.address.countryId);

  }

  public onDelete()
  {
    if(this.billingContact.billingContactId != null) {
      this.billingContactService.delete(this.billingContact.billingContactId).subscribe((bc) => {
        this.onUpdate.emit(bc);
        this.activeModal.close();
      });
    }
  }

  public onSetDefault()
  {
    //not finished.
    if(this.billingContact.billingContactId != null) {
      this.billingContactService.setdefault(this.billingContact).subscribe((bc) => {
        this.onUpdate.emit(bc);
        this.activeModal.close();
      });
    }
  }

  public onCopyUserDetails(){
    var prettyPhone = '';
    //Although billingContact often works in terms of mobilePhone, workPhone is preferred for this copy because it's a required field.
    if(this.user.phoneWork){
      if(this.user.phoneWorkCountryCode)
        prettyPhone = this.user.phoneWorkCountryCode + ' ';
      prettyPhone += this.user.phoneWork;
    }
    this.form.patchValue({
      firstName: this.user.first,
      lastName: this.user.last,
      middleName: this.user.middle,
      phone: prettyPhone,
      email: this.user.email,
      address1 : this.user.address1,
      address2: this.user.address2,
      city: this.user.city,
      state: this.user.stateId,
      province: this.user.province,
      zipCode: this.user.zipCode,
      country: this.user.countryId
    });
    this.isUSA = this.isUSofA(this.user.countryId);
  }

  public onClose() {
    this.disableAddButton = true;
    if (this.form.invalid) {

      for (const ctrlKey in this.form.controls) {
        const ctrl = this.form.get(ctrlKey);
        ctrl.markAsTouched();
      }

      console.warn('Form is invalid');
      this.disableAddButton = false;
      return;
    }

    const formBillingContact = this.formValues as IUserBillingContactModel;
    //this.billingContact is passed in if we are editing an existing request.
    if(this.billingContact != null) {
      formBillingContact.billingContactId = this.billingContact.billingContactId;
      this.billingContactService.update(formBillingContact).subscribe((bc) => {
        this.onUpdate.emit(bc);
        this.activeModal.close();
      });
    }
    else {
      formBillingContact.userId = this.user.userId;
      formBillingContact.isDefault = true;

      this.billingContactService.add(formBillingContact).subscribe((bc) => {
        this.onUpdate.emit(bc);
        this.activeModal.close();
      });
    }
  }

  public onCancel() {
    this.activeModal.dismiss();
  }

  public onSelectCountry(countryId: number): void {
    this.isUSA = this.isUSofA(countryId);
  }

  private isUSofA(countryId: number): boolean {
    return countryId == 196;
  }

  private buildForm(billingContact: IBillingContactModel): UntypedFormGroup {
    if(billingContact.billingContactId != null)
      this.verb = "Edit/Delete";

    const address = (billingContact.address != null) ? billingContact.address: new AddressModel();
    return this.fb.group({
      firstName: [billingContact.firstName, [Validators.required, Validators.maxLength(100)]],
      middleName: [billingContact.middleName, Validators.maxLength(100)],
      lastName: [billingContact.lastName, [Validators.required, Validators.maxLength(100)]],
      phone: [address.phoneMobile, [Validators.required, Validators.maxLength(50)]],
      email: [billingContact.email, [Validators.required, Validators.maxLength(254)]],
      address1: [address.address1, [Validators.required, Validators.maxLength(200)]],
      address2: [address.address2, Validators.maxLength(200)],
      city: [address.city, [Validators.required, Validators.maxLength(100)]],
      state: [address.stateId],
      province: [address.province, Validators.maxLength(200)],
      zipCode: [address.zipCode,  Validators.maxLength(10)],
      country: [address.countryId, [Validators.required, Validators.maxLength(100)]]
    });
  }

  private get formValues(): IBillingContactModel {
    const values = this.form.value;

    return {
      firstName: values.firstName,
      middleName: values.middleName,
      lastName: values.lastName,
      email: values.email,
      address: {
        phoneMobile: values.phone,
        address1: values.address1,
        address2: values.address2,
        city: values.city,
        stateId: values.state,
        province: values.province,
        zipCode: values.zipCode,
        countryId: values.country
      },
      orgUnitName: this.orgUnitName
    } as IBillingContactModel;
  }
}
