<action-bar>
  <action-button [className]="'action-danger'" [iconClass]="'fa fa-ban'" (actionClick)="onCancel()">Cancel</action-button>
  <action-button *ngIf="enoughToSave && !isNewAdditionalService" [className]="'action-primary'" [iconClass]="'fa fa-floppy-o'" (actionClick)="onSave()" [disabled]="disableSave">Save and Close</action-button>
  <action-button *ngIf="isNewAdditionalService" [className]="'action-primary'" [iconClass]="'fa fa-floppy-o'" (actionClick)="onSave()" [disabled]="!allowSaveAdditionalService || disableSave">Submit</action-button> <!--  New FeedBack Request has been moved to EditAR -->
  <action-button *ngIf="!isNewAdditionalService" [className]="'action-primary'" [iconClass]="'fa fa-floppy-o'" (actionClick)="onSubmit()" [disabled]="!form || disableSave">Submit</action-button>
  <div *ngIf="assessmentRequest.isConfidential" class="action-object green-background"><span style="padding-right:8px;" class="fa fa-lock"></span>{{assessmentRequest.isConfidential?'Confidential':'Non-Confidential'}}</div>
</action-bar>
<ngb-alert *ngIf="alertMessage" class="alert-overlay"
           type="danger" (close)="alertMessage = null">{{ alertMessage }}
</ngb-alert>
<ngb-alert *ngIf="successMessage" class="alert-overlay"
           type="success" (close)="successMessage = null">{{ successMessage }}
</ngb-alert>

<ps-sections-container [formGroup]="form" *ngIf="form" [showNav]="true" >
  <!-- Client -->
  <ps-section formGroupName="client" [sectionId]="'client'" [sectionTitle]="'Client'">
    <div class="form-group row">
      <ps-input-validation *ngIf="!disableClientEdit; else staticClientControl">
        <label class="form-control-label" for="client-name">Client *</label>
        <ps-autocomplete-select psInput [placeHolderText]="'Client'" id="client-name"
                                formControlName="client" [selectList]="clients">
        </ps-autocomplete-select>
      </ps-input-validation>
      <ng-template #staticClientControl>
        <div class="col">
          <label class="form-control-label">Client</label>
          <p class="form-control-static">{{client?.name}}</p>
        </div>
      </ng-template>
      <div class="col" *ngIf="(!canEditDepartment && orgUnit)">
        <label class="form-control-label">Department *</label>
        <p class="form-control-static">{{orgUnit.name}}</p>
      </div>
      <ps-input-validation *ngIf="canEditDepartment">
        <label class="form-control-label" for="dept-select">Department * </label>
        <select [required]="true" ps-input id="dept-select" class="form-control" formControlName="orgUnit" #orgUnitSelect>
          <option *ngFor="let ou of orgUnits" [value]="ou.orgUnitId">{{ou.name}}</option>
        </select>
      </ps-input-validation>
    </div>
    <div class="form-group row">
      <ps-input-validation *ngIf="isAdmin; else staticRequestedByUserControl">
        <label class="form-control-label" for="requested-by">Requested By *</label>
        <select psInput class="form-control" id="requested-by" formControlName="requestedBy">
          <option *ngFor="let user of clientUsers" [ngValue]="user">{{user.name}}</option>
        </select>
      </ps-input-validation>
      <ng-template #staticRequestedByUserControl>
        <div class="col">
          <label class="form-control-label">Requested By</label>
          <p class="form-control-static">{{requestedByUser?.name}}</p>
        </div>
      </ng-template>

      <ps-input-validation *ngIf="settings && settings.enableGeneralLedger">
          <label class="form-control-label" for="general-ledger">General Ledger {{settings?.enableGeneralLedgerRequired ? '*' : null}}</label>
          <input [required]="settings?.enableGeneralLedgerRequired ? '' : null" psInput class="form-control" #generalLedger id="general-ledger" formControlName="generalLedger" maxlength="45">
        </ps-input-validation>
    </div>
    <div class="form-group row" *ngIf="(settings && (settings.enableCostCenter || settings.enableCostCenterOwner))">
      <ps-input-validation *ngIf="settings?.enableCostCenter">
        <label class="form-control-label" for="cost-center">Cost Center {{settings?.enableCostCenterRequired ? '*' : null}}</label>
        <input [required]="settings?.enableCostCenterRequired ? '' : null" psInput class="form-control" #costCenter id="cost-center" formControlName="costCenter" maxlength="45">
      </ps-input-validation>
      <ps-input-validation *ngIf="settings.enableCostCenterOwner">
        <label class="form-control-label" for="activity-center">Cost Center Owner {{settings?.enableCostCenterOwnerRequired ? '*' : null}}</label>
        <input [required]="settings?.enableCostCenterOwnerRequired ? '' : null" psInput class="form-control" id="cost-center-owner" #costCenterOwner formControlName="costCenterOwner" maxlength="45">
      </ps-input-validation>
    </div>
    <div class="form-group row" *ngIf="(settings && (settings.enableCandidateId || settings.enablePo))">
      <ps-input-validation *ngIf="settings.enableCandidateId">
        <label class="form-control-label" for="activity-center">Candidate Id {{settings?.enableCandidateIdRequired ? '*' : null}}</label>
        <input [required]="settings?.enableCandidateIdRequired ? '' : null" psInput class="form-control" id="custom-candidate-id" #customCandidateId formControlName="customCandidateId" maxlength="45">
      </ps-input-validation>
      <ps-input-validation *ngIf="settings.enablePo">
        <label class="form-control-label" for="activity-center">Purchase Order {{settings?.enablePoRequired ? '*' : null}}</label>
        <input [required]="settings?.enablePoRequired ? '' : null" psInput class="form-control" id="custom-po" #customPo formControlName="customPo" maxlength="45">
      </ps-input-validation>
    </div>    
    <div class="form-group row" *ngIf="(settings && (settings.enableActivityCenter || settings.enableCompanyCode))">
      <ps-input-validation *ngIf="settings.enableCompanyCode">
        <label class="form-control-label" for="activity-center">Company Code {{settings?.enableCompanyCodeRequired ? '*' : null}}</label>
        <input [required]="settings?.enableCompanyCodeRequired ? '' : null" psInput class="form-control" id="company-code" #companyCode formControlName="companyCode" maxlength="45">
      </ps-input-validation>
      <ps-input-validation *ngIf="settings.enableActivityCenter">
          <label class="form-control-label" for="activity-center">Activity Center {{settings?.enableActivityCenterRequired ? '*' : null}}</label>
          <input [required]="settings?.enableActivityCenterRequired ? '' : null" psInput class="form-control" id="activity-center" #activityCenter formControlName="activityCenter" maxlength="45">
        </ps-input-validation>
    </div>
  </ps-section>

  <ng-container *ngIf="assessmentRequest.hasParent || clientSection.formGroup.valid">

    <!-- Candidate -->
    <ps-section formGroupName="candidate" [sectionId]="'candidate'" [sectionTitle]="'Candidate'">
      <ps-input-validation>
        <div class="form-group row">
          <ps-input-validation>
          <label class="form-control-label" for="candidate-type">Internal/External *</label>
          <select psInput class="form-control" id="candidate-type" formControlName="type" (change)="ensureAssessmentLevel()">
            <option value="I">Internal</option>
            <option value="E">External</option>
          </select>
        </ps-input-validation>
        <ps-input-validation>
          <label class="form-control-label" for="position">Current Position {{candidateSection.typeControl.value == 'I' ? '*' : null}}</label>
          <input [required]="((candidateSection.typeControl.value == 'I') ? '' : null)"  psInput type="text" class="form-control" #position id="position" formControlName="position" maxlength="200">
        </ps-input-validation>
        </div>


        <div class="form-group row">
          <ps-input-validation>
          <label class="col-form-label" for="candidate-first-name">First Name *</label>
          <input psInput type="text" class="form-control" #firstName id="candidate-first-name" (blur)="duplicateCandidateCheck()" formControlName="firstName" maxlength="200">
        </ps-input-validation>
        <ps-input-validation *ngIf="isAdmin" >
          <label class="form-control-label" for="candidate-middle-name">Middle Name</label>
          <input psInput type="text" class="form-control" #middleName id="candidate-middle-name" formControlName="middleName" maxlength="200">
        </ps-input-validation>
        <ps-input-validation>
          <label class="form-control-label" for="lastName">Last Name *</label>
          <input psInput type="text" class="form-control" #lastName id="lastName" (blur)="duplicateCandidateCheck()" formControlName="lastName" maxlength="200">
        </ps-input-validation>
        </div>

      
        <div class="form-group row">
          <ps-input-validation>
          <label class="form-control-label" for="work-phone">Primary Phone</label>
          <span *ngIf="isNewAdditionalService && !this.upgrade && !this.candidateSection.workPhoneControl.dirty" ngbTooltip="Has this number changed?" container="body"><span style="margin-left:5px;" class="fa fa-exclamation-triangle text-warning"></span>Please provide work phone number</span>
          <input psInput type="tel" class="form-control" #workPhone id="work-phone" formControlName="workPhone" maxlength="50">
          <label class="form-control-label" for="mobile-phone">Secondary Phone</label>
          <input psInput type="tel" class="form-control" #mobilePhone id="mobile-phone" formControlName="mobilePhone" maxlength="50">
        </ps-input-validation>
        </div>


        <div class="form-group row">
          <ps-input-validation>
            <label class="form-control-label" for="email">Email *</label>
            <span *ngIf="isNewAdditionalService && !this.upgrade && !this.candidateSection.emailControl.dirty" ngbTooltip="Has this email changed?" container="body"><span style="margin-left:5px;" class="fa fa-exclamation-triangle text-warning"></span>Please provide internal company email address</span>
            <input psInput type="email" class="form-control" #email id="email" (blur)="duplicateCandidateCheck()" formControlName="email" maxlength="254">
          </ps-input-validation>
        </div>
      
      
        <div class="form-group row">
          <ps-input-validation>
          <label class="form-control-label" for="gender">Gender *</label>
          <select psInput class="form-control" id="gender" formControlName="gender">
            <option value="F">Female</option>
            <option value="M">Male</option>
          </select>
        </ps-input-validation>
        <ps-input-validation>
          <span container="body" [ngbTooltip]="'When available, preferred language will be used for assessments.'" ><label class="form-control-label" for="language">Native/Preferred Language *</label></span>
          <select psInput class="form-control" id="language" formControlName="language">
              <option [value]="null"></option>
            <option *ngFor="let language of languages" [ngValue]="language">{{language.name}}</option>
          </select>
        </ps-input-validation>
      </div>

      <div class="form-group row">
        <div class="col">
          <a [hidden]="!candidateSection.resumeAttachmentIdControl.value" (click)="AttachmentService.download(candidateSection.resumeAttachmentIdControl.value)" class="btn btn-sm btn-secondary button-margin-right"><span class="fa fa-cloud-download"></span> {{candidateSection.originalFilenameControl.value}}</a>
          <label class="btn btn-sm btn-secondary btn-file button-right" [ngClass]="{'disabled': (assessmentRequest.hasParent && !upgrade)}">
            <span class="fa fa-cloud-upload"></span> Upload Resume <input type="file" #resumeInput hidden (change)="onUploadResumeFileChange($event)" accept=".pdf,.doc,.docx" [disabled]="assessmentRequest.hasParent && !upgrade">
          </label>
        </div>

        <div class="col">
          <div class="form-check button-right">
            <label class="form-check-label">
              <input type="checkbox" class="form-check-input" formControlName="noResume">
              No Resume
            </label>
          </div>
        </div>
      </div>
    </ps-input-validation>
    </ps-section>

    <!-- Assessment Purpose -->
    <ps-section *ngIf="(assessmentRequest.additionalService != 1 && !assessmentRequest.hasParent) || upgrade" [sectionId]="'assessment-purpose'" [sectionTitle]="'Assessment Purpose'" formGroupName="assessmentPurpose" [showInvalid]="assessmentPurposeSection.isInvalid">
      <div class="form-group row">
        <ps-input-validation class="col-auto">
          <label class="form-control-label col" for="purpose">Purpose of Assessment {{isLevelZeroRequest() ? null : '*'}}</label>
          <select style="min-width: 345px;" [required]="isLevelZeroRequest() ? null : true" psInput class="form-control" id="purpose" formControlName="purpose" [attr.disabled]="upgrade ? '' : null" (change)="ensureAssessmentLevel()">
            <option [attr.disabled]="((candidateSection.typeControl.value == 'I') ? '' : null)" value="1">Pre-hire assessment</option>
            <option [attr.disabled]="((candidateSection.typeControl.value == 'E') ? '' : null)" value="4">Promotion (includes promotability recommendation)</option>
            <option [attr.disabled]="((candidateSection.typeControl.value == 'E') ? '' : null)" value="2">Development (does not include recommendation)</option>
            <!-- <option value="3">Both</option> -->
          </select>
        </ps-input-validation>
        <div class="col-auto">
          <div container="body" [ngbTooltip]="disableConfidentialButton ? 'Please contact Client Services at testing@leadershipall.com to remove the confidential status.' : ''" >
          <button *ngIf="userLevel != 1" type="button" [disabled]="disableConfidentialButton" ng-style="{'pointer-events':disableConfidentialButton ? 'none' : ''}" class="btn btn-sm btn-confidential button-right" (click)="onConfidential()">
            <span style="padding-right:8px;" [ngClass]="assessmentRequest.isConfidential?'fa fa-lock':'fa fa-unlock'"></span> {{assessmentRequest.isConfidential ? 'Confidential' : 'Set Confidential'}}

          </button>
        </div>
        </div>
        <div class="col-auto" [hidden]="!assessmentRequest.isConfidential">
          <ps-input-validation>
            <label style="min-width: 345px;" class="form-control-label col" for="confidentialReason">Confidential Reason*</label>
            <input id="confidentialReason" class="form-control" [required]="assessmentRequest.isConfidential" 
              type="text" placeholder="Pick one or enter your own here" aria-label="Number" matInput formControlName="confidentialReason" [matAutocomplete]="confAuto">
            <i class="placeholder-text-caret" (click)="onPositionsClick(trigger); $event.stopPropagation()">
              <i class="sf-input-icon fa fa-caret-down"></i>
            </i> 
            <mat-autocomplete #confAuto="matAutocomplete">
              <mat-option *ngFor="let option of confidentialOptions" [value]="option">
                {{option}}
              </mat-option>
            </mat-autocomplete>
          </ps-input-validation>
        </div>
      </div>
      <div class="form-group row" style="white-space: nowrap;">
        <ps-input-validation style="max-width:375px;">
          <label class="form-control-label" for="position-function">{{(assessmentPurposeSection.purposeControl.value == 2) ? 'Current Position Details' : 'Position being Filled'}} {{isLevelZeroRequest() ? null : '*'}}</label>
            <div [hidden]="upgrade">
                <span *ngIf="this.assessmentPurposeSection.positionControl.value && this.assessmentPurposeSection.positionControl.value.isConfidential" 
                  style="position:absolute; z-index: 1000; padding-top: 11px; padding-left: 13px;" class="fa fa-lock"></span>
                <input #trigger="matAutocompleteTrigger" [required]="isLevelZeroRequest() ? null : true" class="form-control" id="position-function" formControlName="position" type="text" matInput  
                  [matAutocomplete]="auto"  (blur)="onPositionBlur()" (focus)="onPositionsClick(trigger)" (click)="onPositionsClick(trigger)" (keydown.ArrowUp)="onArrowKey()" 
                  (keydown.ArrowDown)="onArrowKey()" (keydown.Enter)="chooseFirstOption($event)">
                <i class="placeholder-text-caret" (click)="onPositionsClick(trigger); $event.stopPropagation()">
                  <i class="sf-input-icon fa fa-caret-down"></i>
                </i> 
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayPositionFn" (optionSelected)="positionSelected($event.option.value)">
                <mat-option *ngFor="let position of filteredOptions" [value]="position">
                  <span style="padding-right:8px;" [ngClass]="position.isConfidential?'fa fa-lock':''"></span> {{ position.description }}
                </mat-option>
                <mat-option *ngIf="positions.length==0 && !this.assessmentPurposeSection.positionControl.value">No Positions Available</mat-option>
              </mat-autocomplete>
            </div>
            <div [hidden]="!upgrade">{{assessmentPurposeSection?.positionControl?.value?.description}}</div>
        </ps-input-validation>
        <button type="button" class="btn btn-sm btn-primary button-right" (click)="onClickAddPosition()" [attr.disabled]="upgrade ? '' : null">
          <span class="fa fa-plus-circle"></span> Add Position
        </button>
        
        <div class="col-2 button-right">
        <a style="overflow:hidden;" [hidden]="!assessmentPurposeSection.positionControl.value?.jobDescriptionAttachmentId" (click)="AttachmentService.download(assessmentPurposeSection.positionControl.value?.jobDescriptionAttachmentId)" class="btn btn-sm btn-secondary"
            [ngbTooltip]="assessmentPurposeSection.positionControl.value?.jobDescriptionFileName" container="body">
          <span class="fa fa-cloud-download"></span> {{assessmentPurposeSection.positionControl.value?.jobDescriptionFileName}}
        </a>
      </div>
    </div>
      <div class="form-group row">
        <div class="col-4">
          <label class="form-control-label">
            Position Level
          </label>
          <p class="form-control-static">{{assessmentPurposeSection.positionControl.value?.positionLevelName}}</p>
        </div>
      </div>
      <div class="form-group row">
        <ps-input-validation>
          <label class="form-control-label" for="requesting-manager">Requesting Manager</label>
          <input [attr.disabled]="upgrade ? '' : null" psInput type="text" class="form-control" id="requesting-manager" #requestingManager formControlName="requestingManager" maxlength="50">
        </ps-input-validation>
        <ps-input-validation>
          <label class="form-control-label" for="position-reports-to">Position Reports To</label>
          <input [attr.disabled]="upgrade ? '' : null" psInput type="text" class="form-control" id="position-reports-to" #positionReportsTo formControlName="positionReportsTo" maxlength="50">
        </ps-input-validation>
        <ps-input-validation>
          <label class="form-control-label" for="direct-reports"># Direct Reports</label>
          <input [attr.disabled]="upgrade ? '' : null" psInput type="number" step="1" min="0" class="form-control" id="direct-reports" formControlName="directReports">
        </ps-input-validation>
      </div>
    </ps-section>

    <!-- Position Competencies -->
    <ps-section *ngIf="(assessmentRequest.additionalService != 1 && !assessmentRequest.hasParent) || upgrade" [sectionId]="'position-competencies'" [sectionTitle]="'Position Competencies'" formGroupName="positionCompetencies" [showInvalid]="positionCompetenciesSection.isInvalid">
      <div class="row">
        <div class="col">
          <button type="button" class="btn btn-sm" [attr.disabled]="upgrade ? '' : null"
                  [ngClass]="{ 'btn-secondary': !positionCompetenciesSection.isInvalid, 'btn-danger': positionCompetenciesSection.isInvalid }" (click)="onClickEditCompetencies()">
            <span class="fa fa-edit"></span> Edit Competencies
          </button>
          <span> {{isLevelZeroRequest() ? null : '*'}}</span><span class="text-danger" *ngIf="positionCompetenciesSection.isInvalid"> Please select at least one competency</span>
        </div>
      </div>
      <div class="form-group row">
        <competency-list *ngIf="positionCompetenciesSection.competenciesControl.value && positionCompetenciesSection.competenciesControl.value.length"
                         [columns]="2" [competencies]="positionCompetenciesSection.competenciesControl.value">
        </competency-list>
      </div>
      <div class="form-group row">
        <ps-input-validation>
          <label class="form-control-label" for="custom-competencies">Custom Competencies</label>
          <input psInput class="form-control" id="custom-competencies" #customCompetencies formControlName="customCompetencies" maxlength="4000" [attr.disabled]="upgrade ? '' : null">
        </ps-input-validation>
      </div>
      <div class="form-group row">
        <ps-input-validation>
          <label class="form-control-label" for="notes">Questions/Concerns/Comments</label>
          <input psInput type="text" class="form-control" id="notes" #positionCompetenciesNotes formControlName="notes" maxlength="4000">
        </ps-input-validation>
      </div>
    </ps-section>

    <!-- Services Requested -->
    <ps-section [sectionId]="'services-requested'" [sectionTitle]="'Services Requested'" formGroupName="servicesRequested">
      <ps-input-validation *ngIf="(assessmentRequest.additionalService != 1 && !assessmentRequest.hasParent) || upgrade">
        <div class="form-group row">
          <div class="col">
            <label class="form-control-label">Assessment Level Requested *</label>
          </div>
        </div>
        <div class="form-group row">
          <div class="col">
            <table class="table table-striped">
              <thead>
              <tr>
                <th></th>
                <th>Assessment Level</th>
                <th>Sample</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let assessmentLevelReference of assessmentLevelReferences; trackBy: servicesRequestedSection.trackByAssessmentLevelReferenceId">
                <ng-container *ngIf="assessmentLevelDisplayed(assessmentLevelReference.assessmentLevelReferenceId, assessmentLevelReference.orderNumber)">
                <td>
                  <span class="tool-tip" container="body" [ngbTooltip]="assessmentLevelDisabled(assessmentLevelReference.assessmentLevelReferenceId) ?? assessmentLevelEnabledToolTip(assessmentLevelReference.assessmentLevelReferenceId)">
                    <input [attr.disabled]="assessmentLevelDisabled(assessmentLevelReference.assessmentLevelReferenceId) ? 'true' : null" 
                      psInput type="radio" 
                      [value]="assessmentLevelReference.assessmentLevelReferenceId" 
                      formControlName="assessmentLevelReference">
                  </span>
                </td>
                <td>{{assessmentLevelReference.name}}</td>
                <td>
                  <a *ngIf="assessmentLevelReference.hasSampleReport" [href]="getAssessmentLevelSampleReportUrl(assessmentLevelReference)"><span class="fa fa-cloud-download"></span></a>
                </td>
                </ng-container>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ps-input-validation>
      <div class="form-group row">
        <div class="col">
          <label class="form-control-label">Additional Services Requested - Additional Fees May Apply</label>
        </div>
      </div>
      <div class="form-group row">
        <div class="col">
          <table class="table table-striped">
            <thead>
            <tr>
              <th></th>
              <th>Additional Service</th>
              <th>Sample</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let control of servicesRequestedSection.additionalServiceReferencesArray.controls; let i = index" formArrayName="additionalServiceReferences">
              <ng-container *ngIf="!(isNewAdditionalService && additionalServiceReferences[i].additionalServiceReferenceId == 3)">
                <td>
                    <span class="tool-tip" container="body" [ngbTooltip]="additionalServiceToolTipText(additionalServiceReferences[i])">
                        <input type="checkbox" [value]="additionalServiceReferences[i].additionalServiceReferenceId" [formControlName]="i" [attr.disabled]="additionalServiceDisabled(additionalServiceReferences[i]) ? '' : null"  [id]="'checkbox-' + additionalServiceReferences[i].additionalServiceReferenceId">
                    </span>
                </td>
                <td>{{additionalServiceReferences[i].name}}</td>
                <td>
                  <a *ngIf="additionalServiceReferences[i].hasSampleReport" [href]="getAdditionalServiceSampleReportUrl(additionalServiceReferences[i])"><span class="fa fa-cloud-download"></span></a>
                </td>
              </ng-container>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="form-group row">
        <ps-input-validation>
          <label class="form-control-label" for="service-notes">Service Question/Comments</label>
          <input psInput type="text" class="form-control" id="service-notes" #serviceNotes formControlName="notes" maxlength="8000">
        </ps-input-validation>
      </div>
    </ps-section>

    <!-- Feedback -->
    <ps-section *ngIf="!assessmentRequest.hasParent || upgrade" [sectionId]="'feedback'" [sectionTitle]="'Recipient of Assessment Results (Verbal Debrief and Report Notification)'" [showInvalid]="feedbackSection.isInvalid">
      <div *ngIf="feedbackSection.isInvalid" class="text-danger">There must be at least one verbal recipient.</div>
      <div class="form-group row">
        <div class="col" *ngIf="!feedbackSection.hasFeedbackUsers">
          <i>No Feedback Recipients {{isLevelZeroRequest() ? null : '*'}}</i>
        </div>
        <div class="col">
          <div class="btn-group btn-group-sm float-right">
            <button type="button" class="btn btn-sm btn-primary" (click)="onClickAddExistingFeedbackRecipient()" [disabled]="!requestedByUser"><span class="fa fa-plus-circle"> Add Recipient</span></button>
            <!-- <button type="button" class="btn btn-sm btn-primary" (click)="onClickAddNewFeedbackRecipient()"><span class="fa fa-plus-circle"> Add New</span></button> -->
          </div>
        </div>
      </div>
      <div class="form-group row">
        <div class="col">
          <list-feedback-user *ngIf="feedbackSection.hasFeedbackUsers"
                              [feedbackUsers]="feedbackSection.feedbackUsersControl.value"
                              (onUpdatedFeedbackUsers)="feedbackSection.onUpdatedFeedbackUsers($event)"
                              [disableWritten]="assessmentRequest.isConfidential">
          </list-feedback-user>
        </div>
      </div>
    </ps-section>

    <!-- Testing Location -->
    <ps-section *ngIf="assessmentRequest.additionalService != 1 && !assessmentRequest.hasParent" [sectionId]="'location'" [sectionTitle]="'Testing Location'" formGroupName="location">
      <div class="form-group row">
        <div class="col">
          <ps-input-validation>
            <label class="form-control-label">Testing Location *</label>
            <div class="form-check">
              <label class="form-check-label">
                <input psInput class="form-check-input" type="radio" [value]="2" formControlName="testingLocation">
                Self-proctored online testing
              </label>
            </div>
            <div class="form-check">
              <label class="form-check-label">
                <input psInput class="form-check-input" type="radio" [value]="1" formControlName="testingLocation">
                My company will arrange proctored testing
              </label>
            </div>
          </ps-input-validation>
        </div>
        <div class="col">
          <ps-input-validation>
            <label class="form-control-label" style="padding-bottom: 5px;">Does Chapman & Co have permission to contact this candidate/employee for the purposes of the assessment? *</label>
            <div class="form-check">
              <label class="form-check-label">
                <input psInput class="form-check-input" type="radio" [value]="false" formControlName="permissionToContact"> No
              </label>
            </div>
            <div class="form-check">
              <label class="form-check-label">
                <input psInput class="form-check-input" type="radio" [value]="true" formControlName="permissionToContact"> Yes
              </label>
            </div>
          </ps-input-validation>
        </div>
      </div>
      <div class="form-group row">
        <ps-input-validation [columnSize]="4" *ngIf="needTestingInstructions">
          <label class="form-control-label" for="send-instructions">Send Instructions To Name *</label>
          <input psInput type="text" class="form-control" #sendInstructionsName id="send-instructions" formControlName="sendInstructionsName">
        </ps-input-validation>
        <ps-input-validation [columnSize]="4" *ngIf="needTestingInstructions">
          <label class="form-control-label" for="send-email">Send Instructions To Email *</label>
          <input psInput type="text" class="form-control" #sendInstructionsEmail id="send-email" formControlName="sendInstructionsEmail">
        </ps-input-validation>
        <div class="col-sm-4">
          <div class="form-group">
            <label class="form-control-label" for="candidate-zip-code">Candidate Zip Code</label>
            <input psInput type="text" class="form-control" #candidateZipCode id="candidate-zip-code" formControlName="candidateZipCode" maxlength="9">
          </div>
        </div>
      </div>
    </ps-section>

    <!-- Billing Contact -->
    <ps-section [sectionId]="'billing-contact'" [sectionTitle]="'Invoice Recipient'" formGroupName="billingContact" [showInvalid]="billingContactSection.isInvalid">
      <div class="form-group row">
        <ps-input-validation>
          <label class="form-control-label" for="billing-contact">Invoice Recipient *</label>
          <select psInput class="form-control" id="billing-contact" formControlName="billingContact">
            <option value="-1"></option> <!--    Part of a bug fix for IE11/Edge possible related to angular issue #10010 -->
            <option *ngFor="let billingContact of billingContactSection.billingContacts" [ngValue]="billingContact">{{billingContact.displayName}}</option>
          </select>
        </ps-input-validation>
        <div class="col-sm-4">
          <label class="form-control-label">&nbsp;</label>
          <button *ngIf="billingContact!=null&&billingContact!='-1'" type="button" class="btn btn-sm btn-secondary" (click)="onClickEditBillingContact()"><span class="fa fa-pencil"></span> Edit/Delete Recipient</button>
          <button type="button" class="btn btn-sm btn-primary" (click)="onClickAddBillingContact()" [disabled]="!requestedByUser"><span class="fa fa-plus-circle"></span> Add Invoice Recipient</button>
        </div>
      </div>
      <ng-container *ngIf="billingContact">
        <div class="row">
          <div class="col" *ngIf="billingContact.address">
            <label class="form-control-label">Address</label>
            <address class="static-text">
              <span *ngIf="billingContact.address.address1">{{billingContact.address.address1}}<br/></span>
              <span *ngIf="billingContact.address.address2">{{billingContact.address.address2}}<br/></span>
              <span *ngIf="billingContact.address.cityStateZip">{{billingContact.address.cityStateZip}}<br/></span>
              <span *ngIf="billingContact.address.country">{{billingContact.address.country}}</span>
            </address>
          </div>
          <div class="col">
            <div class="row" *ngIf="billingContact.email">
              <div class="col">
                <label class="form-control-label">Email</label>
                <div class="static-text">{{billingContact.email}}</div>
              </div>
            </div>
            <div class="row" *ngIf="billingContact.address?.phone">
              <div class="col">
                <label class="form-control-label">Phone</label>
                <div class="static-text">{{billingContact.address.phone}}</div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ps-section>

  </ng-container>

  <!-- Debug -->
  <ps-section [sectionId]="'debug'" [sectionTitle]="'Debug'" *ngIf="false">
    <div class="row">
      <div class="col">
        <pre><code>{{sortedFormValues | json}}</code></pre>
        <div>
          <strong>Touched:</strong> {{form.touched}}
        </div>
        <div>
          <strong>Dirty:</strong> {{form.dirty}}
        </div>
        <div>
          <strong>Valid:</strong> {{form.valid}}
        </div>
        <div>
          <strong>Add Service:</strong> {{assessmentRequest.additionalService}}
        </div>
      </div>
    </div>
  </ps-section>
</ps-sections-container>
