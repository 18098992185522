import {Component} from '@angular/core';
import {FilterBaseComponent} from './filter-base.component';
import {PsTableFilterService} from '../../services/table-filter.service';
import {DateFilter} from '../../models/date-filter';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ps-date-range-filter',
  templateUrl: './date-range-filter.component.html'
})
export class PsDateRangeFilterComponent extends FilterBaseComponent {
  public fromDate: NgbDateStruct;
  public toDate: NgbDateStruct;

  constructor(service: PsTableFilterService) {
    super(service);
  }

  public valueChanged(date: NgbDateStruct) {
  }

  public clearFrom() {
    this.fromDate = null;
  }

  public clearTo() {
    this.toDate = null;
  }

  public get displayFromDate() {
    return this.displayDate(this.fromDate);
  }

  public get displayToDate() {
    return this.displayDate(this.toDate);
  }

  private displayDate(date?: NgbDateStruct) {
    return date ? `${date.month}/${date.day}/${date.year}` : null;
  }

  protected clearPending(): void {
    this.fromDate = null;
    this.toDate = null;
  }

  protected setFilterQuery(): void {
    if (this.hasText) {
      const dateFilter = new DateFilter();
      dateFilter.from = this.fromDate ? new Date(this.displayDate(this.fromDate)) : null;
      dateFilter.to = this.toDate ? new Date(this.displayDate(this.toDate)) : null;
      const fromDisplay = this.fromDate ? this.displayDate(this.fromDate) : 'Not set';
      const toDisplay = this.toDate ? this.displayDate(this.toDate) : 'Not set';
      this.filterConfig.search = dateFilter;
      this.filterConfig.searchDisplay = `From: ${fromDisplay}, To: ${toDisplay}`;
    } else {
      this._filterService.removePendingFilter(this.columnName);
    }
    this.registerFilter();


  }

  private get hasText() {
    return (this.fromDate) || (this.toDate);
  }


}
