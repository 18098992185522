import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'bool'})
export class BoolPipe implements PipeTransform {

  transform(value: boolean, format: string): string {
    switch (format.toLowerCase()) {
      case 'on': {
        return value ? 'On' : 'Off';
      }
      case 'yn': {
        return value ? 'Yes' : 'No';
      }
      default:
      case 'tf': {
        return value ? 'True' : 'False';
      }
    }
  }
}

