import {PsDateRangeFilterComponent} from './components/filters/date-range-filter.component';
import {NgModule} from '@angular/core';
import {CellComponent} from './components/cell.component';
import {ViewCellComponent} from './components/view-cell.component';
import {QuickFilterComponent} from './components/quickfilter.component';
import {PagerComponent} from './components/pager.component';
import {TitleComponent} from './components/title.component';
import {PiquantTableComponent} from './piquant-table.component';
import {SharedModule} from '../shared/shared.module';
import {PsAdvancedFiltersComponent} from './components/filters/advanced-filter.component';
import {PsMultiSelectFilterComponent} from './components/filters/multi-select-filter.component';
import {PsTextFilterComponent} from './components/filters/text-filter.component';
import {PsTableFilterDirective} from './components/filters/filter-base.component';
export * from './components/filters/filter-base.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    CellComponent,
    ViewCellComponent,
    QuickFilterComponent,
    PagerComponent,
    TitleComponent,
    PiquantTableComponent,
    PsMultiSelectFilterComponent,
    PsTextFilterComponent,
    PsAdvancedFiltersComponent,
    PsDateRangeFilterComponent,
    PsTableFilterDirective
  ],
  exports: [
    PiquantTableComponent,
    PsMultiSelectFilterComponent,
    PsTextFilterComponent,
    PsAdvancedFiltersComponent,
    PsDateRangeFilterComponent,
    PsTableFilterDirective
  ]
})
export class PiquantTableModule {
}
