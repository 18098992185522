import {Component} from '@angular/core';
import {ISelectItemModel, IUserSelectItemModel} from '../../core/interfaces/select-item.interface';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../auth.service';
import {CacheService} from '../../core/cache.service';
import {UserService} from '../../core/user.service';
import {IUserAuthModel} from '../../core/interfaces/user.interface';

@Component({
  selector: 'app-impersonate-user',
  templateUrl: './impersonate-user.component.html',
  styleUrls: ['./impersonate-user.component.scss']
})
export class ImpersonateUserComponent {
  public clients: IClientUsersModel[];

  constructor(private route: ActivatedRoute,
              private router: Router,
              private authService: AuthService,
              private userService: UserService,
              private cacheService: CacheService) {
    this.route.data.subscribe(({clients, users}: { clients: ISelectItemModel[], users: IUserSelectItemModel[] }) => {
      this.clients = this.prepareClientUsers(clients, users);
    });
  }

  public get isImpersonating(): boolean {
    return !!this.authService.impersonatedUser;
  }

  public onClickUser(user: IUserSelectItemModel) {
    this.impersonate(user);
  }

  public onCancelImpersonationClick() {
    this.impersonate(null);
  }

  private impersonate(user: IUserSelectItemModel) {
    this.cacheService.clear();

    if (user) {
      this.userService.getUserAuth(user.id).subscribe((userAuth: IUserAuthModel) => {
        this.authService.impersonatedUser = Object.assign({}, userAuth, {displayName: user.name});
        this.router.navigate(['/']);
      });
    } else {
      this.authService.impersonatedUser = null;
      this.router.navigate(['/']);
    }

  }

  private prepareClientUsers(clients: ISelectItemModel[], users: IUserSelectItemModel[]): IClientUsersModel[] {
    return clients.filter((c) => users.some((u) => u.clientId === c.id))
      .map((c) => {
        const clientUsers = users.filter((u) => u.clientId === c.id);
        return Object.assign({}, c, {users: clientUsers})
      });
  }
}

interface IClientUsersModel extends ISelectItemModel {
  users: IUserSelectItemModel[];
}
