import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ServiceBase} from './service-base';
import {IOutOfOfficeModel} from './interfaces/out-of-office.interface';
import { HttpClient, HttpResponse} from '@angular/common/http';
@Injectable()
export class OutOfOfficeService extends ServiceBase {
  private ApiUrl = 'out-of-office';

  constructor(private http: HttpClient) {
    super();
  }

  public CheckOOO(): Observable<IOutOfOfficeModel>{
    return this.http.get<IOutOfOfficeModel>(this.ApiUrl + '/check');
  }

  public getData(): Observable<IOutOfOfficeModel[]> {
    return this.http.get<IOutOfOfficeModel[]>(this.ApiUrl);
  }

  public saveData(data: IOutOfOfficeModel): Observable<HttpResponse<Object>> {
    const json = JSON.stringify(data);
    return this.http.put<HttpResponse<Object>>(this.ApiUrl, json, {headers: this.jsonHeaders, observe: "response" });
  }
}
