import {Component, EventEmitter, Input, OnInit, Inject, Output} from '@angular/core';
import {IPositionLevelModel} from '../../core/interfaces/position-level.interface';
import {NgbActiveModal, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {IPositionListItemModel} from '../../core/interfaces/position-list-item.interface';
import {PositionService} from '../../core/position.service';
import {AttachmentService} from '../../core/attachment.service';
import {ICompetencyModel} from '../../core/interfaces/competency.interface';
import {Observable} from 'rxjs';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {UploadErrorDialogComponent} from '../../shared/upload-error-dialog/upload-error-dialog.component';
import {EditUserModel} from '../../users/edit-user.model';
import {UserService} from '../../core/user.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-manage-mfa-modal',
  templateUrl: './manage-mfa-modal.component.html',
  styleUrls: ['./manage-mfa-modal.component.scss']
})
export class ManageMfaModalComponent implements OnInit {
  public userData: EditUserModel;
  public mobileCountryCode = 1;
  public mobilePhone;

  constructor(public dialogRef: MatDialogRef<ManageMfaModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private attachmentService: AttachmentService,
    private userService: UserService,
    private route: ActivatedRoute) { 
      if(this.data.userData){
        this.userData = this.data.userData;
        this.userData.mfaType = 2;
      }
     }

  public ngOnInit() {

  }

  public onDone()
  {
    const profile = new EditUserModel(this.userData);
    this.userService.saveProfile(profile).subscribe(() => {
    });
    this.dialogRef.close();
  }
}
