import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {IFeedbackUserModel} from '../../core/interfaces/feedback-user.interface';
import {Utility} from '../../core/utility';
import {FeedbackUserModel} from '../../core/models/feedback-user.model';

@Component({
  selector: 'list-feedback-user',
  templateUrl: './list-feedback-user.component.html',
  styleUrls: ['./list-feedback-user.component.scss']
})
export class ListFeedbackUserComponent implements OnChanges {
  @Input() public feedbackUsers: IFeedbackUserModel[];
  @Input() public disableWritten: boolean;
  @Output() public onUpdatedFeedbackUsers = new EventEmitter<IFeedbackUserModel[]>();

  public allFeedbackUsers: FeedbackUserModel[];

  public ngOnChanges(changes: SimpleChanges): void {
    this.allFeedbackUsers = this.getCombinedFeedbackUsers();
  }

  public onChange(user: FeedbackUserModel) {
    if (!user) {
      return;
    }
    this.onUpdatedFeedbackUsers.emit(this.feedbackUsers);
  }

  public remove(user: FeedbackUserModel) {
    if (!user) {
      return;
    }

    this.feedbackUsers = this.feedbackUsers.filter((fu) => fu.userId !== user.feedbackUser.userId);
    this.onUpdatedFeedbackUsers.emit(this.feedbackUsers);
    this.allFeedbackUsers = this.getCombinedFeedbackUsers();
  }

  // map feedbackUsers into a single, sorted list
  private getCombinedFeedbackUsers(): FeedbackUserModel[] {
    let users: FeedbackUserModel[] = [];

    if (this.feedbackUsers) {
      users = users.concat(this.feedbackUsers.map((fu) => new FeedbackUserModel(fu)));
    }

    return users.sort(Utility.sortByName);
  }
}
