import {Component, Input} from '@angular/core';
import {ActionCanLoad} from '../models/actionCanLoad';

@Component({
  selector: 'action-group',
  template: `
    <div ngbDropdown>
      <button ngbDropdownToggle class="action-group-opener">
        {{title}}
      </button>
      <ul ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
        <ng-content select="action-button"></ng-content>
      </ul>
    </div>`
})
export class ActionGroupComponent {
  @Input() title: string;
  @Input() guard: ActionCanLoad;

  constructor() {
  }
}
