import {NgModule} from '@angular/core';
import {CompetencyNodeComponent} from './competency-node/competency-node.component';
import {SharedModule} from '../shared/shared.module';
import {CompetencyTreeComponent} from './competency-tree/competency-tree.component';
import {CompetencyHierarchyComponent} from './competency-tree/competency-hierarchy.component';
import {CompetencyListComponent} from './competency-list/competency-list.component';
import {EditCompetenciesComponent} from './edit-competencies/edit-competencies.component';
import {ListCompetencyComponent} from './list-competency/list-competency.component';
import {AuthGuard} from '../auth/auth.guard';
import {SecureLayoutComponent} from '../shared/secure-layout/secure-layout.component';
import {RouterModule, Routes} from '@angular/router';
import {CompetencySetResolver, CompetencySetsResolver} from '../core/resolvers.service';
import {AdminGuard} from '../core/admin.guard';
import {AddCompetencySetComponent} from './add-competency-set/add-competency-set.component';
import {PiquantTableModule} from '../piquant-table/piquant-table.module';
import {EditCompetencySetComponent} from './edit-competency-set/edit-competency-set.component';
import {AssignCompetenciesComponent} from './assign-competencies/assign-competencies.component';
import {CompetencyListItemComponent} from './edit-competency-set/competency-list-item.component';
import {DeleteCompetencyDialogComponent} from './edit-competency-set/delete-competency-dialog.component';
import {ConfirmEditDialogComponent} from './edit-competency-set/confirm-edit-dialog.component';
import {AgGridModule} from 'ag-grid-angular';
import {ViewButtonRendererComponent} from './view-button-renderer/view-button-renderer.component';

const routes: Routes = [
  {
    path: 'competencies', component: SecureLayoutComponent, canActivateChild: [AuthGuard, AdminGuard],
    children: [{
        path: '',
        component: ListCompetencyComponent,
        resolve: {
          competencySets: CompetencySetsResolver
        }
      }, {
        path: 'add-competency-set',
        component: EditCompetencySetComponent
      }, {
      path: 'set/:id',
      component: EditCompetencySetComponent,
      resolve: {
        competencySet: CompetencySetResolver
      }
    }]
  }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        SharedModule,
        PiquantTableModule,
        AgGridModule.withComponents([ViewButtonRendererComponent]) //  EditButtonRendererComponent .withComponents([SquareComponent, CubeComponent]),
    ],
    exports: [
        CompetencyTreeComponent,
        CompetencyHierarchyComponent,
        CompetencyListComponent,
        EditCompetenciesComponent,
        EditCompetencySetComponent,
        AssignCompetenciesComponent,
        CompetencyListItemComponent,
        DeleteCompetencyDialogComponent,
        ConfirmEditDialogComponent
    ],
    declarations: [
        CompetencyNodeComponent,
        CompetencyTreeComponent,
        CompetencyHierarchyComponent,
        CompetencyListComponent,
        EditCompetenciesComponent,
        ListCompetencyComponent,
        AddCompetencySetComponent,
        EditCompetencySetComponent,
        AssignCompetenciesComponent,
        CompetencyListItemComponent,
        DeleteCompetencyDialogComponent,
        ConfirmEditDialogComponent,
        ViewButtonRendererComponent
    ]
})
export class CompetenciesModule {
}
