
import {defer as observableDefer, Observable} from 'rxjs';
import {map, publishReplay, refCount, take} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ISelectItemModel} from './interfaces/select-item.interface';
import {ServiceBase} from './service-base';
import {AuthHttpService} from '../auth/auth-http.service';
import {HttpClient} from '@angular/common/http';



@Injectable()
export class EmailTemplateService extends ServiceBase {
  private static ApiUrl = 'api/email-template';
  private data$: Observable<ISelectItemModel[]>;
  private cacheDuration: number = 1000 * 60 * 60;

  constructor(private http: HttpClient) {
    super();
  }

  // this will only return assessment templates
  public getSelectItems(): Observable<ISelectItemModel[]> {
    if (!this.data$) {
      const url = EmailTemplateService.ApiUrl + '/list/assessment-templates';

      this.data$ = observableDefer(() => this.http.get(url)).pipe(
        map((res: Response) => (res || []) as ISelectItemModel[]),
        publishReplay(1, this.cacheDuration),
        refCount(),
        take(1)
      );
    }

    return this.data$;
  }
}
