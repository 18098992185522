<div *ngIf="invalid">
  <p>
  <h1>Unable to Reset Your Password</h1>
  <p>
  <p class="lead">Sorry, but this link is no longer valid. <br/>Please <a [routerLink]="['/login']">Login</a> or <a [routerLink]="['/forgot-password']">Reset your password?</a></p>
</div>
<div *ngIf="!invalid && !resetSuccess">
  <form [formGroup]="changePasswordForm" (submit)="submitReset()">
    <div class="form-group row">
      <div class="col">
        <label class="form-control-label">Username</label>
        <input psInput #username class="form-control" id="username" type="text" formControlName="username" />
      </div>
    </div>
    <div class="form-group row">
      <ps-input-validation [columnSize]="12">
        <ng-template #tipContent>
          <div>Must be at least 6 characters.</div>
          <div>Must have 1 letter.</div>
          <div>Must have 1 number</div>
          <div>Must have 1 of these special characters !@#$%^&*_-=</div>
        </ng-template>
        <label class="form-control-label">New Password
          <i class="fa fa-question-circle" placement="right" [ngbTooltip]="tipContent"></i></label>
        <input psInput #newPassword class="form-control" id="newPassword" type="password" (keyup)="newPasswordChange()" formControlName="newPassword"/>
      </ps-input-validation>
    </div>
    <div class="form-group row">
      <ps-input-validation [columnSize]="12">
        <label class="form-control-label">Confirm New Password </label>
        <input psInput #confirmPassword class="form-control" id="confirmPassword" type="password" formControlName="confirmPassword"/>
      </ps-input-validation>
    </div>
    <button type="submit" class="btn btn-primary" [disabled]="changePasswordForm.invalid">Submit</button>
  </form>
</div>
<div *ngIf="resetFailed">
  <h3>Your password reset failed to complete. {{errorMessage}}</h3>
  <p>
  <p>Additional Information: This could be due to an invalid username (which may or may not be your email address) or the link has expired. <br/><br/> You can request a new link <a [routerLink]="['/forgot-password']">here.</a></p>
</div>
<div *ngIf="failedComplexity">
  <h3>Reset Failed</h3>
  <p>
  <p class="lead">Your password does not meet our complexity requirements.</p>
    <br>Must be at least 6 characters.
    <br>Must have 1 letter.
    <br>Must have 1 number
    <br>Must have 1 of these special characters !@#$%^&*_-=
</div>
<div *ngIf="resetSuccess">
  <h3>Your password has been successfully updated.</h3>
  <p>
  <p class="lead">Please login to the portal with your new password <a [routerLink]="['/login']">here.</a></p>
</div>
