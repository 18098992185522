export interface IAssessmentRequestListItemModel {
  isConfidential: boolean;
  assessmentRequestId: number;
  resumeAttachmentId: number;
  jobDescriptionAttachmentId: number;
  parentAssessmentRequestId: number;
  hasCompletedAdditionalService: boolean;
  hasAnyAdditionalService: boolean;
  isUpgradeService: boolean;
  statusId: number;
  candidateId: string;
  candidateName: string;
  clientId: string;
  clientName: string;
  orgUnitName: string;
  requestedByName: string;
  consultantName: string;
  position: string;
  createdDate: Date;
  submittedDate: Date;
  statusName: string;
  consultantAssigned: boolean;
  reportsUploaded: number;
  hiringOutcome: number;
  lastStatusChangeDate: Date;
  requestedByUserId: number;
  candidateType: string;
  assessmentPurposeId: number;
  assessmentLevelReferenceId: number;
}

export class ARQuickAdditionalServiceModel {
  public assessmentRequestId: number;
  public candidateName: string;
  public orgUnitName: string;
  public position: string;
  public createService: boolean;
  public updatedPhone: string;
  public updatedEMail: string;
  public writtenFeedback: boolean;
  public verbalFeedback: boolean;

  constructor(obj: IAssessmentRequestListItemModel){
    this.assessmentRequestId = obj.assessmentRequestId;
    this.candidateName = obj.candidateName;
    this.orgUnitName = obj.orgUnitName;
    this.position = obj.position;
  }
}

export class AssessmentRequestListItemModel implements IAssessmentRequestListItemModel {
  public isConfidential: boolean;
  public assessmentRequestId: number;
  public resumeAttachmentId: number;
  public jobDescriptionAttachmentId: number;
  public parentAssessmentRequestId: number;
  public hasCompletedAdditionalService: boolean;
  public hasAnyAdditionalService: boolean;
  public isUpgradeService: boolean;
  public statusId: number;
  public candidateId: string;
  public candidateName: string;
  public clientId: string;
  public clientName: string;
  public orgUnitName: string;
  public requestedByName: string;
  public consultantName: string;
  public position: string;
  public createdDate: Date;
  public submittedDate: Date;
  public statusName: string;
  public consultantAssigned: boolean;
  public reportsUploaded: number;
  public hiringOutcome: number;
  public lastStatusChangeDate: Date;
  public requestedByUserId: number;
  public candidateType: string;
  public assessmentPurposeId: number;
  public assessmentLevelReferenceId: number;
  constructor(obj: IAssessmentRequestListItemModel) {
    if (!obj) {
      return;
    }
    this.isConfidential = obj.isConfidential;
    this.assessmentRequestId = obj.assessmentRequestId;
    this.resumeAttachmentId = obj.resumeAttachmentId;
    this.jobDescriptionAttachmentId = obj.jobDescriptionAttachmentId;
    this.parentAssessmentRequestId = obj.parentAssessmentRequestId;
    this.hasCompletedAdditionalService = obj.hasCompletedAdditionalService;
    this.hasAnyAdditionalService = obj.hasAnyAdditionalService;
    this.isUpgradeService = obj.isUpgradeService;
    this.statusId = obj.statusId;
    this.candidateId = obj.candidateId;
    this.candidateName = obj.candidateName;
    this.clientId = obj.clientId;
    this.clientName = obj.clientName;
    this.orgUnitName = obj.orgUnitName;
    this.requestedByName = obj.requestedByName;
    this.consultantName = obj.consultantName;
    this.position = obj.position;
    this.createdDate = new Date(obj.createdDate);
    this.submittedDate = new Date((obj.submittedDate)?obj.submittedDate:obj.createdDate);
    this.statusName = obj.statusName;
    this.consultantAssigned = obj.consultantAssigned;
    this.reportsUploaded = obj.reportsUploaded;
    this.hiringOutcome = obj.hiringOutcome;
    this.requestedByUserId = obj.requestedByUserId;
    this.candidateType = obj.candidateType;
    this.assessmentPurposeId = obj.assessmentPurposeId;
    this.assessmentLevelReferenceId = obj.assessmentLevelReferenceId;
    this.lastStatusChangeDate = new Date(obj.lastStatusChangeDate);
    //Legacy data does not have a statusChangeDate - use createdDate instead.
    if (this.lastStatusChangeDate.getFullYear()==1)
      this.lastStatusChangeDate = this.createdDate;

  }

}
